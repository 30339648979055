import '../styles.css'

import { Icon } from '@ant-design/compatible'
import { InputNumber, Row, Switch, Tooltip } from 'antd'
import { Button, IntlMessages, Text, Title } from 'components'
import React, { Component } from 'react'
import styled from 'styled-components'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'

import DeliveryChargeDiscountModal from './deliveryChargeDiscountModal'


const InfoDeliveryDiscountStyled = styled.div`
    background: #fff4d4;
    border-radius: 8px;
    padding: 4px;
  `
const DetailsContainerStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 71%;
  `

interface DeliveryDiscountProps {
  getDeliveryDiscount: any
  deliveryDiscount: any
  deliveryDiscountStatus: any
  intl: any
}
interface DeliveryDiscountState {
  isVisible: boolean
  isChecked: boolean
}
class DeliveryDiscount extends Component<DeliveryDiscountProps, DeliveryDiscountState> {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
      isChecked: true
    }
  }

  static getDerivedStateFromProps(nextProps: DeliveryDiscountProps, prevState: DeliveryDiscountState) {
    const nextState = {} as DeliveryDiscountState
    const { deliveryDiscountStatus } = nextProps
    const isChecked = deliveryDiscountStatus === 'ACTIVE'

    if (prevState.isChecked !== isChecked) {
      nextState.isChecked = isChecked
    }

    return nextState
  }

  render() {
    const { isVisible, isChecked } = this.state
    const { deliveryDiscount } = this.props

    return (
      <>
        <Row style={{ marginTop: '24px' }}>
          <div
            style={{
              width: '28%',
              paddingLeft: '16px',
              display: 'flex',
              alignItems: 'center'
            }}>
            <div>
              <Title level="h5" style={{ marginBottom: '0' }}>
                <IntlMessages id="store.deliveryDiscountTitle" />
              </Title>
              <Button
                type="link"
                style={{
                  color: COLORS.PRIMARY,
                  marginLeft: '-8px',
                  marginBottom: 0,
                  marginTop: '-10px'
                }}
                onClick={() => {
                  this.setState({ isVisible: true })
                }}>
                <IntlMessages id="knowMore" />
              </Button>
            </div>
          </div>
          <DetailsContainerStyled>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <Switch
                checked={isChecked}
                onChange={status => {
                  this.setState({ isChecked: status })
                  this.props.getDeliveryDiscount(deliveryDiscount, status)
                }}
              />
              <Text level="body-2" style={{ marginLeft: '10px' }}>
                <IntlMessages id="store.upTo" />
              </Text>
              <InputNumber
                min={3}
                max={90}
                value={deliveryDiscount}
                disabled={!isChecked}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                onChange={val => {
                  this.props.getDeliveryDiscount(val, isChecked)
                }}
                style={{ marginLeft: '10px' }}
              />
              <Text level="body-2" style={{ marginLeft: '10px' }}>
                <IntlMessages id="store.onTotalBill" />
              </Text>
            </div>
            <InfoDeliveryDiscountStyled>
              <Tooltip
                title={intlAlertMessage({
                  id: 'store.deliveryDiscountToolTipInfo',
                  intl: this.props.intl
                })}>
                <Icon
                  style={{ color: COLORS.SECONDARY, marginRight: '4px' }}
                  type="info-circle"
                />
              </Tooltip>
              <Text level="caption" style={{ color: COLORS.SECONDARY }}>
                <IntlMessages id="store.deliveryDiscountInfo" />
              </Text>
            </InfoDeliveryDiscountStyled>
          </DetailsContainerStyled>
          {isVisible && <DeliveryChargeDiscountModal intl={this.props.intl} isVisible={isVisible} onCancel={() => this.setState({ isVisible: false })} />}
        </Row>
      </>
    )
  }
}
export default DeliveryDiscount
