import { Icon } from '@ant-design/compatible'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { graphql } from '@apollo/client/react/hoc'
import { Form, Input } from 'antd'
import { FormInstance } from 'antd/es/form'
import { IMAGES } from 'assets'
import { Button, IntlMessages, Switch } from 'components'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import { createRef } from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { getOrgDetails, getUserDetails, getViewType, intlAlertMessage, isAdmin } from 'Utils'
import { COLORS, EMAIL_REGEX, ORG_STATUS, VIEW_TYPES } from 'Utils/constants'

import { SIGN_IN_CLIENT } from '../../services/query'
import * as serviceWorker from '../../serviceWorker'

interface IProps {
  history?: History
  routeQuery?: any
  intl: any
}

interface IState { 
  isRememberMeChecked?: boolean
}

class NormalLoginForm extends React.Component<IProps, IState> {
  formRef: any

  constructor(props: IProps) {
    super(props)
    this.goToNextPage()
    this.formRef = createRef<FormInstance>()
    this.state = {
      isRememberMeChecked: true
    }
  }

  goToNextPage = () => {
    if (!localStorage.getItem('jwt')) return
    const { history } = this.props
    let redirectRoute = '/orderx/orders'
    const usrDetails = getUserDetails()
    const orgDetails = getOrgDetails()
    const viewType = getViewType()
    const isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY
    const onboardingStatus = orgDetails && orgDetails.onboardingStatus

    if (isBMSDealsOnlyView) {
      redirectRoute = '/orderx/reports'
      history.push(redirectRoute)

      return
    }

    if (isAdmin()) {
      if (!orgDetails) {
        redirectRoute = '/'
        localStorage.clear()
        serviceWorker.unregister()
        history.push(redirectRoute)

        return
      }
      if (usrDetails.resetPassword) {
        redirectRoute = '/changePassword'
        history.replace(redirectRoute)

        return
      }
      if (onboardingStatus && onboardingStatus === ORG_STATUS.INITIATED) {
        redirectRoute = '/orderx/onboarding'
      }
    }
    history.push(redirectRoute)
  }

  async handleSubmit(values, signIn) {
    const { email, password } = values.target
    const isValidEmail = EMAIL_REGEX.test(email.value)

    const signinInput = {
      password: password.value
    }

    isValidEmail ? signinInput['email']= email.value : signinInput['userName']= email.value

    const data = await signIn({
      variables: {
        input: signinInput
      }
    })

    if (data && data.data && data.data.signIn) {
      this.goToNextPage()
    } else {
      console.log('Login Failed')
    }
  }

  render() {
    const { isRememberMeChecked } = this.state
    
    return (
      <div style={{ background: COLORS.PRIMARY }} className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={IMAGES.login} alt="Delivery_Boy" />
                {/* https://via.placeholder.com/272x395 */}
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>
            <div className="gx-app-login-content">
              <div className="gx-app-logo">
                <img
                  alt="example"
                  src={IMAGES.peppo_logo_transparent}
                  style={{
                    width: 100,
                    marginLeft: '-21px',
                    marginBottom: '10px'
                  }}
                />
              </div>
              <Mutation mutation={SIGN_IN_CLIENT} fetchPolicy="no-cache">
                {(signIn, { loading, error, data }: any) => (
                  <Form
                    // OnSubmit is throwing error. so using onSubmitCapture
                    onSubmitCapture={e => this.handleSubmit(e, signIn)}
                    className="login-form">
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="signIn.typeUsername" />
                        }
                      ]}>
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: COLORS.DARK_TEXT }}
                          />
                        }
                        placeholder={intlAlertMessage({
                          id: 'usernameOrEmail',
                          intl: this.props.intl
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="signIn.typePassword" />
                        }
                      ]}>
                      <Input.Password
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: COLORS.DARK_TEXT }}
                          />
                        }
                        placeholder={intlAlertMessage({
                          id: 'appModule.password',
                          intl: this.props.intl
                        })}
                      />
                    </Form.Item>
                    <Form.Item name="remember">
                      <Switch variant='toggle1' checked={isRememberMeChecked} onClick={() => this.setState({ isRememberMeChecked: !isRememberMeChecked })} />
                      <span style={{ paddingLeft: 5 }}>
                        <IntlMessages id="rememberMe" />
                      </span>
                      <Link className="link-text" to="/forgotpassword">
                        <IntlMessages id="app.userAuth.forgotPassword" />?
                      </Link>
                      <br />
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                          style={{
                            marginTop: 10,
                            width: '200px',
                          }}
                          >
                          <IntlMessages id="login" />
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                )}
              </Mutation>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const GET_REDIRECT_ROUTE = gql`
  query redirectRoute {
    redirectRoute @client
  }
`

export default injectIntl(
  compose(
    graphql(GET_REDIRECT_ROUTE, {
      name: 'routeQuery'
    })
  )(NormalLoginForm)
)
