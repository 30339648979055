import { Icon } from '@ant-design/compatible'
import { Col, Row, Switch } from 'antd'
import { Tag } from 'components'
import React from 'react'
import { intlAlertMessage } from 'Utils'
import { COLORS, CONSOLE_MENU_CATEGORY_STATUS, PRODUCT_TYPE_ENUM } from 'Utils/constants'

import DragIcon from './DragIcon'

const CategoryRow = props => {
  return (
    <Row
      key={props.category.id}
      align="middle"
      className={
        props.elem === props.rowIndex
          ? 'category-table-body selected-item'
          : 'category-table-body'
      }>
      <Col
        span={props.isAdmin ? 18 : 20}
        className={'no-pd-right'}
        onClick={() => {
          props.showCategoryProduct(props.category)
        }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: COLORS.NORMAL_TEXT
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <div style={{ width: '35px' }}>
            <span className="rightIcon">
              <DragIcon className="dragIcon" />
            </span>
          </div>
          <span>{props.category.name}</span>
        </div>
        {(props.category.productType === PRODUCT_TYPE_ENUM.ADDON || props.category.productType === PRODUCT_TYPE_ENUM.COMBO) && <Tag label={intlAlertMessage({ id: 'addonsCombos', intl: props.intl })} style={{ minWidth: '82px', marginRight: '5px' }}></Tag>}
      </Col>
      {props.isAdmin && (
        <Col span={6} className={'no-pd-right no-pd-left'}>
          <Switch
            checkedChildren={CONSOLE_MENU_CATEGORY_STATUS.ACTIVE}
            unCheckedChildren={CONSOLE_MENU_CATEGORY_STATUS.INACTIVE}
            checked={props.category.listable}
            onChange={() => props.onSwitchChange()}
          />
        </Col>
      )}
      {!props.isAdmin && (
        <Col span={4} className={'no-pd-right no-pd-left text-align-center'}>
          <div
            onClick={() => {
              props.showCategoryProduct(props.category)
            }}>
            <span className="rightIcon">
              <Icon type="right" />
            </span>
          </div>
        </Col>
      )}
    </Row>
  )
}

export default CategoryRow
