

import { ApolloClient, gql } from '@apollo/client'
import { message } from 'antd'
import { ClientManager } from 'appApollo/client'
import { decode } from 'jsonwebtoken'
import { getAllChannels, getFileSystems, getStoreFormat } from 'services'
import { getPMSOrganizationDetails } from 'services/pmsServices'
import { getUserData,SignInUser } from 'services/userService'
import { isAdmin, isManager } from 'Utils'
import { FILE_SYSTEM_TYPES, ORG_STATUS } from 'Utils/constants'
import { setChannels, setFileSystemId, setStoreFormats } from 'Utils/localStorageHandlers'

const toggleCollapsedSideNav = async (_, { navCollapsed }, { client }) => {
  const data = await client.writeQuery({
    query: gql`
        query navCollapsed {
          settings {
            navCollapsed
          }
        }
      `,
    data: {
      settings: {
        navCollapsed,
        __typename: 'settings'
      }
    }
  })

  return data
}
const updateWindowWidth = (_, { width }, { client }) => {
  return client.writeQuery({
    query: gql`
        query navCollapsed {
          settings {
            width
          }
        }
      `,
    data: {
      settings: {
        width,
        __typename: 'settings'
      }
    }
  })
}
const setThemeType = (_, { themeType }, { client }) => {
  return client.writeQuery({
    query: gql`
        query navCollapsed {
          settings {
            themeType
          }
        }
      `,
    data: {
      settings: {
        themeType,
        __typename: 'settings'
      }
    }
  })
}
const setThemeColorSelection = (_, { colorSelection }, { client }) => {
  return client.writeQuery({
    query: gql`
        query navCollapsed {
          settings {
            colorSelection
          }
        }
      `,
    data: {
      settings: {
        colorSelection,
        __typename: 'settings'
      }
    }
  })
}
const onNavStyleChange = (_, { navStyle }, { client }) => {
  return client.writeQuery({
    query: gql`
        query navCollapsed {
          settings {
            navStyle
          }
        }
      `,
    data: {
      settings: {
        navStyle,
        __typename: 'settings'
      }
    }
  })
}
const onLayoutTypeChange = (_, { layoutType }, { client }) => {
  return client.writeQuery({
    query: gql`
        query navCollapsed {
          settings {
            layoutType
          }
        }
      `,
    data: {
      settings: {
        layoutType,
        __typename: 'settings'
      }
    }
  })
}
const switchLanguage = (_, input, { client }) => {
  const inputLocale = input.locale
  const { icon, languageId, locale, name } = inputLocale

  return client.writeQuery({
    query: gql`
        query navCollapsed {
          settings {
            locale {
              icon
              languageId
              locale
              name
            }
          }
        }
      `,
    data: {
      settings: {
        locale: {
          icon,
          languageId,
          locale,
          name,
          __typename: 'locale'
        },
        __typename: 'settings'
      }
    }
  })
}


async function getFileSystemId(client, org_id) {
  const fileSystemIdRes = await getFileSystems(client, org_id)

  fileSystemIdRes?.data?.fileSystems?.data.forEach(element => {
    if (element.fileSystemType === FILE_SYSTEM_TYPES.CLOUDINARY && element.status === ORG_STATUS.ACTIVE) {
      setFileSystemId(element.id)
    }
  })

}

async function getChannels(client) {
  try {
    const getAllChannelsResponse = await getAllChannels(client)
    const channels = getAllChannelsResponse.data.channels.data

    setChannels(channels)
  }
  catch (err) {
    console.error('Get Channels error', err)
  }
}

async function getStoreFormats(client) {
  try {
    const response = await getStoreFormat(client)
    const { data } = response.data.storeFormats

    setStoreFormats(data)
  } catch (err) {
    console.error('Get Store formats error', err)
  }
}

const signIn = async (_, { input }, { client }) => {
  let pmsClient: ApolloClient<any>

  try {
    const data = await SignInUser(client, input)

    if (!data || !data.data || !data.data.login || !data.data.login.jwt) {
      return false
    }
    const { jwt } = data.data.login
    const { id, org_id, external_org_id } = decode(jwt) as any
    const query = gql`
          query auth {
            auth {
              organizationId
              userId
            }
          }
        `
    const writeData = {
      auth: {
        __typename: 'auth',
        organizationId: org_id,
        userId: id
      }
    }

    client.writeQuery({
      query,
      data: writeData
    })

    localStorage.setItem('jwt', jwt)
    pmsClient = ClientManager.getInstance().pms

    const [orgResponse, response] = await Promise.all([getPMSOrganizationDetails(pmsClient, external_org_id), getUserData(client, { id, organizationId: org_id })])

    if (!orgResponse || !orgResponse.data || !orgResponse.data.organization) {
      localStorage.clear()
      sessionStorage.clear()
      message.error('Organization details not found')

      return false
    }

    if (!response || !response.data || !response.data.user) {
      localStorage.clear()
      sessionStorage.clear()
      message.error('User details not found')

      return false
    }


    
    /*
     * Response.data.user.resetPassword = true;
     * response.data.user.store = [];
     * response.data.user.organization.bankAccount = [];
     */

    localStorage.setItem('organization', JSON.stringify(orgResponse.data.organization))
    localStorage.setItem('userData', JSON.stringify(response.data.user))

    if (isAdmin()) {
      getFileSystemId(client, org_id)
    }
    getChannels(client)
    getStoreFormats(client)

    return true
  } catch (error) {
    console.log('Login Error', error)

    return false
  }
}
/*
 * Const hideMessage = (_, input, { client }) => {}
 * const showAuthLoader = (_, input, { client }) => {}
 */
const setRedirectRoute = async (_, { route }, { client }) => {
  await client.writeQuery({
    query: gql`
        query redirectRoute {
          redirectRoute
        }
      `,
    data: {
      redirectRoute: route
    }
  })

  return route
}
const resolvers = {
  Mutation: {
    toggleCollapsedSideNav,
    updateWindowWidth,
    setThemeType,
    setThemeColorSelection,
    onNavStyleChange,
    onLayoutTypeChange,
    switchLanguage,
    signIn,
    setRedirectRoute
  }
}

export default resolvers
