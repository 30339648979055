import { Icon } from '@ant-design/compatible'
import { Form, Input } from 'antd'
import { FormInstance } from 'antd/es/form'
import { Button, IntlMessages } from 'components'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import { intlAlertMessage } from 'Utils'
import { ADMIN_PASSWORD_REGEX, COLORS } from 'Utils/constants'

interface IProps {
  onSubmit: any
  intl: any
  loading: boolean
  submitButtonText?: string
  submitButtonClassName?: string
}

interface IState {
  confirmDirty: boolean
}

class ChangePasswordForm extends React.Component<IProps, IState> {
  formRef: any

  constructor(props) {
    super(props)
    this.state = {
      confirmDirty: false
    }
    this.formRef = React.createRef<FormInstance>()
  }

  handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target

    this.setState({ confirmDirty: this.state.confirmDirty || Boolean(value) })
  }

  compareToFirstPassword = (_, value: any) => {

    if (value && value !== this.formRef.current.getFieldValue('password')) {
      return Promise.reject(new Error(intlAlertMessage({ id: 'updatepassword.errMsg.newPass&confirmPassShouldBeSame', intl: this.props.intl })))
    }

    return Promise.resolve()
  }

  validateToNextPassword = (_, value: any) => {

    if (value && !ADMIN_PASSWORD_REGEX.test(String(value))) {
      return Promise.reject(
        new Error(intlAlertMessage({
          id: 'validationForPassword',
          intl: this.props.intl
        }))
      )
    } else if (value && this.formRef.current.getFieldValue('currentPassword') && value === this.formRef.current.getFieldValue('currentPassword')) {
      return Promise.reject(new Error(intlAlertMessage({ id: 'userInfo.errMsg.currentAndNewPasswordCannotBeSame', intl: this.props.intl })))
    }

    return Promise.resolve()
  }

  onFinishFailed({ values, errorFields, outOfDate }) {
    console.log({ values, errorFields, outOfDate })
  }

  render() {
    const { loading, submitButtonText } = this.props

    return (
      <Form
        ref={this.formRef}
        onFinish={values => this.props.onSubmit(values)}
        className="login-form">
        <Form.Item
          key="current-pwd"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: intlAlertMessage({
                id: 'enterCurrentPassword',
                intl: this.props.intl
              })
            }
          ]}
          hasFeedback>
          <Input.Password
            visibilityToggle={false}
            prefix={<Icon type="lock" style={{ color: COLORS.DARK_TEXT }} />}
            placeholder={intlAlertMessage({
              id: 'currentpassword',
              intl: this.props.intl
            })}
          />
        </Form.Item>
        <Form.Item
          key="pwd"
          name="password"
          rules={[
            {
              required: true,
              message: (
                <IntlMessages id="pleaseInputYourPassword" key="pass-error" />
              )
            },
            {
              validator: this.validateToNextPassword
            }
          ]}
          hasFeedback>
          <Input.Password
            visibilityToggle={false}
            prefix={<Icon type="lock" style={{ color: COLORS.DARK_TEXT }} />}
            placeholder={intlAlertMessage({
              id: 'newPassword',
              intl: this.props.intl
            })}
          />
        </Form.Item>
        <Form.Item
          key="confirm-pwd"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: (
                <IntlMessages
                  id="pleaseConfirmYourPassword"
                  key="confirm-pass-error"
                />
              )
            },
            {
              validator: this.compareToFirstPassword
            }
          ]}
          hasFeedback>
          <Input.Password
            prefix={<Icon type="lock" style={{ color: COLORS.DARK_TEXT }} />}
            onBlur={this.handleConfirmBlur}
            placeholder={intlAlertMessage({
              id: 'confirmPassword',
              intl: this.props.intl
            })}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{
              marginTop: 0,
              marginBottom: 0,
              width: 200
            }}>
            <IntlMessages id={submitButtonText || 'menu.submit'} />
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default ChangePasswordForm
