import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Button, Col, List, message, Modal, Row, Select } from 'antd'
import { IntlMessages } from 'components'
import { History } from 'history'
import * as jwt from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  arrayMove,
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc'
import {
  addCharges,
  addMenuTimingsForProduct,
  addProductTags,
  addTaxes,
  createChargeForProduct,
  createDiscountForProducts,
  createPriceForProducts,
  createProduct,
  createProductDiscountValue,
  createProductPrice,
  createProductRelationships,
  getAllProductCategory,
  getChargeValues,
  getDiscountValues,
  getPriceValues,
  getProductsByCategory,
  getTags,
  removeMenuTimingsForProduct,
  removeProductCharge,
  removeProductRelationships,
  removeProductTags,
  republishCatalog,
  updateCategorySortSeq,
  updateChargeForProducts,
  updateDiscountForProducts,
  updateMenuTimingsForProduct,
  updatePriceForProducts,
  updateProduct,
  updateProductCharge,
  updateProductPrice,
  updateProductRelationships,
  updateProductSortSeq
} from 'services'
import {
  compareArrayValues,
  getStrikeoutDiscountType,
  intlAlertMessage,
  randomString,
  setInfoMessage
} from 'Utils'
import {
  ACTIONS,
  DISCOUNT_VALUE_TYPE_ENUM,
  ORG_STATUS,
  PRODUCT_TAG_ENUM,
  PRODUCT_TYPE_ENUM,
  PRODUCT_VARIANT_TYPE_ENUM,
} from 'Utils/constants'
import { addProductRelationships, addProductVariantsTaxAndCharges, fetchAndCreateMustTryTag, getAddonsGroupWithSelection, getCustomisationGroupsWithSelections, getExtendFromSelectedTag, getMustTryTagCount, getNestedCustomizationGroups, getOldProductVariantPrices, getProductsToCreate, getProductsToUpdate, getProductsWithUpdatedPropertyId, getProductVariant, getRemovedProductRelationShipsInputForAddon, getRemovedProductRelationShipsInputForVariant, getSelectedAddonsGroups, getSelectedCutomisationGroups, getSelectedOptionIds, getSelectedTag, getSelectedTagWithName, getUpdateCategorySortSeqInput, getUpdateProductSortSeqInput, isMustTryEnabled, onValidateFields, prepareChargeValueCreateInput, prepareChargeValueUpdateInput, prepareDiscountValueCreateInput, prepareDiscountValueUpdateInput, prepareFormatChannelPrices, prepareFormatsChannels, prepareOriginalPrices, preparePriceValueCreateInput, preparePriceValueUpdateInput, updateFormatsChannels, updateOriginalPricesWithDiscountValue, updateOriginalPricesWithPackagingPrice, updateOriginalPricesWithProductPrice, updateProductIdWithOptionValuesId, updateProductRelationShips, updateProductVariantPricesWithId, updateProductVariantsTag } from 'Utils/menuUtils'
import { setSuccessMessage } from 'Utils/OrderUtils'

import HeaderText from '../../../styles/Typography/Headers'
import DragIcon from '../components/DragIcon'
import { ProductItem } from '../components/ProductItem'
import ProductForm from './productForm'

const { Option } = Select

interface IProductsProps extends ApolloProviderProps<any> {
  history: History
  channels: any
  storeFormats: any
  categories: any
  packagingChargeType: any
  selectedProductId: string
  onFormDataChange: any
  onTargetLocationChange: any
  intl: any
  categoriesWithProducts: any
  priceData: any
  options: any[]
  onTabChange: any
  onAddonSeqChange: any
}

interface IProductsState {
  products: any
  selectedCategory: Object
  isFetchingProducts: boolean
  isLoadingForm: boolean
  isUpdatingForm: boolean
  categories: any
  productFormDetails: any
  selectedProductIndex: number
  tags: any
  isOrder: boolean
  isOrderChanged: boolean
  isOrderUpdating: boolean
  showConfirmModal: boolean
  dealPrice: any
  productDiscountValueId: any
  discountValue: any
  strikeOutDiscountValueData: any
  mustTryTag: object
  currentMustTryTagCount: number
}

class Products extends Component<IProductsProps, IProductsState> {
  tags: []

  org_id: string | { [key: string]: any }

  productFormDetails: any

  isFormDataChange: boolean

  unblock: any

  selectedCategoryId: any

  currentProductId: number

  productIndexToSelect: any

  selectedAddonGroups: any

  selectedOptions: any

  productRelationShips: any

  productOptions: any[]

  formatChannelPrices: any[]

  productVariants: any[]

  productVariantPrices: any[]

  updatedProductVariantPrices: any[]

  strikeOutDiscountType: any

  newProductVariantPrices: any[]

  addonGroups: any[]

  constructor(props) {
    super(props)
    const formatsChannels = prepareFormatsChannels(props)

    this.productFormDetails = {
      id: null,
      code: '',
      imageUrl: '',
      name: 'New Item',
      description: '',
      tag: '',
      formatsChannels: [...formatsChannels],
      tagCode: '',
      prevTagCode: '',
      tags: [],
      productType: null,
      menuTimings: '',
      isMenuTimingEnable: false,
      isMustTryEnable: false,
      currentTags: []
    }
    this.isFormDataChange = false
    this.unblock = {}
    this.selectedCategoryId = null
    this.currentProductId = null
    this.productIndexToSelect = null
    this.selectedAddonGroups = []
    this.selectedOptions = []
    this.productRelationShips = []
    this.formatChannelPrices = []
    this.productVariants = []
    this.updatedProductVariantPrices = []
    this.newProductVariantPrices = []
    this.strikeOutDiscountType = getStrikeoutDiscountType()
    this.addonGroups = []
    this.state = {
      products: [],
      selectedCategory: {},
      isFetchingProducts: true,
      isLoadingForm: true,
      isUpdatingForm: false,
      categories: [],
      selectedProductIndex: 0,
      productFormDetails: { ...this.productFormDetails },
      tags: [],
      isOrder: false,
      isOrderChanged: false,
      isOrderUpdating: false,
      showConfirmModal: false,
      dealPrice: 0,
      productDiscountValueId: '',
      discountValue: 0,
      strikeOutDiscountValueData: null,
      mustTryTag: {},
      currentMustTryTagCount: 0.
    }
  }

  UNSAFE_componentWillUnMount() {
    this.unblock && this.unblock()
  }

  handleItemWarningModalCancel = () => {
    this.setState({ showConfirmModal: false })
  }

  goToProduct = () => {
    const { products } = this.state

    this.handleItemWarningModalCancel()
    this.isFormDataChange = false
    if (this.selectedCategoryId) {
      this.onCategoryChange(this.selectedCategoryId)
    } else if (this.productIndexToSelect) {
      this.currentProductId = null
      this.onProductSelection(
        products[this.productIndexToSelect].product,
        this.productIndexToSelect
      )
    } else {
      this.onAddNewProductSelection()
    }
  }

  componentDidMount = () => {
    const jwtToken: any = localStorage.getItem('jwt')

    this.org_id = jwt.decode(jwtToken).org_id
    const catalog = this.props.history.location.state
      ? this.props.history.location.state.catalog
      : {}

    this.getAllCategoryByCatalog(catalog.id)
    const { channels, storeFormats } = this.props

    this.formatChannelPrices = prepareFormatChannelPrices(
      storeFormats,
      channels
    )

    this.unblock = this.props.history.block(targetLocation => {
      this.props.onTargetLocationChange(targetLocation)
      if (this.isFormDataChange) {
        return false
      }
    })
  }

  getAllCategoryByCatalog = async catalogId => {
    try {
      const allCategoriesResponse = await getAllProductCategory(
        this.props.client,
        { id: catalogId }
      )
      const categories = allCategoriesResponse?.data?.categories.filter(
        category =>
          category.productType === PRODUCT_TYPE_ENUM.PRODUCT &&
          category.listable
      )

      this.setState(
        prevState => {
          return {
            ...prevState,
            selectedCategory: categories[0],
            categories,
            currentMustTryTagCount: getMustTryTagCount(categories)
          }
        },
        () => this.getAllTags()
      )
    } catch (err) {
      console.warn('Category List Error:', err)
    }
  }

  setMustTryTagCount = (count) => {
    this.setState({ currentMustTryTagCount: count })
  }

  getAllTags = async () => {
    const { productFormDetails, selectedCategory } = this.state

    try {
      const allTagsResponse = await getTags(this.props.client)

      const tags = allTagsResponse.data.getTags.data.filter(tag => {
        return (
          tag.tagName.toLowerCase() === PRODUCT_TAG_ENUM.VEG ||
          tag.tagName.toLowerCase() === PRODUCT_TAG_ENUM.NON_VEG
        )
      })
      const mustTryTag = await fetchAndCreateMustTryTag(this.props.client,allTagsResponse.data)

      tags.reverse()
      const pFormDetails = { ...productFormDetails, tags: [...tags] }

      this.setState({
        tags,
        productFormDetails: pFormDetails,
        mustTryTag
      })
      this.productFormDetails.tags = tags
      this.getAllProductByCategory(selectedCategory['id'])
    } catch (err) {
      console.error('Get all tags error', err)
    }
  }

  getAllProductByCategory = async categoryId => {
    let { products } = this.state

    this.setState({ isFetchingProducts: true, isLoadingForm: true })
    const allProductsResponse = await getProductsByCategory(
      this.props.client,
      categoryId
    ).catch(err => {
      console.error('Product List Error:', err)
      this.setState({ isFetchingProducts: false, isLoadingForm: false })
    })
    const fetchedProducts =
      allProductsResponse.data.productCategoriesByCategoryId
    const pFormDetails = { ...this.productFormDetails }

    products = [...fetchedProducts].filter(
      fetchedProduct =>
        fetchedProduct.product.productType === PRODUCT_TYPE_ENUM.REGULAR
    )
    if (!products.length) {
      pFormDetails.formatsChannels = prepareFormatsChannels(this.props)
      this.setState(prevState => {
        return {
          ...prevState,
          products,
          isFetchingProducts: false,
          productFormDetails: pFormDetails,
          selectedProductIndex: 0,
          isLoadingForm: false
        }
      })
    } else {
      let selectedProduct = products[0].product
      let selectedProductIndex = 0
      const productIndex = products.findIndex(
        p => p.product.id === this.props.selectedProductId
      )

      if (productIndex !== -1) {
        selectedProduct = products[productIndex].product
        selectedProductIndex = productIndex
      }
      this.setState(
        prevState => {
          return {
            ...prevState,
            products,
            isFetchingProducts: false
          }
        },
        () => {
          this.currentProductId = null
          this.onProductSelection(selectedProduct, selectedProductIndex)
        }
      )
    }
  }

  onCategoryChange = categoryId => {
    const { categories } = this.state

    if (this.isFormDataChange) {
      this.setState({ showConfirmModal: true })
      this.selectedCategoryId = categoryId

      return
    }
    const selectedCategory = categories.find(
      category => category.id === categoryId
    )

    this.setState({
      selectedCategory
    })
    this.productIndexToSelect = null
    this.selectedCategoryId = null
    this.getAllProductByCategory(categoryId)
  }

  onProductSelection = async (product, selectedIndexParam) => {
    let selectedIndex = selectedIndexParam

    if (this.currentProductId === product.id) {
      return
    }
    this.currentProductId = product.id
    const { productFormDetails, products, tags, isUpdatingForm } = this.state
    const {
      id,
      name,
      code,
      description,
      imageUrl,
      extend,
      productType,
      menuTimings,
      tags: currentTags
    } = product

    const {
      packagingChargeType,
      client,
      categoriesWithProducts,
      options
    } = this.props

    if (this.isFormDataChange) {
      this.setState({ showConfirmModal: true })
      this.productIndexToSelect = selectedIndex

      return
    }
    this.productIndexToSelect = null
    const pFormDetails = { ...productFormDetails }

    pFormDetails.id = id
    pFormDetails.name = name
    pFormDetails.code = code
    pFormDetails.description = description
    pFormDetails.imageUrl = imageUrl
    pFormDetails.tags = tags
    pFormDetails.productType = productType
    pFormDetails.menuTimings = menuTimings || {}
    pFormDetails.isMenuTimingEnable = Boolean(menuTimings)
    pFormDetails.isMustTryEnable = isMustTryEnabled(product)
    pFormDetails.currentTags = currentTags
    if (!products[0].id && selectedIndex > 0) {
      products.shift()
      selectedIndex -= 1
    }
    if (product.id) {
      const productPriceValuesInput = {
        productId: product.id
      }
      let isLoadForm = true

      if (isUpdatingForm) {
        isLoadForm = false
        setSuccessMessage(
          'category.successMsg.itemUpdatedSuccessfully',
          this.props.intl
        )
      }
      this.setState({
        productFormDetails: pFormDetails,
        isLoadingForm: isLoadForm
      })

      const updateInput = {
        id: product.id,
        organizationId: this.org_id,
        name: product.name
      }
      const productUpdateRes = await updateProduct(client, updateInput).catch(
        () => {
          this.setState({ isLoadingForm: false })
        }
      )

      const updatedProduct = productUpdateRes.data.updateProduct

      this.newProductVariantPrices = []
      this.productRelationShips = updatedProduct.productRelationShip
      this.productVariants = getProductVariant(this.productRelationShips)
      this.selectedAddonGroups = getSelectedAddonsGroups(
        getAddonsGroupWithSelection(
          categoriesWithProducts,
          this.productRelationShips
        )
      )
      this.selectedOptions = getSelectedCutomisationGroups(
        getCustomisationGroupsWithSelections(options, this.productRelationShips)
      )
      this.productVariantPrices = prepareOriginalPrices([
        ...this.productVariants
      ])
      this.updatedProductVariantPrices = [...this.productVariantPrices]
      this.isFormDataChange = false
      this.props.onFormDataChange(false, this.unblock)
      try {
        this.productVariants.length > 0 &&
          (await this.getProductVariantPrices())
        const priceValuesRes = await getPriceValues(
          client,
          productPriceValuesInput
        ).catch(err => {
          console.error('Get product prices error', err)
          this.setState({ isLoadingForm: false })
        })
        const priceValues = priceValuesRes.data.productPriceValues.data
        const chargeValuesInput = {
          ...productPriceValuesInput,
          chargeType: packagingChargeType.id
        }
        const chargeValuesRes = await getChargeValues(
          client,
          chargeValuesInput
        ).catch(err => {
          console.error('Get product charges error', err)
          this.setState({ isLoadingForm: false })
        })
        const chargeValues = chargeValuesRes.data.productChargeValues.data

        pFormDetails.formatsChannels = updateFormatsChannels(
          priceValues,
          chargeValues,
          productFormDetails
        )
        await this.getDiscountValue(updatedProduct.id)
        const selectedTags = []

        if (extend) {
          const tagName = extend.extend_non_veg
            ? PRODUCT_TAG_ENUM.NON_VEG
            : PRODUCT_TAG_ENUM.VEG
          const selectedTag = getSelectedTagWithName(tagName, tags)

          selectedTags.push(selectedTag)
        }
        let prevTagCode = ''

        if (selectedTags && selectedTags.length) {
          pFormDetails.tagCode = selectedTags[0].code
          prevTagCode = selectedTags[0].code
        } else {
          pFormDetails.tagCode = this.productFormDetails.tagCode
        }

        const productCopy = { ...products[selectedIndex].product, prevTagCode }

        products[selectedIndex] = {
          ...products[selectedIndex],
          product: productCopy
        }
        pFormDetails.menuTimings = updatedProduct.menuTimings
        pFormDetails.isMenuTimingEnable = Boolean(updatedProduct.menuTimings)
        pFormDetails.isMustTryEnable = isMustTryEnabled(updatedProduct)
        pFormDetails.currentTags = updatedProduct.tags
        this.setState({
          productFormDetails: pFormDetails,
          products,
          selectedProductIndex: selectedIndex,
          isLoadingForm: false,
          isUpdatingForm: false
        })
      } catch (error) {
        console.error('Get product prices', error)
      }
    } else {
      this.setState({
        productFormDetails: pFormDetails,
        products,
        selectedProductIndex: selectedIndex
      })
    }
  }

  onAddNewProductSelection = () => {
    const { productFormDetails } = this.state
    let pFormDetails = { ...productFormDetails }

    this.productRelationShips = []
    this.productVariants = []
    this.selectedAddonGroups = []
    this.selectedOptions = []
    this.productVariantPrices = []
    this.updatedProductVariantPrices = []
    this.newProductVariantPrices = []
    this.setState({
      dealPrice: ''
    })
    if (!pFormDetails.id) {
      setInfoMessage('category.message.newAddItemInfo', this.props.intl)

      return
    }
    if (this.isFormDataChange) {
      this.setState({ showConfirmModal: true })
      this.productIndexToSelect = null

      return
    }
    pFormDetails = { ...this.productFormDetails }
    pFormDetails.formatsChannels = prepareFormatsChannels(this.props)
    this.isFormDataChange = false
    this.props.onFormDataChange(false, this.unblock)
    this.productIndexToSelect = null
    this.setState({
      productFormDetails: pFormDetails,
      selectedProductIndex: 0
    })
  }

  onFormChange = (type: string, value: any) => {
    this.isFormDataChange = true
    this.props.onFormDataChange(true, this.unblock)
    this.setState(
      (
        prevState: Readonly<IProductsState>,
        props: Readonly<IProductsProps>
      ) => {
        return {
          ...prevState,
          [type]: value
        }
      }
    )
  }

  onProductRelationChange = (type, selectedValues) => {
    this.isFormDataChange = true
    this.props.onFormDataChange(true, this.unblock)
    if (type === PRODUCT_TYPE_ENUM.ADDON) {
      this.selectedAddonGroups = selectedValues
    } else {
      this.selectedOptions = selectedValues
      const { productVariantIds } = getNestedCustomizationGroups([
        ...selectedValues
      ])

      if (!selectedValues.length) {
        this.updatedProductVariantPrices = []
        const { formatsChannels } = this.state.productFormDetails

        formatsChannels[0].priceValue = 0
        formatsChannels[0].isPackaging = false
        formatsChannels[0].charge.chargeValue = ''
        this.setState({
          productFormDetails: {
            ...this.state.productFormDetails,
            formatsChannels
          }
        })
        this.onDealPriceChanged(0, 0)
      }

      const newProductVariantIds = productVariantIds.filter(
        productVariantId => {
          const productVariantPriceFromNew = this.newProductVariantPrices.find(
            newProductVariantPrice => {
              return (
                newProductVariantPrice.productId === productVariantId ||
                compareArrayValues(
                  newProductVariantPrice.productId,
                  productVariantId
                )
              )
            }
          )
          const productVariantPriceFromOld = this.updatedProductVariantPrices.find(
            updatedProductVariantPrice => {
              return (
                updatedProductVariantPrice.productId === productVariantId ||
                compareArrayValues(
                  updatedProductVariantPrice.productId,
                  productVariantId
                )
              )
            }
          )

          return !productVariantPriceFromNew && !productVariantPriceFromOld
        }
      )
      const oldProductVariantPrices = getOldProductVariantPrices(
        [...this.newProductVariantPrices],
        [...productVariantIds]
      )
      const productVariants = newProductVariantIds.map(variantId => {
        return { id: variantId, tag: PRODUCT_TAG_ENUM.VEG }
      })
      let newProductVariantPrices = prepareOriginalPrices(productVariants)

      newProductVariantPrices = updateOriginalPricesWithProductPrice(
        [...newProductVariantPrices],
        []
      )
      newProductVariantPrices = updateOriginalPricesWithDiscountValue(
        [...newProductVariantPrices],
        []
      )
      newProductVariantPrices = updateOriginalPricesWithPackagingPrice(
        [...newProductVariantPrices],
        []
      )
      this.newProductVariantPrices = [
        ...oldProductVariantPrices,
        ...newProductVariantPrices
      ]
      this.setState({})
    }
  }

  onProductVariantPriceChange = (productVariantPrices, variantType) => {
    this.isFormDataChange = true
    this.props.onFormDataChange(true, this.unblock)
    if (variantType === PRODUCT_VARIANT_TYPE_ENUM.OLD) {
      this.updatedProductVariantPrices = productVariantPrices
    } else {
      this.newProductVariantPrices = productVariantPrices.map(
        newProductVariantPrice => {
          return { ...newProductVariantPrice }
        }
      )
    }
  }

  productCreationCompleted = async createdProduct => {
    const { products, selectedCategory } = this.state

    await addTaxes(this.props.client, createdProduct.id)
    await addCharges(this.props.client, createdProduct.id)
    const newProduct = {
      id: createdProduct.id,
      product: createdProduct
    }

    products.unshift(newProduct)
    this.setState(
      prevState => {
        return {
          ...prevState,
          isLoadingForm: false,
          products
        }
      },
      async () => {
        if (products.length > 1) {
          const updateProductSortSeqInput = getUpdateProductSortSeqInput(
            this.state.products,
            this.org_id
          )

          updateProductSortSeqInput.productCategory.splice(0, 1)
          await updateProductSortSeq(
            this.props.client,
            updateProductSortSeqInput
          )
        }
        this.getAllProductByCategory(selectedCategory['id'])
      }
    )
    setSuccessMessage(
      'category.message.ItemCreatedSuccessfully',
      this.props.intl
    )
    republishCatalog(this.props.client)
  }

  createProductDiscountValue = async productDetails => {
    const { dealPrice, productFormDetails } = this.state

    try {
      const discountInput = {
        productId: productDetails.productId,
        storeFormat: productDetails.storeFormat,
        channel: productDetails.channel,
        discountValue: Math.round(
          productFormDetails.formatsChannels[0].priceValue - dealPrice
        ),
        discountType: this.strikeOutDiscountType.id,
        discountValueType: DISCOUNT_VALUE_TYPE_ENUM.ABSOLUTE
      }
      // CREATE_MENU_DISCOUNT_VALUE
      const updateRes = await createProductDiscountValue(
        this.props.client,
        discountInput
      )

      console.log(updateRes, 'update response')
      this.setState({
        productDiscountValueId:
          updateRes?.data?.createDiscountValueForProduct?.id
      })
    } catch (error) {
      console.log(error, 'error')
    }
  }

  createPriceAndCharge = async createdProduct => {
    const { productFormDetails } = this.state
    const { client } = this.props
    const formatChannelLength = productFormDetails.formatsChannels.length
    let createdPriceAndChargeCount = 0
    const price = productFormDetails.formatsChannels[0].priceValue // Setting same price for all channels
    const charge = productFormDetails.formatsChannels?.[0]?.charge?.chargeValue // Setting same charge for all channels

    await addProductRelationships(
      createdProduct.id,
      this.selectedAddonGroups,
      this.props.client
    )
    const updateInput = {
      id: createdProduct.id,
      organizationId: this.org_id,
      name: createdProduct.name
    }
    let productUpdateRes = await updateProduct(client, updateInput)

    /* eslint-disable-next-line require-atomic-updates */
    let updatedProduct = productUpdateRes.data.updateProduct

    this.updateAddonsVariants(updatedProduct)
    await this.updateProductVariantPrices()
    productUpdateRes = await updateProduct(client, updateInput)

    /* eslint-disable-next-line require-atomic-updates */
    updatedProduct = productUpdateRes.data.updateProduct
    productFormDetails.formatsChannels.forEach(async formatChannel => {
      try {
        const createPriceInput = {
          productId: updatedProduct.id,
          storeFormat: formatChannel.storeFormat.id,
          channel: formatChannel.channel.id,
          productPrice: parseFloat(price) || 0 // Setting same price for all channels
        }

        await createProductPrice(this.props.client, createPriceInput)
        const createChargeInput = {
          productId: updatedProduct.id,
          storeFormat: formatChannel.storeFormat.id,
          channel: formatChannel.channel.id,
          chargeValue: parseFloat(charge) || 0, // Setting same charge for all channels
          chargeType: formatChannel.charge.chargeType.id
        }

        await createChargeForProduct(this.props.client, createChargeInput)
        await this.createProductDiscountValue(createPriceInput)

        createdPriceAndChargeCount += 1
        if (createdPriceAndChargeCount === formatChannelLength) {
          this.productCreationCompleted(updatedProduct)
        }
      } catch (err) {
        console.error('Create product price error', err)
        this.setState({
          isLoadingForm: false
        })
      }
    })
  }

  onDealPriceChanged = (val, discountValue) => {
    this.setState({
      dealPrice: val,
      discountValue
    })
  }

  getDiscountValue = async productId => {
    const { productFormDetails } = this.state
    let productDiscountValueIdArr = []
    const discountValueInput = {
      discountTypeId: this.strikeOutDiscountType.id,
      productId: [productId]
    }

    try {
      const discountValuesRes = await getDiscountValues(
        this.props.client,
        discountValueInput
      )
      const discountValues = discountValuesRes.data.productDiscountValues.data

      if (discountValues.length) {
        productDiscountValueIdArr = discountValues.map(
          discountValue => discountValue.id
        )
        const newDealPrice =
          productFormDetails.formatsChannels[0].priceValue -
          discountValues[0].discountValue

        this.setState({
          productDiscountValueId: productDiscountValueIdArr,
          dealPrice: newDealPrice,
          strikeOutDiscountValueData: discountValues[0]
        })
      } else {
        this.setState({
          dealPrice: productFormDetails.formatsChannels[0].priceValue,
          strikeOutDiscountValueData: null,
          productDiscountValueId: []
        })
      }
    } catch (error) {
      this.setState({
        dealPrice: productFormDetails.formatsChannels[0].priceValue,
        strikeOutDiscountValueData: null,
        productDiscountValueId: []
      })
    }
  }

  updateProductDiscountValue = async () => {
    const { dealPrice, productFormDetails, productDiscountValueId } = this.state

    try {
      const payload = []

      productDiscountValueId.forEach(elem => {
        payload.push({
          productDiscountValueId: elem,
          discountValue: Math.round(
            productFormDetails.formatsChannels[0].priceValue - dealPrice
          )
        })
      })
      const productDiscountValueIdArr = []
      const updateRes = await updateDiscountForProducts(
        this.props.client,
        payload
      )

      updateRes.data?.updateDiscountValueForProducts.forEach(elem => {
        productDiscountValueIdArr.push(elem.id)
      })

      this.setState({
        productDiscountValueId: productDiscountValueIdArr
      })
    } catch (error) {
      console.log(error, 'error')
    }
  }

  updatePriceAndCharge = async updatedProduct => {
    const {
      productFormDetails,
      selectedProductIndex,
      strikeOutDiscountValueData
    } = this.state
    const formatChannelLength = productFormDetails.formatsChannels.length
    let updatePriceAndChargeCount = 0
    const price = productFormDetails.formatsChannels[0].priceValue // Setting same price for all channels
    const { isPackaging } = productFormDetails.formatsChannels[0] // Setting same package charge for all channels
    const charge =
      isPackaging && productFormDetails.formatsChannels[0].charge.chargeValue // Setting same charge for all channels

    if (strikeOutDiscountValueData) {
      await this.updateProductDiscountValue()
    }

    productFormDetails.formatsChannels.forEach( async (formatChannel) => {
      try {
        const createPriceInput = {
          productId: updatedProduct.id,
          storeFormat: formatChannel.storeFormat.id,
          channel: formatChannel.channel.id,
          productPrice: parseFloat(price) // Setting same price for all channels
        }

        if (!strikeOutDiscountValueData) {
          this.createProductDiscountValue(createPriceInput)
        }
        if (formatChannel.productPriceValueId) {
          const updatePriceInput = {
            productPriceValueId: formatChannel.productPriceValueId,
            storeFormat: formatChannel.storeFormat.id,
            channel: formatChannel.channel.id,
            productPrice: parseFloat(price) // Setting same price for all channels
          }

          await updateProductPrice(this.props.client, updatePriceInput)
        } else {
          await createProductPrice(this.props.client, createPriceInput)
          await this.createProductDiscountValue(createPriceInput)
        }
      } catch (err) {
        console.error('Update product price error', err)
        this.setState({ isUpdatingForm: false })
      }
      updatePriceAndChargeCount += 1
      if (isPackaging) {
        if (formatChannel.charge.productChargeValueId) {
          const updateChargeInput = {
            productChargeValueId: formatChannel.charge.productChargeValueId,
            storeFormat: formatChannel.storeFormat.id,
            channel: formatChannel.channel.id,
            chargeValue: parseFloat(charge), // Setting same charge for all channels
            chargeType: formatChannel.charge.chargeType.id
          }

          try {
            await updateProductCharge(this.props.client, updateChargeInput)
            if (updatePriceAndChargeCount === formatChannelLength) {
              republishCatalog(this.props.client)
              this.onProductSelection(updatedProduct, selectedProductIndex)
            }
          } catch (err) {
            console.error('Update charge error', err)
            this.setState({ isUpdatingForm: false })
          }
        } else {
          const createChargeInput = {
            productId: updatedProduct.id,
            storeFormat: formatChannel.storeFormat.id,
            channel: formatChannel.channel.id,
            chargeValue: parseFloat(charge), // Setting same charge for all channels
            chargeType: formatChannel.charge.chargeType.id
          }

          try {
            await createChargeForProduct(this.props.client, createChargeInput)
            if (updatePriceAndChargeCount === formatChannelLength) {
              republishCatalog(this.props.client)
              this.onProductSelection(updatedProduct, selectedProductIndex)
            }
          } catch (err) {
            console.error('Create charge error', err)
            this.setState({ isUpdatingForm: false })
          }
        }
      } else if (formatChannel.charge.productChargeValueId) {
        const removeProductChargeInput = {
          id: formatChannel.charge.productChargeValueId
        }

        try {
          await removeProductCharge(this.props.client, removeProductChargeInput)
          if (updatePriceAndChargeCount === formatChannelLength) {
            republishCatalog(this.props.client)
            this.onProductSelection(updatedProduct, selectedProductIndex)
          }
          // This.setState({ isLoadingForm: false });
        } catch (err) {
          console.error('Update charge error', err)
          this.setState({ isUpdatingForm: false })
        }
      } else if (updatePriceAndChargeCount === formatChannelLength) {
        republishCatalog(this.props.client)
        this.onProductSelection(updatedProduct, selectedProductIndex)
      }
    })
  }

  onSaveForm = async () => {
    const { productFormDetails, selectedCategory, tags, currentMustTryTagCount } = this.state
    const selectedTag = getSelectedTag(productFormDetails.tagCode, tags)
    let extendObj: any = getExtendFromSelectedTag(
      selectedTag && selectedTag.tagName ? selectedTag.tagName : ''
    )

    extendObj = JSON.stringify(extendObj)
    const createProductInput = {
      name: productFormDetails.name,
      description: productFormDetails.description,
      imageUrl: productFormDetails.imageUrl ? productFormDetails.imageUrl : '',
      organizationId: this.org_id,
      listable: true,
      status: ORG_STATUS.ACTIVE,
      productType: PRODUCT_TYPE_ENUM.REGULAR,
      categoryIds: [selectedCategory['id']],
      code: randomString(10),
      extend: extendObj
    }
    const optionIds = getSelectedOptionIds(this.selectedOptions)

    if (optionIds.length) {
      createProductInput['optionIds'] = optionIds
    }
    this.setState({ isLoadingForm: true })
    this.isFormDataChange = false
    this.unblock && this.unblock()
    this.props.onFormDataChange(false, this.unblock)
    try {
      const productResponse = await createProduct(
        this.props.client,
        createProductInput
      )
      const createdProduct = productResponse.data.createProduct

      this.updateCategorySortSeq()
      const productTagInput = {
        productId: createdProduct.id,
        tagCodes: [productFormDetails.tagCode]
      }

      if (productFormDetails.isMustTryEnable) {
        productTagInput.tagCodes.push(this.state.mustTryTag['code'])
        this.setMustTryTagCount(currentMustTryTagCount + 1)
      }

      if (productFormDetails.isMenuTimingEnable) {
        const { code } = productFormDetails.menuTimings

        this.addMenuTimingsForProduct(createdProduct.id, code)
      }
      await addProductTags(this.props.client, productTagInput)
      this.createPriceAndCharge(createdProduct)
    } catch (err) {
      console.error('Create Product Error', err)
      this.setState({ isLoadingForm: false })
    }
  }

  setMenuTimings = (productId, menuTimings) => {
    const { products } = this.state

    products.forEach((product, i) => {
      if (product.id === productId) {
        products[i].menuTimings = menuTimings
      }
    })
    this.setState({
      products
    })
  }

  addMenuTimingsForProduct = async (productId, menuTimingCode) => {
    try {
      const addMenuTimingRes = await addMenuTimingsForProduct(
        this.props.client,
        productId,
        menuTimingCode
      )

      this.setMenuTimings(
        productId,
        addMenuTimingRes.data.addMenuTimingsToProduct.menuTimings
      )
    } catch (error) {
      console.log('Error', error)
    }
  }

  removeMenuTimingsForProduct = async (productId, code) => {
    try {
      await removeMenuTimingsForProduct(this.props.client, productId, code)
      this.setMenuTimings(productId, null)
    } catch (error) {
      console.log(error)
    }
  }

  updateMenuTimingsForProduct = async (productId, menuTimingCode) => {
    try {
      const updateMenuTimingRes = await updateMenuTimingsForProduct(
        this.props.client,
        productId,
        menuTimingCode
      )

      this.setMenuTimings(
        productId,
        updateMenuTimingRes.data.updateMenuTimingsForProduct.menuTimings
      )
    } catch (error) {
      console.log(error)
    }
  }

  updateMenuTimingForProductVariant = async code => {
    try {
      /* eslint-disable no-await-in-loop */
      for (const productVariant of this.productRelationShips) {
        const menuTimings = productVariant?.child?.menuTimings

        if (menuTimings && Object.keys(menuTimings).length) {
          await updateMenuTimingsForProduct(
            this.props.client,
            productVariant.child.id,
            code
          )
        } else {
          await addMenuTimingsForProduct(
            this.props.client,
            productVariant.child.id,
            code
          )
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  removeVariantMenuTiming = async code => {
    try {
      /* eslint-disable no-await-in-loop */
      for (const productVariant of this.productRelationShips) {
        const menuTimings = productVariant?.child?.menuTimings

        if (menuTimings && Object.keys(menuTimings).length) {
          await removeMenuTimingsForProduct(
            this.props.client,
            productVariant?.child?.id,
            code
          )
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  onUpdateForm = async () => {
    this.currentProductId = null
    const {
      productFormDetails,
      products,
      selectedProductIndex,
      tags,
      currentMustTryTagCount
    } = this.state
    const { client, onFormDataChange } = this.props
    const { prevTagCode } = products[selectedProductIndex].product
    const productParentId = products[selectedProductIndex].id
    const selectedTag = getSelectedTag(productFormDetails.tagCode, tags)
    const newExtendObj: any = {
      extend_non_veg:
        selectedTag?.tagName?.toLowerCase() === PRODUCT_TAG_ENUM.NON_VEG,
      extend_veg: selectedTag?.tagName?.toLowerCase() === PRODUCT_TAG_ENUM.VEG
    }
    const updateProductInput = {
      id: productFormDetails.id,
      name: productFormDetails.name,
      description: productFormDetails.description,
      imageUrl: productFormDetails.imageUrl ? productFormDetails.imageUrl : '',
      organizationId: this.org_id,
      extend: newExtendObj
    }
    const optionIds = getSelectedOptionIds(this.selectedOptions)

    if (optionIds.length) {
      updateProductInput['optionIds'] = optionIds
    }
    this.setState({ isUpdatingForm: true })
    this.isFormDataChange = false
    this.unblock && this.unblock()
    onFormDataChange(false, this.unblock)
    await this.removeProductRelationships(PRODUCT_TYPE_ENUM.VARIANT)
    await this.removeProductRelationships(PRODUCT_TYPE_ENUM.ADDON)
    await updateProductRelationShips(
      this.selectedAddonGroups,
      this.productRelationShips,
      this.props.client
    )
    await addProductRelationships(
      updateProductInput.id,
      this.selectedAddonGroups,
      this.props.client
    )
    const productUpdateResponse = await updateProduct(
      client,
      updateProductInput
    ).catch(err => {
      console.warn('Update Product Error', err)
      this.setState({ isUpdatingForm: false })
    })

    if (!productUpdateResponse?.data) {
      setSuccessMessage('item.errMsg.itemNotUpdated', this.props.intl)

      return false
    }
    this.updateCategorySortSeq()
    let updatedProduct = productUpdateResponse.data.updateProduct

    this.updateAddonsVariants(updatedProduct)
    await this.updateProductVariantPrices()
    const productUpdateRes = await updateProduct(client, updateProductInput)

    updatedProduct = productUpdateRes.data.updateProduct
    updatedProduct.prevTagCode = prevTagCode
    const productTagInput = {
      productId: updatedProduct.id,
      tagCodes: [productFormDetails.tagCode]
    }

    await addTaxes(this.props.client, updatedProduct.id)
    if (!productFormDetails.isMenuTimingEnable && updatedProduct.menuTimings) {
      this.removeMenuTimingsForProduct(
        updatedProduct.id,
        updatedProduct.menuTimings.code
      )
      this.removeVariantMenuTiming(updatedProduct.menuTimings.code)
    } else if (
      productFormDetails.isMenuTimingEnable &&
      !updatedProduct.menuTimings
    ) {
      const code = productFormDetails.menuTimings?.code

      this.addMenuTimingsForProduct(updatedProduct.id, code)
      this.updateMenuTimingForProductVariant(code)
    } else if (updatedProduct.menuTimings) {
      const code = productFormDetails.menuTimings?.code

      this.updateMenuTimingsForProduct(updatedProduct.id, code)
      this.updateMenuTimingForProductVariant(code)
    }
    products.splice(selectedProductIndex, 1, {
      id: productParentId,
      product: updatedProduct
    })
    this.setState({ products })
    try {
      const addRemoveMustTryTagInput = {
        productId: updatedProduct.id,
        tagCodes: [this.state.mustTryTag['code']]
      }

      if (!isMustTryEnabled(updatedProduct) && productFormDetails.isMustTryEnable) {
        await addProductTags(this.props.client, addRemoveMustTryTagInput)
        this.setMustTryTagCount(currentMustTryTagCount + 1)
      }
      else if (isMustTryEnabled(updatedProduct) && !productFormDetails.isMustTryEnable) {
        await removeProductTags(this.props.client, addRemoveMustTryTagInput)
        this.setMustTryTagCount(currentMustTryTagCount - 1)
      }
    } catch (error) {
      console.log(error)
    }
    if (!prevTagCode) {
      try {
        await addProductTags(this.props.client, productTagInput)
      } catch (err) {
        console.error('Update Tag Error', err)
        this.setState({ isUpdatingForm: false })
      }
    } else if (prevTagCode !== productFormDetails.tagCode) {
      const removeProductTagInput = {
        productId: updatedProduct.id,
        tagCodes: [prevTagCode]
      }

      try {
        await removeProductTags(this.props.client, removeProductTagInput)
        await addProductTags(this.props.client, productTagInput)
      } catch (err) {
        console.log('Remove/Update Tag Error', err)
        this.setState({ isUpdatingForm: false })
      }
    }

    if (!this.selectedOptions.length) {
      this.updatePriceAndCharge(updatedProduct)
    } else {
      republishCatalog(this.props.client)
      this.onProductSelection(updatedProduct, selectedProductIndex)
    }
  }

  updateCategorySortSeq = () => {
    if (this.addonGroups.length) {
      updateCategorySortSeq(
        this.props.client,
        getUpdateCategorySortSeqInput(this.addonGroups, this.org_id)
      ).catch(err => {
        console.warn('Update category sort sequence err', err)
      })
      this.props.onAddonSeqChange()
    }
  }

  removeProductRelationships = async productType => {
    let removeProductRelationShipInput = []

    if (productType === PRODUCT_TYPE_ENUM.ADDON) {
      removeProductRelationShipInput = getRemovedProductRelationShipsInputForAddon(
        this.selectedAddonGroups,
        this.productRelationShips
      )
    } else {
      const { productVariantIds } = getNestedCustomizationGroups([
        ...this.selectedOptions
      ])

      this.updatedProductVariantPrices = getOldProductVariantPrices(
        [...this.updatedProductVariantPrices],
        productVariantIds
      )
      removeProductRelationShipInput = getRemovedProductRelationShipsInputForVariant(
        productVariantIds,
        this.productRelationShips
      )
    }
    if (removeProductRelationShipInput.length) {
      await removeProductRelationships(
        this.props.client,
        removeProductRelationShipInput
      )
    }
  }

  updateAddonsVariants = updatedProduct => {
    const { categoriesWithProducts, options } = this.props

    this.productRelationShips = updatedProduct.productRelationShip
    this.productVariants = getProductVariant(this.productRelationShips)
    this.newProductVariantPrices = updateProductVariantPricesWithId(
      this.productRelationShips,
      this.newProductVariantPrices,
      this.formatChannelPrices[0]
    )
    this.updatedProductVariantPrices = updateProductVariantPricesWithId(
      this.productRelationShips,
      [...this.updatedProductVariantPrices],
      this.formatChannelPrices[0]
    )
    this.formatChannelPrices.slice(1).forEach(formatChannelPrice => {
      formatChannelPrice.prices = updateProductVariantPricesWithId(
        this.productRelationShips,
        [...formatChannelPrice.prices],
        formatChannelPrice
      )
    })

    this.newProductVariantPrices.forEach(newProductVariantPrice => {
      if (
        !this.updatedProductVariantPrices.find(
          updatedProductVariantPrice =>
            updatedProductVariantPrice.productId ===
            newProductVariantPrice.productId
        )
      ) {
        this.updatedProductVariantPrices.push(newProductVariantPrice)
      }
      this.formatChannelPrices.forEach(formatChannelPrice => {
        if (
          !formatChannelPrice.prices.find(
            price => price.productId === newProductVariantPrice.productId
          )
        ) {
          formatChannelPrice.prices.push(newProductVariantPrice)
        }
      })
    })
    this.selectedAddonGroups = getSelectedAddonsGroups(
      getAddonsGroupWithSelection(
        categoriesWithProducts,
        this.productRelationShips
      )
    )
    this.selectedOptions = getSelectedCutomisationGroups(
      getCustomisationGroupsWithSelections(options, this.productRelationShips)
    )
  }

  toggleOrderWrapper = () => {
    this.setState({
      isOrder: !this.state.isOrder,
      isOrderChanged: false
    })
  }

  onClickOrderProduct = type => {
    const { selectedProductIndex, products } = this.state

    if (!products[selectedProductIndex].id) return
    let newIndex =
      type === 'up' ? selectedProductIndex - 1 : selectedProductIndex + 1

    newIndex = newIndex < 0 ? products.length - 1 : newIndex % products.length
    products.splice(newIndex, 0, products.splice(selectedProductIndex, 1)[0])
    this.setState({
      products,
      selectedProductIndex: newIndex,
      isOrderChanged: true
    })
  }

  updateOrderedProduct = () => {
    const updateProductSortSeqInput = getUpdateProductSortSeqInput(
      this.state.products,
      this.org_id
    )

    this.setState({ isFetchingProducts: true })
    updateProductSortSeq(this.props.client, updateProductSortSeqInput)
      .then(() => {
        setSuccessMessage(
          'category.successMsg.productsReorderedSuccessFully',
          this.props.intl
        )
        this.setState({
          isFetchingProducts: false
        })
        republishCatalog(this.props.client)
      })
      .catch(err => {
        this.setState({ isFetchingProducts: false })
        console.error('Products reordering Error:', err)
      })
  }

  getProductVariantPrices = async () => {
    const { packagingChargeType } = this.props
    const productVariantIds = this.productVariants.map(
      productVariant => productVariant.id
    )

    try {
      for await (const formatChannelPrice of this.formatChannelPrices) {
        const storeFormat = formatChannelPrice.format.id
        const channel = formatChannelPrice.channel.id
        const priceValuesInput = {
          storeFormat,
          channel,
          productId: productVariantIds
        }
        const priceResponse = await getPriceValues(
          this.props.client,
          priceValuesInput
        )
        const priceValues = priceResponse.data.productPriceValues.data

        formatChannelPrice.prices = updateOriginalPricesWithProductPrice(
          [...this.productVariantPrices],
          [...priceValues]
        )

        const discountValueInput = {
          storeFormat,
          channel,
          discountTypeId: this.strikeOutDiscountType.id,
          productId: productVariantIds
        }
        const discountValuesRes = await getDiscountValues(
          this.props.client,
          discountValueInput
        )
        const discountValues = discountValuesRes.data.productDiscountValues.data

        formatChannelPrice.prices = updateOriginalPricesWithDiscountValue(
          [...formatChannelPrice.prices],
          [...discountValues]
        )

        const chargeValuesInput = {
          storeFormat,
          channel,
          productId: productVariantIds,
          chargeType: packagingChargeType.id
        }
        const packagingResponse = await getChargeValues(
          this.props.client,
          chargeValuesInput
        )
        const chargeValues = packagingResponse.data.productChargeValues.data

        formatChannelPrice.prices = updateOriginalPricesWithPackagingPrice(
          [...formatChannelPrice.prices],
          [...chargeValues]
        )
      }
      this.formatChannelPrices.forEach(formatChannelPrice => {
        const prices = formatChannelPrice.prices.map(p => {
          return { ...p }
        })
        const updatedPrices = updateProductIdWithOptionValuesId(
          prices,
          this.productRelationShips
        )

        formatChannelPrice.prices = updatedPrices
      })
      this.productVariantPrices = this.formatChannelPrices[0].prices
      this.updatedProductVariantPrices = this.productVariantPrices.map(op => {
        return { ...op }
      })
    } catch (err) {
      console.error('Get prices for all format channel error', err)
    }
  }

  updateProductVariantPrices = async () => {
    const { client, packagingChargeType } = this.props
    const productsForPriceCreate = getProductsToCreate(
      [...this.updatedProductVariantPrices],
      'priceValueChanged',
      'priceValueId'
    )
    const productsForPriceUpdate = getProductsToUpdate(
      [...this.updatedProductVariantPrices],
      'priceValueChanged',
      'priceValueId'
    )
    const productsForChargeCreate = getProductsToCreate(
      [...this.updatedProductVariantPrices],
      'chargeValueChanged',
      'chargeValueId'
    )
    const productsForChargeUpdate = getProductsToUpdate(
      [...this.updatedProductVariantPrices],
      'chargeValueChanged',
      'chargeValueId'
    )
    const productsForDiscountUpdate = getProductsToUpdate(
      [...this.updatedProductVariantPrices],
      'discountValueChanged',
      'discountValueId'
    )
    const productsForDiscountCreate = getProductsToCreate(
      [...this.updatedProductVariantPrices],
      'discountValueChanged',
      'discountValueId'
    )
    let priceValueCreateInput = preparePriceValueCreateInput(
      [...productsForPriceCreate],
      this.formatChannelPrices[0].format,
      this.formatChannelPrices[0].channel
    )
    let priceValueUpdateInput = preparePriceValueUpdateInput([
      ...productsForPriceUpdate
    ])
    let chargeValueCreateInput = prepareChargeValueCreateInput(
      [...productsForChargeCreate],
      this.formatChannelPrices[0].format,
      this.formatChannelPrices[0].channel,
      packagingChargeType
    )
    let chargeValueUpdateInput = prepareChargeValueUpdateInput([
      ...productsForChargeUpdate
    ])
    let discountValueUpdateInput = prepareDiscountValueUpdateInput([
      ...productsForDiscountUpdate
    ])
    let discountValueCreateInput = prepareDiscountValueCreateInput(
      [...productsForDiscountCreate],
      this.formatChannelPrices[0].format,
      this.formatChannelPrices[0].channel,
      this.strikeOutDiscountType
    )

    try {
      const formatChannelPrices = this.formatChannelPrices.slice(1)

      await updateProductVariantsTag(
        this.props.client,
        this.org_id,
        this.updatedProductVariantPrices
      )
      for await (const formatChannelPrice of formatChannelPrices) {
        const newProductsForPriceCreate = getProductsWithUpdatedPropertyId(
          [...productsForPriceCreate],
          formatChannelPrice.prices,
          'priceValueId',
          ACTIONS.CREATE
        )
        const newProductsForPriceUpdate = getProductsWithUpdatedPropertyId(
          [...productsForPriceUpdate],
          formatChannelPrice.prices,
          'priceValueId',
          ACTIONS.UPDATE
        )
        const newProductsForChargeCreate = getProductsWithUpdatedPropertyId(
          [...productsForChargeCreate],
          formatChannelPrice.prices,
          'chargeValueId',
          ACTIONS.CREATE
        )
        const newProductsForChargeUpdate = getProductsWithUpdatedPropertyId(
          [...productsForChargeUpdate],
          formatChannelPrice.prices,
          'chargeValueId',
          ACTIONS.UPDATE
        )
        const newProductsForDiscountUpdate = getProductsWithUpdatedPropertyId(
          [...productsForDiscountUpdate],
          formatChannelPrice.prices,
          'discountValueId',
          ACTIONS.UPDATE
        )
        const newProductsForDiscountCreate = getProductsWithUpdatedPropertyId(
          [...productsForDiscountCreate],
          formatChannelPrice.prices,
          'discountValueId',
          ACTIONS.CREATE
        )
        const newPriceValueCreateInput = preparePriceValueCreateInput(
          [...newProductsForPriceCreate],
          formatChannelPrice.format,
          formatChannelPrice.channel
        )

        priceValueCreateInput = [
          ...priceValueCreateInput,
          ...newPriceValueCreateInput
        ]
        const newPriceValueUpdateInput = preparePriceValueUpdateInput([
          ...newProductsForPriceUpdate
        ])

        priceValueUpdateInput = [
          ...priceValueUpdateInput,
          ...newPriceValueUpdateInput
        ]
        const newChargeValueCreateInput = prepareChargeValueCreateInput(
          [...newProductsForChargeCreate],
          formatChannelPrice.format,
          formatChannelPrice.channel,
          packagingChargeType
        )

        chargeValueCreateInput = [
          ...chargeValueCreateInput,
          ...newChargeValueCreateInput
        ]
        const newChargeValueUpdateInput = prepareChargeValueUpdateInput([
          ...newProductsForChargeUpdate
        ])

        chargeValueUpdateInput = [
          ...chargeValueUpdateInput,
          ...newChargeValueUpdateInput
        ]
        const newDiscountValueUpdateInput = prepareDiscountValueUpdateInput([
          ...newProductsForDiscountUpdate
        ])

        discountValueUpdateInput = [
          ...discountValueUpdateInput,
          ...newDiscountValueUpdateInput
        ]
        const newDiscountValueCreateInput = prepareDiscountValueCreateInput(
          [...newProductsForDiscountCreate],
          formatChannelPrice.format,
          formatChannelPrice.channel,
          this.strikeOutDiscountType
        )

        discountValueCreateInput = [
          ...discountValueCreateInput,
          ...newDiscountValueCreateInput
        ]
      }
      if (priceValueCreateInput.length) {
        await createPriceForProducts(client, priceValueCreateInput)
      }
      if (priceValueUpdateInput.length) {
        await updatePriceForProducts(client, priceValueUpdateInput)
      }
      if (discountValueCreateInput.length) {
        await createDiscountForProducts(client, discountValueCreateInput)
      }
      if (discountValueUpdateInput.length) {
        await updateDiscountForProducts(client, discountValueUpdateInput)
      }
      if (chargeValueCreateInput.length) {
        chargeValueCreateInput.forEach(async chargeValueInput => {
          await createChargeForProduct(client, chargeValueInput)
        })
      }
      if (chargeValueUpdateInput.length) {
        await updateChargeForProducts(client, chargeValueUpdateInput)
      }
      addProductVariantsTaxAndCharges(
        this.updatedProductVariantPrices,
        this.props.client
      )
      this.newProductVariantPrices = []
    } catch (err) {
      console.error('Publish menu error', err)
    }
  }

  updateAddonGroups = updatedAddonGroups => {
    this.addonGroups = updatedAddonGroups.map(addonGroup => {
      return { ...addonGroup, id: addonGroup.value }
    })
  }

  onSortEnd = e => {
    const { products } = this.state
    const newProducts = arrayMove(products, e.oldIndex, e.newIndex)

    this.setState({
      products: newProducts
    })
    this.onProductSelection(products[e.oldIndex].product, e.newIndex)
    this.updateOrderedProduct()
  }

  render() {
    const {
      products,
      selectedCategory,
      categories,
      isFetchingProducts,
      productFormDetails,
      isLoadingForm,
      isOrder,
      isUpdatingForm,
      showConfirmModal,
      dealPrice,
      currentMustTryTagCount
    } = this.state
    const { categoriesWithProducts, options, onTabChange } = this.props

    const SortableCategoryItem = SortableElement(ProductItem)
    const ProductList = () => {
      return (
        <List
          dataSource={products ? products : []}
          loading={isFetchingProducts}
          renderItem={(product, index) => (
            <SortableCategoryItem
              distance={1}
              product={product}
              index={index}
              elem={index}
              productFormDetails={productFormDetails}
              onProductSelection={(product, elem) =>
                this.onProductSelection(product, elem)
              }
            />
          )}
        />
      )
    }
    const SortableProductList = SortableContainer(ProductList)

    return (
      <>
        <Col span={6} className="list-wrapper">
          <HeaderText type="H5" margin="0 0 10px 0" color="#000">
            <IntlMessages id="menu.chooseCategory" />
          </HeaderText>
          <Select
            loading={!categories.length}
            defaultValue={selectedCategory['name']}
            value={selectedCategory['name']}
            style={{ width: '100%', marginBottom: '10px' }}
            onChange={value => {
              this.onCategoryChange(value)
            }}>
            {categories.map(category => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
          </Select>

          {!isOrder && (
            <Button
              className="add-new-btn"
              onClick={() => {
                this.onAddNewProductSelection()
              }}>
              <IntlMessages id="category.addNewItem" />
            </Button>
          )}
          <SortableProductList onSortEnd={this.onSortEnd} distance={1} />
        </Col>
        <Col span={18} style={{ paddingLeft: '0px' }}>
          <ProductForm
            categories={categories}
            currentMustTryTagCount={currentMustTryTagCount}
            dealPrice={dealPrice}
            onDealPriceChanged={this.onDealPriceChanged}
            onChange={this.onFormChange}
            productFormDetails={productFormDetails}
            client={this.props.client}
            onValidateFields={() =>
              onValidateFields(
                this.state.productFormDetails,
                this.updatedProductVariantPrices,
                this.newProductVariantPrices,
                this.props.intl
              )
            }
            onSave={this.onSaveForm}
            onUpdate={this.onUpdateForm}
            isLoadingForm={isLoadingForm}
            isUpdatingForm={isUpdatingForm}
            priceData={this.props?.priceData}
            onProductRelationChange={this.onProductRelationChange}
            onAddonGroupsChange={this.updateAddonGroups}
            productRelationships={this.productRelationShips}
            categoriesWithProducts={categoriesWithProducts}
            options={options}
            selectedOptions={this.selectedOptions}
            productVariantPrices={this.updatedProductVariantPrices}
            originalProductVariantPrices={this.productVariantPrices}
            newProductVariantPrices={this.newProductVariantPrices}
            onProductVariantPriceChange={this.onProductVariantPriceChange}
            onTabChange={onTabChange}
          />
        </Col>
        <Modal
          visible={showConfirmModal}
          key="item-form-modal"
          title={<IntlMessages id="category.confirmationMsg.leaveThisPage" />}
          onCancel={this.handleItemWarningModalCancel}
          footer={[
            <div key="footer">
              <Button
                key="stay"
                type="default"
                size="large"
                onClick={this.handleItemWarningModalCancel}>
                <IntlMessages id="stay" />
              </Button>
              <Button
                key="leave"
                type="primary"
                danger
                size="large"
                onClick={() => {
                  this.goToProduct()
                }}>
                <IntlMessages id="leave" />
              </Button>
            </div>
          ]}>
          <div>
            <IntlMessages id="category.infoMsg.changesMayNotSave" />.
          </div>
        </Modal>
      </>
    )
  }
}

const ProductsComponent = compose(withRouter, withApollo)(Products)

export { ProductsComponent }
