import React from 'react'
import { GPAY_BUSINESS_API } from 'Utils/constants'
class GpayDetailsForm extends React.Component<{}, {}> {
  render() {
    return (
      <form id="TheFormCreate" method="post" action={GPAY_BUSINESS_API} target="TheWindow" className="bg-white shadow-md rounded px-12 pt-6 pb-8 mb-4" style={{ visibility: 'hidden', position: 'absolute' }}>
        <label className="block font-bold text-4xl text-red-600">Merchant Onboarding Console</label>

        <label className="block font-bold text-xl"> Basic Information </label>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-4">Display Name</label>
          <input type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="displayName" placeholder="Display Name" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Legal Name</label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="legalName" />
        </div>
        <label className="block font-bold text-xl"> Address </label>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Address Line 1</label>
          <input id="addressline1" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Address Line 2</label>
          <input id="addressline2" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Locality</label>
          <input id="locality" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Administrative Area (eg. State)</label>
          <input id="admin_area" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Postal Code</label>
          <input id="postal_code" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <label className="block font-bold text-xl">Contact</label>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Phone</label>
          <input id="phone" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Use +917778977910 format" />
        </div>
        <label className="block font-bold text-xl">Merchant Payment Details</label>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">VPA</label>
          <input id="vpa" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">PAN</label>
          <input id="pan" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">GSTIN</label>
          <input id="gstin" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>

        <input type="hidden" name="prefilled_info" value="" id="prefilled_info" />
      </form>
    )
  }
}
export default GpayDetailsForm
