import * as jwt from 'jsonwebtoken'

export const setFileSystemId = (id) => {
  localStorage.setItem('fileSystemId', JSON.stringify(id))  
}

export const getFileSystemId = () => {
  return localStorage.getItem('fileSystemId')
}

export const getAuthToken = () => {
  return localStorage.getItem('jwt')
}

export const getWcoreOrganizationId = () => {
  const jwtToken: any = localStorage.getItem('jwt')

  return jwt.decode(jwtToken).org_id
}

export const getChannelsData = () => {
  const channels = localStorage.getItem('channels')

  return channels ? JSON.parse(channels) : []
}

export const setIsUnsavedStoreDetails = (flag) => {
  localStorage.setItem('isUnsavedStoreDetails', JSON.stringify(flag))
}

export const getIsUnsavedStoreDetails = () => {
  return JSON.parse(localStorage.getItem('isUnsavedStoreDetails'))
}
export const setStoreFormats = data =>{
  return localStorage.setItem('storeFormats', JSON.stringify(data))
}

export const setChannels = data => {
  return localStorage.setItem('channels', JSON.stringify(data))
}

export const getStoreFormats = () => {
  const storeFormats = localStorage.getItem('storeFormats')
  
  return storeFormats ? JSON.parse(storeFormats) : []
}

export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData'))
}

export const setUserData = (data) => {
  return localStorage.setItem('userData', JSON.stringify(data))
}

export const getDiscountTypes = () => {
  return JSON.parse(localStorage.getItem('discountTypes'))
}