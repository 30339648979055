import '../index.css'

import { Icon } from '@ant-design/compatible'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Col, Row } from 'antd'
import { History } from 'history'
import jwt from 'jsonwebtoken'
import React from 'react'
import { injectIntl } from 'react-intl'
import { getAllChannels, getCatalogue, getStore, getStoreCatalogue, getStoreFormat, onDeleteCatalogue } from 'services'
import { isAdmin } from 'Utils'
import { CHANNELS } from 'Utils/constants'
import { getChannelsData, getStoreFormats, getWcoreOrganizationId } from 'Utils/localStorageHandlers'

import { getStores } from '../../../Utils/menuUtils/commonFunc'
import { CatalogsNotFound, CatalogueCard, Loader, MenuHeader } from '../components'
interface ICatalogsProps extends ApolloProviderProps<any> {
  history: History
  intl: any
}
interface ICatalogsState {
  catalogs: any[]
  showOrder: boolean
  loggedInUserData: any
  isFetching: boolean
  managerStore: any
  channels: any
  storeFormats: any
}
class Catalogs extends React.Component<ICatalogsProps, ICatalogsState> {
  org_id: string

  isAdminUser: boolean

  channels: any[]

  storeFormats: any[]

  constructor(props: Readonly<ICatalogsProps>) {
    super(props)
    const userData = JSON.parse(localStorage.getItem('userData'))

    this.state = {
      catalogs: [],
      showOrder: true,
      loggedInUserData: userData,
      isFetching: false,
      managerStore: getStores(userData)[0],
      channels: [],
      storeFormats: []
    }
  }

  componentDidMount = () => {

    this.org_id = getWcoreOrganizationId()
    this.isAdminUser = isAdmin()
    this.channels = getChannelsData()
    const defaultChannel = this.channels.find(channel => channel.name === CHANNELS.PEPPO_PWA)

    this.storeFormats = getStoreFormats()
    this.setState({ isFetching: true })
    if (this.isAdminUser) {
      this.getAdminCatalogs()
    } else {
      this.getManagerCatalogs(defaultChannel)
    }
  }

  getStoreDetails = () => {
    const { managerStore } = this.state

    this.setState({ isFetching: true })
    getStore(this.props.client, managerStore?.id)
      .then(res => {
        this.setState(
          {
            channels: res?.data?.store?.channels,
            storeFormats: res?.data?.store?.storeFormats
          },
          () => {
            this.getManagerCatalogs(res?.data?.store?.channels[0])
          }
        )
      })
      .catch(err => {
        console.error('Store Error:', err)
        this.setState({ isFetching: false })
      })
  }

  getAdminCatalogs = () => {
    getCatalogue(this.props.client, this.org_id)
      .then(catalogsResponse => {
        const { catalogs } = catalogsResponse.data
        const isCatalogsAvailable = catalogs.length > 1

        this.setState({
          isFetching: false,
          catalogs: isCatalogsAvailable ? catalogs : []
        })
        catalogs.length &&
          this.setState(
            {
              catalogs
            },
            () => {
              this.goToCatalogDetails(catalogs[0])
            }
          )
      })
      .catch(err => {
        console.error('Catalogs Error:', err)
        this.setState({ isFetching: false })
      })
  }

  getManagerCatalogs = channel => {
    const { managerStore } = this.state
    const storeCatalogInput = {
      storeId: managerStore ? managerStore.id : null,
      channelIds: [channel.id]
    }

    getStoreCatalogue(this.props.client, storeCatalogInput)
      .then(catalogsResponse => {
        const catalogs = []

        catalogs.push(catalogsResponse.data.getStoreCatalog.catalog)
        if (catalogs.length) {
          this.setState(
            {
              catalogs
            },
            () => {
              this.goToCatalogDetails(catalogs[0])
            }
          )
        }
        this.setState({
          isFetching: false,
          catalogs: []
        })
      })
      .catch(err => {
        console.error('Catalogs List Error:', err)
        this.setState({ isFetching: false })
      })
  }

  async getChannel() {
    this.setState({ isFetching: true })
    try {
      const response = await getAllChannels(this.props.client)
      const channels = response.data.channels.data

      this.setState({ channels }, () => this.getStoreFormats())
    } catch (err) {
      console.error('Get channels error', err)
      this.setState({ isFetching: false })
    }
  }

  getStoreFormats() {
    getStoreFormat(this.props.client)
      .then(response => {
        const storeFormats = response.data.storeFormats.data

        this.setState({ storeFormats }, () => this.getAdminCatalogs())
      })
      .catch(err => {
        console.error('Get Store formats error', err)
        this.setState({ isFetching: false })
      })
  }

  goToCatalogDetails = catalog => {
    const { channels, storeFormats, catalogs } = this.state

    this.props.history.push({
      pathname: `/orderx/menu-list/${catalog.id}/detail`,
      state: {
        catalog,
        channels,
        storeFormats,
        catalogCount: catalogs.length
      }
    })
  }

  deleteCatalog(catalogId) {
    this.setState({ isFetching: true })
    this.getAdminCatalogs()
    onDeleteCatalogue(this.props.client, catalogId, this.org_id).then(response => {
      this.getAdminCatalogs()
    })
  }

  render() {
    const { catalogs, isFetching } = this.state

    if (isFetching) {
      return <Loader {...this.props} />
    }

    return (
      <div className="gx-main-content-wrapper">
        <div className="body">
          <MenuHeader />
          {catalogs.map(catalogue => {
            return (
              <Row key={catalogue.id} align="middle">
                <Col span={12} offset={6}>
                  <Row>
                    <Col
                      span={22}
                      onClick={(event: any) => {
                        this.goToCatalogDetails(catalogue)
                      }}>
                      <CatalogueCard catalogue={catalogue} isAdmin={this.isAdminUser} />
                    </Col>
                    {this.isAdminUser && (
                      <Col span={2} className="delete-icon">
                        <span>
                          <Icon type="delete" onClick={() => this.deleteCatalog(catalogue.id)} />
                        </span>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            )
          })}
          <CatalogsNotFound catalogs={catalogs} />
        </div>
      </div>
    )
  }
}
// @ts-ignore
export default injectIntl(withApollo(Catalogs))
