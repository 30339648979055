import { Col, Collapse, Divider, InputNumber, Row } from 'antd'
import { IntlMessages, Switch, Tag, Text } from 'components'
import React from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { COLORS, DEAL_PRICE_REGEX, PRICE_TYPE, PRODUCT_TAG_ENUM } from 'Utils/constants'

import { getNestedCustomizationGroups, getPriceDataWithProductId } from '../../../Utils/menuUtils/commonFunc'
import { StyledCollapse, StyledIcon } from '../../../Utils/menuUtils/styleConstants'

const { Panel } = Collapse
const PanelLabel = styled(props => <Text {...props}/>)`
    font-size: 13px;
    color: ${COLORS.DARK_TEXT};
  `
const GroupTag = styled(props => <Tag {...props}/> )``
const DividerWithoutMargin = styled(Divider)`
    margin: 0;
  `

interface IProductCustomisationProps {
  intl: any
  customisationGroups: any[]
  productVariantPrices: any[]
  newProductVariantPrices: any[]
  onBasePriceChange: any
  onDealPriceChange: any
  onPackagingChargeChange: any
  validateAndUpdatePrices: any
  onTagChange: any
}

interface IProductCustomisationState {}

class SelectedCustomisations extends React.Component<IProductCustomisationProps, IProductCustomisationState> {
  formProductTag = productId => {
    const { productVariantPrices, onTagChange, newProductVariantPrices } = this.props
    let productPriceData = getPriceDataWithProductId([...productVariantPrices], productId)

    if (!productPriceData) {
      productPriceData = getPriceDataWithProductId([...newProductVariantPrices], productId)
    }
    const tag = productPriceData?.tag

    return (
      <Switch
        checked={tag === PRODUCT_TAG_ENUM.VEG}
        variant="toggle2"
        onClick={(checked, event) => {
          onTagChange(productId, checked ? PRODUCT_TAG_ENUM.VEG : PRODUCT_TAG_ENUM.NON_VEG)
        }}
      />
    )
  }

  formProductBasePrice = productId => {
    const {
      productVariantPrices,
      onBasePriceChange,
      newProductVariantPrices,
      validateAndUpdatePrices
    } = this.props
    let productPriceData = getPriceDataWithProductId(
      [...productVariantPrices],
      productId
    )

    if (!productPriceData) {
      productPriceData = getPriceDataWithProductId(
        [...newProductVariantPrices],
        productId
      )
    }
    const price = productPriceData?.priceValue || null

    return (
      <InputNumber
        size="middle"
        value={price}
        min={1}
        onChange={value => {
          onBasePriceChange(productId, value)
        }}
        formatter={value => `₹ ${value}`.replace(DEAL_PRICE_REGEX, ',')}
        onBlur={() => {
          validateAndUpdatePrices(productId, PRICE_TYPE.BASE)
        }}
      />
    )
  }

  formProductDealPrice = productId => {
    const {
      productVariantPrices,
      onDealPriceChange,
      newProductVariantPrices,
      validateAndUpdatePrices
    } = this.props
    let productPriceData = getPriceDataWithProductId(
      [...productVariantPrices],
      productId
    )

    if (!productPriceData) {
      productPriceData = getPriceDataWithProductId(
        [...newProductVariantPrices],
        productId
      )
    }
    const price = productPriceData?.dealPrice || 0

    return (
      <InputNumber
        size="middle"
        value={price}
        min={0}
        onChange={value => {
          onDealPriceChange(productId, value)
        }}
        formatter={value => `₹ ${value}`.replace(DEAL_PRICE_REGEX, ',')}
        onBlur={() => {
          validateAndUpdatePrices(productId, PRICE_TYPE.DEAL)
        }}
      />
    )
  }

  formProductPackagingCharge = productId => {
    const {
      productVariantPrices,
      onPackagingChargeChange,
      newProductVariantPrices
    } = this.props
    let productPriceData = getPriceDataWithProductId(
      [...productVariantPrices],
      productId
    )

    if (!productPriceData) {
      productPriceData = getPriceDataWithProductId(
        [...newProductVariantPrices],
        productId
      )
    }
    const packagingCharge = productPriceData?.chargeValue || null

    return (
      <InputNumber
        size="middle"
        value={packagingCharge}
        min={0}
        onChange={value => {
          onPackagingChargeChange(productId, value)
        }}
        formatter={value => `₹ ${value}`.replace(DEAL_PRICE_REGEX, ',')}
      />
    )
  }

  render() {
    const { customisationGroups } = this.props
    const { nestedCustomisationGroups } = getNestedCustomizationGroups(customisationGroups)

    return (
      <>
        <Row className="mt-1">
          <Col span={9}>
            <Text level="caption" style={{ paddingLeft: '16px' }}>
              <IntlMessages id="groups" />
            </Text>
          </Col>
          <Col span={3}>
            <Text level="caption">
              <IntlMessages id="vegItem?" />
            </Text>
          </Col>
          <Col span={4}>
            <Text level="caption">
              <IntlMessages id="menu.basePrice" />
            </Text>
            <span className="requiredFieldRedColor">*</span>
          </Col>
          <Col span={4}>
            <Text level="caption">
              <IntlMessages id="menu.dealPrice" />
            </Text>
            <span className="requiredFieldRedColor">*</span>
          </Col>
          <Col span={4}>
            <Text level="caption">
              <IntlMessages id="menu.packagingCharge" />
            </Text>
          </Col>
        </Row>
        {nestedCustomisationGroups?.customisations.map((nCG, index) => {
          if (nCG.nestedCustomisations) {
            return (
              <div key={`collapse-wrapper-${index}`}>
                <Row>
                  <Col span={24}>
                    <StyledCollapse bordered={false} expandIcon={({ isActive }) => <StyledIcon type="caret-down" rotate={isActive ? 180 : 0} />} expandIconPosition={'left'}>
                      <Panel
                        key={`customization-panel-${index}`}
                        style={{ borderBottom: 0 }}
                        header={
                          <div>
                            <PanelLabel
                              level="body-2"
                              style={{
                                fontSize: '13px',
                                color: COLORS.DARK_TEXT
                              }}>
                              {nCG.label}
                            </PanelLabel>
                            <GroupTag
                              label={nestedCustomisationGroups.groupLabel}
                              style={{
                                marginLeft: '10px'
                              }}
                            />
                          </div>
                        }>
                        {nCG.nestedCustomisations.customisations.map((innerNCG, innerIndex) => {
                          if (innerNCG.nestedCustomisations) {
                            return (
                              <>
                                <StyledCollapse bordered={false} expandIcon={({ isActive }) => <StyledIcon type="caret-down" rotate={isActive ? 180 : 0} />} expandIconPosition={'left'} key={`panel-wrapper-${innerIndex}`}>
                                  <Panel
                                    style={{ borderBottom: 0 }}
                                    key={`inner-customization-panel-${innerIndex}`}
                                    header={
                                      <div>
                                        <PanelLabel
                                          level="body-2"
                                          style={{
                                            fontSize: '13px',
                                            color: COLORS.DARK_TEXT
                                          }}>
                                          {innerNCG.label}
                                        </PanelLabel>
                                        <GroupTag
                                          label={nCG.nestedCustomisations.groupLabel}
                                          style={{
                                            marginLeft: '10px'
                                          }}
                                        />
                                      </div>
                                    }>
                                    {innerNCG.nestedCustomisations.customisations.map((customization, innerMostIndex) => {
                                      return (
                                        <>
                                          <Row className="mt-1" key={`panel-content-wrapper-${innerMostIndex}`}>
                                            <Col span={9}>
                                              <div
                                                style={{
                                                  marginLeft: '16px'
                                                }}>
                                                <PanelLabel
                                                  level="body-2"
                                                  style={{
                                                    fontSize: '13px',
                                                    color: COLORS.DARK_TEXT
                                                  }}>
                                                  {customization.label}
                                                </PanelLabel>
                                                <GroupTag
                                                  label={innerNCG.nestedCustomisations.groupLabel}
                                                  style={{
                                                    marginLeft: '10px'
                                                  }}
                                                />
                                              </div>
                                            </Col>
                                            <Col span={3}>{this.formProductTag(customization.productId)}</Col>
                                            <Col span={4}>{this.formProductBasePrice(customization.productId)}</Col>
                                            <Col span={4}>{this.formProductDealPrice(customization.productId)}</Col>
                                            <Col span={4}>{this.formProductPackagingCharge(customization.productId)}</Col>
                                          </Row>
                                        </>
                                      )
                                    })}
                                  </Panel>
                                </StyledCollapse>
                                <DividerWithoutMargin />
                              </>
                            )
                          }

                          return (
                            <>
                              <Row className="mt-1">
                                <Col span={9}>
                                  <div style={{ marginLeft: '16px' }}>
                                    <PanelLabel
                                      level="body-2"
                                      style={{
                                        fontSize: '13px',
                                        color: COLORS.DARK_TEXT
                                      }}>
                                      {innerNCG.label}
                                    </PanelLabel>
                                    <GroupTag label={nCG.nestedCustomisations.groupLabel} style={{ marginLeft: '10px' }} />
                                  </div>
                                </Col>
                                <Col span={3}>{this.formProductTag(innerNCG.productId)}</Col>
                                <Col span={4}>{this.formProductBasePrice(innerNCG.productId)}</Col>
                                <Col span={4}>{this.formProductDealPrice(innerNCG.productId)}</Col>
                                <Col span={4}>{this.formProductPackagingCharge(innerNCG.productId)}</Col>
                              </Row>
                            </>
                          )
                        })}
                      </Panel>
                    </StyledCollapse>
                  </Col>
                </Row>
                <Row key={`collapse-divider-${index}`}>
                  <Col span={24}>
                    <DividerWithoutMargin />
                  </Col>
                </Row>
              </div>
            )
          }

          return (
            <div key={`customisation-wrapper-${index}`}>
              <Row className="mt-1">
                <Col span={9}>
                  <div style={{ marginLeft: '16px' }}>
                    <PanelLabel
                      level="body-2"
                      style={{
                        fontSize: '13px',
                        color: COLORS.DARK_TEXT
                      }}>
                      {nCG.label}
                    </PanelLabel>
                    <GroupTag label={nestedCustomisationGroups.groupLabel} style={{ marginLeft: '10px' }} />
                  </div>
                </Col>
                <Col span={3}>{this.formProductTag(nCG.productId)}</Col>
                <Col span={4}>{this.formProductBasePrice(nCG.productId)}</Col>
                <Col span={4}>{this.formProductDealPrice(nCG.productId)}</Col>
                <Col span={4}>{this.formProductPackagingCharge(nCG.productId)}</Col>
              </Row>
            </div>
          )
        })}
      </>
    )
  }
}

export default injectIntl(SelectedCustomisations)
