import { Icon } from '@ant-design/compatible'
import { Col, List, Row } from 'antd'
import React from 'react'

import DragIcon from './DragIcon'

export const ProductItem = props => {
  const {
    distance,
    product,
    elem,
    productFormDetails,
    onProductSelection
  } = props

  return (
    <Row
      className={
        productFormDetails && productFormDetails.id === product.product.id
          ? 'selected-item'
          : ''
      }
      onClick={() => {
        onProductSelection(product.product, elem)
      }}>
      <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '50px' }}>
          <DragIcon className="dragIcon rightIcon" />
        </div>
        <List.Item>{product.product.name}</List.Item>
      </Col>
      <Col span={4}>
        <span className="rightIcon">
          <Icon type="right" />
        </span>
      </Col>
    </Row>
  )
}
