import { message } from 'antd'
import { NetworkStatus } from 'components'
import Loader from 'components/simple/loader'
import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getOrgDetails, intlAlertMessage } from 'Utils'
import { DEFAULT_CONSOLE_VIEW_CONFIG } from 'Utils/constants'

const ActiveOrders = React.lazy(() => import('../pages/activeOrders'))
const CatalogDetail = React.lazy(() => import('../pages/menu/catalogDetails/menu'))
const Catalogs = React.lazy(() => import('../pages/menu/catalogs'))
const Customize = React.lazy(() => import('../pages/menu/customize_new'))
const OrderHistory = React.lazy(() => import('../pages/orderHistory'))
const Onboarding = React.lazy(() => import('../pages/onboarding/Onboarding_new'))
const OrderReports = React.lazy(() => import('../pages/orderReports'))
const Profile = React.lazy(() => import('../pages/profile'))
const Store = React.lazy(() => import('../pages/store/store'))
const Stores = React.lazy(() => import('../pages/stores/storeList'))
const MiniUrlPage = React.lazy(() => import('../pages/onboarding/Onboarding_new/components/CreateMiniUrlPage'))
const GpayRegistrationPage = React.lazy(() => import('../pages/onboarding/components/GpayRegistrationPage1'))
const GpayRegistrationMerchant = React.lazy(() => import('../pages/onboarding/components/GpayRegistrationPage2'))



function OrderXRoutes({ intl }) {
  const orgDetails = getOrgDetails()
  const { store_management, menu_management, order_management, order_history, pay_direct_transactions, reports } = orgDetails?.menuConfiguration || DEFAULT_CONSOLE_VIEW_CONFIG

  const routes = [
    {
      name: 'Store List',
      path: '/orderx/store-list',
      exact: true,
      component: Stores,
      meta: {
        isEnabled: store_management,
      }
    },
    {
      name: 'Store',
      path: '/orderx/store',
      exact: true,
      component: Store,
      meta: {
        isEnabled: store_management,
      }
    },
    {
      name: 'Edit Store',
      path: '/orderx/store/:id/edit',
      exact: true,
      component: Store,
      meta: {
        isEnabled: store_management,
      },
    },
    {
      name: 'Menu List',
      path: '/orderx/menu-list',
      exact: true,
      component: Catalogs,
      meta: {
        isEnabled: menu_management,
      },
    },
    {
      name: 'Menu',
      path: '/orderx/menu-list/:id/detail',
      exact: true,
      component: CatalogDetail,
      meta: {
        isEnabled: menu_management,
      },
    },
    {
      name: 'Edit Menu',
      path: '/orderx/menu-list/:id/customize',
      exact: true,
      component: Customize,
      meta: {
        isEnabled: menu_management,
      },
    },
    {
      name: 'Orders',
      path: '/orderx/orders',
      exact: true,
      component: ActiveOrders,
      meta: {
        isEnabled: order_management,
      }
    },
    {
      name: 'Order History',
      path: '/orderx/order-history',
      exact: true,
      component: OrderHistory,
      meta: {
        isEnabled: order_history,
      }
    },
    {
      name: 'Order Reports',
      path: '/orderx/reports',
      exact: true,
      component: OrderReports,
      meta: {
        isEnabled: reports.food_order_settlements || pay_direct_transactions,
      }
    }
  ]

  return (
    <div className="OrderX-Main">
      <Suspense fallback={<Loader intl={intl} />}>
        {(window.navigator['connection'] || window.navigator['mozConnection'] || window.navigator['webkitConnection']) && <NetworkStatus />}
        <Switch>
          {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/register`} /> */}
          {/* ************ Register *************** */}
          {routes.map(({ meta, name, ...rest }, index) => meta.isEnabled && <Route key={index} {...rest} />)}
          <Route exact path={'/orderx/profile'} component={Profile} />

          <Route exact path={'/orderx/onboarding'} component={Onboarding} />
          <Route exact path={'/orderx/onboarding/create-mini-url'} component={MiniUrlPage} />
          <Route exact path={'/orderx/onboarding/gpay-registration'} component={GpayRegistrationPage} />
          <Route exact path={'/orderx/onboarding/gpay-merchant'} component={GpayRegistrationMerchant} />

          <Route
            render={() => {
              message.info(
                intlAlertMessage({
                  id: 'redirect.to.home',
                  intl
                })
              )

              return <Redirect from="*" to="/" />
            }}>
            {/* <Route component={Onboarding} />
            <Redirect from="*" to="/orderx/onboarding" /> */}
          </Route>
        </Switch>
      </Suspense>
    </div>
  )
}
export default OrderXRoutes
