function MetabaseReports({ dashboardURL, isTitled = true }) {
  const org = JSON.parse(localStorage.getItem('userData'))

  return (
    <div style={{ margin: '0 20px' }}>
      <iframe
        src={`${dashboardURL}?org_id=${org.organization.id}#hide_parameters=org_id&titled=${isTitled}`}
        frameBorder="0"
        width="100%"
        height="500"
        style={{ backgroundColor: 'transparent' }}></iframe>
    </div>
  )

}

export default MetabaseReports