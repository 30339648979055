import { Icon } from '@ant-design/compatible'
import { Input } from 'antd'
import { Button, IntlMessages } from 'components'
import * as React from 'react'

function PercentageChangeInput({ onSubmit, onValueChange }) {
  const [percentageChange, setPercentageChange] = React.useState('0')
  const handleChange = newVal => {
    if (newVal && (Number(newVal) < -100 || Number(newVal) > 100)) return
    setPercentageChange(newVal)
    if (newVal !== '') {
      onValueChange(Number(newVal))
    }
  }
  const percentageChangeNumber = percentageChange ? Number(percentageChange) : 0

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '72px', fontSize: '13px' }}>
        <IntlMessages id="menu.percentChangePrice" />
      </div>
      <div className="percentage-input">
        <div
          className="control-wrapper"
          onClick={() => handleChange(percentageChangeNumber - 1)}>
          <Icon type="minus" />
        </div>
        <Input
          type="number"
          min={-100}
          max={100}
          className="number-input"
          value={percentageChange}
          onChange={e => handleChange(e.target.value)}
        />
        <div
          className="control-wrapper"
          onClick={() => handleChange(percentageChangeNumber + 1)}>
          <Icon type="plus" />
        </div>
      </div>
      <Button
        type="primary"
        style={{ margin: 0, width: '82px', height: '39px' }}
        onClick={() => onSubmit(percentageChangeNumber)}>
        <IntlMessages id={'apply'} />
      </Button>
    </div>
  )
}

export default PercentageChangeInput
