import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Button, Col, message, Modal, Row } from 'antd'
import { IntlMessages } from 'components'
import { History } from 'history'
import jwt from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { arrayMove, SortableContainer } from 'react-sortable-hoc'
import { addMenuTimingsForCategory, createdCategory, getAllProductCategory, removeMenuTimingsForCategory, updateCategory, updateCategorySortSeq, updateMenuTimingsForCategory } from 'services'
import { intlAlertMessage } from 'Utils'
import { PRODUCT_TYPE_ENUM } from 'Utils/constants'
import { getFileSystemId } from 'Utils/localStorageHandlers'

import { HeaderText } from '../../../styles/index'
import { getCategoryData, getUpdateCategorySortSeqInput, republishCatalog, setErrorMessage, setSuccessMessage } from '../../../Utils/menuUtils'
import CategoriesList from '../components/CategoriesList'
import CategoryForm from '../components/CategoryForm'

interface ICategoriesProps extends ApolloProviderProps<any> {
  history: History
  fileSystems: any
  onCategoryChange: any
  onTargetLocationChange: any
  onFormDataChange: any
  intl: any
  onTabChange: any
}

interface ICategoriesState {
  categories: any
  isListed: boolean
  isNewCategory: boolean
  categoryForm: Object
  catalog: Object
  isEdit: boolean
  categoryDetails: object
  loading?: boolean
  isFetchingCategory: boolean
  isOrder: boolean
  showConfirmModal: boolean
  selectedMenuTimingsforCategory: string
  isMenuTimingEnable: boolean
  menuTimings: any
}

class Categories extends Component<ICategoriesProps, ICategoriesState> {
  org_id: string

  imageData: object

  isFormDataChange: boolean

  unblock: any

  categoryIndexToSelect: any

  fileSystemId: string

  constructor(props) {
    super(props)
    this.imageData = {}
    this.isFormDataChange = false
    this.unblock = {}
    this.categoryIndexToSelect = null
    this.state = {
      categories: [],
      isListed: true,
      loading: false,
      isNewCategory: false,
      isEdit: false,
      catalog: {},
      categoryForm: {
        name: '',
        description: '',
        menuTiming: {},
        isMenuTimingEnable: false
      },
      categoryDetails: {},
      isFetchingCategory: false,
      isOrder: false,
      showConfirmModal: false,
      selectedMenuTimingsforCategory: '',
      menuTimings: [],
      isMenuTimingEnable: false
    }
  }

  componentDidMount() {
    const jwtToken: any = localStorage.getItem('jwt')

    this.org_id = jwt.decode(jwtToken)['org_id']
    const catalog = this.props.history.location.state ? this.props.history.location.state['catalog'] : {}

    this.fileSystemId = getFileSystemId()

    this.setState({ catalog })
    this.getAllCategoryByCatalog(catalog.id)
    this.unblock = this.props.history.block(targetLocation => {
      this.props.onTargetLocationChange(targetLocation)
      if (this.isFormDataChange) {
        return false
      }
    })
  }

  UNSAFE_componentWillUnMount() {
    this.unblock && this.unblock()
  }

  getAllCategoryByCatalog = async catalogId => {
    this.setState({ isFetchingCategory: true })
    try {
      const allCategoriesResponse = await getAllProductCategory(this.props.client, { id: catalogId })
      const listedCategories = allCategoriesResponse?.data?.categories.filter(val => val.listable === true)

      this.props.onCategoryChange(listedCategories)
      const listedRegularCategories = listedCategories.filter(category => category.productType === PRODUCT_TYPE_ENUM.PRODUCT)

      this.setState({
        categories: listedRegularCategories,
        isFetchingCategory: false,
        isNewCategory: true
      })
      this.isFormDataChange = false
      this.onCategorySelection(listedRegularCategories[0])
    } catch (err) {
      console.warn('Category List Error:', err)
      this.setState({ isFetchingCategory: false })
    }
  }

  onChange = (type: string, value: any) => {
    const { categoryForm } = this.state

    categoryForm[type] = value
    this.isFormDataChange = true
    this.props.onFormDataChange(true, this.unblock)
    this.setState((prevState: Readonly<ICategoriesState>, props: Readonly<ICategoriesProps>) => {
      return {
        ...prevState,
        categoryForm
      }
    })
  }

  toggleNewCategory = () => {
    const { categoryForm, categoryDetails } = this.state

    if (!categoryDetails['id'] && categoryForm['name']) {
      message.info(
        intlAlertMessage({
          id: 'category.message.newCategoryInfo',
          intl: this.props.intl
        })
      )

      return
    }
    if (this.isFormDataChange) {
      this.setState({ showConfirmModal: true })
      this.categoryIndexToSelect = null

      return
    }
    categoryForm['name'] = ''
    categoryForm['description'] = ''
    categoryForm['menuTiming'] = {}
    categoryForm['isMenuTimingEnable'] = false
    this.isFormDataChange = false
    this.categoryIndexToSelect = null
    this.props.onFormDataChange(false, this.unblock)
    this.setState({
      categoryDetails: {},
      categoryForm,
      isListed: true,
      isEdit: false,
      isNewCategory: true
    })
  }

  toggleOrderWrapper = () => {
    this.setState({
      isOrder: !this.state.isOrder
    })
  }

  updateListableProp = async () => {
    const { isListed, isEdit, categoryDetails, categories } = this.state

    this.setState({ isListed: !isListed })
    if (isEdit) {
      try {
        const updateCategoryInput = {
          id: categoryDetails['id'],
          listable: !isListed,
          organizationId: this.org_id
        }
        const updateCategoryResponse = await updateCategory(this.props.client, updateCategoryInput)
        const newUpdateCategory = updateCategoryResponse.data.updateCategory
        const categoryIndex = categories.findIndex(category => category.id === newUpdateCategory.id)

        if (categoryIndex !== -1) {
          categories[categoryIndex] = {
            ...categories[categoryIndex],
            listable: newUpdateCategory.listable
          }
        }
        this.setState({
          categories
        })
        republishCatalog(this.props.client)
      } catch (err) {
        this.setState({
          isListed
        })
        console.warn('Update listable Error:', err)
      }
    }
  }

  isCategoryNameExist = () => {
    const { categoryForm, isEdit, categories, categoryDetails } = this.state
    const newCategoryName = categoryForm['name'].toLowerCase().trim()
    let currentCategories = [...categories]

    if (isEdit) {
      currentCategories = categories.filter(c => c.id !== categoryDetails['id'])
    }
    if (newCategoryName && currentCategories.length && currentCategories.map(category => category.name.toLowerCase().trim()).indexOf(newCategoryName) !== -1) {
      setErrorMessage('category.errMsg.categoryNameAlreadyExists', this.props.intl)

      return true
    }
  }

  isMenuTimingNotAvailable = () => {
    const { categoryForm } = this.state

    if (categoryForm['isMenuTimingEnable'] && (!categoryForm['menuTiming'] || !Object.keys(categoryForm['menuTiming']).length)) {
      setErrorMessage('category.errMsg.selectTimings', this.props.intl)

      return true
    }
  }

  menuTimingUpdate = id => {
    const { categoryForm, categoryDetails } = this.state

    if (categoryDetails['menuTimings'] && categoryForm['isMenuTimingEnable']) {
      this.updateMenuTimingsForCategory(id)
    } else if (!categoryForm['isMenuTimingEnable']) {
      this.removeMenuTimingsForCategory(id)
    } else if (categoryForm['menuTiming']) {
      this.addMenuTimingsForCategory(id)
    }
  }

  createOrUpdateCategory = async () => {
    const { categoryForm, isEdit, isListed, categoryDetails } = this.state

    if (this.isMenuTimingNotAvailable()) {
      return
    }
    if (this.isCategoryNameExist()) {
      return
    }
    this.setState({ loading: true })
    const categoryInput = {
      id: categoryDetails['id'],
      name: categoryForm['name'],
      description: categoryForm['description'],
      listable: isListed,
      organizationId: this.org_id
    }

    if (!isEdit) {
      this.onCreateNewCategory(categoryInput)
    } else {
      this.updateCategory(categoryInput)
    }
  }

  updateCategory = async categoryInput => {
    const { categories } = this.state

    try {
      const updateCategoryResponse = await updateCategory(this.props.client, categoryInput)
      const updatedCategory = updateCategoryResponse.data.updateCategory

      this.menuTimingUpdate(categoryInput.id)
      const newCategoryData = getCategoryData(updatedCategory)
      const categoryIndex = categories.findIndex(category => category.id === categoryInput.id)

      categories[categoryIndex] = {
        ...categories[categoryIndex],
        ...newCategoryData
      }
      setSuccessMessage('category.successMsg.categoryUpdatedSuccessfully', this.props.intl)
      this.isFormDataChange = false
      this.props.onCategoryChange(categories)
      this.props.onFormDataChange(false, this.unblock)
      this.setState({
        categories,
        loading: false,
        categoryDetails: newCategoryData
      })
      republishCatalog(this.props.client)
    } catch (err) {
      this.setState({
        loading: false
      })
      console.warn('Update Category Error:', err)
    }
  }

  onCreateNewCategory = async categoryInput => {
    const { categoryForm, catalog, categories } = this.state
    const createCategoryInput = {
      status: 'ACTIVE',
      catalogId: catalog['id'],
      code: categoryForm['name']
        .split(' ')
        .join('_')
        .toLowerCase()
    }

    try {
      const createCategoryResponse = await createdCategory(this.props.client, categoryInput, createCategoryInput)
      const newCategory = createCategoryResponse.data.createCategory

      if (categoryForm['isMenuTimingEnable'] && categoryForm['menuTiming']) {
        this.addMenuTimingsForCategory(newCategory.id)
      } else if (!this.state.isMenuTimingEnable) {
        this.removeMenuTimingsForCategory(newCategory.id)
      }
      categories.unshift(newCategory)
      setSuccessMessage('category.successMsg.categoryCreatedSuccessfully', this.props.intl)
      this.isFormDataChange = false
      this.props.onCategoryChange(categories)
      this.props.onFormDataChange(false, this.unblock)
      this.setState({
        categories,
        loading: false,
        categoryDetails: newCategory,
        isNewCategory: false
      }, this.updateCategorySortSequence)
    } catch (err) {
      this.setState({
        loading: false,
        categoryDetails: {}
      })
      console.warn('Create Category Error:', err)
    }
  }

  updateCategorySortSequence = async () => {
    const updateCategorySortSeqInput = getUpdateCategorySortSeqInput(this.state.categories, this.org_id)

    try {
      await updateCategorySortSeq(this.props.client, updateCategorySortSeqInput)
      republishCatalog(this.props.client)
    }
    catch(err) {
      this.setState({
        categoryDetails: {}
      })
      console.warn('Create Category Error:', err)
    }
  }

  updateMenuTimingsForCategory = async categoryId => {
    try {
      const { categoryForm, categories, categoryDetails } = this.state
      const menuTimingCode = categoryForm['menuTiming'].code
      const updateMenuTimingRes = await updateMenuTimingsForCategory(this.props.client, categoryId, menuTimingCode)

      categoryDetails['menuTimings'] = updateMenuTimingRes?.data?.updateMenuTimingsForCategory?.menuTimings
      categories.forEach((category, i) => {
        if (category.id === categoryId) {
          categories[i].menuTimings = updateMenuTimingRes?.data?.updateMenuTimingsForCategory?.menuTimings

          return true // Breaks out of he loop
        }
      })
      categoryForm['menuTiming'] = updateMenuTimingRes?.data?.updateMenuTimingsForCategory?.menuTimings || {}
      this.setState({
        categories,
        categoryForm,
        categoryDetails
      })
    } catch (error) {
      console.warn(error)
    }
  }

  removeMenuTimingsForCategory = async categoryId => {
    try {
      const { categoryForm, categoryDetails, categories } = this.state
      const menuTimingCode = categoryDetails['menuTimings'].code

      if (categoryDetails['menuTimings']) {
        await removeMenuTimingsForCategory(this.props.client, categoryId, menuTimingCode)
        categories.forEach((category, i) => {
          if (category.id === categoryId) {
            categories[i].menuTimings = null

            return true // Breaks out of he loop
          }
        })
        this.setState({
          categories,
          categoryForm
        })
      } else {
        categoryForm['menuTiming'] = {}
        this.setState({
          categoryForm
        })
      }
    } catch (error) {
      console.log('Error', error)
    }
  }

  addMenuTimingsForCategory = async categoryId => {
    try {
      const { categoryForm, categories, categoryDetails } = this.state
      const menuTimingCode = categoryForm['menuTiming'].code
      const addMenuTimingRes = await addMenuTimingsForCategory(this.props.client, categoryId, menuTimingCode)

      categoryDetails['menuTimings'] = addMenuTimingRes.data.addMenuTimingsForCategory.menuTimings
      categoryForm['menuTiming'] = addMenuTimingRes.data.addMenuTimingsForCategory.menuTimings || {}
      categories.forEach((category, i) => {
        if (category.id === categoryId) {
          categories[i].menuTimings = addMenuTimingRes.data.addMenuTimingsForCategory.menuTimings

          return true // Breaks out of he loop
        }
      })
      this.setState({
        categories,
        categoryForm,
        categoryDetails
      })
    } catch (error) {
      console.log(error)
    }
  }

  onCategorySelection = category => {
    const { categoryForm, categories, isEdit } = this.state

    if (this.isFormDataChange) {
      this.setState({ showConfirmModal: true })
      this.categoryIndexToSelect = categories.findIndex(c => c.id === category.id)

      return
    }
    categoryForm['name'] = category?.name
    categoryForm['description'] = category?.description
    categoryForm['menuTiming'] = category?.menuTimings
    categoryForm['isMenuTimingEnable'] = Boolean(category?.menuTimings)
    this.isFormDataChange = false
    this.categoryIndexToSelect = null
    this.props.onFormDataChange(false, this.unblock)
    this.setState({
      isNewCategory: true,
      categoryForm,
      isEdit: category.id ? true : isEdit,
      isListed: Boolean(category.listable),
      categoryDetails: category
    })
  }

  updateReorderedCategory = async () => {
    const { categories } = this.state

    this.setState({
      isFetchingCategory: true
    })
    const updateCategorySortSeqInput = getUpdateCategorySortSeqInput(categories, this.org_id)

    try {
      await updateCategorySortSeq(this.props.client, updateCategorySortSeqInput)
      setSuccessMessage('category.successMsg.categoryOrderedSuccessfully', this.props.intl)
      this.toggleOrderWrapper()
      this.setState({
        isFetchingCategory: false
      })
      republishCatalog(this.props.client)
    } catch (err) {
      this.setState({
        isFetchingCategory: false,
        categoryDetails: {}
      })
      console.warn('Order Category Error:', err)
    }
  }

  handleItemWarningModalCancel = () => {
    this.setState({ showConfirmModal: false })
  }

  goToCategory = () => {
    const { categories } = this.state

    this.handleItemWarningModalCancel()
    this.isFormDataChange = false
    if (this.categoryIndexToSelect) {
      this.onCategorySelection(categories[this.categoryIndexToSelect])
    } else {
      this.toggleNewCategory()
    }
  }

  onSortEnd = e => {
    const { categories } = this.state
    const newCategories = arrayMove(categories, e.oldIndex, e.newIndex)

    this.onCategorySelection(categories[e.oldIndex])
    this.setState({
      categories: newCategories
    })
    this.updateReorderedCategory()
  }

  render() {
    const { categories, isListed, categoryForm, isEdit, loading, categoryDetails, isFetchingCategory, showConfirmModal } = this.state
    const { onTabChange } = this.props
    const SortableCategoriesList = SortableContainer(CategoriesList)

    return (
      <>
        <Col span={6} className="list-wrapper">
          <HeaderText type="H5" margin="0 0 20px 0" color="#000">
            <IntlMessages id="menu.categories" />
          </HeaderText>
          <Button
            className="add-new-btn"
            onClick={() => this.toggleNewCategory()}>
            <IntlMessages id="category.addNewCategory" />
          </Button>
          <SortableCategoriesList
            onSortEnd={this.onSortEnd}
            distance={1}
            categories={categories}
            categoryDetails={categoryDetails}
            isFetchingCategory={isFetchingCategory}
            onCategorySelection={cate => this.onCategorySelection(cate)}
          />
        </Col>
        <CategoryForm
          isListed={isListed}
          categoryForm={categoryForm}
          onTabChange={onTabChange}
          isEdit={isEdit}
          loading={loading}
          updateListableProp={this.updateListableProp}
          onChange={(label, value) => this.onChange(label, value)}
          createOrUpdateCategory={() => this.createOrUpdateCategory()}
          client={this.props.client}
          intl={this.props.intl}
        />
        <Modal
          visible={showConfirmModal}
          key="item-form-modal"
          title={<IntlMessages id="category.confirmationMsg.leaveThisPage" />}
          onCancel={this.handleItemWarningModalCancel}
          footer={[
            <div key="footer">
              <Button
                key="stay"
                type="default"
                size="large"
                onClick={this.handleItemWarningModalCancel}>
                <IntlMessages id="stay" />
              </Button>
              <Button
                key="leave"
                type="primary"
                danger
                size="large"
                onClick={() => {
                  this.goToCategory()
                }}>
                <IntlMessages id="leave" />
              </Button>
            </div>
          ]}>
          <div>
            <IntlMessages id="category.infoMsg.changesMayNotSave" />.
          </div>
        </Modal>
      </>
    )
  }
}

const CategoriesComponent = compose(withRouter, withApollo)(Categories)

export { CategoriesComponent }
