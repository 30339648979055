import React from 'react'
import { MENU_HELP_URL } from 'Utils/constants'

import CommonOnboardingCard from './commonOnboardingCard'

interface YourMenuCardProps {
  intl: any
  pending: boolean
  onButtonClick: any
  disable: boolean
}
interface YourMenuCardState {}

class YourMenuCard extends React.Component<YourMenuCardProps, YourMenuCardState> {
  render() {
    const { pending } = this.props

    return <CommonOnboardingCard {...this.props} title="onboarding.yourMenuCard.title" description={pending ? 'onboarding.yourMenuCard.description' : 'onboarding.yourMenuCard.description.success'} buttonText="button.verify" helpUrl={MENU_HELP_URL} required={true} />
  }
}
export default YourMenuCard
