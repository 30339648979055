import { Icon } from '@ant-design/compatible'
import { ApolloClient } from '@apollo/client'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Input } from 'antd'
import { ClientManager } from 'appApollo/client'
import {
  Button,
  Card,
  ImageUpload,
  IntlMessages,
  Text,
  Title
} from 'components'
import { History } from 'history'
import React from 'react'
import { injectIntl } from 'react-intl'
import { getFileSystems, updatePMSOrganization } from 'services'
import styled from 'styled-components'
import { getOrgDetails, intlAlertMessage, isAdmin, setOrgDetails } from 'Utils'
import {
  ASSOCIATION,
  BROWSE_COVER_IMG,
  COLORS,
  GSTIN_REGEX,
  ORG_STATUS,
  PAN_NUMBER_REGEX
} from 'Utils/constants'
import { getGpayDetails, getUploadApiConfig,openCreateWindowWithPost, updateGpayDetailsForm } from 'Utils/onboardingUtils'

import GpayDetailsForm from './GpayDetails'

interface GpayRegistrationPageProps extends ApolloProviderProps<any> {
  history: History
  intl: any
  fileSystemId: string
}

interface GpayRegistrationPageState {
  fileTypeError: boolean
  fileSizeError: boolean
  fileDimensionError: boolean
  legalBusinessName: string
  panNo: string
  isPanAccurate: boolean
  isGSTINAccurate: boolean
  GSTIN: string
  fileSystemId: string
  isImgUpload: boolean
  imageUrl: string
  savingData: boolean
}
const Header = styled.div`
  display: flex;
  align-items: baseline;
  align-self: center;
  -webkit-align-self: initial;
  margin: 0 15%;
`
const CardBlock = styled.div`
  display: flex;
  flex-direction: row;
`
const ContentBlock = styled.div`
  padding-left: 26px;
  position: relative;
`
const ErrorBlock = styled.div`
  bottom: 0;
  position: absolute;
`
const Container = styled.div`
  margin: 2% 5%;
  display: flex;
  flex-direction: column;
`
const MainBlock = styled.div`
  margin: 1% 5%;
  width: 58%;
  display: flex;
  flex-direction: column;
  align-self: center;
`
const BankDetailsCard = styled.div`
  display: flex;
`
const CaptionStyled = styled.div`
  margin: 2% 0;
`
const GSTBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
  margin-right: 46px;
  width: 45%;
  &:last-child {
    margin-right: 0;
  }
`
const RegistrationForm = styled.div`
  margin-top: 3%;
`
const BackButton = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${COLORS.NORMAL_TEXT};
  padding: 0px 5% 0 1%;
  cursor: pointer;
`

class GpayRegistrationPage extends React.Component<
  GpayRegistrationPageProps,
  GpayRegistrationPageState
> {
  pmsClient: ApolloClient<any>

  constructor(props: GpayRegistrationPageProps) {
    super(props)
    this.pmsClient = ClientManager.getInstance().pms
    const fileSystemId = this.props?.history?.location?.state

    this.state = {
      fileTypeError: false,
      fileSizeError: false,
      fileDimensionError: false,
      legalBusinessName: '',
      panNo: '',
      isPanAccurate: false,
      GSTIN: '',
      isGSTINAccurate: false,
      fileSystemId,
      isImgUpload: false,
      imageUrl: null,
      savingData: false
    }
  }

  componentDidMount() {
    const organization = getOrgDetails()

    if (
      !isAdmin() ||
      (isAdmin() &&
        organization &&
        organization.onboardingStatus !== ORG_STATUS.INITIATED)
    ) {
      this.props.history.push({ pathname: '/orderx/orders' })
    } else if (organization.associations && organization.associations.length) {
      const gpayDetails = getGpayDetails(organization.associations)

      if (gpayDetails) {
        const {
          featureImage,
          gstNumber,
          legalBusinessName,
          panNumber
        } = gpayDetails
        const verifyPan = PAN_NUMBER_REGEX.test(String(panNumber))
        const verifyGSTIN = GSTIN_REGEX.test(String(gstNumber))

        this.setState({
          imageUrl: featureImage,
          GSTIN: gstNumber,
          legalBusinessName,
          panNo: panNumber,
          isPanAccurate: verifyPan,
          isGSTINAccurate: verifyGSTIN
        })
      }
    }
  }

  saveCoverImage = url => {
    this.resetErrors()
    this.setState({
      imageUrl: url
    })
  }

  resetErrors = () => {
    this.setState({
      fileTypeError: false,
      fileSizeError: false,
      fileDimensionError: false
    })
  }

  onPanChangeHandler = e => {
    const verifyPan = PAN_NUMBER_REGEX.test(String(e.target.value))

    this.setState({
      panNo: e.target.value.trim(),
      isPanAccurate: verifyPan
    })
  }

  onGSTChangeHandler = e => {
    const verifyGSTIN = GSTIN_REGEX.test(String(e.target.value))

    this.setState({
      GSTIN: e.target.value.trim(),
      isGSTINAccurate: verifyGSTIN
    })
  }

  goBackToOnboarding = () => {
    localStorage.removeItem('isOnboarding')
    this.props.history.replace({
      pathname: '/orderx/onboarding',
      state: {
        isOnboarding: false,
        fromStoreOrCatalog: true
      }
    })
  }

  saveGpayDetails = async () => {
    const { id } = getOrgDetails()
    const { GSTIN, imageUrl, panNo, legalBusinessName } = this.state

    try {
      const gPayRegInput = {
        id,
        associations: [
          {
            orgId: id,
            type: ASSOCIATION.CHANNEL,
            terms: {
              featureImage: imageUrl,
              legalBusinessName: legalBusinessName.trim(),
              panNumber: panNo,
              gstNumber: GSTIN
            }
          }
        ]
      }

      this.setState({ savingData: true })

      const updateRes = await updatePMSOrganization(
        this.pmsClient,
        gPayRegInput
      )

      this.setState({ savingData: false })
      if (updateRes && updateRes.data && updateRes.data.updateOrganization) {
        const orgDetails = updateRes.data.updateOrganization.organization

        setOrgDetails(orgDetails)
        updateGpayDetailsForm(orgDetails)
        openCreateWindowWithPost(this.props.history)
      }
    } catch (err) {
      this.setState({ savingData: false })
      console.log('Save Google pay details error', err)
    }
  }

  render() {
    const {
      fileTypeError,
      fileSizeError,
      fileDimensionError,
      panNo,
      legalBusinessName,
      fileSystemId,
      imageUrl,
      isGSTINAccurate,
      isPanAccurate,
      savingData,
      GSTIN
    } = this.state
    const isValidGSTIN = GSTIN ? isGSTINAccurate : true
    const { organizationId, token } = getUploadApiConfig()

    return (
      <Container>
        <Header>
          <BackButton onClick={() => this.goBackToOnboarding()}>
            <Icon
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                color: COLORS.NORMAL_TEXT,
                padding: '0px 3% 0 1%',
                cursor: 'pointer'
              }}
              type="arrow-left"
            />
          </BackButton>
          <Title level="h2">
            <IntlMessages id="googlePayRegistration" />
          </Title>
        </Header>
        <MainBlock>
          <Card>
            <CardBlock>
              <ImageUpload
                key={imageUrl}
                allowedFileSize={5}
                onFileTypeError={() => {
                  this.resetErrors()
                  this.setState({
                    fileTypeError: true
                  })
                }}
                onSizeError={() => {
                  this.resetErrors()
                  this.setState({
                    fileSizeError: true
                  })
                }}
                onDimensionError={() => {
                  this.resetErrors()
                  this.setState({
                    fileDimensionError: true
                  })
                }}
                onUpload={url => this.saveCoverImage(url)}
                validateDimension={true}
                uploadApiConfig={{
                  organizationId,
                  authToken: token,
                  graphqlUrl: process.env.REACT_APP_WCORE_URL,
                  systemId: fileSystemId
                }}
                fixedDimension={false}
                cropWidth={800}
                cropHeight={450}
                previewUrl={imageUrl}
              />
              <ContentBlock>
                <Title level={'h3'}>
                  <IntlMessages id="featureImage" />
                </Title>
                <Text level={'body-2'}>
                  <IntlMessages id="gpaypage1Info" />
                </Text>
                <a
                  href={BROWSE_COVER_IMG}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Text level={'body-2'} style={{ color: COLORS.SUCCESS }}>
                    <IntlMessages id="gallery" />
                  </Text>
                </a>
                <ErrorBlock>
                  {fileTypeError && (
                    <Text level="caption" style={{ color: COLORS.ERROR }}>
                      <IntlMessages id="error.onboarding.fileTypeError" />
                    </Text>
                  )}
                  {fileSizeError && (
                    <Text level="caption" style={{ color: COLORS.ERROR }}>
                      <IntlMessages id="error.onboarding.fileSizeError" />
                    </Text>
                  )}
                  {fileDimensionError && (
                    <Text level="caption" style={{ color: COLORS.ERROR }}>
                      <IntlMessages id="error.onboarding.coverImage.dimensionError" />
                    </Text>
                  )}
                </ErrorBlock>
              </ContentBlock>
            </CardBlock>
          </Card>
          <RegistrationForm>
            <Card>
              <Title level={'h5'}>
                <IntlMessages id="registerForm" />
              </Title>
              <BankDetailsCard>
                <GSTBlock>
                  <Text level={'caption'}>
                    <IntlMessages id="legalBusinessName" />
                  </Text>
                  <Input
                    placeholder={intlAlertMessage({
                      id: 'tamak',
                      intl: this.props.intl
                    })}
                    style={{ margin: '5px 0', width: '100%' }}
                    value={legalBusinessName}
                    onChange={e =>
                      this.setState({
                        legalBusinessName: e.target.value
                      })
                    }
                  />
                </GSTBlock>
                <GSTBlock>
                  <Text level={'caption'}>
                    <IntlMessages id="panNo" />.
                  </Text>
                  <Input
                    placeholder={intlAlertMessage({
                      id: 'AAAAAA0000A',
                      intl: this.props.intl
                    })}
                    style={{ margin: '5px 0', width: '100%' }}
                    value={panNo}
                    onChange={e => this.onPanChangeHandler(e)}
                    suffix={
                      panNo ? (
                        this.state.isPanAccurate ? (
                          <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: COLORS.SUCCESS }}
                          />
                        ) : (
                          <Icon
                            theme="filled"
                            type="close-circle"
                            style={{
                              color: COLORS.ERROR
                            }}
                          />
                        )
                      ) : (
                        <span />
                      )
                    }
                  />
                </GSTBlock>
              </BankDetailsCard>
              <GSTBlock>
                <Text level={'caption'}>
                  <IntlMessages id="GSTIN" />
                </Text>
                <Input
                  placeholder={intlAlertMessage({
                    id: 'XXXXXXXXX00',
                    intl: this.props.intl
                  })}
                  style={{ margin: '1% 0', flexBasis: '40%' }}
                  onChange={e => this.onGSTChangeHandler(e)}
                  value={GSTIN}
                  suffix={
                    this.state.GSTIN ? (
                      this.state.isGSTINAccurate ? (
                        <Icon
                          type="check-circle"
                          theme="filled"
                          style={{ color: COLORS.SUCCESS }}
                        />
                      ) : (
                        <Icon
                          theme="filled"
                          type="close-circle"
                          style={{
                            color: COLORS.ERROR
                          }}
                        />
                      )
                    ) : (
                      <span />
                    )
                  }
                />
              </GSTBlock>
            </Card>
          </RegistrationForm>
          <CaptionStyled>
            <Text level={'caption'}>
              <IntlMessages id="gpay.captionForm1" />
            </Text>
          </CaptionStyled>
          <div>
            <Button
              ghost={false}
              onClick={this.saveGpayDetails}
              disabled={
                !(
                  legalBusinessName &&
                  isValidGSTIN &&
                  isPanAccurate &&
                  imageUrl
                )
              }
              size="default"
              type="primary"
              loading={savingData}>
              <IntlMessages id="signInGoogleBusiness" />
            </Button>
          </div>
        </MainBlock>
        <GpayDetailsForm />
      </Container>
    )
  }
}
// @ts-ignore
export default injectIntl(withApollo(GpayRegistrationPage))
