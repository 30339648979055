import '../../../pages/activeOrders/index.css'

import { Icon } from '@ant-design/compatible'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { Button as AntdButton,Col, Divider, message, Modal, notification, Row, Tooltip } from 'antd'
import { ICONS } from 'assets'
import { Button, IntlMessages, RejectPopup, Text, Title } from 'components'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getPartners, onShipOrder } from 'services'
import styled from 'styled-components'
import {
  getItemNameAlongWithAddons,
  getItemPriceAlongWithAddons,
  intlAlertMessage
} from 'Utils'
import {
  CANCEL_REASONS,
  COLORS,
  CONSOLE_ORDER_STATUS,
  DELIVERY_PARTNER,
  ORDER_STATUS,
  ORDER_TYPE,
  REJECT_REASONS,
  SELF_PiCKUP_CANCEL_REASONS,
  SELF_PiCKUP_REJECT_REASONS
} from 'Utils/constants'
import { setSuccessMessage, statusColor } from 'Utils/OrderUtils'

import {
  DELIVER_ORDER,
  ORDER_PICKED,
  ORDER_READY,
  PROCESS_ORDER_ACCEPTANCE,
  PROCESS_ORDER_CANCEL,
  PROCESS_ORDER_STORE_REJECT
} from '../../../services/query/index'
import OrderDetailTable from '../orderDetailTable'
import StoreStaff from '../storeStaff'

const StyledButton = styled(AntdButton)`
    &.ant-btn {
      height: 35px;
      padding: 0 12px;
      border-radius: 4px;
    }
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.BG_WHITE};
    border-color: ${COLORS.PRIMARY};
    &:hover {
      background-color: ${COLORS.PRIMARY_DARK};
      border-color: ${COLORS.PRIMARY};
      color: ${COLORS.BG_WHITE};
    }
    &:disabled {
      color: ${COLORS.GRAY_TEXT};
      background-color: ${COLORS.DISABLED};
      border-color: ${COLORS.BORDER};
    }
    
    `
const StyledSelfInstructionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`
const StyleMarkedButton = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 10px 0px 17px;
  background: ${COLORS.DISABLED};
  border-radius: 5px;
  height: 24px;
  cursor: not-allowed;
`
const StyleMarkedIcon = styled.div`
  border-radius: 10px;
  width: 15px;
  height: 15px;
  background: ${COLORS.PRIMARY};
  color: ${COLORS.BG_WHITE};
  display: flex;
  justify-content: space-around;
  align-items: center;
`
const StyleMarkButton = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 10px 0px 17px;
  background: ${COLORS.PRIMARY};
  border-radius: 5px;
  height: 24px;
  cursor: pointer;
`
const StyleMarkIcon = styled.div`
  border-radius: 10px;
  width: 15px;
  height: 15px;
  background: ${COLORS.BG_WHITE};
  color: ${COLORS.BG_WHITE};
  display: flex;
  justify-content: space-around;
  align-items: center;
`

interface IOrderDetailProps extends ApolloProviderProps<any> {
  cancelOrder?: any
  rejectOrder?: any
  acceptOrder?: any
  deliverOrder?: any
  orderReady?: any
  orderPicked?: any
  viewOrder: boolean
  viewOrderData: any
  closeModal: any
  onCancelClick: any
  isOrderHistory?: boolean
  intl: any
}
interface IOrderDetailState {
  loading: boolean
  visible: boolean
  loadingCancel: boolean
  loadingAccept: boolean
  selectedDeliveryStaff: string
  showCancelDialog: boolean
  showOrderCancelDialog: boolean
  cancelNote: string
  isAccept: boolean
  deliveryPartnerCodes: any
  orderCancelReason: string
  hideActions: boolean
  orderId: string
  viewOrderReceipt: boolean
  deliveryDiscountCharges: any
  isOrderCancelledAfterShipped: boolean
  isOrderCancelledBeforeShipment: boolean
  loadingShipped: boolean
  isSelfInstructionRead: boolean
}
class OrderDetail extends Component<IOrderDetailProps, IOrderDetailState> {
  totalItemQuantity = 0

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      visible: true,
      loadingCancel: false,
      loadingAccept: false,
      selectedDeliveryStaff: null,
      isAccept: false,
      showCancelDialog: false,
      showOrderCancelDialog: false,
      cancelNote: null,
      deliveryPartnerCodes: [],
      orderCancelReason: null,
      hideActions: false,
      orderId: this.props?.viewOrderData?.id,
      viewOrderReceipt: false,
      deliveryDiscountCharges: [],
      isOrderCancelledAfterShipped: false,
      isOrderCancelledBeforeShipment: false,
      loadingShipped: false,
      isSelfInstructionRead: false
    }
  }

  componentDidMount() {
    if (this.props?.viewOrderData) {
      const { orderStatus, orderDelivery } = this.props.viewOrderData
      const {
        ORDER_ACCEPTED,
        ORDER_CANCELLED,
        ORDER_COMPLETE,
        ORDER_DELIVERED,
        ORDER_DELIVERY_FOUND,
        ORDER_SHIPPED,
        ORDER_CREATED,
        ORDER_REJECTED,
        ORDER_READY
      } = ORDER_STATUS
      const selectedDeliveryStaff =
        orderStatus === ORDER_ACCEPTED ||
        orderStatus === ORDER_CANCELLED ||
        orderStatus === ORDER_DELIVERED ||
        orderStatus === ORDER_COMPLETE ||
        orderStatus === ORDER_DELIVERY_FOUND ||
        orderStatus === ORDER_SHIPPED
          ? orderDelivery[0] && orderDelivery[0].internalDeliveryStaff
            ? orderDelivery[0].internalDeliveryStaff.id
            : DELIVERY_PARTNER
          : (orderStatus === ORDER_CREATED || orderStatus === ORDER_REJECTED) &&
            orderDelivery[0] &&
            orderDelivery[0].externalDeliveryDetails
            ? DELIVERY_PARTNER
            : null
      const isAccept = Boolean(
        orderStatus === ORDER_ACCEPTED ||
          orderStatus === ORDER_DELIVERY_FOUND ||
          orderStatus === ORDER_SHIPPED ||
          orderStatus === ORDER_READY
      )
      const hideActions = Boolean(
        orderStatus === ORDER_CANCELLED ||
          orderStatus === ORDER_DELIVERED ||
          orderStatus === ORDER_COMPLETE ||
          orderStatus === ORDER_REJECTED
      )

      const externalDeliveryDetails = orderDelivery[0]?.externalDeliveryDetails

      const isOrderCancelledAfterShipped =
        orderDelivery[0]?.deliveryOrderStatus ===
          ORDER_STATUS.DELIVERY_CANCELLED &&
        externalDeliveryDetails?.bpp_task_state ===
          ORDER_STATUS.EN_ROUTE_TO_DROP
      const isOrderCancelledBeforeShipment =
        orderDelivery[0]?.deliveryOrderStatus ===
          ORDER_STATUS.DELIVERY_CANCELLED &&
        (externalDeliveryDetails?.bpp_task_state ===
          ORDER_STATUS.ASSIGNED_AGENT ||
          externalDeliveryDetails?.bpp_task_state ===
            ORDER_STATUS.SEARCHING_FOR_FMD_AGENT ||
          externalDeliveryDetails?.bpp_task_state ===
            ORDER_STATUS.AT_PICKUP_LOCATION ||
          externalDeliveryDetails?.bpp_task_state ===
            ORDER_STATUS.DELIVERY_CANCELLED)

      this.setState({
        selectedDeliveryStaff,
        isAccept,
        hideActions,
        isOrderCancelledAfterShipped,
        isOrderCancelledBeforeShipment
      })
    }
  }

  handleOk = async (orderId: string, staffId: string) => {
    const { acceptOrder, closeModal, viewOrderData } = this.props
    const { deliveryPartnerCodes } = this.state

    this.setState({ loadingAccept: true })
    try {
      let payload

      if (
        this.state.selectedDeliveryStaff === DELIVERY_PARTNER ||
        viewOrderData.orderType === ORDER_TYPE.MOBILE_PICKUP
      ) {
        payload = {
          id: orderId
        }
      } else {
        payload = {
          id: orderId,
          deliveryStaffId: staffId,
          deliveryTypeCode: 'INTERNAL_STAFF',
          deliveryPartnerCode: deliveryPartnerCodes[1].code
        }
      }
      await acceptOrder({
        variables: payload
      })
      setSuccessMessage(
        'newOrder.info.orderSuccessfullyAccepted',
        this.props.intl
      )
      this.setState({
        loadingAccept: false,
        visible: false,
        cancelNote: null,
        selectedDeliveryStaff: staffId,
        isAccept: true
      })
      closeModal()
      notification.close(orderId)
    } catch (error) {
      console.log(error)
      this.setState({
        loadingAccept: false,
        visible: false,
        selectedDeliveryStaff: null,
        cancelNote: null
      })
      // CloseModal();
    }
  }

  handleShipped = async orderId => {
    const { closeModal } = this.props

    this.setState({ loadingShipped: true })
    try {
      const res = await onShipOrder(this.props.client, orderId)

      if (res.data) {
        this.setState({
          loadingShipped: false
        })
        closeModal()
        setSuccessMessage(
          'newOrder.info.orderSuccessfullyShipped',
          this.props.intl
        )
      }
    } catch (err) {
      console.log(err)
      this.setState({
        loadingShipped: false
      })
      message.error(
        intlAlertMessage({
          id: 'newOrder.somethingWetWrongMsg',
          intl: this.props.intl
        })
      )
    }
  }

  handleReadyForPickup = async (orderId: string) => {
    const { orderReady, closeModal } = this.props

    this.setState({ loadingAccept: true })
    try {
      await orderReady({
        variables: {
          id: orderId
        }
      })
      setSuccessMessage('newOrder.info.orderReadyForPickup', this.props.intl)
      this.setState({
        loadingAccept: false
      })
      closeModal()
    } catch (error) {
      console.log(error)
      this.setState({
        loadingAccept: false
      })
      message.error(
        intlAlertMessage({
          id: 'newOrder.somethingWetWrongMsg',
          intl: this.props.intl
        })
      )
    }
  }

  handleOrderPicked = async (orderId: string) => {
    const { orderPicked, closeModal } = this.props

    this.setState({ loadingAccept: true })
    try {
      await orderPicked({
        variables: {
          id: orderId
        }
      })
      setSuccessMessage(
        'newOrder.info.orderSuccessfullyDelivered',
        this.props.intl
      )
      this.setState({
        loadingAccept: false,
        cancelNote: null,
        visible: false,
        isAccept: false,
        selectedDeliveryStaff: null
      })
      closeModal()
    } catch (error) {
      console.log(error)
      this.setState({
        loadingAccept: false,
        visible: false
      })
    }
  }

  handleDelivered = async (orderId: string) => {
    const { deliverOrder, closeModal } = this.props

    this.setState({ loadingAccept: true })
    try {
      await deliverOrder({
        variables: {
          id: orderId
        }
      })
      setSuccessMessage(
        'newOrder.info.orderSuccessfullyDelivered',
        this.props.intl
      )
      this.setState({
        loadingAccept: false,
        cancelNote: null,
        visible: false,
        isAccept: false,
        selectedDeliveryStaff: null
      })
      closeModal()
    } catch (error) {
      console.log(error)
      this.setState({
        loadingAccept: false,
        visible: false
      })
    }
  }

  handleReject = async (orderId: string) => {
    const { rejectOrder, closeModal } = this.props

    this.setState({ loadingCancel: true })
    try {
      await rejectOrder({
        variables: {
          id: orderId,
          note: this.state.cancelNote
        }
      })
      setSuccessMessage('newOrder.info.orderRejected', this.props.intl)
      this.setState({
        loadingCancel: false,
        visible: false,
        selectedDeliveryStaff: null,
        cancelNote: null
      })
      closeModal()
      notification.close(orderId)
    } catch (error) {
      console.log(error)
      this.setState({
        loadingCancel: false,
        visible: false,
        selectedDeliveryStaff: null,
        cancelNote: null
      })
    }
  }

  handleOrderCancel = async (orderId: string) => {
    const { cancelOrder, closeModal } = this.props

    this.setState({ loadingCancel: true })
    try {
      await cancelOrder({
        variables: {
          id: orderId,
          note: {
            cancelOrderSource: 'STORE_CANCEL_ORDER',
            cancelOrderReason: this.state.orderCancelReason
          }
        }
      })
      setSuccessMessage('newOrder.info.orderCancelled', this.props.intl)
      this.setState({
        loadingCancel: false,
        visible: false,
        selectedDeliveryStaff: null,
        cancelNote: null,
        orderCancelReason: null
      })
      closeModal()
      notification.close(orderId)
    } catch (error) {
      console.log(error)
      this.setState({
        loadingCancel: false,
        visible: false,
        selectedDeliveryStaff: null,
        cancelNote: null,
        orderCancelReason: null
      })
    }
  }

  cancelConfirm = selectedReason => {
    const { viewOrderData } = this.props
    const orderId = viewOrderData ? viewOrderData.id : ''

    this.setState(
      { showOrderCancelDialog: false, orderCancelReason: selectedReason },
      () => {
        this.handleOrderCancel(orderId)
      }
    )
  }

  rejectConfirm = selectedReason => {
    const { viewOrderData } = this.props
    const orderId = viewOrderData ? viewOrderData.id : ''

    this.setState(
      { showCancelDialog: false, cancelNote: selectedReason },
      () => {
        this.handleReject(orderId)
      }
    )
  }

  handleDeliverystaff = (e: any) => {
    this.setState({ selectedDeliveryStaff: e })
    this.handleChangeDeliverystaff()
  }

  handleChangeDeliverystaff = async () => {
    try {
      const res = await getPartners(this.props.client, {
        partnerType: 'DELIVERY'
      })

      this.setState({ deliveryPartnerCodes: res.data.getPartners })
    } catch (err) {
      console.log(err)
    }
  }

  onCancel() {
    const { onCancelClick } = this.props

    this.setState({ selectedDeliveryStaff: null, cancelNote: null }, () => {
      onCancelClick()
    })
  }

  buildViewOrderColumnData = orderItems => {
    this.totalItemQuantity = 0
    const columnOrders = orderItems.map(item => {
      this.totalItemQuantity += item.quantity
      const itemNameWithAddons = getItemNameAlongWithAddons(item)
      const itemPriceWithAddons = getItemPriceAlongWithAddons(item)

      return {
        itemName: itemNameWithAddons,
        quantity: `x${item.quantity}`,
        price: `₹${parseFloat(itemPriceWithAddons).toFixed(2)}`
      }
    })

    return columnOrders
  }

  toggleReceiptModal = status => {
    this.setState({ viewOrderReceipt: status })
  }

  render() {
    const {
      loadingAccept,
      loadingCancel,
      isAccept,
      hideActions,
      selectedDeliveryStaff,
      viewOrderReceipt,
      isSelfInstructionRead
    } = this.state
    const { viewOrder, viewOrderData, isOrderHistory, intl } = this.props
    const orderId = viewOrderData ? viewOrderData.id : ''
    const orderItems = viewOrderData ? viewOrderData.orderItems : []
    const columnsData = this.buildViewOrderColumnData(orderItems)

    const acceptButtonHelperText = viewOrderData?.note?.note && !isSelfInstructionRead ? <IntlMessages id="specialInstructionHelpText" />: viewOrderData?.orderType === ORDER_TYPE.MOBILE_DELIVERY && !selectedDeliveryStaff ? <IntlMessages id="selectDeliveryPartnerHelpText" />: ''
        
    return (
      <div>
        <Modal
          width={800}
          visible={viewOrder}
          title={
            <div>
              <span style={{ width: '70%', display: 'inline-block' }}>
                <span
                  className="title-label"
                  style={{ color: COLORS.NORMAL_TEXT }}>
                  <IntlMessages id="newOrder.orderID" />:
                </span>
                {viewOrderData ? viewOrderData.orderId : ''}
              </span>
              <span className="status-block">
                <span
                  className="circle"
                  style={{
                    color: statusColor(viewOrderData)
                  }}>
                  {viewOrderData &&
                    CONSOLE_ORDER_STATUS[viewOrderData.orderStatus]}
                </span>
              </span>
            </div>
          }
          onCancel={this.onCancel.bind(this)}
          footer={[]}
          className="view-order-modal">
          <Row>
            <Col span={12} style={{ marginTop: '-20px' }}>
              {viewOrderData !== null &&
              selectedDeliveryStaff === DELIVERY_PARTNER &&
              viewOrderData?.orderType === ORDER_TYPE.MOBILE_DELIVERY ? (
                <div className="rider-busy-text">
                  <span className="peppo-rider-symbol">
                    <span className="peppo-rider-text-p">P</span>
                  </span>
                  {isOrderHistory ? (
                    <IntlMessages id="newOrder.orderDeliveryByPartner" />
                  ) : (
                    <IntlMessages id="newOrder.orderDeliveryWillBeByPartner" />
                  )}
                </div>
              ) : viewOrderData?.orderType === ORDER_TYPE.MOBILE_PICKUP ? (
                <div className="self-pickup-container">
                  <Icon
                    style={{ fontSize: '22px', paddingRight: '12px' }}
                    component={() => (
                      <img src={ICONS.selfPickupIcon} alt="self-Pickup" />
                    )}
                  />
                  <Title level="h5" style={{ marginBottom: '-2px' }}>
                    <IntlMessages id="selfPickup.selfPickupOrder" />
                  </Title>
                </div>
              ) : (
                <StoreStaff
                  {...this.state}
                  {...this.props}
                  handleDeliverystaff={this.handleDeliverystaff.bind(this)}
                />
              )}
            </Col>
            <Col span={12}>
              {!hideActions && !isOrderHistory ? (
                !isAccept ? (
                  <div>
                    <Button
                      key="submit"
                      loading={loadingCancel}
                      type="danger"
                      style={{ width: '30%', float: 'left' }}
                      onClick={() => {
                        this.setState({ showCancelDialog: true })
                      }}>
                      <IntlMessages id="newOrder.Reject" />
                    </Button>
                    <Tooltip title={ acceptButtonHelperText } style={{ width: '65%' }}>
                      <StyledButton
                        style={{ width: acceptButtonHelperText ? '100%': '65%' }}
                        key="back"
                        loading={loadingAccept}
                        disabled={
                          viewOrderData?.note?.note &&
                          viewOrderData.orderType !== ORDER_TYPE.MOBILE_PICKUP
                            ? this.state.selectedDeliveryStaff === null ||
                              !isSelfInstructionRead
                            : viewOrderData.orderType !== ORDER_TYPE.MOBILE_PICKUP
                            ? this.state.selectedDeliveryStaff === null
                            : viewOrderData?.note?.note &&
                              viewOrderData.orderType === ORDER_TYPE.MOBILE_PICKUP
                            ? !isSelfInstructionRead
                            : null
                        }
                        onClick={this.handleOk.bind(
                          null,
                          orderId,
                          this.state.selectedDeliveryStaff
                        )}>
                        <IntlMessages id="newOrder.Accept" />
                      </StyledButton>
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <Button
                      key="submit"
                      loading={loadingCancel}
                      style={{ width: '30%' }}
                      type="danger"
                      disabled={
                        viewOrderData.orderStatus === ORDER_STATUS.ORDER_SHIPPED || viewOrderData.orderType === ORDER_TYPE.MOBILE_PICKUP && viewOrderData.orderStatus === ORDER_STATUS.ORDER_READY
                      }
                      onClick={() => {
                        this.setState({
                          showOrderCancelDialog: true,
                          orderCancelReason: null
                        })
                      }}>
                      <IntlMessages id="newOrder.Cancel" />
                    </Button>
                    {selectedDeliveryStaff === DELIVERY_PARTNER &&
                    this.state.isOrderCancelledBeforeShipment &&
                    viewOrderData.orderStatus ===
                      ORDER_STATUS.ORDER_DELIVERY_FOUND ? (
                        <Button
                        type={'primary'}
                        key="back"
                        loading={this.state.loadingShipped}
                        style={{ width: '65%' }}
                        onClick={() => this.handleShipped(orderId)}>
                          <IntlMessages id="newOrder.Shipped" />
                        </Button>
                    ) : viewOrderData.orderStatus ===
                    ORDER_STATUS.ORDER_ACCEPTED && viewOrderData.orderType === ORDER_TYPE.MOBILE_PICKUP ? (
                      <Button
                        type={'primary'}
                        key="back"
                        loading={loadingAccept}
                        style={{ width: '65%' }}
                        onClick={this.handleReadyForPickup.bind(null, orderId)}>
                        <IntlMessages id="newOrder.readyForPickup" />
                      </Button>
                    ) : (
                      <Button
                        type={'primary'}
                        key="back"
                        loading={loadingAccept}
                        style={{ width: '65%' }}
                        disabled={
                          this.state.selectedDeliveryStaff === null &&
                          viewOrderData.orderType === ORDER_TYPE.MOBILE_DELIVERY
                            ? true
                            : false ||
                              (selectedDeliveryStaff === DELIVERY_PARTNER &&
                                !this.state.isOrderCancelledAfterShipped &&
                                !this.state.isOrderCancelledBeforeShipment)
                        }
                        onClick={
                          viewOrderData.orderType === ORDER_TYPE.MOBILE_DELIVERY
                            ? this.handleDelivered.bind(null, orderId)
                            : this.handleOrderPicked.bind(null, orderId)
                        }>
                        <IntlMessages id="newOrder.Delivered" />
                      </Button>
                    )}
                  </div>
                )
              ) : (
                <div />
              )}
            </Col>
          </Row>
          {viewOrderData?.note?.note && (
            <Row>
              <Col span={24} style={{ margin: '10px 0' }}>
                <div
                  style={{
                    padding: '5px 16px 1px 12px',
                    background: '#e2e2e2',
                    borderRadius: '3px'
                  }}>
                  <Title level="h6" style={{ marginBottom: '3px' }}>
                    <IntlMessages id="specialInstructions" />
                  </Title>
                </div>
                <StyledSelfInstructionContainer>
                  <Text style={{ width: '70%' }} level="body-2">
                    {viewOrderData?.note?.note}
                  </Text>
                  {viewOrderData?.orderStatus === ORDER_STATUS.ORDER_CREATED &&
                    (isSelfInstructionRead ? (
                      <StyleMarkedButton>
                        <Text
                          style={{ color: COLORS.PRIMARY, paddingRight: '5px' }}
                          level="caption">
                          <IntlMessages id="markedAsRead" />
                        </Text>
                        <StyleMarkedIcon>
                          <Icon type={'check'} style={{ fontSize: '11px' }} />
                        </StyleMarkedIcon>
                      </StyleMarkedButton>
                    ) : (
                      <StyleMarkButton
                        onClick={() =>
                          this.setState({ isSelfInstructionRead: true })
                        }>
                        <Text
                          style={{ color: COLORS.BG_WHITE, paddingRight: '5px' }}
                          level="caption">
                          <IntlMessages id="markAsRead" />
                        </Text>
                        <StyleMarkIcon />
                      </StyleMarkButton>
                    ))}
                </StyledSelfInstructionContainer>
              </Col>
            </Row>
          )}
          <Divider />
          <OrderDetailTable
            columnsData={columnsData}
            {...this.props}
            totalItemQuantity={this.totalItemQuantity}
            viewOrderReceipt={viewOrderReceipt}
            toggleReceiptModal={this.toggleReceiptModal}
            intl={intl}
            deliveryDiscountCharges={viewOrderData?.deliveryDiscountCharge}
          />
        </Modal>
        <RejectPopup
          onCancel={() => {
            this.setState({ showCancelDialog: false })
          }}
          onSave={this.rejectConfirm}
          title={intlAlertMessage({
            id: 'newOrder.announcement.confirmationForOrderRejection',
            intl: this.props.intl
          })}
          isVisible={this.state.showCancelDialog}
          rejectOptions={viewOrderData.orderType === ORDER_TYPE.MOBILE_DELIVERY ? REJECT_REASONS : SELF_PiCKUP_REJECT_REASONS}
          buttonText={intlAlertMessage({
            id: 'newOrder.Reject',
            intl: this.props.intl
          })}
          client={this.props.client}>
          {this.props.children}
        </RejectPopup>
        <RejectPopup
          onCancel={() => {
            this.setState({ showOrderCancelDialog: false })
          }}
          onSave={this.cancelConfirm}
          title={intlAlertMessage({
            id: 'newOrder.announcement.confirmationForOrderCancellation',
            intl: this.props.intl
          })}
          isVisible={this.state.showOrderCancelDialog}
          rejectOptions={viewOrderData.orderType === ORDER_TYPE.MOBILE_DELIVERY ? CANCEL_REASONS : SELF_PiCKUP_CANCEL_REASONS}
          buttonText={intlAlertMessage({
            id: 'newOrder.Cancel',
            intl: this.props.intl
          })}
          client={this.props.client}>
          {this.props.children}
        </RejectPopup>
      </div>
    )
  }
}

export default compose(
  withRouter,
  graphql(PROCESS_ORDER_ACCEPTANCE, {
    name: 'acceptOrder'
  }),
  graphql(ORDER_READY, {
    name: 'orderReady'
  }),
  graphql(ORDER_PICKED, {
    name: 'orderPicked'
  }),
  graphql(PROCESS_ORDER_STORE_REJECT, {
    name: 'rejectOrder'
  }),
  graphql(DELIVER_ORDER, {
    name: 'deliverOrder'
  }),
  graphql(PROCESS_ORDER_CANCEL, {
    name: 'cancelOrder'
  }),
  withApollo
)(OrderDetail)
