import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { message,Row } from 'antd'
import { ChangePasswordForm, IntlMessages } from 'components'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import { injectIntl } from 'react-intl'
import { getUserDetails, intlAlertMessage, setUserDetails } from 'Utils'
import { COLORS } from 'Utils/constants'

import { changePassword } from '../../services/userService'

interface IProps extends ApolloProviderProps<any> {
  history?: History
  intl: any
}

interface IState {
  confirmDirty: boolean
  loading: boolean
}

class ChangePassword extends React.Component<IProps, IState> {
  unblock: any

  constructor(props) {
    super(props)
    this.state = {
      confirmDirty: false,
      loading: false
    }
  }

  /*
   * UNSAFE_componentWillMount() {
   *     if (localStorage.getItem("jwt")) this.props.history.push("/");
   * }
   */

  componentWillUnmount() {
    this.unblock && this.unblock()
  }

  componentDidMount() {
    this.unblock = this.props.history.block(targetLocation => {
      if (targetLocation.pathname === '/signin' || targetLocation.pathname === '/orderx/onboarding' || targetLocation.pathname === '/orderx/orders') {
        return false
      }
    })
    window.history.pushState(null, null, window.location.href)
    window.history.back()
    window.history.forward()
    window.onpopstate = () => {
      window.history.go(1)
    }
  }

  componentDidUpdate() {
    window.onbeforeunload = e => {
      e.preventDefault()
    }
  }

  handleSubmit = async values => {
    const { currentPassword: oldPassword, password: newPassword } = values
    const { history } = this.props

    try {
      this.setState({ loading: true })
      const data = await changePassword(this.props.client, {
        oldPassword,
        newPassword
      })

      if (data && data.data && data.data.updatePassword && data.data.updatePassword.updated) {
        message.success(intlAlertMessage({ id: 'passwordUpdated', intl: this.props.intl }))
        let userDetails = getUserDetails()

        userDetails = { ...userDetails, resetPassword: false }
        setUserDetails(userDetails)
        this.unblock && this.unblock()
        history.push('/orderx/onboarding')
        this.setState({ loading: false })
      }
    } catch (err) {
      console.error('Change password error')
      this.setState({ loading: false })
    }
  }

  render() {
    const { intl } = this.props
    const { loading } = this.state

    return (
      <div
        style={{ background: '#00946E 0% 0% no-repeat padding-box' }}
        className="gx-app-change-pw-wrap">
        <div className="gx-app-change-pw-container">
          <div className="gx-app-change-pw-main-content">
            <div className="gx-app-change-pw-content">
              <Row style={{ marginBottom: '20px' }}>
                <h2 style={{ marginBottom: 0, color: COLORS.DARK_TEXT }}>
                  <IntlMessages id="welcomeOnboard" />
                </h2>
                <div style={{ fontSize: '13px' }}>
                  <IntlMessages id="store.setYourPassword" />.
                </div>
              </Row>
              <ChangePasswordForm
                onSubmit={this.handleSubmit}
                intl={intl}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(compose(withApollo)(ChangePassword))
