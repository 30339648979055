import { Icon } from '@ant-design/compatible'
import { Input } from 'antd'
import { CheckboxGroup, IntlMessages, Modal, Text } from 'components'
import React, { Component } from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

import { StyledCheckbox } from '../../../Utils/menuUtils/styleConstants'

interface ISelectedAddonsProps {
  selectedAddonGroups: any
  onAddonChange: any
  onMinMaxChange: any
}

interface ISelectedAddonsState {
  showAddonGroupPopup: boolean
  selectedAddonGroupIndex: number
}

const TitleStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding: 5px;
`
const FooterStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${COLORS.BORDER};
  padding: 4px 0px;
`
const FooterStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const VISIBLE_ADDON_COUNT = 4

class SelectedAddons extends Component<
  ISelectedAddonsProps,
  ISelectedAddonsState
> {
  constructor(props) {
    super(props)
    this.state = {
      showAddonGroupPopup: false,
      selectedAddonGroupIndex: null
    }
  }

  formCheckboxGroup = addonGroupId => {
    const { onAddonChange, selectedAddonGroups } = this.props
    const selectedAddonGroup = selectedAddonGroups.find(
      selectedAddonGroup => selectedAddonGroup.value === addonGroupId
    )

    return (
      <CheckboxGroup
        name={selectedAddonGroup.label}
        onChange={selectedValues => {
          onAddonChange(addonGroupId, selectedValues, true)
        }}
        value={selectedAddonGroup.customisations
          .filter(cs => cs.selected)
          .map(cus => cus.value)}>
        {selectedAddonGroup.customisations.map((c, cIndex) => (
          <div key={`customization-${cIndex}`}>
            <StyledCheckbox key={`customization-cb-${cIndex}`} value={c.value}>
              {c.label}
            </StyledCheckbox>
          </div>
        ))}
      </CheckboxGroup>
    )
  }

  validateMinMaxValue(selectedAddonGroup) {
    const { onMinMaxChange } = this.props
    const selectedAddonsCount = selectedAddonGroup.customisations.filter(
      cs => cs.selected
    ).length
    let minValue = selectedAddonGroup.customisations[0].config.min
    let maxValue = selectedAddonGroup.customisations[0].config.max

    maxValue =
      maxValue > selectedAddonsCount || maxValue === 0
        ? selectedAddonsCount
        : maxValue

    minValue = minValue > maxValue ? maxValue : minValue

    onMinMaxChange(selectedAddonGroup.value, minValue, maxValue, true)
  }

  render() {
    const { selectedAddonGroups, onAddonChange, onMinMaxChange } = this.props
    const { showAddonGroupPopup, selectedAddonGroupIndex } = this.state

    return (
      <>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {selectedAddonGroups.map((selectedAddonGroup, index) => (
            <div
              key={selectedAddonGroup.value}
              style={{
                backgroundColor: COLORS.BG_WHITE,
                borderRadius: '5px',
                border: '1px solid #e6e6e6',
                width: '225px',
                height: '236px',
                boxShadow: '0px 1px 2px #00000029',
                margin: '15px 30px 0px 0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
              <div>
                <TitleStyles>
                  <Text level="button">{selectedAddonGroup.label}</Text>
                  <Icon
                    type="close"
                    onClick={() =>
                      onAddonChange(selectedAddonGroup.value, [], true)
                    }
                  />
                </TitleStyles>
                <div style={{ height: '120px', overflow: 'hidden' }}>
                  {this.formCheckboxGroup(selectedAddonGroup.value)}
                </div>
                {/* <CheckboxGroup
                  name={selectedAddonGroup.label}
                  onChange={selectedValues => {
                    onAddonChange(index, selectedValues, true);
                  }}
                  value={selectedAddonGroup.customisations
                    .filter(cs => cs.selected)
                    .map(cus => cus.value)}
                >
                  {selectedAddonGroup.customisations.map((c, cIndex) => (
                    <div key={`customization-${cIndex}`}>
                      <StyledCheckbox key={`customization-cb-${cIndex}`} value={c.value}>
                        {c.label}
                      </StyledCheckbox>
                    </div>
                  ))}
                </CheckboxGroup> */}
              </div>
              <div>
                {selectedAddonGroup?.customisations.length >
                  VISIBLE_ADDON_COUNT && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '10px 8px',
                      alignItems: 'center'
                    }}>
                    <Text level="caption">{`+ ${selectedAddonGroup
                      ?.customisations.length -
                      VISIBLE_ADDON_COUNT} More`}</Text>
                    <div
                      style={{ color: COLORS.PRIMARY, cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          selectedAddonGroupIndex: index,
                          showAddonGroupPopup: true
                        })
                      }}>
                      <IntlMessages id="view.all" />
                    </div>
                  </div>
                )}
                <FooterStyle>
                  <FooterStyles>
                    <Text level="caption" style={{ padding: '0px 5px' }}>
                      <IntlMessages id="minChoice" />
                    </Text>
                    <Input
                      placeholder=""
                      value={selectedAddonGroup.customisations[0].config.min}
                      style={{ width: '32px', height: '23px', padding: '4px' }}
                      onChange={e => {
                        onMinMaxChange(
                          selectedAddonGroup.value,
                          e.target.value,
                          selectedAddonGroup.customisations[0].config.max,
                          false
                        )
                      }}
                      onBlur={() =>
                        this.validateMinMaxValue(selectedAddonGroup)
                      }
                    />
                  </FooterStyles>
                  <FooterStyles>
                    <Text level="caption">
                      <IntlMessages id="maxChoice" />
                    </Text>
                    <Input
                      value={selectedAddonGroup.customisations[0].config.max}
                      style={{
                        width: '32px',
                        height: '23px',
                        margin: '0px 5px',
                        padding: '4px'
                      }}
                      onChange={e => {
                        onMinMaxChange(
                          selectedAddonGroup.value,
                          selectedAddonGroup.customisations[0].config.min,
                          e.target.value,
                          false
                        )
                      }}
                      onBlur={() =>
                        this.validateMinMaxValue(selectedAddonGroup)
                      }
                    />
                  </FooterStyles>
                </FooterStyle>
              </div>
            </div>
          ))}
        </div>
        {selectedAddonGroupIndex !== null && (
          <Modal
            title={selectedAddonGroups[selectedAddonGroupIndex].label}
            visible={showAddonGroupPopup}
            onClose={() =>
              this.setState({
                showAddonGroupPopup: false,
                selectedAddonGroupIndex: null
              })
            }>
            {this.formCheckboxGroup(
              selectedAddonGroups[selectedAddonGroupIndex].value
            )}
          </Modal>
        )}
      </>
    )
  }
}
export default SelectedAddons
