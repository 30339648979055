import { Checkbox, Col, Collapse, message,Row } from 'antd'
import { Button, CheckboxGroup, IntlMessages, Modal, TextButton, Title } from 'components'
import * as jwt from 'jsonwebtoken'
import React from 'react'
import { injectIntl } from 'react-intl'
import { arrayMove,SortableContainer, SortableElement } from 'react-sortable-hoc'
import { onSortCustomisation, onSortOptionValue } from 'services/productService'
import { compareArrayValues,intlAlertMessage } from 'Utils'
import { COLORS, MENU_TABS, PRODUCT_TYPE_ENUM, PRODUCT_VARIANT_TYPE_ENUM } from 'Utils/constants'

import { getCustomisationGroupsWithSelections, getPriceDataWithProductId, getSelectedCutomisationGroups, getValidatedPrices, prepareOriginalPrices, republishCatalog } from '../../../Utils/menuUtils/commonFunc'
import { hideItemUpDownArrow, setItemUpDownArrowPosition } from '../../../Utils/menuUtils/customizeHelper'
import { ButtonsContainer, StyledCheckbox, StyledCollapse, StyledIcon } from '../../../Utils/menuUtils/styleConstants'
import DragIcon from '../components/DragIcon'
import SelectedCustomisations from './selectedCustomisations'

const { Panel } = Collapse

interface IProductCustomisationProps {
  intl: any
  onProductRelationChange: any
  onProductVariantPriceChange: any
  productRelationships: any[]
  options: any[]
  productVariantPrices: any[]
  originalProductVariantPrices: any[]
  newProductVariantPrices: any[]
  onTabChange: any
  client: any
}

interface IProductCustomisationState {
  showCustomizeGroupPopup: boolean;
  customisationGroups: any[];
  previousCustomisationGroups: any[];
  selectedCutomisationGroups: any[];
  options: any;
  productRelationships: any;
}

class ProductCustomisation extends React.Component<
  IProductCustomisationProps,
  IProductCustomisationState
> {
  constructor(props) {
    super(props)
    const { options, productRelationships } = props
    const customisationGroupsWithSelection = getCustomisationGroupsWithSelections(
      options,
      productRelationships
    )

    this.state = {
      options,
      productRelationships,
      showCustomizeGroupPopup: false,
      customisationGroups: customisationGroupsWithSelection,
      previousCustomisationGroups: [],
      selectedCutomisationGroups: getSelectedCutomisationGroups(
        customisationGroupsWithSelection
      )
    }
  }

  onCustomisationChange = (cgIndex, selectedValues) => {
    const { customisationGroups } = this.state
    const newCutomisationGroups = [...customisationGroups]
    const selectedCustomisationGroup = { ...newCutomisationGroups[cgIndex] }

    selectedCustomisationGroup.customisations = selectedCustomisationGroup.customisations.map(
      cs => {
        const newCs = { ...cs }

        newCs.selected = selectedValues.includes(cs.value)

        return newCs
      }
    )
    selectedCustomisationGroup.selected =
      selectedValues.length === selectedCustomisationGroup.customisations.length
    selectedCustomisationGroup.indeterminate =
      Boolean(selectedValues.length) &&
      selectedValues.length < selectedCustomisationGroup.customisations.length

    selectedCustomisationGroup.customisations = selectedCustomisationGroup.customisations.map(
      cs => {
        const newCs = { ...cs }

        newCs.selected = selectedValues.includes(cs.value)

        return newCs
      }
    )
    selectedCustomisationGroup.selected =
      selectedValues.length === selectedCustomisationGroup.customisations.length
    selectedCustomisationGroup.indeterminate =
      Boolean(selectedValues.length) &&
      selectedValues.length < selectedCustomisationGroup.customisations.length

    newCutomisationGroups[cgIndex] = selectedCustomisationGroup

    this.setState({ customisationGroups: newCutomisationGroups })
  }

  onCustomisationGroupChange = (cgIndex, selectedValues) => {
    const { customisationGroups } = this.state
    const newCutomisationGroups = [...customisationGroups]
    const selectedCustomisationGroup = { ...newCutomisationGroups[cgIndex] }
    const isSelected = selectedValues.includes(selectedCustomisationGroup.value)
    let customisations = [...selectedCustomisationGroup.customisations]

    customisations = customisations.map(cs => {
      cs.selected = isSelected

      return cs
    })
    newCutomisationGroups[cgIndex] = {
      ...selectedCustomisationGroup,
      selected: isSelected,
      indeterminate: false,
      customisations: [...customisations]
    }
    this.setState({ customisationGroups: newCutomisationGroups })
  }

  validateSelection = () => {
    const { customisationGroups } = this.state
    let valid = false
    const selectedGroup = customisationGroups.filter(
      cg => cg.selected || cg.indeterminate
    )

    if (selectedGroup.length < 4) {
      valid = true
    }

    return valid
  }

  addSelectedGroup = () => {
    const { customisationGroups } = this.state
    const selectedGroup = getSelectedCutomisationGroups(customisationGroups)

    this.setState({
      showCustomizeGroupPopup: false,
      selectedCutomisationGroups: selectedGroup
    })
    this.props.onProductRelationChange(PRODUCT_TYPE_ENUM.VARIANT, selectedGroup)
  }

  getUpdatedPriceData = productId => {
    const { productVariantPrices, newProductVariantPrices } = this.props
    let variantType = PRODUCT_VARIANT_TYPE_ENUM.OLD
    let productPriceData = getPriceDataWithProductId(
      [...productVariantPrices],
      productId
    )

    if (!productPriceData) {
      variantType = PRODUCT_VARIANT_TYPE_ENUM.NEW
      productPriceData = getPriceDataWithProductId(
        [...newProductVariantPrices],
        productId
      )
    }

    return { variantType, productPriceData: productPriceData || {} }
  }

  updateProductPriceData = (
    variantType,
    productPriceData,
    updatedProductPriceData
  ) => {
    const {
      productVariantPrices,
      onProductVariantPriceChange,
      newProductVariantPrices
    } = this.props

    if (variantType === PRODUCT_VARIANT_TYPE_ENUM.OLD) {
      const updatedPricesIndex = productVariantPrices.findIndex(e => {
        return (
          e.productId === productPriceData.productId ||
          compareArrayValues(e.productId, productPriceData.productId)
        )
      })

      productVariantPrices.splice(
        updatedPricesIndex,
        1,
        updatedProductPriceData
      )
      onProductVariantPriceChange(
        productVariantPrices,
        PRODUCT_VARIANT_TYPE_ENUM.OLD
      )
    } else {
      const updatedPricesIndex = newProductVariantPrices.findIndex(e => {
        return (
          e.productId === productPriceData.productId ||
          compareArrayValues(e.productId, productPriceData.productId)
        )
      })

      newProductVariantPrices.splice(
        updatedPricesIndex,
        1,
        updatedProductPriceData
      )
      onProductVariantPriceChange(
        newProductVariantPrices,
        PRODUCT_VARIANT_TYPE_ENUM.NEW
      )
    }
    const { customisationGroups } = this.state
    const selectedGroup = getSelectedCutomisationGroups(customisationGroups)

    this.setState({
      selectedCutomisationGroups: [...selectedGroup]
    })
  }

  updateProductBasePrice = (productId, value) => {
    if (isNaN(Math.round(value))) return
    const { variantType, productPriceData } = this.getUpdatedPriceData(
      productId
    )
    const updatedProductPriceData = {
      ...productPriceData,
      priceValue: Math.round(value) === 0 ? 1 : Math.round(value),
      priceValueChanged: true
    }

    this.updateProductPriceData(
      variantType,
      productPriceData,
      updatedProductPriceData
    )
  }

  updateProductDealPrice = (productId, value) => {
    if (isNaN(Math.round(value))) return
    const { variantType, productPriceData } = this.getUpdatedPriceData(
      productId
    )
    const updatedProductPriceData = {
      ...productPriceData,
      dealPrice: Math.round(value)
    }

    this.updateProductPriceData(
      variantType,
      productPriceData,
      updatedProductPriceData
    )
  }

  updateProductPackagingCharge = (productId, value) => {
    const newPackagingCharge = Math.round(value)

    if (isNaN(newPackagingCharge)) return
    const { variantType, productPriceData } = this.getUpdatedPriceData(
      productId
    )

    if (productPriceData.chargeValue === newPackagingCharge) {
      return
    }
    const updatedProductPackagingChargeData = {
      ...productPriceData,
      chargeValue: newPackagingCharge,
      chargeValueChanged: true
    }

    this.updateProductPriceData(
      variantType,
      productPriceData,
      updatedProductPackagingChargeData
    )
  }

  validateAndUpdatePrices = (productId, priceType) => {
    const { newProductVariantPrices, originalProductVariantPrices } = this.props
    const { variantType, productPriceData } = this.getUpdatedPriceData(
      productId
    )
    const products = newProductVariantPrices.map(newProductVariantPrice => {
      const { productId, tag } = newProductVariantPrice

      return { id: productId, tag }
    })
    let originalNewProductVariantPrices = prepareOriginalPrices(products)

    originalNewProductVariantPrices = originalNewProductVariantPrices.map(
      originalNewProductVariantPrice => {
        return {
          ...originalNewProductVariantPrice,
          priceValue: null,
          discountValue: null,
          chargeValue: null
        }
      }
    )
    const updatedProductPriceData = getValidatedPrices(
      variantType === PRODUCT_VARIANT_TYPE_ENUM.OLD
        ? [...originalProductVariantPrices]
        : [...originalNewProductVariantPrices],
      productPriceData,
      priceType
    )

    this.updateProductPriceData(
      variantType,
      productPriceData,
      updatedProductPriceData
    )
  }

  launchCustomisationsGroupPopup = () => {
    const { customisationGroups } = this.state
    const previousCustomisationGroups = customisationGroups.map(addonGroup => {
      return { ...addonGroup }
    })

    this.setState({
      previousCustomisationGroups,
      showCustomizeGroupPopup: true
    })
  }

  closeCustomisationsGroupPopup = () => {
    const { previousCustomisationGroups } = this.state
    const customisationGroups = previousCustomisationGroups.map(
      previousAddonGroup => {
        return { ...previousAddonGroup }
      }
    )

    this.setState({
      showCustomizeGroupPopup: false,
      customisationGroups
    })
  }

  updateProductTag = (productId, value) => {
    const { variantType, productPriceData } = this.getUpdatedPriceData(
      productId
    )

    if (productPriceData.tag === value) {
      return
    }
    const updatedProductPriceData = {
      ...productPriceData,
      tag: value
    }

    this.updateProductPriceData(
      variantType,
      productPriceData,
      updatedProductPriceData
    )
  }

  onSortEnd = e => {
    const { customisationGroups } = this.state
    const newCustomisationGroups = arrayMove(
      customisationGroups,
      e.oldIndex,
      e.newIndex
    )

    this.setState({
      customisationGroups: newCustomisationGroups,
      options: newCustomisationGroups
    })
    this.onSortCustomisation()
  }

  onOptionValueSortEnd = (e, optionValue, index) => {
    const { customisationGroups } = this.state

    customisationGroups.map((customizationGroup, index) => {
      if (customizationGroup.value === optionValue.value) {
        const newCustomisationOptionValue = arrayMove(
          customizationGroup.customisations,
          e.oldIndex,
          e.newIndex
        )

        customisationGroups[index].customisations = newCustomisationOptionValue
        this.onSortOptionValue(customizationGroup, newCustomisationOptionValue)
      }
    })
  }

  onSortCustomisation = async () => {
    const { customisationGroups, productRelationships } = this.state
    const jwtToken: any = localStorage.getItem('jwt')
    const { org_id }: any = jwt.decode(jwtToken)
    const customisationSortSeq = customisationGroups.map((c, index) => {
      return {
        id: c.value,
        sortSeq: index
      }
    })

    try {
      const customisationInput = {
        organizationId: org_id.toString(),
        optionSeq: customisationSortSeq
      }
      const res = await onSortCustomisation(
        this.props.client,
        customisationInput
      )
      const customisationGroupsWithSelection = getCustomisationGroupsWithSelections(
        res.data.updateOptionSortSeq,
        productRelationships
      )

      this.setState({
        options: res.data.updateOptionSortSeq,
        customisationGroups: customisationGroupsWithSelection,
        previousCustomisationGroups: customisationGroupsWithSelection
      })
      message.success(
        intlAlertMessage({
          id: 'category.successMsg.OptionReorderSuccessfully',
          intl: this.props.intl
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  onSortOptionValue = async (option, optionValue) => {
    const { customisationGroups } = this.state
    const optionValueSortSeq = optionValue.map((option, index) => {
      return {
        id: option.productId,
        sortSeq: index
      }
    })

    try {
      const optionValueInput = {
        optionId: option.value,
        optionValueSeq: optionValueSortSeq
      }

      await onSortOptionValue(this.props.client, optionValueInput)
      message.success(
        intlAlertMessage({
          id: 'category.successMsg.OptionReorderSuccessfully',
          intl: this.props.intl
        })
      )
      this.setState({
        customisationGroups
      })
      republishCatalog(this.props.client)
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const {
      productVariantPrices,
      newProductVariantPrices,
      onTabChange
    } = this.props
    const {
      showCustomizeGroupPopup,
      customisationGroups,
      selectedCutomisationGroups
    } = this.state
    const CustomisationListItem = props => {
      const { cg, val } = props

      return (
        <Panel
          key={val}
          style={{ borderColor: '#f7f2f2' }}
          {...props}
          header={
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                hideItemUpDownArrow(customisationGroups)
                setItemUpDownArrowPosition(val)
              }}>
              <CheckboxGroup
                onChange={selectedValues => {
                  this.onCustomisationGroupChange(val, selectedValues)
                  setItemUpDownArrowPosition(val)
                  hideItemUpDownArrow(customisationGroups)
                }}
                name={`${cg.label}-val`}
                value={cg.selected ? [cg.value] : []}>
                <span className="dragIcon">
                  <DragIcon />
                </span>

                <Checkbox
                  value={cg.value}
                  indeterminate={cg.indeterminate}
                  onClick={e => {
                    e.stopPropagation()
                  }}>
                  {cg.label}
                </Checkbox>
              </CheckboxGroup>
            </div>
          }>
          <SortableOptionValueList
            cg={cg}
            val={val}
            distance={1}
            onSortEnd={e => this.onOptionValueSortEnd(e, cg, val)}
            style={{ zIndex: 99999999 }}
            helperClass="sortableHelper"
          />
        </Panel>
      )
    }
    const SortableCustomisationListItem = SortableElement(CustomisationListItem)
    const OptionValueItem = prop => {
      const { c, elem } = prop

      return (
        <div key={`customization-${elem}`}>
          <StyledCheckbox
            key={`customization-cb-${elem}`}
            value={c.value}
            // Checked={c.selected}
          >
            {c.label}
          </StyledCheckbox>
        </div>
      )
    }
    const SortableOptionValueItem = SortableElement(OptionValueItem)
    const OptionValueList = props => {
      const { cg, val } = props

      return (
        <CheckboxGroup
          name={cg.label}
          onChange={selectedValues => {
            this.onCustomisationChange(val, selectedValues)
          }}
          value={cg.customisations
            .filter(cs => cs.selected)
            .map(cus => cus.value)}>
          {cg.customisations.map((c, cIndex) => (
            <SortableOptionValueItem
              key={cIndex}
              c={c}
              index={cIndex}
              elem={cIndex}
              distance={1}
              style={{ zIndex: 99999999 }}
            />
          ))}
        </CheckboxGroup>
      )
    }
    const SortableOptionValueList = SortableContainer(OptionValueList)
    const CustomisationList = () => {
      return (
        <StyledCollapse
          bordered={false}
          expandIcon={({ isActive }) => {
            return <StyledIcon type="caret-down" rotate={isActive ? 180 : 0} />
          }}
          expandIconPosition={'right'}
          onChange={key => {
            setItemUpDownArrowPosition(key)
          }}>
          {customisationGroups.map((cg, val) => (
            <SortableCustomisationListItem
              key={val}
              cg={cg}
              val={val}
              distance={1}
              index={val}
              style={{ zIndex: 99999999 }}
            />
          ))}
        </StyledCollapse>
      )
    }
    const SortableCustomisationList = SortableContainer(CustomisationList)

    return (
      <>
        <Row className="mt-2">
          <Col span={20}>
            <Title
              level="h5"
              background={true}
              style={{
                color: COLORS.DARK_TEXT,
                marginBottom: 0,
                fontSize: '15px'
              }}>
              <IntlMessages id="customization" />
            </Title>
          </Col>
          <Col span={4} className="no-pd-left">
            <Button
              type="secondary"
              style={{ width: '100%', height: '41px', marginBottom: 0 }}
              onClick={() => {
                this.launchCustomisationsGroupPopup()
              }}>
              <IntlMessages id="button.+add" />
            </Button>
          </Col>
        </Row>
        {selectedCutomisationGroups.length ? (
          <SelectedCustomisations
            customisationGroups={selectedCutomisationGroups}
            productVariantPrices={productVariantPrices}
            newProductVariantPrices={newProductVariantPrices}
            onBasePriceChange={this.updateProductBasePrice}
            onDealPriceChange={this.updateProductDealPrice}
            onPackagingChargeChange={this.updateProductPackagingCharge}
            onTagChange={this.updateProductTag}
            validateAndUpdatePrices={this.validateAndUpdatePrices}
            // Key={JSON.stringify([...newProductVariantPrices,...selectedCutomisationGroups,...productVariantPrices])}
          />
        ) : null}
        <Modal
          title={<IntlMessages id="customisationGroups" />}
          visible={showCustomizeGroupPopup}
          onClose={() => this.closeCustomisationsGroupPopup()}
          subTitle={<IntlMessages id="customisationMaxGroups" />}>
          <SortableCustomisationList
            onSortEnd={this.onSortEnd}
            distance={1}
            helperClass="sortableHelper"
          />
          <ButtonsContainer>
            <TextButton
              label={intlAlertMessage({
                id: 'button.+createNew',
                intl: this.props.intl
              })}
              onClick={() => onTabChange(MENU_TABS.CUSTOMIZATIONS)}
            />
            <Button
              type="primary"
              onClick={this.addSelectedGroup}
              style={{ width: '100px', marginBottom: 0 }}
              disabled={!this.validateSelection()}>
              {/* <Text level="button"> */}
              <IntlMessages id="add" />
              {/* </Text> */}
            </Button>
          </ButtonsContainer>
        </Modal>
      </>
    )
  }
}

export default ProductCustomisation
