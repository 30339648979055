import { Icon } from '@ant-design/compatible'
import {
  Checkbox,
  Col,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Spin,
  Tooltip
} from 'antd'
import { Button, FileUpload, IntlMessages, Text } from 'components'
import React from 'react'
import { injectIntl } from 'react-intl'
import { getMenuTimings } from 'services'
import { intlAlertMessage, randomString } from 'Utils'
import { COLORS, MAX_MUST_TRY_TAG_COUNT, MENU_TABS, PRICE_TYPE } from 'Utils/constants'
import { getFileSystemId } from 'Utils/localStorageHandlers'

import { isMustTryEnabled, priceFormatter } from '../../../Utils/menuUtils'
import { MenuTimingsDropdown } from '../components/MenuTimingsDropdown'
import ProductAddons from './productAddons'
import ProductCustomisation from './productCustomisation'

const RadioGroup = Radio.Group

interface IProductFormProps {
  categories: any;
  onChange: any
  productFormDetails: any
  client: any
  onValidateFields: any
  onSave: any
  onUpdate: any
  isLoadingForm: boolean
  isUpdatingForm: boolean
  intl: any
  priceData: any
  onDealPriceChanged: any
  dealPrice: any
  onProductRelationChange: any
  productRelationships: any
  onAddonGroupsChange: any
  categoriesWithProducts: any
  options: any[]
  productVariantPrices: any[]
  onProductVariantPriceChange: any
  newProductVariantPrices: any[]
  onTabChange: any
  originalProductVariantPrices: any[]
  selectedOptions: any[]
  currentMustTryTagCount: number
}

interface IProductFormState {
  showUploader: boolean
  selectedMenuTimingsForProduct: string
  menuTimingsForProduct: any
}

const Box = props => (
  <div className={`height-${props.value}`}>{props.children}</div>
)

class ProductForm extends React.Component<
  IProductFormProps,
  IProductFormState
> {
  priceData: any

  fileSystemId: string

  constructor(props) {
    super(props)
    this.state = {
      showUploader: false,
      selectedMenuTimingsForProduct: '',
      menuTimingsForProduct: null
    }
  }

  componentDidMount() {
    this.fileSystemId = getFileSystemId()
    this.getMenuTimingsForProduct()
  }

  onValueChange = (type, value) => {
    const { productFormDetails } = this.props

    productFormDetails[type] = value
    this.props.onChange('storeFormDetails', productFormDetails)
  }

  onTagChange = e => {
    this.onValueChange('tagCode', e.target.value)
  }

  onFormatChannelChange = (type, formatChannelIndex, value) => {
    const { productFormDetails } = this.props
    const { formatsChannels } = productFormDetails

    if (type === 'priceValue') {
      formatsChannels[formatChannelIndex][type] = value
    } else if (type === 'chargeValue') {
      formatsChannels[formatChannelIndex].charge[type] = value
    } else if (type === 'isPackaging') {
      formatsChannels[formatChannelIndex][type] = value
      if (type === 'isPackaging' && value === false) {
        formatsChannels[formatChannelIndex].charge.chargeValue = 0
      }
    }
    this.onValueChange('formatsChannels', formatsChannels)
  }

  showImageUpload() {
    if (!this.fileSystemId) {
      message.warn(
        intlAlertMessage({
          id: 'category.warnMsg.imgMissingforFilesystem',
          intl: this.props.intl
        })
      )
    } else {
      this.setState({ showUploader: true })
    }
  }

  fileUploadSave = data => {
    this.setState({ showUploader: false })
    this.onValueChange('imageUrl', data)
  }

  fileUploadCancel = data => {
    this.setState({ showUploader: false })
  }

  removeImageUpload = () => {
    this.onValueChange('imageUrl', '')
  }

  onPriceValueChanged = (
    dealPrice,
    basePrice,
    formatChannelIndex,
    fieldName
  ) => {
    if (dealPrice < 0 || basePrice < 0) {
      return
    }
    const discountValue = basePrice - dealPrice

    if (isNaN(dealPrice)) {
      this.props.onDealPriceChanged(0, basePrice)
    }
    if (dealPrice < basePrice && dealPrice !== null) {
      this.props.onDealPriceChanged(dealPrice, discountValue)
      this.onFormatChannelChange('priceValue', formatChannelIndex, basePrice)
    } else if (dealPrice >= basePrice) {
      if (fieldName === PRICE_TYPE.DEAL) {
        this.props.onDealPriceChanged(dealPrice, 0)
        this.onFormatChannelChange('priceValue', formatChannelIndex, dealPrice)
      } else {
        this.props.onDealPriceChanged(basePrice, 0)
        this.onFormatChannelChange('priceValue', formatChannelIndex, basePrice)
      }
    }
  }

  getMenuTimingsForProduct = async () => {
    try {
      const productMenuTimingsRes = await getMenuTimings(this.props.client)

      this.setState({
        menuTimingsForProduct: productMenuTimingsRes?.data?.getMenuTimings || []
      })
    } catch (error) {
      console.log('Error', error)
    }
  }

  render() {
    const {
      productFormDetails,
      isLoadingForm,
      isUpdatingForm,
      intl,
      onChange,
      onProductRelationChange,
      productRelationships,
      categoriesWithProducts,
      categories,
      options,
      productVariantPrices,
      onProductVariantPriceChange,
      newProductVariantPrices,
      onTabChange,
      originalProductVariantPrices,
      selectedOptions,
      onAddonGroupsChange,
      currentMustTryTagCount
    } = this.props
    const { showUploader } = this.state
    const isRegularProductPriceDisabled = selectedOptions.length > 0


    if (isLoadingForm || isUpdatingForm) {
      return (
        <div className="divCenter" style={{ marginTop: '20px' }}>
          <Spin
            size="large"
            tip={intlAlertMessage({
              id: 'orderTable.message.consoleIsLoading',
              intl: this.props.intl
            })}
          />
        </div>
      )
    }

    const isMaxCountReached = isMustTryEnabled({ tags: productFormDetails.currentTags }) ? false : currentMustTryTagCount >= MAX_MUST_TRY_TAG_COUNT

    return (
      <>
        <div className="form-wrapper">
          <Row className="mb-2" style={{ alignItems: 'flex-end' }}>
            <Col span={4}>
              <div className="mb-1">
                <IntlMessages id="itemImage" />
              </div>
              <div>
                {!productFormDetails.imageUrl ||
                productFormDetails.imageUrl.length < 20 ? (
                  <div
                    className="noImage"
                    onClick={() => this.showImageUpload()}>
                    <span>
                      <IntlMessages id="category.message.clickHereToAttachTheImage" />
                    </span>
                  </div>
                ) : (
                  <div>
                    <img
                      className="detailImage"
                      style={{ marginRight: '20px' }}
                      src={productFormDetails.imageUrl}
                      onClick={() => {
                        this.showImageUpload()
                      }}
                    />
                    <div
                      style={{
                        padding: '6px',
                        position: 'absolute',
                        top: '26px',
                        right: '-5px',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        this.removeImageUpload()
                      }}>
                      <Icon
                        theme="filled"
                        type="close-circle"
                        style={{
                          color: COLORS.NORMAL_TEXT,
                          borderRadius: '10px'
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col span={20}>
              <Box value={50}>
                <Row>
                  <Col span={24} className="mb-2">
                    <div>
                      <IntlMessages id="menu.itemName" />
                      <span className="requiredFieldRedColor">*</span>
                    </div>
                    <Input
                      size="large"
                      placeholder={intlAlertMessage({
                        id: 'menu.itemName',
                        intl: this.props.intl
                      })}
                      value={productFormDetails.name}
                      onChange={e => {
                        this.onValueChange('name', e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                {/* {productFormDetails['tags'].length > 0 && ( */}
                <Row>
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <RadioGroup
                      onChange={this.onTagChange}
                      value={productFormDetails.tagCode}>
                      {' '}
                      {productFormDetails.tags.map(tag => (
                        <Radio
                          value={tag.code}
                          className={tag.tagName?.toLowerCase()}
                          key={tag.code}>
                          {tag.tagName?.toLowerCase()}
                        </Radio>
                      ))}
                    </RadioGroup>
                    <MenuTimingsDropdown
                      isButtonAvailable={true}
                      buttonLabel={intlAlertMessage({
                        id: 'menu.addNewTimeSlot',
                        intl: this.props.intl
                      })}
                      data={this.state.menuTimingsForProduct}
                      onButtonClick={() => {
                        onTabChange(MENU_TABS.MENU_TIMINGS)
                      }}
                      onChangeItem={item => {
                        this.onValueChange('menuTimings', item)
                      }}
                      selectedMenuTiming={productFormDetails.menuTimings}
                      onMenuTimingHandler={() => {
                        this.onValueChange(
                          'isMenuTimingEnable',
                          !productFormDetails.isMenuTimingEnable
                        )
                      }}
                      isMenuTimingEnable={productFormDetails.isMenuTimingEnable}
                    />
                  </Col>
                </Row>
                {/* )} */}
              </Box>
            </Col>
          </Row>
          <Row className="mb-2" align='middle'>
            <Col span={18}>
              <div>
                <IntlMessages id="description" />
              </div>
              <Input
                size="large"
                placeholder={intlAlertMessage({
                  id: 'item.enterDescription',
                  intl: this.props.intl
                })}
                value={productFormDetails.description}
                onChange={e => {
                  this.onValueChange('description', e.target.value)
                }}
              />
            </Col>
            <Col span={6} style={{ marginTop: '15px', textAlign: 'right' }}>
              <Checkbox
                style={{ marginRight: '8px' }}
                checked={productFormDetails.isMustTryEnable}
                onChange={(e) => { this.onValueChange('isMustTryEnable', e.target.checked) }}
                disabled={isMaxCountReached}
              ></Checkbox>
              <Text level='body-2'>
                <IntlMessages id="menu.mustTryItem" />
              </Text>
              {isMaxCountReached ? (
                <Tooltip
                  title={intlAlertMessage({
                    id: 'mustTry.limitExceedWarning',
                    intl
                  }, { count: MAX_MUST_TRY_TAG_COUNT })}
                  className="store-tooltip">
                  <Icon
                    className="store-tooltip-icon"
                    type="info-circle"
                  />
                </Tooltip>
              ):null}

            </Col>
          </Row>
          {!isRegularProductPriceDisabled && (
            <Row style={{ marginBottom: '10px' }}>
              <Col span={7}>
                <div>
                  <IntlMessages id="menu.basePrice" />
                  <span className="requiredFieldRedColor">*</span>
                </div>
              </Col>
              <Col span={7}>
                <div>
                  <IntlMessages id="menu.dealPrice" />
                  <span className="requiredFieldRedColor">*</span>
                </div>
              </Col>
              <Col span={1}></Col>
              <Col span={6}>
                <div>
                  <IntlMessages id="newOrder.packagingCharges" />
                </div>
              </Col>
            </Row>
          )}
          {!isRegularProductPriceDisabled &&
            productFormDetails.formatsChannels.map((formatChannel, index) =>
              index === 0 ? (
                <Row key={`formatchannel-${index}`}>
                  {/* <Col span={4}>
                  <div>{formatChannel['storeFormat']['name']}</div>
                </Col>
                <Col span={4}>
                  <div>{formatChannel['channel']['name']}</div>
                </Col> */}
                  <Col span={7}>
                    <div>
                      <InputNumber
                        size="large"
                        formatter={priceFormatter}
                        value={Math.round(formatChannel.priceValue)}
                        min={0}
                        onChange={value => {
                          this.onFormatChannelChange('priceValue', index, value)
                        }}
                        onBlur={() => {
                          this.onPriceValueChanged(
                            this.props.dealPrice,
                            formatChannel.priceValue,
                            index,
                            PRICE_TYPE.BASE
                          )
                        }}
                        disabled={isRegularProductPriceDisabled}
                      />
                    </div>
                  </Col>
                  <Col span={7}>
                    <div>
                      <InputNumber
                        size="large"
                        formatter={priceFormatter}
                        value={Math.round(this.props.dealPrice)}
                        min={0}
                        onChange={value => {
                          const val = value < 0 ? 0 : value

                          this.props.onDealPriceChanged(val, 0)
                        }}
                        onBlur={() => {
                          this.onPriceValueChanged(
                            this.props.dealPrice,
                            formatChannel.priceValue,
                            index,
                            PRICE_TYPE.DEAL
                          )
                        }}
                        disabled={isRegularProductPriceDisabled}
                      />
                    </div>
                  </Col>
                  <Col span={1}>
                    <div>
                      <Checkbox
                        style={{ marginTop: '10px' }}
                        checked={formatChannel.isPackaging}
                        onChange={e => {
                          this.onFormatChannelChange(
                            'isPackaging',
                            index,
                            e.target.checked
                          )
                        }}
                        disabled={isRegularProductPriceDisabled}></Checkbox>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <InputNumber
                        formatter={priceFormatter}
                        size="large"
                        value={formatChannel.charge.chargeValue}
                        disabled={
                          !formatChannel.isPackaging ||
                          isRegularProductPriceDisabled
                        }
                        onChange={value => {
                          const roundOffValue = value && Math.round(parseFloat(value))

                          this.onFormatChannelChange(
                            'chargeValue',
                            index,
                            roundOffValue
                          )
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <span key={`formatchannel-${index}`}></span>
              )
            )}

          <ProductCustomisation
            intl={intl}
            client={this.props.client}
            onProductRelationChange={onProductRelationChange}
            options={options}
            productRelationships={productRelationships}
            originalProductVariantPrices={originalProductVariantPrices}
            productVariantPrices={productVariantPrices}
            newProductVariantPrices={newProductVariantPrices}
            onProductVariantPriceChange={onProductVariantPriceChange}
            key={JSON.stringify(productFormDetails.id)}
            onTabChange={onTabChange}
          />
          <ProductAddons
            productFormDetails={productFormDetails}
            intl={intl}
            onChange={onChange}
            onProductRelationChange={onProductRelationChange}
            onAddonGroupsChange={onAddonGroupsChange}
            productRelationships={productRelationships}
            categoriesWithProducts={categoriesWithProducts}
            key={JSON.stringify(productFormDetails.code)}
            onTabChange={onTabChange}
          />
        </div>
        <Button
          style={{
            marginLeft: '20px',
            marginTop: '20px',
            borderRadius: '5px',
            width: '120px'
          }}
          type='primary'
          loading={isUpdatingForm}
          onClick={() => {
            if (!this.props.onValidateFields()) {
              if (productFormDetails.id === null) {
                this.props.onSave()
              } else {
                this.props.onUpdate()
              }
            }
          }}>
          {productFormDetails.id === null ? (
            <IntlMessages id="create" />
          ) : (
            <IntlMessages id="update" />
          )}
        </Button>

        <FileUpload
          key={randomString(4)}
          title={<IntlMessages id="uploadImage" />}
          isVisible={showUploader}
          fileSystemId={this.fileSystemId}
          onSave={this.fileUploadSave}
          onCancel={this.fileUploadCancel}
          client={this.props.client}
          allowedSizeInMB={1}
          singleFile={true}
          imgDimension={'400x400 px'}
          fileSize={'1 MB'}
          imgProportion={'Square'}
          fileTypeList={['jpg', 'jpeg', 'png']}>
          {this.props.children}
        </FileUpload>
      </>
    )
  }
}

export default injectIntl(ProductForm)
