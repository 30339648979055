import { DatePicker, Row, Table, Tabs as AntDTabs } from 'antd'
import { ICONS } from 'assets'
import { Button, IntlMessages, Search, Tabs, Text, TrimmedText } from 'components'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import TimeAgo from 'react-timeago'
import { getRedemptions } from 'services/promotionService'
import { isAdmin, setErrMsg } from 'Utils'
import {
  COLORS,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_START_DATE,
  END_TIME,
  HOUR_TO_MINUTE_TIME_FORMAT,
  MAX_ITEMS_PER_PAGE,
  REDEMPTION_STATUS,
  RUPEE,
  START_TIME
} from 'Utils/constants'
import { getUserData } from 'Utils/onboardingUtils'

import MetabaseReports from './MetabaseReports'

const { TabPane } = AntDTabs
const { RangePicker } = DatePicker


const RenderPriceValue = ({ value }) => (
  <div>
    {`${RUPEE} ${value}`}
  </div>
)

const transactionHistoryTableColumns = [
  {
    title: 'Time',
    key: 'transactionTime',
    dataIndex: 'transactionTime',
    width: '11%',
    render: dateTime => (
      <div>
        {dateTime ?
          <>
            <div>{dateTime?.time}</div>
            <div>{dateTime?.date}</div>
          </> :
          <div> - </div>
        }
      </div>
    )
  },
  {
    title: 'Transaction ID',
    key: 'transactionId',
    dataIndex: 'transactionId',
    width: '25%'
  },
  {
    title: 'Store Details',
    key: 'storeDetails',
    dataIndex: 'storeDetails',
    render: storeDetails => (
      <div>
        <TrimmedText limitText={1} text={storeDetails?.storeName || ''} />
        <TrimmedText limitText={1} text={storeDetails?.venueName || ''} />
      </div>
    )
  },
  {
    title: 'Bill Amount',
    key: 'billAmount',
    dataIndex: 'billAmount',
    width: '8%',
    render: billAmount => (
      <RenderPriceValue value={billAmount} />
    )
  },
  {
    title: 'Merchant Discount',
    key: 'merchantDiscount',
    dataIndex: 'merchantDiscount',
    width: '8%',
    render: merchantDiscount => (
      <RenderPriceValue value={merchantDiscount} />
    )
  },
  {
    title: 'Other Offers',
    key: 'otherOffers',
    dataIndex: 'otherOffers',
    width: '8%',
    render: otherOffers => (
      <RenderPriceValue value={otherOffers} />
    )
  },
  {
    title: 'Amount Paid By Customer',
    key: 'amountPaidByCustomer',
    dataIndex: 'amountPaidByCustomer',
    width: '10%',
    render: amountPaidByCustomer => (
      <RenderPriceValue value={amountPaidByCustomer} />
    )
  },
  {
    title: 'Transaction Status',
    key: 'transactionStatus',
    dataIndex: 'transactionStatus',
    width: '12%',
  },
]

function formatTransactionHistoryTableData(responseData) {
  return responseData.map(redemption => {
    const {
      redemption_id,
      redemption_status,
      redemption_date,
      claim: { store_promotion, customer },
      payment: { payment_last_modified_time },
      redemption_details: { bill_amount, discount, payable_bill_amount }
    } = redemption

    let transactionTime = null
    const transactionDateTime = redemption_date || payment_last_modified_time

    if (transactionDateTime) {
      transactionTime = {
        date: moment(transactionDateTime).format(DATE_FORMAT),
        time: moment(transactionDateTime).format(HOUR_TO_MINUTE_TIME_FORMAT)
      }
    }

    const { direct_discount_percentage: discountPercentage } = store_promotion.store_promotion_details

    return {
      key: redemption_id,
      transactionTime,
      transactionId: redemption_id,
      storeDetails: {
        storeName: store_promotion.store.store_name,
        venueName: store_promotion.store.venue.name
      },
      billAmount: bill_amount,
      merchantDiscount: discount,
      otherOffers: 0,
      amountPaidByCustomer: payable_bill_amount,
      transactionStatus: redemption_status,
    }
  }
  )

}

// Below constants are specific to this component hence kept here
let data: object[] = []
const endDateTime = new Date()
const startDateTime = new Date(DEFAULT_START_DATE)
const DEFAULT_START_DATE_TIME = moment(startDateTime).set(START_TIME).format(DATE_TIME_FORMAT)
const DEFAULT_END_DATE_TIME = moment(endDateTime).set(END_TIME).format(DATE_TIME_FORMAT)
const DEFAULT_ACTIVE_KEY = '1'

function PromotionsHistory() {
  const [transactionData, setTransactionData] = useState<object[]>([])
  const [IsLoading, setIsLoading] = useState<boolean>(false)
  const [dates, setDates] = useState<any>([])
  const [searchString, setSearchString] = useState<string>('')
  const [activeTabIndex, setActiveTabIndex] = useState<string>(DEFAULT_ACTIVE_KEY)
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(MAX_ITEMS_PER_PAGE)
  const lastUpdatedTime = useRef<any>(endDateTime)
  const isAdminUser = isAdmin()
  const intl = useIntl()

  async function getTransactions(customPageNumber = null) {
    const [startDate, endDate] = dates
    const shiftedStartDateTime = startDate && startDate.set(START_TIME).format(DATE_TIME_FORMAT)
    const shiftedEndDateTime = endDate && endDate.set(END_TIME).format(DATE_TIME_FORMAT)
    const dateRange = {
      startDateTime: shiftedStartDateTime || DEFAULT_START_DATE_TIME,
      endDateTime: shiftedEndDateTime || DEFAULT_END_DATE_TIME
    }
    const pagination = {
      pageNumber: customPageNumber || currentPageNumber,
      pageSize
    }

    try {
      setIsLoading(true)
      const { stores } = getUserData()

      const storeId = !isAdminUser ? stores[0].id : null
      const response = await getRedemptions(dateRange, pagination, storeId, REDEMPTION_STATUS.REDEEMED)
      const { redemptions, pagination: { total_count } } = response
      
      data = formatTransactionHistoryTableData(redemptions)

      setTotalCount(total_count)
      setTransactionData(data)
    }
    catch (err) {
      setTransactionData([])
      setErrMsg('transactionsHistoryError', intl)
      console.log(err)
    }
    finally {
      if (dateRange.startDateTime === DEFAULT_START_DATE_TIME
        && dateRange.endDateTime === DEFAULT_END_DATE_TIME
        && pagination.pageNumber === DEFAULT_PAGE_NUMBER) {
        lastUpdatedTime.current = new Date()
      }
      setIsLoading(false)
    }
  }

  function handleDateChange(selectedDates) {
    setCurrentPageNumber(DEFAULT_PAGE_NUMBER)
    const newDates = selectedDates || []

    setDates(newDates)
  }

  const handleRefresh = () => {
    setCurrentPageNumber(DEFAULT_PAGE_NUMBER)
    setSearchString('')
    if (dates?.length) {
      setDates([])
    }
    else {
      getTransactions(DEFAULT_PAGE_NUMBER)
    }
  }

  function handleSearch(searchInput) {
    if (!searchString || !searchInput) {
      !searchInput && setSearchString('')
      setTransactionData(data)

      return
    }
    const filteredData = data.filter(({ transactionId, storeDetails }: any) => {
      const lowerCaseSearchInput = searchString.toLowerCase()

      return (
        transactionId.toLowerCase().includes(lowerCaseSearchInput)
        || (storeDetails?.storeName && storeDetails.storeName.toLowerCase().includes(lowerCaseSearchInput))
      )
    })

    setTransactionData(filteredData)
  }

  function handlePageAndSizeChange(page, itemPerPage) {
    setCurrentPageNumber(page)
    pageSize === itemPerPage ? getTransactions(page) : setPageSize(itemPerPage)
  }

  function renderRefreshButton() {
    return (
      <Row justify='end' style={{ margin: 0 }} align="middle" >
        <Text level='body-2' style={{ color: COLORS.GRAY_TEXT }}>
          <i> {intl.formatMessage({ id: 'lastUpdateWas' })} <TimeAgo date={lastUpdatedTime.current} formatter={(value, unit) => `${value} ${value <= 1 ? unit : `${unit}s`} ago`} /> : </i>
        </Text>
        <Button
          type='link'
          style={{ margin: 0, fontWeight: 'bold' }}
          onClick={handleRefresh}>
          <img alt="refresh" style={{ width: 16, height: 16, marginRight: 5 }} src={ICONS.refresh} />
          {intl.formatMessage({ id: 'refreshNow' })}
        </Button>
      </Row>
    )
  }

  function renderFilters() {
    return (
      <Row
        justify="space-between"
        style={
          {
            marginTop: 18,
            display: activeTabIndex === DEFAULT_ACTIVE_KEY ? 'flex' : 'none',
            justifyContent: 'flex-end'
          }
        }
        align="middle">
        <Row style={{ margin: 0 }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: 40,
            width: 250
          }}>
            <Text level="caption">
              <IntlMessages id="orderHistory.selectDate" />
            </Text>
            <RangePicker
              format={DATE_FORMAT}
              separator="|"
              value={dates}
              style={{ height: 37 }}
              onChange={handleDateChange}
            />
          </div>

          <Search
            allowClear
            value={searchString}
            placeholder={intl.formatMessage({ id: 'searchByTransactionIdStoreName' })}
            onChange={(e) => setSearchString(e?.target?.value)}
            onSearch={handleSearch}
            style={{ width: 400, marginTop: 15 }}
          />
        </Row>
      </Row>
    )
  }

  // Call once on mount 
  useEffect(() => {
    data = []
  }, [])

  useEffect(() => {
    data = []
    getTransactions()
  }, [dates, pageSize])


  return (
    <div style={{ padding: '0 20px', marginTop: '28px', position: 'relative' }}>
      < Tabs defaultActiveKey={DEFAULT_ACTIVE_KEY} onChange={(key) => setActiveTabIndex(key)}>
        <TabPane tab={intl.formatMessage({ id: 'transactionHistory' })} key="1" style={{ marginBottom: 20 }}>
          {renderFilters()}
          {renderRefreshButton()}
          <Table
            loading={IsLoading}
            columns={transactionHistoryTableColumns}
            dataSource={transactionData}
            pagination={{
              current: currentPageNumber,
              pageSize,
              total: totalCount,
              onChange: handlePageAndSizeChange
            }}
          />
        </TabPane>
        {isAdminUser && <TabPane tab={intl.formatMessage({ id: 'settlementReports' })} key="2">
          <MetabaseReports
            dashboardURL={process.env.REACT_APP_PROMOTION_SETTLEMENT_REPORTS_URL}
            isTitled={false}
          />
        </TabPane>
        }
        {isAdminUser && <TabPane tab={intl.formatMessage({ id: 'brandDetailedReport' })} key="3">
          <MetabaseReports
            dashboardURL={process.env.REACT_APP_PROMOTION_VENUE_LEVEL_BUSINESS_REPORTS_URL}
            isTitled={false}
          />
        </TabPane>
        }
        <TabPane tab={intl.formatMessage({ id: 'brandOutletRedemptions' })} key="4">
          <MetabaseReports
            dashboardURL={process.env.REACT_APP_PROMOTION_OUTLET_LEVEL_BUSINESS_REPORTS_URL}
            isTitled={false}
          />
        </TabPane>
      </Tabs>
    </div >
  )

}
export default PromotionsHistory