import { Icon } from '@ant-design/compatible'
import { Mutation } from '@apollo/client/react/components'
import { Col, Form, Input, Modal, Row } from 'antd'
import { FormInstance } from 'antd/es/form'
import { IMAGES } from 'assets'
import { Button, IntlMessages, Text } from 'components'
import { History } from 'history'
import * as React from 'react'
import { CHANGE_PASSWORD } from 'services/query'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'

interface IProps {
  history?: History
  onSubmit: any
  intl: any
}

interface IState {
  isEmailSent: boolean
  email: string
  showStepsModal: boolean
  sendingEmail: boolean
}

class ResetEmailForm extends React.Component<IProps, IState> {
  isResetLinkExpire: boolean

  formRef: any

  constructor(props) {
    super(props)
    this.isResetLinkExpire = this.props.history.location.state
      ? this.props.history.location.state.isLinkExpire
      : false
    this.state = {
      isEmailSent: false,
      email: '',
      showStepsModal: false,
      sendingEmail: false
    }
    this.formRef = React.createRef<FormInstance>()
  }

  render() {
    const { isEmailSent, email, showStepsModal, sendingEmail } = this.state
    const { intl } = this.props

    return (
      <div>
        {!isEmailSent && (
          <Mutation mutation={CHANGE_PASSWORD} fetchPolicy="no-cache">
            {(changePassword, { loading, error, data }: any) => (
              <Form
                ref={this.formRef}
                onFinish={async values => {
                  const { email } = values

                  this.setState({ sendingEmail: true })
                  await this.props.onSubmit(email)
                  this.setState({ isEmailSent: true, sendingEmail: false })
                  this.setState({ email })
                }}
                className="login-form">
                <Row style={{ marginBottom: '20px' }}>
                  <Col span={20} style={{ paddingLeft: 0 }}>
                    <h2
                      style={{
                        marginBottom: 0,
                        color: COLORS.DARK_TEXT,
                        fontWeight: 500
                      }}>
                      {this.isResetLinkExpire ? (
                        <IntlMessages id="reset.email.form.reset.link.exp.title" />
                      ) : (
                        <IntlMessages id="reset.email.form.reset.title" />
                      )}
                    </h2>
                    <div style={{ fontSize: '13px' }}>
                      {this.isResetLinkExpire ? (
                        <IntlMessages id="reset.email.form.reset.link.exp.desc" />
                      ) : (
                        <IntlMessages id="reset.email.form.reset.desc" />
                      )}
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <Text level="caption">
                        <IntlMessages id="reset.email.form.forgot.password.owner" />
                      </Text>
                    </div>
                  </Col>
                  <Col
                    span={4}
                    style={{
                      paddingRight: 0,
                      textAlign: 'right',
                      color: COLORS.DARK_TEXT,
                      fontSize: '16px',
                      cursor: 'pointer'
                    }}>
                    <Icon
                      type="close"
                      onClick={() => this.props.history.push('/login')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Text level="caption" style={{ fontSize: '11px' }}>
                      <IntlMessages id="reset.email.form.input.label" />
                    </Text>
                  </Col>
                </Row>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: intlAlertMessage({
                        id: 'reset.email.form.email.required.error',
                        intl
                      })
                    },
                    {
                      type: 'email',
                      message: intlAlertMessage({
                        id: 'reset.email.form.email.validation.error',
                        intl
                      })
                    }
                  ]}
                  hasFeedback>
                  <Input
                    placeholder={intlAlertMessage({
                      id: 'reset.email.form.email.placeholder',
                      intl
                    })}
                  />
                </Form.Item>

                <Row>
                  <Col
                    span={24}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginBottom: '10px'
                    }}>
                    <Text level="caption">
                      <IntlMessages id="reset.email.form.forgot.password.manager" />
                    </Text>
                  </Col>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                  <Col span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={sendingEmail}
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        width: '100%'
                      }}>
                      <IntlMessages id="item.emailMeRecoveryLink" />
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Mutation>
        )}
        {isEmailSent && (
          <div>
            <Row>
              <Col span={20} style={{ paddingLeft: 0 }}></Col>
              <Col
                span={4}
                style={{
                  paddingRight: 0,
                  textAlign: 'right',
                  color: COLORS.DARK_TEXT,
                  fontSize: '16px',
                  cursor: 'pointer'
                }}>
                <Icon
                  type="close"
                  onClick={() => this.props.history.push('/login')}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
              <Col span={24} className="gx-text-center">
                {' '}
                <img src={IMAGES.mail_sent} alt="mail sent" />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ paddingLeft: 0 }}>
                <h2
                  style={{
                    marginBottom: '10px',
                    color: COLORS.DARK_TEXT,
                    fontWeight: 500
                  }}>
                  <IntlMessages id="reset.email.form.email.title" />
                </h2>
                <div style={{ fontSize: '15px' }}>
                  <p>
                    <IntlMessages id="reset.email.form.email.desc.1" />
                    {
                      <span
                        style={{ fontWeight: 500, color: COLORS.NORMAL_TEXT }}>
                        {email}
                      </span>
                    }
                    <IntlMessages id="reset.email.form.email.desc.2" />
                    <span
                      className="link-text"
                      style={{ float: 'none', cursor: 'pointer' }}
                      onClick={() => this.setState({ showStepsModal: true })}>
                      <IntlMessages id="clickHere" />
                    </span>
                  </p>
                  <IntlMessages id="reset.email.form.email.desc.3" />
                  <span
                    className="link-text"
                    style={{ float: 'none', cursor: 'pointer' }}
                    onClick={() => this.setState({ isEmailSent: false })}>
                    <IntlMessages id="tryAgain" />
                  </span>
                </div>
              </Col>
            </Row>
            <Modal
              className="steps-modal"
              width={385}
              visible={showStepsModal}
              title={<IntlMessages id="reset.email.form.email.desc.3" />}
              onCancel={() => {
                this.setState({ showStepsModal: false })
              }}
              footer={[]}
              style={{ top: '36%' }}>
              <Row>
                <Col span={24}>
                  <p>
                    <IntlMessages id="reset.email.form.steps.1" /> ({email}).
                  </p>
                  <p>
                    <IntlMessages id="reset.email.form.steps.2" />
                  </p>
                  <p>
                    <IntlMessages id="reset.email.form.steps.3" />
                  </p>
                  <p>
                    <IntlMessages id="reset.email.form.steps.4" />
                  </p>
                </Col>
              </Row>
            </Modal>
          </div>
        )}
      </div>
    )
  }
}

export default ResetEmailForm
