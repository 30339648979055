import { Icon } from '@ant-design/compatible'
import { Checkbox, Col, Collapse, Input, Menu } from 'antd'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

export const StyledCheckbox = styled(Checkbox)`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

export const StyledIcon = styled(Icon)`
  color: ${COLORS.BORDER_DARK};
`

export const StyledCollapse = styled(Collapse)`
  overflow-y: auto;
  &.ant-collapse-icon-position-right .ant-collapse-item .ant-collapse-header {
    padding-left: 0;
  }
  &.ant-collapse-icon-position-right
    .ant-collapse-item-active
    .ant-collapse-header {
    padding-left: 0;
  }
`
export const StyledInputBox = styled.div`
  border: 1px solid ${COLORS.BORDER};
  padding: 5px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.DARK_TEXT};
  border-radius: 5px;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  cursor: pointer;
  &:hover {
    border-color: ${COLORS.BORDER_DARK};
  }
  &.box-default {
    width: 100%;
    height: 37px;
  }
  &.box-small {
    width: 291px;
    height: 36px;
  }
`
export const UpOutlinedStyles = styled(Icon)`
  font-size: 13px;
`
export const DownOutlinedStyles = styled(Icon)`
  font-size: 13px !important;
`

export const MenuStyle = styled(Menu)`
  &.ant-dropdown-menu-item-active {
    background-color: ${COLORS.ERROR};
    &:hover {
      background-color: ${COLORS.BG_SHADE};
    }
  }
`
export const GroupItemStyle = styled.div`
  padding: 12px 12px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: ${COLORS.BG_SHADE};
    color: ${COLORS.PRIMARY};
    font-weight: 600;
  }
`
export const AddItemStyle = styled(Input)`
  margin-right: 5px;
`
export const ItemBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

export const StyledCheckBoxContainer = styled.div`
  margin: 20px 10px 0 10px;
`
export const StyleMenuDropdown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const StyledCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 4px;
    width: 19px;
    height: 19px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${COLORS.PRIMARY};
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: ${COLORS.PRIMARY};
    background-color: ${COLORS.PRIMARY};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    width: 15px;
    background-color: ${COLORS.BG_WHITE};
    height: 2px;
  }
`

export const StyledContainer = styled(Col)`
  height: fit-content;
  margin-left: 18px;
  background-color: ${COLORS.BG_WHITE};
  padding: 15px;
`

export const SaveButtonContainer = styled.div`
  height: fit-content;
  padding: 15px;
`

export const ClearAllSlotsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ScrollableListContainer = styled.div`
  height: 67vh;
  overflow-y: auto;
`
export const AddonContainerStyles = styled(Col)`
  background: white;
  padding: 15px 12px;
  margin-bottom: 20px;
  height: 80vh
`
export const ImageContainerStyles = styled.div`
  background-color: ${COLORS.BG_WHITE};
  padding: 25px;
  width: 500px;
`
export const AddonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
export const AddonPriceContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`