import './style.css'

import { ApolloClient } from '@apollo/client'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Col, Row, Spin } from 'antd'
import { ClientManager } from 'appApollo/client'
import { IntlMessages } from 'components'
import { History } from 'history'
import jwt from 'jsonwebtoken'
import React from 'react'
import { injectIntl } from 'react-intl'
import { getAllChannels, getCatalogue, getFileSystems, getStoreFormat, updateOrderXOrganization, updatePMSBankDetails, updatePMSOrganization } from 'services'
import { getOrgDetails, getUserDetails, intlAlertMessage, isAdmin, setOrgDetails, setUserDetails, showSideBar } from 'Utils'
import { FILE_SYSTEM_TYPES, ORG_STATUS } from 'Utils/constants'
import { getGpayDetails,getUploadApiConfig, getUserData, isGpayDone, setOnboarding } from 'Utils/onboardingUtils'

import { BankAccountCard, BusinessLogoCard, GpayCard, MenuLinkCard, MiniAppUrlCard, OnboardingHeader, OutletInfoCard, YourMenuCard } from './components'

interface OnboardingProps extends ApolloProviderProps<any> {
  history: History
  intl: any
}
interface OnboardingState {
  org_id: string
  isFetching: boolean
  logoUrl: ''
  isStoreCreated: boolean
  isCatalogCreated: boolean
  isMenuVerified: boolean
  gPayRegistrationDone: boolean
  channels: any
  storeFormats: any
  catalogs: any
  stores: any
  roles: any
  bankAccount: any
  addingBankAccount: boolean
  enableOnboard: boolean
  confirmAccountNumber: string
  acctInputType: string
  isAcctnoMismatch: boolean
  fileSystemId: string
  organization: any
  uploadingMenu: boolean
  fetchingMenu: boolean
}
class Onboarding extends React.Component<OnboardingProps, OnboardingState> {
  org_id: string | { [key: string]: any }

  pmsClient: ApolloClient<any>

  constructor(props: OnboardingProps) {
    super(props)
    const organization = getOrgDetails()

    this.pmsClient = ClientManager.getInstance().pms
    this.state = {
      isFetching: true,
      org_id: '',
      logoUrl: '',
      isStoreCreated: false,
      isCatalogCreated: false,
      isMenuVerified: false,
      gPayRegistrationDone: isGpayDone(organization.associations),
      channels: [],
      storeFormats: [],
      catalogs: [],
      stores: [],
      roles: [],
      addingBankAccount: false,
      enableOnboard: false,
      bankAccount: {
        beneficiaryName: '',
        // BankName: '',
        accountNumber: '',
        ifsc: ''
      },
      confirmAccountNumber: '',
      acctInputType: 'text',
      isAcctnoMismatch: false,
      fileSystemId: null,
      organization,
      uploadingMenu: false,
      fetchingMenu: false
    }
  }

  componentDidMount() {
    const { organization } = this.state

    if (!isAdmin() || (isAdmin() && organization && organization.onboardingStatus !== ORG_STATUS.INITIATED)) {
      this.props.history.push({ pathname: '/orderx/orders' })

      return
    }
    const userDetails = getUserDetails()

    if (!userDetails?.catalogs || !userDetails?.catalogs.length) {
      this.getCatalogs(true)
    } else {
      this.setState({
        isCatalogCreated: Boolean(userDetails.catalogs.length),
        catalogs: userDetails.catalogs
      })
      this.initOnboarding()
    }
  }

  getCatalogs = async initOnboarding => {
    const userDetails = getUserDetails()
    const { organizationId } = getUploadApiConfig()

    try {
      const catalogsResponse = await getCatalogue(this.props.client, organizationId)
      const { catalogs } = catalogsResponse.data

      userDetails.catalogs = catalogs
      initOnboarding && this.initOnboarding()
      this.setState({
        isCatalogCreated: Boolean(catalogs?.length) || this.state.isCatalogCreated,
        catalogs
      })
      setUserDetails(userDetails)
    } catch (err) {
      console.error('Get catalogs error', err)
    }
  }

  initOnboarding = () => {
    const { roles, stores, catalogs } = getUserData()
    const isStoreCreated = stores && stores.length > 0
    const isMenuVerified = catalogs && catalogs.length > 0 && catalogs[0].listable

    if (!isMenuVerified || !isStoreCreated) {
      this.getChannels()
    } else {
      this.setState({ isFetching: false })
    }
    this.setState({
      isStoreCreated,
      isMenuVerified,
      catalogs,
      stores,
      roles
    })
    this.getFileSystemId()
  }

  getChannels = async () => {
    try {
      const response = await getAllChannels(this.props.client)
      const channels = response.data.channels.data

      this.setState({ channels }, () => this.getStoreFormats())
      localStorage.setItem('channels', JSON.stringify(channels))
    } catch (err) {
      console.error('Get channels error', err)
    }
  }

  async getStoreFormats() {
    try {
      const response = await getStoreFormat(this.props.client)
      const { data } = response.data.storeFormats

      console.log('storeFormats', data)
      this.setState({ storeFormats: data, isFetching: false })
    } catch (err) {
      console.error('Get Store formats error', err)
    }
  }

  getFileSystemId = async () => {
    const { organizationId } = getUploadApiConfig()

    try {
      const response = await getFileSystems(this.props.client, organizationId)
      const { data } = response.data.fileSystems

      data.forEach(element => {
        if (element.fileSystemType === FILE_SYSTEM_TYPES.CLOUDINARY && element.status === 'ACTIVE') {
          this.setState({ fileSystemId: element.id })
        }
      })
    } catch (err) {
      console.error('Get file system id error', err)
    }
  }

  goToCatalogDetails = () => {
    const { channels, storeFormats, catalogs, roles, stores } = this.state

    setOnboarding()
    const catalog = catalogs[0]

    this.props.history.push({
      pathname: `/orderx/menu-list/${catalog.id}/detail`,
      state: {
        catalog,
        channels,
        storeFormats,
        catalogCount: catalogs.length,
        isOnboarding: true,
        roles,
        store: stores
      }
    })
  }

  goToCreateStore = () => {
    const { catalogs, roles, stores } = this.state

    setOnboarding()
    this.props.history.push({
      pathname: '/orderx/store',
      state: {
        isOnboarding: true,
        roles,
        store: stores,
        catalogs
      }
    })
  }

  createBankAccount = async values => {
    this.setState({ addingBankAccount: true })
    this.saveFssai(values)
  }

  saveFssai = async values => {
    const { fssai } = values
    const { organization } = this.state

    if (organization.fssai === fssai || !fssai) {
      this.savePmsBankDetils(values)
    } else {
      try {
        const details = {
          id: organization.id,
          fssai
        }
        const updateOrgRes = await updatePMSOrganization(this.pmsClient, details)

        if (updateOrgRes && updateOrgRes.data && updateOrgRes.data.updateOrganization) {
          this.savePmsBankDetils(values)
        }
      } catch (err) {
        console.log('Save fssai error', err)
        this.setState({ addingBankAccount: false })
      }
    }
  }

  savePmsBankDetils = async values => {
    const { beneficiaryName, ifsc, accountNumber } = values
    const { organization } = this.state

    try {
      const bankDetailsInput = {
        id: organization.id,
        bankDetails: {
          accountNo: accountNumber,
          name: beneficiaryName.trim(),
          ifscCode: ifsc,
          beneficiaryName: beneficiaryName.trim()
        }
      }
      const updateBankDetailsRes = await updatePMSBankDetails(this.pmsClient, bankDetailsInput)

      if (updateBankDetailsRes && updateBankDetailsRes.data && updateBankDetailsRes.data.updateBankDetails && updateBankDetailsRes.data.updateBankDetails.success) {
        this.updateOnboardingStatus()
      }
    } catch (err) {
      console.log('Save bank details error', err)
      this.setState({ addingBankAccount: false })
    }
  }

  updateOnboardingStatus = async () => {
    const { organization } = this.state

    try {
      const payload = {
        id: organization.id,
        onboardingStatus: ORG_STATUS.ORDER_READY,
        status: ORG_STATUS.ACTIVE
      }
      const updateOrgRes = await updatePMSOrganization(this.pmsClient, payload)

      if (updateOrgRes && updateOrgRes.data && updateOrgRes.data.updateOrganization) {
        this.setState({
          organization: updateOrgRes.data.updateOrganization.organization,
          addingBankAccount: false
        })
        setOrgDetails(updateOrgRes.data.updateOrganization.organization)
        localStorage.removeItem('isOnboarding')
        showSideBar()
        this.props.history.push({
          pathname: '/orderx/orders',
          state: {
            isOnboarding: true
          }
        })
      }
    } catch (err) {
      this.setState({ addingBankAccount: false })
      console.log('Update onboarding status error', err)
    }
  }

  validateToOnboard = () => {
    const { isMenuVerified, isStoreCreated, bankAccount, confirmAccountNumber } = this.state
    const isEnableOnboard = isMenuVerified && isStoreCreated && bankAccount.beneficiaryName && bankAccount.accountNumber && bankAccount.ifsc && confirmAccountNumber

    this.setState({ enableOnboard: Boolean(isEnableOnboard) })
  }

  onAccountNoBlur = () => {
    this.setState({ acctInputType: 'password' })
    const { bankAccount, confirmAccountNumber } = this.state

    if (confirmAccountNumber !== '') {
      if (bankAccount.accountNumber !== confirmAccountNumber) {
        this.setState({ isAcctnoMismatch: true })

        return false
      }
      this.setState({ isAcctnoMismatch: false })
    }
  }

  onAccountNoMismatch = () => {
    const { bankAccount, isAcctnoMismatch } = this.state

    if (isAcctnoMismatch) {
      if (bankAccount.accountNumber === '' || bankAccount.accountNumber === null) {
        this.setState({ acctInputType: 'text' }, () => {
          this.setState({
            isAcctnoMismatch: false
          })
        })
      }
      this.setState({
        confirmAccountNumber: ''
      })
    }
  }

  onConfirmAccountNoValidate = () => {
    const { bankAccount, confirmAccountNumber } = this.state

    if (confirmAccountNumber !== '') {
      if (bankAccount.accountNumber !== confirmAccountNumber) {
        this.setState({ isAcctnoMismatch: true })

        return false
      }
      this.setState({ isAcctnoMismatch: false })
    }
  }

  onLogoSave = async url => {
    const { organization } = this.state
    const jwtToken: any = localStorage.getItem('jwt')
    const { org_id }: any = jwt.decode(jwtToken)

    try {
      const payload = {
        id: organization.id,
        orgLogo: url
      }
      const updateOrgRes = await updatePMSOrganization(this.pmsClient, payload)

      if (updateOrgRes && updateOrgRes.data && updateOrgRes.data.updateOrganization) {
        const payload = {
          id: org_id,
          brandLogo: url
        }

        await updateOrderXOrganization(this.props.client, payload)
        this.setState({
          organization: updateOrgRes.data.updateOrganization.organization
        })
        setOrgDetails(updateOrgRes.data.updateOrganization.organization)
      }
    } catch (err) {
      console.log('Save brand logo error ', err)
    }
  }

  onMenuSave = () => {
    this.getCatalogs(false)
    this.getChannels()
  }

  render() {
    const { isFetching, isMenuVerified, isStoreCreated, addingBankAccount, fileSystemId, gPayRegistrationDone, organization, isCatalogCreated, uploadingMenu, fetchingMenu } = this.state
    const { client, intl } = this.props

    if (isFetching) {
      return (
        <div className="justifyContent-center">
          <Spin
            size="large"
            tip={intlAlertMessage({
              id: 'orderTable.message.consoleIsLoading',
              intl: this.props.intl
            })}
          />
        </div>
      )
    }

    return (
      <div className="onboarding-container">
        <OnboardingHeader />
        <div className="gx-main-content-wrapper">
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
              <div className="onboard-text">
                <IntlMessages id="onboarding.welcomeMsg" />
              </div>
              <div className="onboard-text">
                <IntlMessages id="onboarding.requiredInfoMsg" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
              <div className="mt-1">
                <BusinessLogoCard fileSystemId={fileSystemId} onLogoSave={logoUrl => this.onLogoSave(logoUrl)} logoUrl={organization?.orgLogo} pending={!organization?.orgLogo} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
              <div className="mt-1">
                <MenuLinkCard
                  fileSystemId={fileSystemId}
                  client={client}
                  intl={intl}
                  disable={!organization?.orgLogo || fetchingMenu || uploadingMenu || (isCatalogCreated && !fetchingMenu)}
                  // Disable={false}
                  onMenuSave={() => this.onMenuSave()}
                  uploadProgress={status => this.setState({ uploadingMenu: status })}
                  fetchProgress={status => this.setState({ fetchingMenu: status })}
                  pending={!isCatalogCreated || !organization?.orgLogo || uploadingMenu || fetchingMenu}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
              <div className="mt-1">
                <YourMenuCard
                  intl={intl}
                  // Pending={true}
                  pending={!isMenuVerified}
                  disable={!isCatalogCreated || !organization?.orgLogo || uploadingMenu || fetchingMenu}
                  // Disable={false}
                  onButtonClick={this.goToCatalogDetails}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
              <div className="mt-1">
                <OutletInfoCard intl={intl} pending={!isStoreCreated} disable={!isCatalogCreated || !organization?.orgLogo} onButtonClick={this.goToCreateStore} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
              <div className="mt-1">
                <MiniAppUrlCard intl={intl} history={this.props.history} pending={!organization.miniAppUrlLink} disable={!organization?.orgLogo} miniAppUrl={organization.miniAppUrlLink} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
              <div className="mt-1">
                <GpayCard
                  intl={intl}
                  history={this.props.history}
                  pending={!gPayRegistrationDone}
                  disable={!organization?.orgLogo || !isStoreCreated}
                  gpayDetails={getGpayDetails(organization.associations)}
                  fileSystemId={this.state.fileSystemId}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
              <BankAccountCard intl={intl} history={this.props.history} onSubmit={this.createBankAccount} readyToAddAccount={isMenuVerified && isStoreCreated && organization?.miniAppUrlLink} addingBankAccount={addingBankAccount} />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
// @ts-ignore
export default injectIntl(withApollo(Onboarding))
