import './style.css'

import { Icon } from '@ant-design/compatible'
import { gql } from '@apollo/client'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { Badge, Menu } from 'antd'
import { ICONS, SOUNDS } from 'assets'
import { IntlMessages } from 'components'
import { History } from 'history'
import * as jwt from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import React from 'react'
import { RouteChildrenProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { getOrderCount } from 'services'
import { Auxiliary, getUserDetails, getViewType, isAdmin, isRestaurantLive, showSideBar } from 'Utils'
import { ORDER_STATUS, VIEW_TYPES } from 'Utils/constants'

interface SidebarContentProps extends RouteChildrenProps, ApolloProviderProps<any> {
  pathname: any
  history: History
  isCollapsed: boolean
}

interface SidebarContentState {
  orgId?: any
  orderHash?: any
  orderData: any
  totalItems: number
  isAdmin: boolean
  isPlaying: boolean
  isBMSDealsOnlyView: boolean
}

const effect = new Audio(SOUNDS.new_order_alarm)

effect.muted = true
effect.loop = true

class SidebarContent extends React.Component<SidebarContentProps, SidebarContentState> {
  // NotificationManager: any;
  constructor(props: SidebarContentProps) {
    super(props)
    this.state = {
      orderHash: {},
      orderData: null,
      totalItems: 0,
      isAdmin: false,
      isPlaying: false,
      isBMSDealsOnlyView: false,
    }
  }

  static propTypes: any

  static defaultProps: any

  componentDidMount() {
    const isAdminUser = isAdmin()
    const viewType = getViewType()
    const isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY

    this.setState({ isAdmin: isAdminUser, isBMSDealsOnlyView }, () => {
      if (!isAdminUser || isRestaurantLive()) {
        localStorage.removeItem('isOnboarding')
        showSideBar()
        !this.state.isBMSDealsOnlyView && this.orderData()
      }
    })

    !isBMSDealsOnlyView && this.orderDataTimer()
    /*
     * This.notificationManager = FbNotificationManager.getInstance();
     * this.notificationManager.addListener(LISTENER_TYPE.NEW_ORDER, message => {
     *   console.log('New order count notification', message);
     *   this.orderData();
     * });
     */
  }

  /*
   * ComponentWillUnmount = () => {
   *   this.notificationManager.removeListener(LISTENER_TYPE.NEW_ORDER);
   * };
   */

  showNotification() {
    const { orderHash, orderData, isPlaying } = this.state
    const hashData = orderHash
    const totalData = orderData.data
    let playBufferCheck = false

    totalData.forEach(async element => {
      const propertyName = element.id

      if (orderHash[propertyName]) {
        if (isPlaying === false && playBufferCheck === false) {
          playBufferCheck = true
          this.setState({ isPlaying: true }, () => {
            this.playSoundAlert()
          })
        }
      } else {
        const args = {
          duration: 10000,
          key: element.id,
          message: <IntlMessages id="sidebarContent.youHaveNewOrder" />,
          description: `Bill Value : \u20B9 ${element.totalOrderAmount}`,
          onClose: () => {
            // Effect.pause();
          },
          onClick: () => {
            this.props.history.push('/orderx/orders', {})
          }
        }

        hashData[element.id] = args

        if (isPlaying === false && playBufferCheck === false) {
          // Console.log('Initiate Play');
          playBufferCheck = true
          this.setState({ orderHash: hashData, isPlaying: true }, () => {
            // Notification.open(args);
            this.playSoundAlert()
          })
        } else {
          // Notification.open(args);
          this.setState({ orderHash: hashData })
        }
      }
    })
  }

  closeOldNotifications() {
    const { orderHash, orderData } = this.state
    const totalData = orderData.data
    const allNotificationIds = Object.keys(orderHash)

    totalData.forEach((element, index) => {
      if (allNotificationIds.indexOf(element.id) !== -1) {
        allNotificationIds.splice(index, 1)
      }
    })
    /*
     * AllNotificationIds.forEach(notificationId =>
     *   notification.close(notificationId)
     * );
     */
  }

  playSoundAlert() {
    effect.play()
    effect.muted = false
    // Effect.onended = this.setPlay.bind(this);
  }

  setPlay() {
    this.setState({ isPlaying: false })
  }

  orderData = async () => {
    if (this.state.isAdmin && (!isRestaurantLive() || getUserDetails().resetPassword)) {
      return
    }
    const jwtToken: any = localStorage.getItem('jwt')

    if (!jwtToken) return
    const { org_id }: any = jwt.decode(jwtToken)
    const getOrderCountInput = {
      orderStatus: [ORDER_STATUS.ORDER_CREATED],
      organizationId: org_id
    }

    if (!this.state.isAdmin) {
      const userData = JSON.parse(localStorage.getItem('userData'))

      getOrderCountInput['storeId'] = userData.store[0].id
    }
    try {
      const response = await getOrderCount(this.props.client, getOrderCountInput)
      const data = response.data.orders
      const totalItems = data ? data.paginationInfo.totalItems : 0
      const { isPlaying } = this.state

      this.setState(
        {
          orderData: {
            data: data.data,
            paginationInfo: data.paginationInfo
          },
          totalItems,
          isPlaying: totalItems > 0 ? (isPlaying ? isPlaying : false) : false
        },
        () => {
          this.closeOldNotifications()
          if (totalItems > 0) {
            this.showNotification()
          } else {
            effect.pause()
            effect.muted = true
          }
        }
      )
    } catch (err) {
      console.error(err)
    }
  }

  orderCountBadge = () => {
    const { totalItems } = this.state

    return <Badge count={totalItems} overflowCount={20} />
  }

  menuListItem = () => {
    return (
      <Menu.Item key="menu-list">
        <Link to={'/orderx/menu-list'}>
          <Icon style={{ fontSize: '18px' }} component={() => <img src={ICONS.menu} alt="menu" />} />
          <span className="sideMenuText">
            <IntlMessages id="sidebar.navigation.menu" />
          </span>
        </Link>
      </Menu.Item>
    )
  }

  ordersItem = () => {

    const { isCollapsed } = this.props

    return (
      <Menu.Item key="orders">
        <Link to="/orderx/orders">
          <Icon style={{ fontSize: '18px' }} component={() => <img src={ICONS.activeOrder} alt="active_orders" />} />
          <span className="sideMenuText">
            <IntlMessages id="orders.todaysOrders" />
            {!isCollapsed && this.orderCountBadge()}
          </span>
        </Link>
      </Menu.Item>
    )
  }

  orderDataTimer() {
    setInterval(() => {
      this.orderData()
    }, 10000)
  }

  render() {
    const { isAdmin, isBMSDealsOnlyView } = this.state
    const { pathname, isCollapsed } = this.props
    const selectedKeys = pathname.substr(1)
    const defaultOpenKeys = selectedKeys.split('/')[1]

    return (
      <Auxiliary>
        <div
          style={{ height: '100%' }}
          className={`OrderX-Sidebar ${isAdmin ? 'set-badge-admin' : 'set-badge'}
          ${isCollapsed && 'sidebar-collapsed'}`}>
          {!isBMSDealsOnlyView && isCollapsed && <div className="custom-badge">{this.orderCountBadge()}</div>}
          <Menu style={{ height: '100%' }} className={`${isCollapsed && 'menu-collapsed'}`} defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[defaultOpenKeys]} theme={'dark'} mode="inline">
            {isAdmin && (
              <Menu.Item key="store-list">
                <Link to={'/orderx/store-list'}>
                  <Icon style={{ fontSize: '18px' }} component={() => <img src={ICONS.store} alt="store" />} />
                  <span className="sideMenuText">
                    <IntlMessages id="sidebarContent.stores" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            {!isBMSDealsOnlyView && (isAdmin ? (
              <>{this.menuListItem()}
                {this.ordersItem()}</>) : (<>
                  {this.ordersItem()}
                  {this.menuListItem()}
                </>
            ))
            }
            {!isBMSDealsOnlyView && <Menu.Item key="order-history">
              <Link to="/orderx/order-history">
                <Icon style={{ fontSize: '18px' }} component={() => <img src={ICONS.orderHistory} alt="order_history" />} />
                <span className="sideMenuText">
                  <IntlMessages id="OrderHistory" />
                </span>
              </Link>
            </Menu.Item>}
            {(isAdmin || isBMSDealsOnlyView) && (
              <Menu.Item key="reports">
                <Link to="/orderx/reports">
                  <Icon component={() => <img src={ICONS.report} alt="report" />} style={{ fontSize: '18px' }} />
                  <span className="sideMenuText">
                    <IntlMessages id={isBMSDealsOnlyView ? 'bmsDeals' : 'dataRangeReports'} />
                  </span>
                </Link>
              </Menu.Item>
            )}

            {/* <Menu.Item key="register">
                <Link to="/orderx/register">
                  <Icon style={{ fontSize: '18px' }} type="user" />
                  <span className="sideMenuText">Restaurant Details</span>
                </Link>
              </Menu.Item> */}

            {/* <Menu.Item key="bulkFiles">
                <Link to={`/orderx/easy-upload`}>
                  <CloudUpload
                    style={{
                      fontSize: '20px',
                      marginRight: '19px',
                      marginBottom: '-3px',
                    }}
                  />
                  <span className="sideMenuText">Easy Menu Upload</span>
                </Link>
              </Menu.Item> */}

            {/* <Menu.Item key="customers">
                <Link to="/orderx/customers">
                  <Icon style={{ fontSize: '18px' }} type="team" />
                  <span className="sideMenuText">Customer Search</span>
                </Link>
              </Menu.Item> */}
          </Menu>
        </div>
      </Auxiliary>
    )
  }
}

SidebarContent.propTypes = {}

const mapStateToProps = ({ ownProps }: any) => {
  const { pathname } = ownProps.location

  return { pathname }
}
const GET_SETTINGS = gql`
    query settings {
      settings @client {
        pathname
      }
    }
  `

export default compose(
  withRouter,
  graphql(GET_SETTINGS, {
    props: mapStateToProps,
    name: 'settings'
  }),
  withApollo
)(SidebarContent)
