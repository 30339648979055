import { InputNumber, Switch } from 'antd'
import { IntlMessages } from 'components'
import DragIcon from 'pages/menu/components/DragIcon'
import React from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { CHANNELS, CONSOLE_MENU_CATEGORY_STATUS, PRODUCT_TYPE_ENUM } from 'Utils/constants'

export const getItemColumns = (catalogDetailsState, onItemReorder) => {
  const { isAdmin } = catalogDetailsState

  return [
    {
      title: <IntlMessages id="menu.items" />,
      dataIndex: 'items',
      key: 'items',
      width: '48%',
      render: itemAction => itemAction
    },
    {
      title: isAdmin ? <IntlMessages id="menu.listable" /> : <IntlMessages id="menu.availability" />,
      key: isAdmin ? 'listable' : 'availability',
      dataIndex: isAdmin ? 'listable' : 'availability',
      width: '12%',
      render: productListableOrStockCheckActions => productListableOrStockCheckActions
    },
    {
      title: <IntlMessages id="menu.packingCharge" />,
      key: 'packingCharge',
      dataIndex: 'packingCharge',
      width: '12%',
      render: packagingCharge => packagingCharge
    },
    {
      title: <IntlMessages id="menu.basePrice" />,
      key: 'basePrice',
      dataIndex: 'basePrice',
      width: '14%',
      render: basePrice => basePrice
    },
    {
      title: <IntlMessages id="menu.dealPrice" />,
      key: 'dealPrice',
      dataIndex: 'dealPrice',
      width: '14%',
      render: (dealPrice, record) => {
        return (
          <div>
            {dealPrice}
          </div>
        )
      }
    }
  ]
}

export const setItemUpDownArrowPosition = rowIndex => {
  const upDownIcon = document.querySelector(`.item-row-${rowIndex}`)

  if (!upDownIcon) return
  const selectedRowClientRect = document.querySelector('.category-product-container').getBoundingClientRect()
  const upDownIconClientRect = upDownIcon.getBoundingClientRect()
  const { top } = upDownIconClientRect
  const left = selectedRowClientRect.right - 5

  upDownIcon['style']['top'] = `${top}px`
  upDownIcon['style']['top'] = `${left}px`
}

export const setCategoryUpDownArrowPosition = rowIndex => {
  const upDownIcon = document.querySelector(`.category-row-${rowIndex}`)

  if (!upDownIcon) return
  const selectedRowClientRect = document.querySelector('.category-product-container').getBoundingClientRect()
  // Const top = selectedRowClientRect.top + selectedRowClientRect.height / 2;
  const left = selectedRowClientRect.left - 15

  // UpDownIcon['style']['top'] = `${top}px`;
  upDownIcon['style']['left'] = `${left}px`
}

export const getAddonsCombosAtLast = categoryList => {
  const categories = categoryList.filter(c => c.productType === PRODUCT_TYPE_ENUM.PRODUCT)
  const addonsCombos = categoryList.filter(c => {
    return c.productType === PRODUCT_TYPE_ENUM.ADDON || c.productType === PRODUCT_TYPE_ENUM.COMBO
  })
  const categoriesAddonsCombos = categories.concat(addonsCombos)

  return categoriesAddonsCombos
}

export const toggleCategorySwitch = (record, handleToggle) => {
  const { listable, id, rowIndex } = record

  return (
    <Switch
      checkedChildren={CONSOLE_MENU_CATEGORY_STATUS.ACTIVE}
      unCheckedChildren={CONSOLE_MENU_CATEGORY_STATUS.INACTIVE}
      checked={listable}
      onChange={() => handleToggle(id, rowIndex, !listable)}
    />
  )
}

export const toggleProductSwitch = (record, handleToggle) => {
  const { listable, id, categoryId } = record

  return (
    <Switch
      checkedChildren={CONSOLE_MENU_CATEGORY_STATUS.ACTIVE}
      unCheckedChildren={CONSOLE_MENU_CATEGORY_STATUS.INACTIVE}
      checked={record.isCategoryListable && listable}
      disabled={!record.isCategoryListable}
      onChange={() => handleToggle(id, categoryId, !listable)}
    />
  )
}

export const toggleProductAvailabilitySwitch = (record, handleToggle) => {
  const { available, id, categoryId } = record

  return (
    <Switch
      checkedChildren={CONSOLE_MENU_CATEGORY_STATUS.ACTIVE}
      unCheckedChildren={CONSOLE_MENU_CATEGORY_STATUS.INACTIVE}
      checked={available}
      onChange={() => handleToggle(id, categoryId, !available)}
    />
  )
}

export const getSelectedChannelData = (channelPrices) => {
  return channelPrices?.find(({ channel }) => channel.name === CHANNELS.PEPPO_PWA) || {}
}

export const PriceValueInput = ({ value, handleChange }) => {
  const isValueExists = value !== undefined && value !== null

  return (
    <InputNumber
      size="middle"
      disabled={!isValueExists}
      value={value}
      min={0}
      onChange={handleChange}
    />
  )
}

export const PriceValue = ({ value }) => {
  return (
    <span>
      {' '}
      {value || value === 0
        ? `₹${value}`
        : '-- --'}
    </span>
  )
}

export const DragHandle = SortableHandle(() => <DragIcon className="drag-icon drag-visible" />)

export const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
  <tr {...props} />
))

export const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
  <tbody {...props} />
))

export const populatePriceHash = (values, valueHash) => {
  values.forEach(price => {
    const { product } = price

    if (!valueHash[product.id]) {
      valueHash[product.id] = []
    }
    valueHash[product.id].push(price)
  })
}