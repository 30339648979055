import '../index.css'

import { Icon } from '@ant-design/compatible'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Button, Col, Menu, Modal, Row } from 'antd'
import { IntlMessages } from 'components'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { getCategoriesWithProducts, getOptions } from 'services'
import { CATEGORY_HELP_URL, ITEMS_HELP_URL, MENU_TABS } from 'Utils/constants'

import HeaderText from '../../../styles/Typography/Headers'
import AddonsComponent from './addons'
import { CategoriesComponent } from './categories'
import { CustomizationComponent } from './customization'
import { MenuTimingsComponent } from './menuTimings'
import { ProductsComponent } from './products'

interface ICustomizeProps extends ApolloProviderProps<any> {
  history: History
  intl: any
}

interface ICustomizeState {
  selectedMenu: string
  categories: any
  showConfirmModal: boolean
  categoriesWithProducts: any
  options: any[]
  fileSystemId: any
}

class Customize extends Component<ICustomizeProps, ICustomizeState> {
  channels: any

  productPriceData: any

  storeFormats: any

  catalogId: string

  packagingChargeType: any

  catalogCount: number

  selectedProductId: string

  isFormDataChange: false

  unblock: any

  targetLocation: any

  isCategoryClicked: boolean

  isProductClicked: boolean

  constructor(props: Readonly<ICustomizeProps>) {
    super(props)
    this.isCategoryClicked = false
    this.isProductClicked = false
    this.channels = this.props?.history?.location?.state.channels
    this.productPriceData = this.props?.history?.location?.state.productPriceData
    this.storeFormats = this.props.history.location.state.storeFormats
    this.catalogId = this.props.history.location.state.catalog.id
    this.packagingChargeType = this.props.history.location.state.packagingChargeType
    this.catalogCount = this.props.history.location.state.catalogCount
    this.selectedProductId = this.props.history.location.state.selectedProductId
    const receivedCategories = this.props.history.location.state.categories && this.props.history.location.state.categories.length ? this.props.history.location.state.categories : []
    const selectedMenu = this.selectedProductId ? MENU_TABS.PRODUCTS : MENU_TABS.CATERGORIES

    this.state = {
      selectedMenu,
      categories: receivedCategories,
      showConfirmModal: false,
      categoriesWithProducts: [],
      options: [],
      fileSystemId: ''
    }
  }

  componentDidMount = () => {
    this.getCatalogCategories()
    this.getAllOptions()
  }

  getCatalogCategories = async () => {
    const categoriesWithProductsRes = await getCategoriesWithProducts(this.props.client, this.catalogId).catch(error => {
      console.error('Get catalog categories error', error)
    })
    const categories = categoriesWithProductsRes?.data?.catalog?.categories

    this.setState({ categoriesWithProducts: categories || [] })
  }

  getAllOptions = async () => {
    const optionsRes = await getOptions(this.props.client)
    const options = optionsRes?.data?.options

    this.setState({ options: options || [] })
  }

  handleItemWarningModalCancel = () => {
    let { selectedMenu } = this.state

    if (this.isCategoryClicked) {
      selectedMenu = MENU_TABS.PRODUCTS
    } else if (this.isProductClicked) {
      selectedMenu = MENU_TABS.CATERGORIES
    }
    this.setState({ showConfirmModal: false, selectedMenu })
  }

  goToTargetLocation = () => {
    this.isFormDataChange = false
    if (this.unblock) this.unblock()
    if (!this.isCategoryClicked && !this.isProductClicked) {
      const urlDetails = this.targetLocation.pathname.split('/')

      if (urlDetails[urlDetails.length - 1] === 'detail') {
        this.goBackToMenuDetail()
      } else {
        this.props.history.push({ pathname: this.targetLocation.pathname })
      }
    } else if (this.isCategoryClicked) {
      this.isCategoryClicked = false
      this.setState({
        selectedMenu: MENU_TABS.CATERGORIES,
        showConfirmModal: false
      })
    } else {
      this.isProductClicked = false
      this.setState({
        selectedMenu: MENU_TABS.PRODUCTS,
        showConfirmModal: false
      })
    }
  }

  onFormDataChange = (isFormDataChange, unblock) => {
    this.isFormDataChange = isFormDataChange
    this.unblock = unblock
  }

  onTargetLocationChange = targetLocation => {
    this.targetLocation = targetLocation
    if (this.isFormDataChange) {
      this.setState({ showConfirmModal: true })
    }
  }

  updateCategories = updatedCategories => {
    this.getCatalogCategories()
    if (!updatedCategories.length) return
    this.setState({
      categories: [...updatedCategories]
    })
    this.selectedProductId = ''
  }

  goBackToMenuDetail = () => {
    this.props.history.push({
      pathname: `/orderx/menu-list/${this.catalogId}/detail`,
      state: {
        catalog: this.props.history.location.state.catalog,
        channels: this.props.history.location.state.channels,
        storeFormats: this.props.history.location.state.storeFormats,
        catalogCount: this.catalogCount,
        roles: this.props.history.location.state.roles,
        store: this.props.history.location.state.store,
        isOnboarding: this.props.history.location.state.isOnboarding
      }
    })
  }

  onTabChange = tabName => {
    this.setState({ selectedMenu: tabName })
  }

  render() {
    const { selectedMenu, categories, showConfirmModal, categoriesWithProducts, options } = this.state

    return (
      <div>
        <Row className="customize-header-container">
          <Col span={7}>
            <HeaderText type="H1">
              <Icon
                className="backIcon"
                type="arrow-left"
                onClick={() => {
                  this.goBackToMenuDetail()
                }}
              />
              <IntlMessages id="menu.customizeMenu" />
            </HeaderText>
          </Col>
          <Col span={15}>
            <Menu
              mode="horizontal"
              selectable={true}
              selectedKeys={[selectedMenu]}
              className="customize-menu"
              onSelect={({ key }) => {
                if (this.isFormDataChange && key === MENU_TABS.CATERGORIES) {
                  this.setState({
                    showConfirmModal: true,
                    selectedMenu: MENU_TABS.PRODUCTS
                  })
                  this.isCategoryClicked = true
                } else if (
                  this.isFormDataChange &&
                  key === MENU_TABS.PRODUCTS
                ) {
                  this.setState({
                    showConfirmModal: true,
                    selectedMenu: MENU_TABS.CATERGORIES
                  })
                  this.isProductClicked = true
                } else {
                  this.setState({ selectedMenu: key })

                  this.isCategoryClicked = false
                  this.isProductClicked = false
                }
              }}>
              <Menu.Item
                key={MENU_TABS.CATERGORIES}
                className={
                  selectedMenu === MENU_TABS.CATERGORIES ? 'active' : ''
                }>
                <IntlMessages id="menu.categories" />
              </Menu.Item>
              <Menu.Item
                key={MENU_TABS.PRODUCTS}
                className={selectedMenu === MENU_TABS.PRODUCTS ? 'active' : ''}>
                <IntlMessages id="menu.items" />
              </Menu.Item>
              <Menu.Item
                key={MENU_TABS.CUSTOMIZATIONS}
                className={
                  selectedMenu === MENU_TABS.CUSTOMIZATIONS ? 'active' : ''
                }>
                <IntlMessages id="customization" />
              </Menu.Item>
              <Menu.Item
                key={MENU_TABS.ADDONS}
                className={selectedMenu === MENU_TABS.ADDONS ? 'active' : ''}>
                <IntlMessages id="addons/Combos" />
              </Menu.Item>
              <Menu.Item
                key={MENU_TABS.MENU_TIMINGS}
                className={
                  selectedMenu === MENU_TABS.MENU_TIMINGS ? 'active' : ''
                }>
                <IntlMessages id="menu.menuTimings" />
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={1} style={{ marginTop: 11 }}>
            <a
              className="menu-help"
              href={
                selectedMenu === MENU_TABS.CATERGORIES
                  ? CATEGORY_HELP_URL
                  : ITEMS_HELP_URL
              }
              target="_blank"
              rel="noopener noreferrer">
              <IntlMessages id="menu.help" />?
            </a>
          </Col>
        </Row>
        <Row className="mt-2" style={{ marginLeft: '8px', width: '100%' }}>
          {selectedMenu === MENU_TABS.CATERGORIES && (
            <CategoriesComponent
              onCategoryChange={this.updateCategories}
              onTargetLocationChange={this.onTargetLocationChange}
              onFormDataChange={this.onFormDataChange}
              intl={this.props.intl}
              onTabChange={this.onTabChange}
            />
          )}
          {selectedMenu === MENU_TABS.ADDONS && (
            <AddonsComponent
              intl={this.props.intl}
              channels={this.channels}
              storeFormats={this.storeFormats}
              onAddonsChange={this.getCatalogCategories}
            />
          )}
          {selectedMenu === MENU_TABS.CUSTOMIZATIONS && (
            <CustomizationComponent
              intl={this.props.intl}
              onOptionsChange={this.getAllOptions}
            />
          )}
          {selectedMenu === MENU_TABS.PRODUCTS && (
            <ProductsComponent
              channels={this.channels}
              priceData={this.productPriceData}
              storeFormats={this.storeFormats}
              categories={categories}
              packagingChargeType={this.packagingChargeType}
              selectedProductId={this.selectedProductId}
              onTargetLocationChange={this.onTargetLocationChange}
              onFormDataChange={this.onFormDataChange}
              categoriesWithProducts={categoriesWithProducts}
              options={options}
              intl={this.props.intl}
              onTabChange={this.onTabChange}
              onAddonSeqChange={this.getCatalogCategories}
            />
          )}
          {selectedMenu === MENU_TABS.MENU_TIMINGS && (
            <MenuTimingsComponent intl={this.props.intl} />
          )}
        </Row>
        <Modal
          visible={showConfirmModal}
          key="item-form-modal"
          className="confirm-tab-modal"
          title={<IntlMessages id="category.confirmationMsg.leaveThisPage" />}
          onCancel={this.handleItemWarningModalCancel}
          footer={[
            <div key="footer">
              <Button
                key="leave"
                type="primary"
                danger
                className="leave-btn"
                onClick={() => {
                  this.goToTargetLocation()
                }}>
                <IntlMessages id="leave" />
              </Button>
              <Button
                key="stay"
                type="default"
                className="stay-btn"
                onClick={this.handleItemWarningModalCancel}>
                <IntlMessages id="stay" />
              </Button>
            </div>
          ]}>
          <div>
            <IntlMessages id="category.infoMsg.changesMayNotSave" />.
          </div>
        </Modal>
      </div>
    )
  }
}

const CustomizeComponent = injectIntl(compose(withRouter, withApollo)(Customize))

export default CustomizeComponent
