import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { IMAGES } from 'assets'
import { ResetEmailForm } from 'components'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import { injectIntl } from 'react-intl'
import { sendResetPasswordLink } from 'services/userService'

interface IProps extends ApolloProviderProps<any> {
  history?: History
  intl: any
}

interface IState { }

class ForgotPassword extends React.Component<IProps, IState> {
  sendResetPasswordLink = async (email: string) => {
    if (!email) return
    const emailSent = await sendResetPasswordLink(this.props.client, { email })

    return emailSent
  }

  render() {
    const { history, intl } = this.props

    return (
      <div
        style={{ background: '#00946E 0% 0% no-repeat padding-box' }}
        className="gx-app-change-pw-wrap">
        <div className="gx-app-change-pw-container">
          <div className="peppo-white-logo gx-text-center">
            <img src={IMAGES.peppo_logo_white} alt="Peppo white logo" />
          </div>
          <div className="gx-app-change-pw-main-content">
            <div className="gx-app-change-pw-content">
              <ResetEmailForm
                history={history}
                onSubmit={this.sendResetPasswordLink}
                intl={intl}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default injectIntl(compose(withApollo)(ForgotPassword))
