import { Form,Input } from 'antd'
import { FormInstance } from 'antd/es/form'
import { Button, Card, IntlMessages, Title } from 'components'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import styled from 'styled-components'
import { intlAlertMessage } from 'Utils'
import { ALPHA_NUMERIC_WITHOUT_SPACE_REGEX } from 'Utils/constants'
import { blockCopyPasteAccNumber } from 'Utils/onboardingUtils'


interface IProps {
  onSubmit: any
  intl: any
  history?: any
  addingBankAccount: boolean
  readyToAddAccount: boolean
}

interface IState {
  confirmDirty: boolean
  accountNumber: string
  confirmAccNum: any
  ifscCode: any
  beneficiaryName: any
  fssaiNum: any
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-row {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`

class BankAccountCard extends React.Component<IProps, IState> {
  formRef: any

  constructor(props) {
    super(props)
    this.state = {
      confirmDirty: false,
      accountNumber: '',
      confirmAccNum: '',
      ifscCode: '',
      beneficiaryName: '',
      fssaiNum: ''
    }
    this.formRef = React.createRef<FormInstance>()
  }

  componentDidMount() {
    blockCopyPasteAccNumber()
    const bankDetailsData = JSON.parse(localStorage.getItem('bankDetails'))

    this.formRef.current.setFieldsValue(bankDetailsData)
  }

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target

    this.setState({ confirmDirty: this.state.confirmDirty || Boolean(value) })
  }

  compareToAccountNumber = (_,value: any) => {

    if (value && value !== this.formRef.current.getFieldValue('accountNumber')) {
      return Promise.reject(
        new Error(intlAlertMessage({
          id: 'onboarding.errMsg.accountNumDoNotMatch',
          intl: this.props.intl
        }))
      )
    }

    return Promise.resolve()
  }

  render() {
    const { addingBankAccount, readyToAddAccount } = this.props
    const {
      accountNumber,
      confirmAccNum,
      beneficiaryName,
      ifscCode
    } = this.state

    if (
      this.state.accountNumber.length &&
      this.state.confirmAccNum.length &&
      this.state.beneficiaryName.length &&
      this.state.ifscCode.length
    ) {
      const bankDetails = {
        accountNumber,
        conAccountNumber: confirmAccNum,
        beneficiaryName,
        ifsc: ifscCode
      }

      localStorage.setItem('bankDetails', JSON.stringify(bankDetails))
    }

    return (
      <Form
        ref={this.formRef}
        onFinish={async values => this.props.onSubmit(values)}
        className="mt-1">
        <Card>
          <Title level={'h3'}>
            <IntlMessages id={'onboarding.bankAccountCard.title'} />
          </Title>
          <StyledRow>
            <div style={{ flexGrow: 1, flexBasis: '50%' }}>
              <div className="input-label">
                <IntlMessages id="onboarding.accNum" />
                <span style={{ marginLeft: '3px' }}>*</span>
              </div>
              <Form.Item
                name="accountNumber"
                rules={ [
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.accountNumber.required"
                        key="acc-required-err"
                      />
                    )
                  },
                  {
                    pattern: ALPHA_NUMERIC_WITHOUT_SPACE_REGEX,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.accountNumber.invalid"
                        key="acc-invalid-err"
                      />
                    )
                  }
                ]}
              >
                <Input
                  onChange={e =>
                    this.setState({ accountNumber: e.target.value })
                  }
                  id="add-bank-account_accountNumber"
                  placeholder={intlAlertMessage({
                    id: 'onboarding.accNum',
                    intl: this.props.intl
                  })}
                />
              </Form.Item>
            </div>
            <div style={{ flexGrow: 1, marginLeft: '50px', flexBasis: '50%' }}>
              <div className="input-label">
                <IntlMessages id="onboarding.confirmAccNum" />
                <span style={{ marginLeft: '3px' }}>*</span>
              </div>
              <Form.Item
                name="conAccountNumber"
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.conAccountNumber.required"
                        key="conacc-required-err"
                      />
                    )
                  },
                  {
                    pattern: ALPHA_NUMERIC_WITHOUT_SPACE_REGEX,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.conAccountNumber.invalid"
                        key="conacc-invalid-err"
                      />
                    )
                  },
                  {
                    validator: this.compareToAccountNumber
                  }
                ]}
                hasFeedback
              >
                <Input
                  id="add-bank-account_confirmAccountNumber"
                  onChange={e =>
                    this.setState({ confirmAccNum: e.target.value })
                  }
                  onBlur={this.handleConfirmBlur}
                  placeholder={intlAlertMessage({
                    id: 'onboarding.confirmAccNum',
                    intl: this.props.intl
                  })}
                />
              </Form.Item>
            </div>
          </StyledRow>
          <StyledRow style={{ marginTop: 16 }}>
            <div style={{ flexGrow: 1, flexBasis: '50%' }}>
              <div className="input-label">
                <IntlMessages id="IFSCCode" />
                <span style={{ marginLeft: '3px' }}>*</span>
              </div>
              <Form.Item
                name="ifsc"
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.ifsc.required"
                        key="ifsc-required-err"
                      />
                    )
                  },
                  {
                    pattern: ALPHA_NUMERIC_WITHOUT_SPACE_REGEX,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.ifsc.invalid"
                        key="ifsc-invalid-err"
                      />
                    )
                  }
                ]}
              >
                <Input
                  onChange={e => this.setState({ ifscCode: e.target.value })}
                  placeholder={intlAlertMessage({
                    id: 'IFSCCode',
                    intl: this.props.intl
                  })}
                />
              </Form.Item>
            </div>

            <div style={{ flexGrow: 1, marginLeft: '50px', flexBasis: '50%' }}>
              <div className="input-label">
                <IntlMessages id="onboarding.beneficiaryName" />
                <span style={{ marginLeft: '3px' }}>*</span>
              </div>
              <Form.Item
                name="beneficiaryName"
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.beneficiaryName.required"
                        key="ben-required-err"
                      />
                    )
                  },
                  {
                    min: 4,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.beneficiaryNameMustBe4Char"
                        key="ben-min-err"
                      />
                    )
                  },
                  {
                    whitespace: true,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.beneficiaryName.invalid"
                        key="ben-invalid-err"
                      />
                    )
                  }
                ]}
              >
                <Input
                  onChange={e =>
                    this.setState({ beneficiaryName: e.target.value })
                  }
                  placeholder={intlAlertMessage({
                    id: 'onboarding.beneficiaryName',
                    intl: this.props.intl
                  })}
                />
              </Form.Item>
            </div>
          </StyledRow>
          <StyledRow>
            <div style={{ marginTop: '16px', flexBasis: '47%' }}>
              <div className="input-label">
                <IntlMessages id="onboarding.fssaiNum" />
              </div>
              <Form.Item
                name="fssai"
                rules={[
                  {
                    min: 14,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.fssaiMustBe14Char"
                        key="ben-min-err"
                      />
                    )
                  },
                  {
                    whitespace: true,
                    message: (
                      <IntlMessages
                        id="error.onboarding.bankAccountCard.fssai.invalid"
                        key="ben-invalid-err"
                      />
                    )
                  }
                ]}
              >
                <Input
                  onChange={e => this.setState({ fssaiNum: e.target.value })}
                  placeholder={intlAlertMessage({
                    id: 'onboarding.fssaiNum',
                    intl: this.props.intl
                  })}
                  style={{ margin: '1% 0' }}
                />
              </Form.Item>
            </div>
          </StyledRow>
        </Card>
        <div className="mt-2">
          <Button
            type="primary"
            htmlType="submit"
            loading={addingBankAccount}
            disabled={!readyToAddAccount}>
            <IntlMessages id="button.letsGetStarted" />
          </Button>
        </div>
      </Form>
    )
  }
}

export default BankAccountCard
