import { createProductRelationships, updateProductRelationships } from 'services/customisationServices'
import { addCharges, addTaxes, updateProduct } from 'services/productService'
import { setErrMsg } from 'Utils'
import { CHANNELS, PRODUCT_PRICE_REGEX, PRODUCT_RELATIONSHIP_TYPE_ENUM, PRODUCT_TAG_ENUM, PRODUCT_TYPE_ENUM } from 'Utils/constants'
import { getChannelsData, getStoreFormats } from 'Utils/localStorageHandlers'

import { getExtendFromSelectedTag } from './commonFunc'

export const prepareFormatsChannels = (packagingChargeType) => {
  const channels = getChannelsData()
  const storeFormats = getStoreFormats()
  const formatsChannels = []

  storeFormats.forEach(format => {
    channels.forEach(channel => {
      const formatChannel = {}

      formatChannel['storeFormat'] = format
      formatChannel['channel'] = channel
      formatChannel['priceValue'] = ''
      formatChannel['productPriceValueId'] = ''
      formatChannel['charge'] = {
        chargeType: packagingChargeType,
        chargeValue: '',
        productChargeValueId: ''
      }
      formatChannel['isPackaging'] = true
      formatsChannels.push(formatChannel)
    })
  })

  return formatsChannels
}

export const getSelectedTag = (tagCode, tags) => {
  const selectedTag = tags.find(tag => tag.code === tagCode)

  return selectedTag
}

export const getSelectedTagWithName = (tagName, tags) => {
  const selectedTag = tags.find(tag => tag.tagName.toLowerCase() === tagName.toLowerCase())

  return selectedTag
}

export const updateFormatsChannels = (priceValues, chargeValues, productFormDetails) => {
  // Let { channels, storeFormats } = this.props;
  const formatsChannels = [...productFormDetails.formatsChannels]

  formatsChannels.forEach(formatChannel => {
    const priceValue = priceValues.filter(priceV => priceV.storeFormat.id === formatChannel.storeFormat.id && priceV.channel.id === formatChannel.channel.id)[0]
    const chargeValue = chargeValues.filter(chargeV => chargeV.storeFormat.id === formatChannel.storeFormat.id && chargeV.channel.id === formatChannel.channel.id)[0]
    
    formatChannel.basePrice = priceValue && priceValue.basePrice
    formatChannel.priceValue = priceValue && priceValue.priceValue
    formatChannel.productPriceValueId = priceValue && priceValue.id
    formatChannel.charge.chargeValue = chargeValue && Math.round(chargeValue.chargeValue)
    formatChannel.charge.productChargeValueId = chargeValue && chargeValue.id
    formatChannel.isPackaging = Boolean(chargeValue)
  })

  return formatsChannels
}

export const getProductRelationShipsInput = (parentId, addonGroups) => {
  const relationShips = []

  addonGroups.forEach(adG => {
    adG.customisations.forEach(customization => {
      if (customization.selected && !customization.saved) {
        const relationShip = {
          parentId,
          childId: customization.value,
          parentType: PRODUCT_TYPE_ENUM.REGULAR,
          childType: PRODUCT_TYPE_ENUM.ADDON,
          relationship: PRODUCT_RELATIONSHIP_TYPE_ENUM.PRODUCT_ADDONS,
          config: customization.config
        }

        relationShips.push(relationShip)
      }
    })
  })

  return relationShips
}

export const getUpdateProductRelationShipsInput = (addonGroups, productRelationShips) => {
  const relationShips = []

  const filteredProductRelationShips = productRelationShips.filter(pr => {
    return pr.childType === PRODUCT_TYPE_ENUM.ADDON || pr.childType === PRODUCT_TYPE_ENUM.COMBO
  })

  addonGroups.forEach(adG => {
    adG.customisations.forEach(customization => {
      const productRelationship = filteredProductRelationShips.find(productRelationship => productRelationship.child.id === customization.value)

      if (customization.selected && customization.saved && Boolean(productRelationship)) {
        const relationShip = {
          id: productRelationship.id,
          config: customization.config
        }

        relationShips.push(relationShip)
      }
    })
  })

  return relationShips
}

export const getRemovedProductRelationShipsInputForAddon = (optionGroups, productRelationShips) => {
  const relationShips = []

  const productAddons = productRelationShips.filter(pr => {
    return pr.childType === PRODUCT_TYPE_ENUM.ADDON
  })

  productAddons.forEach(pr => {
    let found = false

    optionGroups.forEach(optionGroup => {
      optionGroup.customisations.forEach(customisation => {
        if (pr.child.id === customisation.value) {
          found = true
          if (!customisation.selected && customisation.saved) {
            // Const prIndex = productRelationShips.findIndex(pr => pr.child.id === customization.value);
            relationShips.push({
              id: pr.id
            })
          }
        }
      })
    })
    if (!found) {
      relationShips.push({
        id: pr.id
      })
    }
  })

  return relationShips
}

export const updateSavedStatusForSelectedAddons = selectedAddonGroups => {
  selectedAddonGroups.forEach(adG => {
    adG.customisations.forEach(customization => {
      if (customization.selected && !customization.saved) {
        customization.saved = true
      }
    })
  })
}

export const updateProductsWithRemovedRelationship = (products, selectedProductIndex, removedProductRelationShips, productRelationShips) => {
  const updatedProductRelationShips = productRelationShips.filter(pr => {
    return removedProductRelationShips.findIndex(removedPr => removedPr.id === pr.id) === -1
  })

  products[selectedProductIndex].product.productRelationShip = updatedProductRelationShips

  return {
    updatedProducts: products,
    productRelationShips: updatedProductRelationShips
  }
}

export const updateProductsWithUpdatedRelationship = (products, selectedProductIndex, updatedProductRelationShips, productRelationShips) => {
  const updatedProductRelationships = productRelationShips.map(pr => {
    const updatedProductRelationship = updatedProductRelationShips.find(updatedPr => updatedPr.id === pr.id)

    if (updatedProductRelationship) {
      return updatedProductRelationship
    }

    return pr
  })

  products[selectedProductIndex].product.productRelationShip = updatedProductRelationships

  return {
    updatedProducts: products,
    productRelationShips: updatedProductRelationships
  }
}

export const updateProductsWithNewRelationship = (products, selectedProductIndex, newProductRelationShips, productRelationShips) => {
  const updatedProductRelationShips = productRelationShips.concat(newProductRelationShips)

  products[selectedProductIndex].product.productRelationShip = updatedProductRelationShips

  return {
    updatedProducts: products,
    productRelationShips: updatedProductRelationShips
  }
}

export const getSelectedOptionIds = customisationGroups => {
  const optionIds = customisationGroups.map(customisationGroup => customisationGroup.value)

  return optionIds
}

export const getProductVariant = productRelationShips => {
  return productRelationShips
    .filter(pr => pr.childType === PRODUCT_TYPE_ENUM.VARIANT && pr.child?.productPrices?.productPriceValues.length)
    .map(variant => {
      let tag = PRODUCT_TAG_ENUM.VEG
      let chargeValue = 0
      let discountValue = 0
      let priceValue = 0
      let chargeValueId = null
      let discountValueId = null
      let priceValueId = null

      if (variant.child.extend) {
        tag = variant.child.extend.extend_non_veg ? PRODUCT_TAG_ENUM.NON_VEG : PRODUCT_TAG_ENUM.VEG
      }

      if(variant.child.productPrices){
        [priceValue, priceValueId] = getPWAChannelPriceValue(variant.child.productPrices.productPriceValues)

        variant.child.productPrices.productChargeValues ? [chargeValue, chargeValueId] = getPWAChannelChargeValue(variant.child.productPrices.productChargeValues): null
        variant.child.productPrices.productDiscountValues ? [discountValue, discountValueId] = getPWAChannelDiscountedValue(variant.child.productPrices.productDiscountValues): null

      }

      return {
        id: variant.child.id,
        tag,
        priceValueId,
        priceValue,
        chargeValue,
        chargeValueId,
        discountValue,
        discountValueId,
        dealPrice: priceValue <= discountValue ? 0 : Math.round(priceValue - discountValue) || priceValue
      }
    })
}

export const getPWAChannelPriceValue = (channelPrices) => {
  const price = channelPrices?.find(({ channel }) => channel.name === CHANNELS.PEPPO_PWA) || {}

  return [price.priceValue, price.id]
}

export const getPWAChannelChargeValue = (channelPrices) => {
  const charges = channelPrices?.find(({ channel }) => channel.name === CHANNELS.PEPPO_PWA) || {}

  return [Math.round(charges.chargeValue), charges.id]
}

export const getPWAChannelDiscountedValue = (channelPrices) => {
  const charges = channelPrices?.find(({ channel }) => channel.name === CHANNELS.PEPPO_PWA) || {}

  return [charges.discountValue, charges.id]
}

export const addProductRelationships = async (productId, selectedAddonGroups, client) => {
  const productRelationShipInput = getProductRelationShipsInput(productId, selectedAddonGroups)

  if (productRelationShipInput.length) {
    await createProductRelationships(client, productRelationShipInput)
  }
}

export const updateProductRelationShips = async (selectedAddonGroups, productRelationShips, client) => {
  const productRelationShipInput = getUpdateProductRelationShipsInput(selectedAddonGroups, productRelationShips)

  if (productRelationShipInput.length) {
    await updateProductRelationships(client, productRelationShipInput)
  }
}

export const onValidateFields = (productFormDetails, updatedProductVariantPrices, newProductVariantPrices, intl) => {
  const productVariantPrices = [...updatedProductVariantPrices, ...newProductVariantPrices]

  let chargeRequired = false
  let customisationPriceRequired = false
  let invalidCharge = false
  let invalidPrice = false
  let priceRequired = false

  if (!productFormDetails.name) {
    setErrMsg('item.errMsg.enterName', intl)

    return true
  }

  if (!productFormDetails.tagCode) {
    setErrMsg('item.errMsg.selectTag', intl)

    return true
  }

  if (productFormDetails.isMenuTimingEnable && !productFormDetails.menuTimings) {
    setErrMsg('item.errMsg.selectMenuTimings', intl)

    return true
  }

  /*
   * TODO: write a logic which can be used in below conditions
   *    when price is different for each channel
   *    when price is same for each channel
   */
  const formatChannel = productFormDetails.formatsChannels[0]

  if (!productVariantPrices.length) {
    if (!formatChannel.priceValue) {
      priceRequired = true
    } else if (!PRODUCT_PRICE_REGEX.test(String(formatChannel.priceValue).toLocaleLowerCase())) {
      invalidPrice = true
    }
    if (formatChannel.isPackaging) {
      if (!formatChannel.charge.chargeValue && parseFloat(formatChannel.charge.chargeValue) !== 0) {
        chargeRequired = true
      } else if (!PRODUCT_PRICE_REGEX.test(String(formatChannel.charge.chargeValue).toLocaleLowerCase())) {
        invalidCharge = true
      }
    }
  }

  for (let i = 0; i < productVariantPrices.length; i++) {
    if (!productVariantPrices[i].priceValue) {
      customisationPriceRequired = true
      break
    }
  }

  if (priceRequired) {
    setErrMsg('category.errMsg.pleaseEnterPriceItem', intl)

    return true
  } else if (invalidPrice) {
    setErrMsg('menu.errMsg.pleaseEnterValidNumberValue', intl)

    return true
  }

  if (chargeRequired) {
    setErrMsg('category.errMsg.enterPackagingCharge', intl)

    return true
  } else if (invalidCharge) {
    setErrMsg('menu.errMsg.pleaseEnterValidPackagingCharge', intl)

    return true
  }

  if (customisationPriceRequired) {
    setErrMsg('category.errMsg.pleaseEnterCutomisation', intl)

    return true
  }

  return false
}

export const updateProductVariantsTag = async (client, org_id, updatedProductVariantPrices) => {

  updatedProductVariantPrices.forEach(async productVariant => {
    const updateProductInput = {
      id: productVariant.productId,
      organizationId: org_id,
      extend: JSON.stringify(getExtendFromSelectedTag(productVariant.tag))
    }

    await updateProduct(client, updateProductInput)
  })
}

export const addProductVariantsTaxAndCharges = async (updatedProductVariantPrices, client) => {
  updatedProductVariantPrices.forEach(async productVariant => {
    await addTaxes(client, productVariant.productId)
    await addCharges(client, productVariant.productId)
  })
}
export const getUpdateProductSortSeqInput = (products, org_id) => {
  const productCategory = products.map((p, index) => {
    return {
      id: p.id,
      sortSeq: index
    }
  })
  const updateProductSortSeqInput = {
    organizationId: org_id,
    productCategory
  }

  return updateProductSortSeqInput
}