import { Checkbox, Col, Dropdown, Input, InputNumber, Radio, Row, Spin } from 'antd'
import { Button, IntlMessages, Text, Title } from 'components'
import React from 'react'
import { intlAlertMessage } from 'Utils'
import { COLORS, PRICE_TYPE, PRODUCT_TAG_ENUM } from 'Utils/constants'
import { priceFormatter } from 'Utils/menuUtils'

import { AddItemStyle, DownOutlinedStyles, GroupItemStyle, ItemBoxStyle, StyledInputBox, UpOutlinedStyles } from '../../../Utils/menuUtils/styleConstants'
import Menu from './AddonMenuItem'

const AddonForm = props => {
  const { isOpen, productsList, selectedProductItem, imageUrl, selectedCategory, productItemName, updateProductLoading, selectedProductItemTagCode, basePrice, dealPrice, isNoExtraCostEnabled, selectedItemName, isCatagoriesLoading, customizationGroupData } = props

  return (
    <>
      <Col
        span={6}
        style={{
          background: 'white',
          padding: '15px 12px',
          marginBottom: '20px',
          height: '80vh'
        }}>
        <Title level="h6">
          <IntlMessages id="addons/Combos" />
        </Title>
        <div>
          <Dropdown
            overlay={
              <Menu
                onMenuItemClickHandler={props.onMenuItemClickHandler}
                customizationGroupData={customizationGroupData}
                isCatagoriesLoading={isCatagoriesLoading}
                getProducts={(elem, bool) => props.getProducts(elem, bool)}
              />
            }
            onVisibleChange={() => props.onDropdownOpenHandler()}
            visible={isOpen}>
            <StyledInputBox
              className={'box-default'}
              onClick={e => e.preventDefault()}>
              <span>
                {selectedCategory ? (
                  selectedCategory.name
                ) : (
                  <IntlMessages id="addons/combo.selectAndCreateAnyGroup" />
                )}
              </span>
              {isOpen ? (
                <UpOutlinedStyles type="up" />
              ) : (
                <DownOutlinedStyles type="down" />
              )}
            </StyledInputBox>
          </Dropdown>
          {props.isProductsLoading ? (
            <div className="divCenter" style={{ marginTop: '-100px' }}>
              <Spin size="default" />
            </div>
          ) : null}
          {selectedCategory && productsList.length ? (
            productsList?.map((item, index) => (
              <GroupItemStyle
                key={index}
                style={
                  item.id === selectedProductItem?.id
                    ? {
                        backgroundColor: COLORS.BG_SHADE,
                        color: COLORS.PRIMARY,
                        padding: '10px 12px',
                        fontWeight: '600'
                      }
                    : {}
                }
                onClick={() => {
                  props.onSelectedItem(item)
                  props.getProductPrice(item.id)
                }}>
                {item.name}
              </GroupItemStyle>
            ))
          ) : (
            <div>
              <IntlMessages id="item.noItemsFound" />
            </div>
          )}
          {selectedCategory ? (
            <ItemBoxStyle>
              <AddItemStyle
                placeholder={intlAlertMessage({
                  id: 'item.enterName',
                  intl: props.intl
                })}
                name="productItemName"
                value={productItemName}
                onChange={e =>
                  props.onProductItemNamehandler(e)
                }></AddItemStyle>
              <Button
                type="primary"
                disabled={!productItemName}
                onClick={() => props.createProduct()}
                loading={props.isLoading}>
                <IntlMessages id="button.+add" />
              </Button>
            </ItemBoxStyle>
          ) : null}
        </div>
      </Col>
      <Col span={12} style={{ marginLeft: '20px' }}>
        <div
          style={{
            backgroundColor: COLORS.BG_WHITE,
            padding: '25px',
            width: '500px'
          }}>
          <div style={{ display: 'flex' }}>
            {imageUrl === null || imageUrl.length < 20 ? (
              <div
                style={{ marginRight: '20px' }}
                className="noImage"
                onClick={() => {
                  props.showImageUpload()
                }}>
                <span>
                  <IntlMessages id="category.message.clickHereToAttachTheImage" />
                </span>
              </div>
            ) : (
              <img
                style={{ marginRight: '20px' }}
                src={imageUrl}
                className="detailImage"
                onClick={() => {
                  props.showImageUpload()
                }}
                alt="Category"
              />
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}>
              <div>
                <div>
                  <IntlMessages id="menu.itemName" />
                </div>
                <Input
                  style={{ width: '330px', marginTop: '5px' }}
                  placeholder={intlAlertMessage({
                    id: 'cheeseBurst',
                    intl: props.intl
                  })}
                  value={props.selectedItemName}
                  onChange={e => props.onSelectedItemNameHandler(e)}
                />
              </div>
              <Radio.Group
                style={{ marginTop: '15px' }}
                value={props.selectedProductItemTagCode}
                onChange={e => {
                  props.onSelectedProductItemTagCodeHandler(e)
                }}>
                <Radio value={PRODUCT_TAG_ENUM.VEG} className="veg">
                  <Text level="body-2" style={{ color: COLORS.PRIMARY }}>
                    <IntlMessages id="addons/combo.Veg" />
                  </Text>
                </Radio>
                <Radio value={PRODUCT_TAG_ENUM.NON_VEG} className="non-veg">
                  <Text level="body-2" style={{ color: COLORS.ERROR_DARK }}>
                    <IntlMessages id="addons/combo.nonveg" />
                  </Text>
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center'
            }}>
            <div style={{ marginRight: '20px' }}>
              <div>
                <Text level="caption">
                  <IntlMessages id="menu.basePrice" />
                </Text>
              </div>
              <InputNumber
                disabled={props.isNoExtraCostEnabled}
                formatter={priceFormatter}
                value={Math.round(props.basePrice)}
                min={0}
                onChange={value => {
                  props.onBasePriceHandler(value)
                }}
                onBlur={() => {
                  props.onPriceValueChanged(
                    props.dealPrice,
                    props.basePrice,
                    PRICE_TYPE.BASE
                  )
                }}
              />
            </div>
            <div style={{ marginRight: '20px' }}>
              <div>
                <Text level="caption">
                  <IntlMessages id="menu.dealPrice" />
                </Text>
              </div>
              <InputNumber
                disabled={props.isNoExtraCostEnabled}
                formatter={priceFormatter}
                value={Math.round(props.dealPrice)}
                min={0}
                onChange={value => {
                  props.onDealPriceHandler(value)
                }}
                onBlur={() => {
                  props.onPriceValueChanged(
                    props.dealPrice,
                    props.basePrice,
                    PRICE_TYPE.DEAL
                  )
                }}
              />
            </div>
            <Checkbox
              style={{ marginTop: '15px' }}
              checked={props.isNoExtraCostEnabled}
              onChange={() => {
                props.onNoExtraCostInputHandler()
              }}>
              <IntlMessages id="addons/combo.noExtraCost" />
            </Checkbox>
          </div>
        </div>

        <Button
          type="primary"
          disabled={
            !(
              selectedProductItemTagCode &&
              selectedItemName &&
              ((basePrice && dealPrice) || isNoExtraCostEnabled) &&
              productsList.length
            )
          }
          loading={updateProductLoading}
          style={{ width: '88px', height: '40px', marginTop: '12px' }}
          onClick={() => props.updateProduct()}>
          <IntlMessages id="update" />
        </Button>
      </Col>
    </>
  )
}

export default AddonForm
