import { Icon } from '@ant-design/compatible'
import { Col, Collapse, Divider, Row, Table, Typography } from 'antd'
import { Button, IntlMessages, Title } from 'components'
import React from 'react'
import { COLORS, ORDER_STATUS } from 'Utils/constants'
import { customPanelStyle, getFormattedPhoneNumber, newOrderColumnHeaders } from 'Utils/OrderUtils'

import OrderReceiptToPrint from './orderReceipt'

const { Text } = Typography
const { Panel } = Collapse

const OrderDetailTable = props => {
  const {
    viewOrderData,
    columnsData,
    totalItemQuantity,
    toggleReceiptModal,
    viewOrderReceipt,
    intl,
    deliveryDiscountCharges
  } = props
  const shippingAddressObj = viewOrderData ? viewOrderData.shippingAddress : ''
  const totalAmount = viewOrderData?.totalAmount - deliveryDiscountCharges

  return (
    <Row>
      <Col span={12}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 16px',
            background: '#e2e2e2'
          }}>
          <Title level="h6">
            <IntlMessages id="newOrder.orderDetails" />
          </Title>
          <div>
            <Button
              type="primary"
              size="small"
              ghost={true}
              onClick={() => toggleReceiptModal(true)}
              style={{ marginBottom: 0 }}>
              <IntlMessages id="button.print" />
            </Button>
            <OrderReceiptToPrint
              viewOrderReceipt={viewOrderReceipt}
              onClose={() => toggleReceiptModal(false)}
              items={columnsData}
              orderDetails={viewOrderData}
              intl={intl}
              deliveryDiscountCharge={deliveryDiscountCharges}
            />
          </div>
        </div>
        <Table
          rowKey="viewordertable"
          dataSource={columnsData}
          columns={newOrderColumnHeaders}
          pagination={false}
          scroll={{
            x: 0,
            y: 250
          }}
        />
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          className="vertical-spacing"
          style={{ marginTop: '10px' }}>
          <Col className="gutter-row" span={10} style={{ paddingLeft: 32 }}>
            <Text strong>
              <IntlMessages id="newOrder.total" />
            </Text>
          </Col>
          <Col className="gutter-row" span={6}>
            <Text strong style={{ paddingLeft: 24 }}>
              x{totalItemQuantity}
            </Text>
          </Col>
          <Col className="gutter-row" span={7}>
            <Text strong>
              ₹
              {viewOrderData
                ? parseFloat(viewOrderData.allLineItemsTotalPrices).toFixed(2)
                : 0}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-down" rotate={isActive ? 180 : 0} />
          )}
          expandIconPosition={'right'}>
          <Panel header="Bill Details" key="1" style={customPanelStyle}>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="top-spacing">
              <Col
                className="gutter-row"
                span={17}
                style={{ paddingLeft: '0px' }}>
                <Text>
                  <IntlMessages id="newOrder.ItemsPrice" />
                </Text>
              </Col>
              <Col
                className="gutter-row"
                span={7}
                style={{ paddingRight: '0px', textAlign: 'right' }}>
                <Text style={{ paddingLeft: 12 }}>
                  <IntlMessages id="newOrder.indianRupeeSign" />
                  {viewOrderData
                    ? parseFloat(viewOrderData.allLineItemsTotalPrices).toFixed(
                        2
                      )
                    : 0}
                </Text>
              </Col>
            </Row>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="top-spacing">
              <Col
                className="gutter-row"
                span={17}
                style={{ paddingLeft: '0px' }}>
                <Text>
                  <IntlMessages id="newOrder.packagingCharges" />
                </Text>
              </Col>
              <Col
                className="gutter-row"
                span={7}
                style={{ paddingRight: '0px', textAlign: 'right' }}>
                <Text style={{ paddingLeft: 12 }}>
                  <IntlMessages id="newOrder.indianRupeeSign" />
                  {viewOrderData
                    ? parseFloat(
                        viewOrderData.allLineItemsTotalCharges +
                          viewOrderData.orderLevelCharges
                      ).toFixed(2)
                    : 0}
                </Text>
              </Col>
            </Row>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="top-spacing">
              <Col
                className="gutter-row"
                span={15}
                style={{ paddingLeft: '0px' }}>
                <Text>
                  <IntlMessages id="newOrder.deliveryCharge" />
                </Text>
              </Col>
              <Col
                className="gutter-row"
                span={9}
                style={{
                  paddingRight: '0px',
                  paddingLeft: '0px',
                  textAlign: 'right'
                }}>
                <Text style={{ paddingLeft: 0 }}>
                  {viewOrderData && viewOrderData.orderDeliveryCharges > 0 && (
                    <span>
                      <IntlMessages id="newOrder.indianRupeeSign" />
                    </span>
                  )}
                  {viewOrderData
                    ? viewOrderData.orderDeliveryCharges > 0
                      ? parseFloat(viewOrderData.orderDeliveryCharges).toFixed(
                          2
                        )
                      : 0
                    : 0}
                </Text>
              </Col>
            </Row>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="top-spacing">
              <Col
                className="gutter-row"
                span={17}
                style={{ paddingLeft: '0px' }}>
                <Text>
                  <IntlMessages id="newOrder.taxes" />
                </Text>
              </Col>
              <Col
                className="gutter-row"
                span={7}
                style={{ paddingRight: '0px', textAlign: 'right' }}>
                <Text style={{ paddingLeft: 12 }}>
                  <IntlMessages id="newOrder.indianRupeeSign" />
                  {viewOrderData
                    ? parseFloat(
                        viewOrderData.allLineItemsTotalTaxes +
                          viewOrderData.orderLevelTaxes
                      ).toFixed(2)
                    : 0}
                </Text>
              </Col>
            </Row>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className="top-spacing">
              <Col
                className="gutter-row"
                span={17}
                style={{ paddingLeft: '0px' }}>
                <Text>
                  <IntlMessages id="order.discountForDeliveryCharge" />
                </Text>
              </Col>
              <Col
                className="gutter-row"
                span={7}
                style={{ paddingRight: '0px', textAlign: 'right' }}>
                <Text style={{ paddingLeft: 12 }}>
                  -<IntlMessages id="newOrder.indianRupeeSign" />
                  {parseFloat(deliveryDiscountCharges.toString()).toFixed(2)}
                </Text>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Divider style={{ marginTop: 0 }} />
        <Row
          className="vertical-spacing">
          <Col className="gutter-row" span={14}>
            <Text strong>
              <IntlMessages id="newOrder.totalAmount" />
            </Text>
          </Col>
          <Col
            className="gutter-row"
            span={10}
            style={{ paddingRight: '16px', textAlign: 'right' }}>
            <Text strong>
              <IntlMessages id="newOrder.indianRupeeSign" />
              {viewOrderData
                ? parseFloat(Math.ceil(totalAmount).toString()).toFixed(2)
                : 0}
            </Text>
          </Col>
        </Row>
        <div className="section-header">
          <IntlMessages id="newOrder.deliveryAddress" />
        </div>
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '13px',
            color: COLORS.DARK_TEXT
          }}>
          <div style={{ fontWeight: 500 }}>
            {viewOrderData &&
              viewOrderData.orderStatus !== ORDER_STATUS.ORDER_CREATED &&
              getFormattedPhoneNumber(shippingAddressObj?.contactNumber)}
            {shippingAddressObj?.name && ` - ${shippingAddressObj?.name}`}
          </div>
          {shippingAddressObj &&
            `${shippingAddressObj.addressLine1},${shippingAddressObj.addressLine2},${shippingAddressObj.city},${shippingAddressObj.state}-${shippingAddressObj.zip},${shippingAddressObj.country}`}
        </div>
      </Col>
    </Row>
  )
}

export default OrderDetailTable
