import React from 'react'
import { intlAlertMessage } from 'Utils'
export const openTime = '0000'
export const closeTime = '2359'

export const isUnSavedStore = () => {
  localStorage.setItem('isUnsavedStoreDetails', JSON.stringify(true))
}

export enum StoreChargeTypeEnum {
  DELIVERY_CHARGE = 'DELIVERY_CHARGE',
  PACKAGING_CHARGE = 'PACKAGING_CHARGE'
}

export const getTenDigitNumber = phoneNumber => {
  return phoneNumber.length >= 12 ? phoneNumber.substring(2) : phoneNumber
}

export const findUser = (roles, userRole) => {
  return roles.find(role => (role.name = userRole))
}

export const getCityStateCountryZip = (addressComponents, searchedLocationText) => {
  const address = { country: '', state: '', pinCode: '', city: '' }

  if (!addressComponents?.length && !searchedLocationText) {
    return address
  } else if (addressComponents?.length) {
    address.pinCode = addressComponents.find(ad => ad.types.includes('postal_code'))?.long_name
    address.city = addressComponents.find(ad => ad.types.includes('administrative_area_level_2'))?.long_name
    address.state = addressComponents.find(ad => ad.types.includes('administrative_area_level_1'))?.long_name
    address.country = addressComponents.find(ad => ad.types.includes('country'))?.long_name
  } else {
    const values = searchedLocationText.split(',')
    const count = values.length

    if (count >= 3) {
      const stateAndPincode = values[count - 2].trim().split(' ')
      let state = ''

      stateAndPincode.forEach((v, i) => {
        if (i !== stateAndPincode.length - 1) {
          state += ' '
          state += v
        }
      })
      address.country = address.country || values[count - 1].trim()
      address.state = address.state || state.trim()
      address.pinCode = address.pinCode || stateAndPincode[stateAndPincode.length - 1]
      address.city = address.city || values[count - 3].trim()
    }
  }

  return address
}

export const padWithZeroes = (number, length = 4) => {
  const numberString = String(number)

  return numberString.padStart(length, '0')
}

export const formatMobileNumber = number => {
  return number.length === 10 ? `91${number}` : number
}

export const columnHeaders = [
  {
    name: 'activeStatus',
    title: 'Active',
    key: 'activeStatus',
    dataIndex: 'activeStatusAction',
    render: (activeStatusAction) => activeStatusAction,
    width: '10%'
  },
  {
    name: 'name',
    title: 'Agent Name',
    key: 'name',
    dataIndex: 'name',
    width: '20%'
  },
  {
    name: 'phone',
    title: 'Mobile Number',
    key: 'phone',
    dataIndex: 'phone',
    width: '50%'
  },
  {
    title: '',
    dataIndex: 'editButtonAction',
    render: editButtonAction => 
      editButtonAction,
    width: '10%'
  },
  {
    title: '',
    dataIndex: 'deleteButtonAction',
    render: deleteButtonAction => deleteButtonAction,
    width: '10%'
  }
]