import { IMAGES } from 'assets'
import { History } from 'history'
import QRCode from 'qrcode.react'
import React from 'react'
import styled from 'styled-components'
import { copyToClipboard, downloadQR } from 'Utils/index'

import CommonOnboardingCard from './commonOnboardingCard'

interface MiniAppUrlCardProps {
  intl: any
  history: History
  pending: boolean
  disable: boolean
  miniAppUrl?: string
}
interface MiniAppUrlCardState {}

const QRCodeContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 40px;
  border: 1px solid #e6e6e6;
  padding: 5px 5px 1px 5px;
  margin: 19px 0;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    &:after {
      content: '';
      background: url(${IMAGES.download_img}) center no-repeat;
      background-repeat: no-repeat;
      background-size: 42px 26px;
      opacity: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`

class MiniAppUrlCard extends React.Component<MiniAppUrlCardProps, MiniAppUrlCardState> {
  onCreateUrl = () => {
    this.props.history.push({
      pathname: '/orderx/onboarding/create-mini-url'
    })
  }

  render() {
    const { miniAppUrl } = this.props

    return (
      <>
        <CommonOnboardingCard {...this.props} onButtonClick={() => this.onCreateUrl()} title="onboarding.createMiniUrl" description="onboarding.createMiniUrlInfo" buttonText="createUrl" required={true} copyToClipboard={() => copyToClipboard(miniAppUrl)} />
        {miniAppUrl && (
          <>
            <QRCodeContainer onClick={() => downloadQR()}>
              <QRCode id="qr-code" value={miniAppUrl} style={{ height: '80px', width: '80px' }} />
            </QRCodeContainer>
          </>
        )}
      </>
    )
  }
}
export default MiniAppUrlCard
