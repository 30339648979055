import { ApolloClient } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Avatar, Button, Divider, message,Modal, Popover } from 'antd'
import { ChangePasswordForm, IntlMessages } from 'components'
import FbNotificationManager from 'firebaseInit'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import { changePassword } from 'services/userService'
import { getOrgDetails, getUserDetails, intlAlertMessage, isAdmin } from 'Utils'
import { COLORS, CONSOLE_VERSION, ORG_STATUS, TERMS_CONDITION_REDIRECT_URL, USER_NAME } from 'Utils/constants'

import * as serviceWorker from '../../../serviceWorker'

interface IProps {
  history: History
  client: ApolloClient<any>
  intl: any
}

interface IState {
  firstName?: string
  lastName?: string
  user?: any
  isAdmin: boolean
  showModal: boolean
  oldPass: string
  newPass: string
  confirmPass: string
  isUnsavedChanges: boolean
  isRoleFetched: boolean
  loading: boolean
  isOnboarding: boolean
}

const ListStyle = { height: '12px', display: 'flex', alignItems: 'center' }

class UserInfo extends Component<IProps, IState> {
  notificationManager: any

  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      user: '',
      showModal: false,
      oldPass: '',
      newPass: '',
      confirmPass: '',
      isAdmin: false,
      isUnsavedChanges: false,
      isRoleFetched: false,
      loading: false,
      isOnboarding: false
    }
    this.notificationManager = FbNotificationManager.getInstance()
  }

  componentDidMount() {
    const orgDetails = getOrgDetails()

    this.setState({
      isOnboarding: orgDetails.onboardingStatus === ORG_STATUS.INITIATED
    })
  }

  logout = () => {
    const urlDetails = this.props.history.location.pathname.split('/')
    const isOnStoreFormPage = urlDetails[urlDetails.length - 1] === 'store' || urlDetails[urlDetails.length - 3] === 'store'
    const isUnsavedStoreDetails = JSON.parse(localStorage.getItem('isUnsavedStoreDetails'))

    if (isUnsavedStoreDetails && isOnStoreFormPage) {
      this.setState({ isUnsavedChanges: true })
    } else {
      this.goToSignIn()
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }

    // This.props.history.push('/');
  }

  goToSignIn = () => {
    this.setState({ isUnsavedChanges: false })
    sessionStorage.clear()
    localStorage.clear()
    this.notificationManager.removeFirebaseToken()
    serviceWorker.unregister()
    this.props.history.push('/signin')
  }

  onUrlHistoryPush = url => {
    this.props.history.push(url)
  }

  UNSAFE_componentWillMount() {
    const userDetails = getUserDetails()

    if (userDetails) {
      this.setState({
        user: userDetails,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        isAdmin: isAdmin(),
        isRoleFetched: true
      })
    } else {
      this.setState({ isRoleFetched: true })
    }
  }

  handleSubmit = async values => {
    const { currentPassword, password } = values

    this.setState({ loading: true })
    try {
      const payload = { oldPassword: currentPassword, newPassword: password }

      await changePassword(this.props.client, payload)
      message.success(intlAlertMessage({ id: 'passwordUpdated', intl: this.props.intl }))
      this.setState({ showModal: false, loading: false })
    } catch (err) {
      this.setState({ loading: false })
      console.log(`Failed to update password${err}`)
    }
  }

  onConfirmPassHandler = event => {
    this.setState({ confirmPass: event.target.value.trim() })
  }

  render() {
    const { firstName, lastName, user, isAdmin, isUnsavedChanges, isRoleFetched, loading, isOnboarding } = this.state
    const { intl } = this.props
    const userName = firstName ? `${firstName} ${lastName ? lastName : ' '}` : isAdmin ? USER_NAME.ADMIN : USER_NAME.STORE_MANAGER
    const uName = firstName ? firstName.charAt(0).toUpperCase() : isAdmin ? 'A' : 'SM'
    const userMenuOptions = (
      <ul className="gx-user-popover" style={{ padding: '12px 0' }}>
        {/* <li>My Account</li>*/}
        {/* <li
          onClick={() =>
            this.onUrlHistoryPush(`/core/organization/${this.state.org_id}`)
          }
        >
          <Link to={`/core/organization/${this.state.org_id}`}>
            {' '}
            Organization{' '}
          </Link>
        </li>
        <li
          onClick={() =>
            this.onUrlHistoryPush(`/core/settings/developer/webhooks`)
          }
        >
          <Link to={`/core/settings/developer`}> Developer Settings </Link>
        </li> */}
        {isAdmin && (
          <>
            {!isOnboarding && (
              <>
                <li key="profile-link" style={ListStyle}>
                  <Link to={'/orderx/profile'} style={{ color: COLORS.PRIMARY }}>
                    <IntlMessages id="profileInfo" />{' '}
                  </Link>
                </li>
                <Divider />
              </>
            )}
            <li
              style={ListStyle}
              onClick={() => this.setState({ showModal: true })}
              key="change-password-link">
              <IntlMessages id="changePassword" />
            </li>
            <Divider />
          </>
        )}
       
        <li key="term-and-condition-link" style={ListStyle}>
          <a
            href={TERMS_CONDITION_REDIRECT_URL}
            target="_blank"
            style={{ color: COLORS.DARK_TEXT }}
            rel="noopener noreferrer">
            <IntlMessages id="terms&condition" />
          </a>
        </li>
        <Divider />
        <li onClick={() => this.logout()} key="logout-link" style={ListStyle}>
          {/* <Link to="/signin" style={{ color: '#444444' }}> */}{' '}
          <IntlMessages id="logout" />
          {/* </Link> */}
        </li>
        <Divider />
        <li style={ListStyle}>{CONSOLE_VERSION}</li>
      </ul>
    )

    return (
      <div>
        <Popover
          overlayClassName="gx-popover-horizontal"
          placement="bottomRight"
          content={userMenuOptions}>
          {isRoleFetched && (
            <div className="gx-flex-row gx-align-items-center gx-pointer">
              <p
                style={{ color: COLORS.NORMAL_TEXT }}
                className="gx-mb-0 gx-d-none gx-d-sm-flex">
                {userName}
              </p>
              &nbsp;
              <div className="gx-ml-2">
                {user.image === null || user.image === undefined ? (
                  <Avatar style={{ backgroundColor: '#424e88' }} size="large">
                    <span style={{ fontSize: 22, fontWeight: 'normal' }}>
                      {uName}
                    </span>
                  </Avatar>
                ) : (
                  <Avatar size="large" alt={firstName} src={user.image} />
                )}
              </div>
            </div>
          )}
        </Popover>
        {this.state.showModal && (
          <Modal
            className={'change-pwd-modal'}
            width="376px"
            visible={this.state.showModal}
            title={<IntlMessages id="updatePassword" />}
            onCancel={() => {
              this.setState({ showModal: false })
            }}
            footer={[]}>
            <div
              className="gx-app-change-pw-content"
              style={{ padding: '0 20px' }}>
              <ChangePasswordForm
                onSubmit={this.handleSubmit}
                intl={intl}
                loading={loading}
                submitButtonText={'update'}
                submitButtonClassName={'ant-btn-green'}
              />
            </div>
          </Modal>
        )}
        <Modal
          visible={isUnsavedChanges}
          onCancel={() => {
            this.setState({ isUnsavedChanges: false })
          }}
          footer={[
            <Button
              key="rejectLogout"
              onClick={() => {
                this.setState({ isUnsavedChanges: false })
              }}>
              <IntlMessages id="button.no" />
            </Button>,
            <Button
              type="primary"
              danger
              key="confirmLogout"
              onClick={() => {
                this.goToSignIn()
              }}>
              <IntlMessages id="button.yes" />
            </Button>
          ]}>
          <div className="ant-modal-title mt-2">
            {' '}
            <IntlMessages id="userInfo.infoMsg.AllInfoWillLost" />
          </div>
        </Modal>
      </div>
    )
  }
}

export default injectIntl(compose(withRouter, withApollo)(UserInfo))
