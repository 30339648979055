import { ApolloProviderProps } from '@apollo/client/react/context'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { Col, Dropdown, Input, message, Row, Spin } from 'antd'
import { Button, FileUpload, IntlMessages, Modal, Text, Title } from 'components'
import jwt from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getOptions, onCreateOption, onOptionValueSubmit, updateOptionValue } from 'services'
import { intlAlertMessage, randomString } from 'Utils'
import { COLORS } from 'Utils/constants'

import { PROCESS_DELIVERY_ACCEPTANCE } from '../../../services/query'
import { republishCatalog } from '../../../Utils/menuUtils'
import { AddItemStyle, DownOutlinedStyles, GroupItemStyle, ItemBoxStyle, MenuStyle, StyledInputBox, UpOutlinedStyles } from '../../../Utils/menuUtils/styleConstants'

interface ICustomizationProps extends ApolloProviderProps<any> {
  intl: any
  onOptionsChange: any
}

interface ICustomizationState {
  isOpen: boolean
  selectedCustomizationGroup: any
  isCustomizationModalOpen: boolean
  customizationName: string
  customizationGroupData: any
  selectedCustomizationGroupItem: any
  isEdit: boolean
  optionValueString: string
  selectedCustomizationGroupOptionValues: any
  optionValueName: any
  isLoading: boolean
  isUpdateOptionValueLoading: boolean
  isCustomisationGroupLoading: boolean
}
class Customization extends Component<ICustomizationProps, ICustomizationState> {
  imageData: object

  constructor(props) {
    super(props)
    this.imageData = {}
    this.state = {
      isOpen: false,
      selectedCustomizationGroup: '',
      selectedCustomizationGroupItem: '',
      isCustomizationModalOpen: false,
      customizationName: '',
      isEdit: false,
      customizationGroupData: [],
      optionValueString: '',
      selectedCustomizationGroupOptionValues: [],
      optionValueName: '',
      isLoading: false,
      isUpdateOptionValueLoading: false,
      isCustomisationGroupLoading: false
    }
  }

  componentDidMount() {
    this.getOptions()
  }

  getOptions = async () => {
    try {
      this.setState({
        isCustomisationGroupLoading: true
      })
      const optionsRes = await getOptions(this.props.client)

      this.setState({
        customizationGroupData: optionsRes.data.options,
        selectedCustomizationGroup: optionsRes.data.options[0]
      })
      const optionValues = optionsRes?.data.options.filter(item => item.id === optionsRes.data.options[0].id)[0].optionValues || []

      this.setState({
        selectedCustomizationGroupOptionValues: optionValues,
        selectedCustomizationGroupItem: optionValues[0],
        optionValueName: optionValues[0]?.value,
        isCustomisationGroupLoading: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        isCustomisationGroupLoading: false
      })
    }
  }

  onUserInputHandler = e => {
    this.setState({
      customizationName: e.target.value
    })
  }

  updateOptionValue = async () => {
    const { optionValueName, selectedCustomizationGroupItem, selectedCustomizationGroup, selectedCustomizationGroupOptionValues } = this.state

    try {
      this.setState({
        isUpdateOptionValueLoading: true
      })
      const res = await updateOptionValue(this.props.client, selectedCustomizationGroupItem, selectedCustomizationGroup, optionValueName)

      console.log(res, 'res')
      if (res?.data) {
        const selectedCustomizationIndex = selectedCustomizationGroupOptionValues.findIndex(elem => elem.id === selectedCustomizationGroupItem.id)

        selectedCustomizationGroupOptionValues.splice(selectedCustomizationIndex, 1, res?.data?.updateOptionValue)
        this.setState({
          isUpdateOptionValueLoading: false
        })
        this.props.onOptionsChange()
        message.success(
          intlAlertMessage({
            id: 'category.successMsg.itemUpdatedSuccessfully',
            intl: this.props.intl
          })
        )
      }
      republishCatalog(this.props.client)
    } catch (error) {
      this.setState({
        isUpdateOptionValueLoading: false
      })
      console.log(error)
    }
  }

  onOptionValueSubmit = async () => {
    const { selectedCustomizationGroup, optionValueString, selectedCustomizationGroupOptionValues } = this.state

    try {
      this.setState({
        isLoading: true
      })
      const res = await onOptionValueSubmit(this.props.client, selectedCustomizationGroup, optionValueString)

      if (res.data) {
        message.success(
          intlAlertMessage({
            id: 'category.message.ItemCreatedSuccessfully',
            intl: this.props.intl
          })
        )
        selectedCustomizationGroupOptionValues.splice(selectedCustomizationGroupOptionValues.length, 0, res?.data?.createOptionValue)
        selectedCustomizationGroup.optionValues = selectedCustomizationGroupOptionValues
        this.setState({
          isLoading: false,
          optionValueName: optionValueString,
          optionValueString: '',
          selectedCustomizationGroup,
          selectedCustomizationGroupOptionValues,
          selectedCustomizationGroupItem: res?.data?.createOptionValue
        })
        this.props.onOptionsChange()
        republishCatalog(this.props.client)
      }
    } catch (error) {
      console.log(error)
    }
  }

  onSubmit = async () => {
    const jwtToken: any = localStorage.getItem('jwt')
    const { org_id }: any = jwt.decode(jwtToken)
    const { customizationName, customizationGroupData } = this.state

    try {
      this.setState({
        isLoading: true
      })
      const createOptionRes = await onCreateOption(this.props.client, org_id, customizationName)

      console.log(createOptionRes)
      customizationGroupData.splice(customizationGroupData.length, 0, createOptionRes?.data?.createOption)
      message.success(
        intlAlertMessage({
          id: 'category.message.ItemCreatedSuccessfully',
          intl: this.props.intl
        })
      )
      this.setState({
        customizationName: '',
        isCustomizationModalOpen: false,
        isLoading: false,
        selectedCustomizationGroup: createOptionRes?.data?.createOption,
        selectedCustomizationGroupOptionValues: createOptionRes?.data?.createOption?.optionValues || [],
        selectedCustomizationGroupItem: createOptionRes?.data?.createOption?.optionValues?.[0],
        optionValueName: createOptionRes?.data?.createOption?.optionValues?.[0]?.value
      })
      this.props.onOptionsChange()
    } catch (error) {
      console.log(error)
      this.setState({
        isLoading: false
      })
    }
  }

  menu = () => (
    <MenuStyle>
      <MenuStyle.Item
        style={{ color: COLORS.PRIMARY }}
        onClick={() => {
          this.setState({
            isCustomizationModalOpen: true,
            isOpen: !this.state.isOpen
          })
        }}>
        <IntlMessages id="button.+createNewGroup" />
      </MenuStyle.Item>
      <MenuStyle.Item>
        {this.state.isCustomisationGroupLoading && (
          <div className="divCenter" style={{ marginTop: '-5px' }}>
            <Spin size="default" />
          </div>
        )}
      </MenuStyle.Item>
      {this.state?.customizationGroupData.map((item, index) => (
        <MenuStyle.Item
          key={item.id}
          onClick={() => {
            this.setState({
              selectedCustomizationGroup: item,
              selectedCustomizationGroupOptionValues: item.optionValues,
              selectedCustomizationGroupItem: item?.optionValues?.[0],
              optionValueName: item?.optionValues?.[0]?.value,
              isOpen: !this.state.isOpen
            })
          }}>
          {item.name}
        </MenuStyle.Item>
      ))}
    </MenuStyle>
  )

  render() {
    const { isOpen, selectedCustomizationGroup, isCustomizationModalOpen, selectedCustomizationGroupItem, optionValueString, selectedCustomizationGroupOptionValues, isLoading, isUpdateOptionValueLoading } = this.state

    return (
      <>
        <Col
          span={6}
          style={{
            background: 'white',
            padding: '15px 12px',
            marginBottom: '20px',
            height: '80vh'
          }}>
          <Title level="h6">
            <IntlMessages id="customization" />
          </Title>
          <div>
            <Dropdown
              overlay={this.menu}
              onVisibleChange={() =>
                this.setState({ isOpen: !this.state.isOpen })
              }
              visible={isOpen}>
              <StyledInputBox
                className={'box-default'}
                onClick={e => e.preventDefault()}>
                <span>
                  {selectedCustomizationGroup ? (
                    selectedCustomizationGroup.name
                  ) : (
                    <IntlMessages id="addons/combo.selectAndCreateAnyGroup" />
                  )}
                </span>
                {isOpen ? (
                  <UpOutlinedStyles type="up" />
                ) : (
                  <DownOutlinedStyles type="down" />
                )}
              </StyledInputBox>
            </Dropdown>
            {this.state.isCustomisationGroupLoading && (
              <div className="divCenter" style={{ marginTop: '-100px' }}>
                <Spin size="default" />
              </div>
            )}
            {selectedCustomizationGroup
              ? selectedCustomizationGroupOptionValues?.map((item, index) => (
                <GroupItemStyle
                  key={item.id}
                  style={
                    item.id === selectedCustomizationGroupItem.id
                      ? {
                        backgroundColor: COLORS.BG_SHADE,
                        color: COLORS.PRIMARY,
                        padding: '10px 12px',
                        fontWeight: '600'
                      }
                      : {}
                  }
                  onClick={() =>
                    this.setState({
                      selectedCustomizationGroupItem: item,
                      optionValueName: item.value
                    })
                  }>
                  {item.value}
                </GroupItemStyle>
              ))
              : null}
            {selectedCustomizationGroup ? (
              <ItemBoxStyle>
                <AddItemStyle
                  placeholder={intlAlertMessage({
                    id: 'item.enterName',
                    intl: this.props.intl
                  })}
                  value={optionValueString}
                  onChange={e =>
                    this.setState({ optionValueString: e.target.value })
                  }></AddItemStyle>
                <Button
                  type="primary"
                  disabled={!optionValueString}
                  onClick={() => this.onOptionValueSubmit()}
                  loading={isLoading}>
                  <IntlMessages id="button.+add" />
                </Button>
              </ItemBoxStyle>
            ) : null}
          </div>
        </Col>
        <Col span={12} style={{ marginLeft: '20px' }}>
          <div
            style={{
              backgroundColor: COLORS.BG_WHITE,
              padding: '25px',
              display: 'flex',
              width: '500px'
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}>
              <div>
                <IntlMessages id="menu.itemName" />
              </div>

              <Input
                style={{ width: '330px', marginTop: '5px' }}
                placeholder={intlAlertMessage({
                  id: 'cheeseBurst',
                  intl: this.props.intl
                })}
                value={this.state.optionValueName}
                onChange={e =>
                  this.setState({ optionValueName: e.target.value })
                }
              />
            </div>
          </div>
          <Button
            type="primary"
            disabled={
              !(
                this.state.optionValueName &&
                selectedCustomizationGroupOptionValues.length
              )
            }
            style={{ width: '88px', height: '40px', marginTop: '12px' }}
            onClick={() => this.updateOptionValue()}
            loading={isUpdateOptionValueLoading}>
            <IntlMessages id="update" />
          </Button>
        </Col>
        <Modal
          visible={isCustomizationModalOpen}
          width={450}
          title=""
          onClose={() => this.setState({ isCustomizationModalOpen: false })}>
          <Title
            level="h4"
            style={{
              marginTop: '-8px',
              color: COLORS.DARK_TEXT,
              fontWeight: '500'
            }}>
            <IntlMessages id="customization.customizationName" />
          </Title>
          <Text level="caption" style={{ marginBottom: '15px' }}>
            <IntlMessages id="addons/combo.enterName" />
          </Text>
          <Input
            placeholder={intlAlertMessage({
              id: 'addons/combo.enterName',
              intl: this.props.intl
            })}
            value={this.state.customizationName}
            style={{ marginTop: '5px' }}
            onChange={e => this.onUserInputHandler(e)}
          />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              type="primary"
              disabled={!this.state.customizationName}
              style={{ height: '35px', width: '154px', marginTop: '16px' }}
              onClick={() => this.onSubmit()}>
              <IntlMessages id="button.submit" />
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

const CustomizationComponent = compose(
  withRouter,
  graphql(PROCESS_DELIVERY_ACCEPTANCE, {
    name: 'pickUpOrder'
  })
)(withApollo(Customization))

export { CustomizationComponent }
