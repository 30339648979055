/* eslint-disable prefer-named-capture-group */

import './styles.css'

import { Icon } from '@ant-design/compatible'
import { ApolloClient } from '@apollo/client'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Col, Divider, message, Modal, Row, Switch } from 'antd'
import { ClientManager } from 'appApollo/client'
import { Button, IntlMessages, Title } from 'components'
import { History } from 'history'
import * as jwt from 'jsonwebtoken'
import moment from 'moment'
import { Loader } from 'pages/menu/components'
import { ConfirmationModal, DeliveryAgent, DeliveryArea, StoreForm, StoreLocation } from 'pages/store/components'
import * as React from 'react'
import { injectIntl } from 'react-intl'
import { activeDeliveryAgent, addBulkStoreOpenTiming, addDeliveryArea, addStaffMembersToStore, addStaffMemberToStore, addUserToOrganiser, addUserToStore, checkUserName, createBulkStaffMembers, createStaff, createStore, createUpdatePMSStore, getAllStores, getCatalogue, getPMSStore, getRoles, getStore, getStoreServiceAreas, getStoreTimings, onDeleteAgent, removeDeliveryArea, removeStoreSlotTimings, removeStoreTimings, storeCharges, updateDeliveryAgent, updateDeliveryArea, updateStore, updateStoreServiceArea, updateUserPassword } from 'services'
import { getOrgDetails, getUserDetails, getViewType, intlAlertMessage, setErrMsg, setUserDetails } from 'Utils'
import { COLORS, DELIVERY_CHARGE_REGEX, HH_MM_FORMAT, ORG_CONSTANT, ORG_STATUS, PASSWORD_REGEX, PHONE_NUMBER_REGEX, RADIUS, ROLE_TYPE, STORE_HELP_URL, USER_NAME_REGEX, VIEW_TYPES } from 'Utils/constants'
import { closeTime, formatMobileNumber, getCityStateCountryZip, getTenDigitNumber, isUnSavedStore, openTime, padWithZeroes, StoreChargeTypeEnum } from 'Utils/storeUtils'



interface iProps extends ApolloProviderProps<any> {
  history: History
  intl: any
}
interface iState {
  isAddAgentsModalOpen: boolean
  agentDetails: any
  editingAgentDetailsIndex: any
  deliveryChargePerKm: string
  storeFormDetails: Object
  deliveryAgentFormDetails: Object
  storeGeoLocation: any
  mapData: any
  roles: any
  isFetching: boolean
  isAddAgent: boolean
  days: any
  showAddSlotDialog: boolean
  daysLength: number
  showDeleteAgentDialog: boolean
  showDeleteSlotDialog: boolean
  isDayClose: boolean
  storeTimings: any
  storeTiming: any
  isNewTimeSlot: boolean
  selectedDay: any
  selectedSlotIndex: number
  createdStore: any
  stores: any
  fleetOption: string
  showLocationModal: boolean
  searchedLocationText: string
  streetAddressText: string
  showDeliveryFleet: boolean
  saveStoreDetails: boolean
  isUpdatingAgent: boolean
  isApplyAllDays: boolean
  isUnsavedChanges: boolean
  address_components: any[]
  showClearAllSlotsDialog: boolean
  catalogs: any[]
  editPassword: boolean
  serviceabilityRadius: string
  storeServiceAreaId: string
  storeDetails: any
  deliveryDiscount: any
  deliveryDiscountStatus: any
  deliveryChargeId: any
  isAgentDetailsModalVisible: boolean
}
const defaultDeliveryDiscount = '10'
const defaultDeliveryDiscountStatus = { YES: 'ACTIVE', NO: 'INACTIVE' }

class Store extends React.Component<iProps, iState> {
  org_id: string | { [key: string]: any }

  FormType: string

  isOnboarding: boolean

  geocoder = new google.maps.Geocoder()

  unblock: any

  urlDetails: any

  targetLocation: any

  channels: any

  pmsClient: ApolloClient<any>

  isBMSDealsOnlyView: boolean

  constructor(props: iProps) {
    super(props)
    this.pmsClient = ClientManager.getInstance().pms
    this.unblock = {}
    this.channels = []
    this.state = {
      fleetOption: '',
      stores: [],
      catalogs: [],
      createdStore: null,
      isFetching: true,
      isAddAgent: false,
      roles: [],
      isAddAgentsModalOpen: false,
      agentDetails: [],
      editingAgentDetailsIndex: 0,
      deliveryChargePerKm: '',
      storeGeoLocation: '',
      storeDetails: {},
      storeFormDetails: {
        storeName: '',
        storeTimingId: '',
        openTime: null,
        closeTime: null,
        days: [],
        userName: '',
        firstName: '',
        password: '',
        phone: '',
        whatsappSupport: true
      },
      serviceabilityRadius: '8',
      deliveryAgentFormDetails: {
        name: '',
        phone: ''
      },
      mapData: {
        polygonArray: [],
        polygonGeoJSON: {},
        places: [
          {
            id: '',
            placeName: '',
            storeId: '',
            selected: true,
            mainPlace: true,
            radius: [0],
            address: '686691',
            center: { lat: null, lng: null }
          }
        ],
        center: { lat: null, lng: null }
      },
      showAddSlotDialog: false,
      showDeleteAgentDialog: false,
      showDeleteSlotDialog: false,
      selectedDay: '',
      isDayClose: false,
      isNewTimeSlot: false,
      selectedSlotIndex: null,
      isApplyAllDays: false,
      daysLength: 7,
      storeTiming: {
        days: '',
        data: []
      },
      storeTimings: [],
      days: [
        {
          name: 'MONDAY',
          abbreviation: 'M',
          isActive: true,
          data: []
        },
        {
          name: 'TUESDAY',
          abbreviation: 'T',
          isActive: true,
          data: []
        },
        {
          name: 'WEDNESDAY',
          abbreviation: 'W',
          isActive: true,
          data: []
        },
        {
          name: 'THURSDAY',
          abbreviation: 'T',
          isActive: true,
          data: []
        },
        {
          name: 'FRIDAY',
          abbreviation: 'F',
          isActive: true,
          data: []
        },
        {
          name: 'SATURDAY',
          abbreviation: 'S',
          isActive: true,
          data: []
        },
        {
          name: 'SUNDAY',
          abbreviation: 'S',
          isActive: true,
          data: []
        }
      ],
      showLocationModal: false,
      searchedLocationText: '',
      streetAddressText: '',
      showDeliveryFleet: false,
      saveStoreDetails: false,
      isUpdatingAgent: false,
      isUnsavedChanges: false,
      address_components: [],
      showClearAllSlotsDialog: false,
      editPassword: false,
      storeServiceAreaId: '',
      deliveryDiscount: defaultDeliveryDiscount,
      deliveryDiscountStatus: defaultDeliveryDiscountStatus.NO,
      deliveryChargeId: '',
      isAgentDetailsModalVisible: false
    }
    this.urlDetails = this.props.history.location.pathname.split('/')
  }

  getServiceabilityArea = async id => {
    if (id) {
      try {
        const payload = { storeId: id, organizationId: this.org_id }
        const res = await getStoreServiceAreas(this.props.client, payload)
        const data = res.data.getStoreServiceAreas[0]

        this.setState({
          storeServiceAreaId: data.id
        })
        const radius = data.area / 1000

        this.addServiceabilityArea(radius)
      } catch (err) {
        console.log(err)
      }
    }
  }

  getPMSStoreDetails = async id => {
    try {
      const payload = { wcoreStoreId: id }
      const storeRes = await getPMSStore(this.pmsClient, payload)
      const isStoreAvailable = storeRes?.data?.store

      if (isStoreAvailable) {
        const discounts = storeRes?.data?.store?.discounts
        const status = discounts[0].status === null || discounts[0].status === 'PENDING' ? defaultDeliveryDiscountStatus.NO : discounts[0].status

        this.setState({
          deliveryDiscount: discounts[0].discountValue,
          deliveryDiscountStatus: status
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  componentDidMount() {
    localStorage.removeItem('isUnsavedStoreDetails')
    const jwtToken: any = localStorage.getItem('jwt')

    this.org_id = jwt.decode(jwtToken).org_id
    const viewType = getViewType()

    this.isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY
    this.FormType = this.urlDetails[this.urlDetails.length - 1]
    this.getAllStore()
    getRoles(this.props.client).then(rolesResponse => {
      this.setState({ roles: rolesResponse?.data?.roles })
    })
    if (this.urlDetails[3]) {
      this.isBMSDealsOnlyView ? this.getStoreAndStaffDetails() : this.getAllStoreDetails()
    } else {
      const orgDetails = getOrgDetails()

      this.isOnboarding = Boolean(orgDetails && orgDetails.onboardingStatus === ORG_STATUS.INITIATED)
      this.setState({ isFetching: false })
      let channels = localStorage.getItem('channels')

      channels = channels ? JSON.parse(channels) : channels
      this.channels = channels
      const userDetails = getUserDetails()

      if (!userDetails?.catalogs || !userDetails?.catalogs.length) {
        this.getCatalogs()
      } else {
        this.setState({ catalogs: userDetails.catalogs })
      }
    }
    this.unblock = this.props.history.block(targetLocation => {
      this.targetLocation = targetLocation
      if (localStorage.getItem('isUnsavedStoreDetails')) {
        this.setState({ isUnsavedChanges: true })

        return false
      }
    })
  }


  getAllStoreDetails = () => {
    const { storeFormDetails, mapData } = this.state

    getStore(this.props.client, this.urlDetails[3]).then(storeResponse => {
      getStoreTimings(this.props.client, this.urlDetails[3]).then(storeTimingResponse => {
        const storeDetails = storeResponse.data.store

        const storeGeoLocation = storeDetails.geoLocation

        storeFormDetails['storeName'] = storeDetails.name
        this.getServiceabilityArea(storeDetails.id)
        this.getPMSStoreDetails(storeDetails.id)
        const { days, daysLength } = this.state

        if (storeTimingResponse?.data?.storeOpenTimings?.data?.length) {
          days.forEach((day, i) => {
            let matchedDay: any

            storeTimingResponse.data.storeOpenTimings.data.forEach(d => {
              if (JSON.parse(d.days)[0] === day.name) {
                matchedDay = d
              }
            })
            if (matchedDay) {
              days[i].isActive = true
              days[i].data = matchedDay.data
            }
          })
        }
        const activeDeliveryAgents = []

        const agentDetails = storeDetails.staff.filter((staff, staffIndex) => {
          if (staff.status === ORG_STATUS.ACTIVE && staff.staffRole === 'DELIVERY') {
            activeDeliveryAgents.push(staff)
          }

          return staff.staffRole === 'DELIVERY'
        })
        const fleetOpt = activeDeliveryAgents.length <= 0 ? 'no' : 'yes'
        const storeManagerDetails = storeDetails.staff.find(staff => staff.status === ORG_STATUS.ACTIVE && staff.staffRole === ROLE_TYPE.STORE_MANAGER)

        storeFormDetails['whatsappSupport'] = storeManagerDetails?.whatsappSupport

        let managerDetails

        storeDetails.users.forEach(user => {
          user.roles.forEach(role => {
            if (role.name === ROLE_TYPE.MANAGER) {
              managerDetails = user
            }
          })
        })

        if (storeManagerDetails) {
          storeFormDetails['phone'] = storeManagerDetails.phone || ''
          storeFormDetails['userName'] = storeManagerDetails.name || ''
          storeFormDetails['storeManagerId'] = storeManagerDetails.id || ''
        }
        storeFormDetails['managerId'] = managerDetails ? managerDetails.id : ''
        let searchedLocation = storeDetails.addressLine2
        let center = { lat: 20.5937, lng: 78.9629 }

        if (storeGeoLocation) {
          const latLng = storeGeoLocation.match(/((-?\d+.?\d+) (-?\d+.?\d+))/)

          if (latLng && latLng[2] && latLng[3]) center = { lat: latLng[2], lng: latLng[3] }
          if (!searchedLocation) {
            const newLatLng = new google.maps.LatLng(Number(center.lat), Number(center.lng))

            searchedLocation = this.getSearchedLocation(newLatLng)
          }
        }
        mapData.center = center
        this.setState({
          storeDetails,
          storeFormDetails,
          agentDetails,
          mapData,
          editingAgentDetailsIndex: 0,
          isFetching: false,
          storeGeoLocation,
          days,
          daysLength,
          streetAddressText: storeDetails.addressLine1,
          searchedLocationText: searchedLocation,
          fleetOption: fleetOpt
        })
      })
    })

  }

  async getStoreAndStaffDetails() {
    const storeResponse = await getStore(this.props.client, this.urlDetails[3])
    const storeDetails = storeResponse.data.store
    const { geoLocation, name, staff, users } = storeDetails
    let searchedLocation = storeDetails.addressLine2
    let center = { lat: 20.5937, lng: 78.9629 }

    const { storeFormDetails, mapData } = this.state

    storeFormDetails['storeName'] = name
    const storeManagerDetails = staff.find(staff => staff.status === ORG_STATUS.ACTIVE && staff.staffRole === ROLE_TYPE.STORE_MANAGER)

    const managerUserDetails = users.find(user => user.roles[0].name === ROLE_TYPE.MANAGER)

    storeFormDetails['managerId'] = managerUserDetails?.id

    if (storeManagerDetails) {
      storeFormDetails['phone'] = storeManagerDetails.phone || ''
      storeFormDetails['userName'] = storeManagerDetails.name || ''
      storeFormDetails['storeManagerId'] = storeManagerDetails.id || ''
    }
    if (geoLocation) {
      const latLng = geoLocation.match(/((-?\d+.?\d+) (-?\d+.?\d+))/)

      if (latLng && latLng[2] && latLng[3]) center = { lat: latLng[2], lng: latLng[3] }

      if (!searchedLocation) {
        const newLatLng = new google.maps.LatLng(Number(center.lat), Number(center.lng))

        searchedLocation = this.getSearchedLocation(newLatLng)
      }
    }
    mapData.center = center

    this.setState({
      storeFormDetails,
      isFetching: false,
      storeGeoLocation: geoLocation,
      streetAddressText: storeDetails.addressLine1,
      searchedLocationText: searchedLocation
    })
  }

  UNSAFE_componentWillUnmount = () => {
    this.unblock && this.unblock()
  }

  getCatalogs = () => {
    getCatalogue(this.props.client, this.org_id)
      .then(catalogsResponse => {
        const { catalogs } = catalogsResponse.data
        const isCatalogsAvailable = catalogs.length > 0

        this.setState({
          catalogs: isCatalogsAvailable ? catalogs : []
        })
        const userDetails = getUserDetails()

        userDetails.catalogs = catalogs
        setUserDetails(userDetails)
      })
      .catch(err => {
        console.error('Catalogs Error:', err)
      })
  }

  goToTargetLocation = () => {
    localStorage.removeItem('isUnsavedStoreDetails')
    this.setState({ isUnsavedChanges: false })
    if (this.unblock) this.unblock()
    if (this.targetLocation.pathname === '/orderx/onboarding') {
      this.goBackToOnboarding()
    } else {
      this.props.history.push({ pathname: this.targetLocation.pathname })
    }
  }

  onLocationChange = (storeLocation, searchedLocationText, streetAddressText, address_components) => {
    const { mapData } = this.state
    const geoLoc = `POINT(${storeLocation.lat} ${storeLocation.lng})`
    const mData = { ...mapData }

    mData.center = { lat: storeLocation.lat, lng: storeLocation.lng }
    mData.polygonArray = []
    this.setState({
      searchedLocationText,
      streetAddressText,
      storeGeoLocation: geoLoc,
      mapData: mData,
      address_components
    })
    isUnSavedStore()
    this.toggleLocationPopup('')
  }

  goBackToOnboarding = () => {
    const { roles, createdStore } = this.state
    const user = JSON.parse(localStorage.getItem('userData'))

    if (createdStore && createdStore.id) user.store = [createdStore]
    localStorage.removeItem('isOnboarding')
    localStorage.setItem('userData', JSON.stringify(user))
    this.props.history.push({
      pathname: '/orderx/onboarding',
      state: {
        isOnboarding: false,
        roles,
        store: user.store,
        catalogs: user.catalogs,
        fromStoreOrCatalog: true
      }
    })
  }

  getAllStore() {
    let { stores } = this.state

    getAllStores(this.props.client, this.org_id)
      .then(allStoresResponse => {
        stores = allStoresResponse.data.stores.data
        if (this.urlDetails[this.urlDetails.length - 1] === 'edit') {
          stores = stores.filter(store => store.id !== this.urlDetails[3])
        }
        stores = stores.map(store => store.name.toLocaleLowerCase())
        this.setState({ stores })
      })
      .catch(err => {
        console.warn('Store List Error:', err)
      })
  }

  saveChanges = async () => {
    const validationResult = await this.onValidateFields()

    if (!validationResult) {
      if (this.FormType === 'edit') {
        this.onSaveEditing()
      } else {
        this.createStore()
      }
    }
  }

  createStore = () => {
    if (this.isBMSDealsOnlyView) {
      this.createBMSStore()

      return
    }
    this.setState({ saveStoreDetails: true })
    const channelCodes = this.channels.map(channel => channel.channelCode)
    const { storeFormDetails, mapData, createdStore, searchedLocationText, streetAddressText, address_components, catalogs, serviceabilityRadius, deliveryDiscount, deliveryDiscountStatus } = this.state

    if (createdStore && createdStore.id) {
      this.addStoreDetails()
    } else {
      const address = getCityStateCountryZip(address_components, searchedLocationText)
      const createStoreInput = {
        name: storeFormDetails['storeName'],
        organizationId: this.org_id,
        storeFormatCode: ORG_CONSTANT.STOREFORMAT,
        catalogCode: catalogs[0].catalogCode,
        channelCodes,
        latitude: `${mapData.center.lat}`,
        longitude: `${mapData.center.lng}`,
        addressLine1: streetAddressText,
        addressLine2: searchedLocationText,
        serviceAreaValue: (Number(serviceabilityRadius) * 1000).toString(),
        serviceAreaType: RADIUS,
        ...address
      }

      createStore(this.props.client, createStoreInput)
        .then(createStoreResponse => {
          const createdStoreData = createStoreResponse.data.createStore

          this.updateDeliveryDiscount(deliveryDiscount, deliveryDiscountStatus, createdStoreData)
          const userDetails = getUserDetails()

          userDetails.store = [createdStoreData]
          setUserDetails(userDetails)
          this.setState({ createdStore: createdStoreData }, () => this.addStoreDetails())
        })
        .catch(e => {
          this.setState({ saveStoreDetails: false })
          console.log(e, 'createStore error')
        })
    }
  }

  createBMSStore = async () => {
    const { storeFormDetails, mapData, roles, searchedLocationText, streetAddressText, address_components, catalogs } = this.state
    const address = getCityStateCountryZip(address_components, searchedLocationText)
    const { userName, password, phone, storeName }: any = storeFormDetails
    const channelCodes = this.channels.map(channel => channel.channelCode)
    const createStoreInput = {
      name: storeName,
      organizationId: this.org_id,
      storeFormatCode: ORG_CONSTANT.STOREFORMAT,
      catalogCode: catalogs[0].catalogCode,
      latitude: `${mapData.center.lat}`,
      longitude: `${mapData.center.lng}`,
      channelCodes,
      addressLine1: streetAddressText,
      addressLine2: searchedLocationText,
      ...address
    }
    const role_id = roles.filter(role => role.name === ROLE_TYPE.MANAGER)[0].id
    const addUserToOrganisationInput = {
      organization_id: this.org_id,
      role_id,
      userData: {
        userName,
        firstName: userName,
        password
      }
    }

    const addStaffInput = {
      staff_role: ROLE_TYPE.STORE_MANAGER,
      name: userName,
      phone: formatMobileNumber(phone)
    }

    this.setState({ saveStoreDetails: true })
    try {
      const createdStoreResponse = await createStore(this.props.client, createStoreInput)
      const createdStore = createdStoreResponse.data.createStore
      const storeId = createdStore.id

      // Create Store Manager
      await Promise.all([
        addUserToOrganiser(this.props.client, addUserToOrganisationInput)
          .then(addUserToOrganisationResponse => {

            const userId = addUserToOrganisationResponse.data.addUserToOrganization.id

            addUserToStore(this.props.client, userId, storeId)
          }),
        createStaff(this.props.client, addStaffInput)
          .then(addStaffResponse => {

            const addStaffMemberToStoreInput = {
              staffMemberId: addStaffResponse.data.addStaff.id,
              storeId
            }

            addStaffMemberToStore(this.props.client, addStaffMemberToStoreInput)
          })
      ])
    } catch (err) {
      console.log(err, 'createStore error')
    } finally {
      this.setState({ saveStoreDetails: false })
      localStorage.removeItem('isUnsavedStoreDetails')
      this.props.history.push('/orderx/store-list')
    }

  }

  updateBMSStore = async () => {
    const { storeFormDetails, mapData, createdStore, searchedLocationText, streetAddressText, address_components, editPassword } = this.state
    const address = getCityStateCountryZip(address_components, searchedLocationText)
    const { password, phone, storeName, managerId, storeManagerId }: any = storeFormDetails


    const updateStoreInput = {
      id: this.urlDetails[3],
      name: storeName,
      organizationId: this.org_id,
      latitude: `${mapData.center.lat}`,
      longitude: `${mapData.center.lng}`,
      addressLine1: streetAddressText,
      addressLine2: searchedLocationText,
      ...address
    }
    const editStaffInput = {
      id: storeManagerId,
      phone: formatMobileNumber(phone),
      staff_role: ROLE_TYPE.STORE_MANAGER
    }

    this.setState({ saveStoreDetails: true })
    try {
      await Promise.all([
        updateStore(this.props.client, updateStoreInput),
        updateDeliveryAgent(this.props.client, editStaffInput)
      ])
      if (editPassword) {
        const updateUserPasswordInput = {
          userId: managerId,
          password
        }

        const updateUserResponse = await updateUserPassword(this.props.client, updateUserPasswordInput)

        if (updateUserResponse.data.updateUserPassword.updated) {
          message.success(
            intlAlertMessage({
              id: 'store.message.password.update.success',
              intl: this.props.intl
            })
          )
        }

      }
    } catch (err) {
      console.log('Update store Error', err)
    } finally {
      this.setState({ saveStoreDetails: false })
      localStorage.removeItem('isUnsavedStoreDetails')
      this.props.history.push('/orderx/store-list')
    }
  }

  addServiceabilityArea = val => {
    this.setState({
      serviceabilityRadius: val
    })
  }

  addStoreDetails = () => {
    const { storeFormDetails, mapData, roles, createdStore } = this.state
    const role_id = roles.filter((role, roleIndex) => role.name === ROLE_TYPE.MANAGER)[0].id
    const addUserToOrganisationInput = {
      organization_id: this.org_id,
      role_id,
      userData: {
        userName: storeFormDetails['userName'],
        firstName: storeFormDetails['userName'],
        password: storeFormDetails['password']
      }
    }
    const addStaffInput = {
      staff_role: ROLE_TYPE.STORE_MANAGER,
      name: storeFormDetails['userName'],
      phone: formatMobileNumber(storeFormDetails['phone']),
      whatsappSupport: storeFormDetails['whatsappSupport']
    }
    const storeId = createdStore.id

    addUserToOrganiser(this.props.client, addUserToOrganisationInput)
      .then(addUserToOrganisationResponse => {
        const userId = addUserToOrganisationResponse.data.addUserToOrganization.id

        addUserToStore(this.props.client, userId, storeId)
          .then(addUserToStoreRes => {
            createStaff(this.props.client, addStaffInput)
              .then(addStaffResponse => {
                console.log(addStaffResponse, '-')
                const addStaffMemberToStoreInput = {
                  staffMemberId: addStaffResponse.data.addStaff.id,
                  storeId
                }

                addStaffMemberToStore(this.props.client, addStaffMemberToStoreInput)
                  .then(addStaffMemberToStoreResponse => {
                    this.addStoreTiming(storeId)
                    if (this.state.fleetOption === 'yes') {
                      const addDeliveryAreaInput = {
                        organizationId: this.org_id,
                        deliveryAreaType: 'GEO_AREA',
                        deliveryAreaValue: JSON.stringify(mapData.polygonGeoJSON.area),
                        storeId
                      }

                      addDeliveryArea(this.props.client, addDeliveryAreaInput)
                        .then(addDeliveryAreaResponse => {
                          this.addDeliveryCharge(storeId)
                          this.addDeliveryAgentToStore(storeId)
                          this.setState({ saveStoreDetails: false })
                          localStorage.removeItem('isUnsavedStoreDetails')
                          if (this.isOnboarding) {
                            this.goBackToOnboarding()
                          } else {
                            this.props.history.push('/orderx/store-list')
                          }
                        })
                        .catch(e => {
                          this.setState({ saveStoreDetails: false })
                          console.log(e, 'addDeliveryAreaResponse error')
                        })
                    } else {
                      this.setState({ saveStoreDetails: false })
                      localStorage.removeItem('isUnsavedStoreDetails')
                      if (this.isOnboarding) {
                        this.goBackToOnboarding()
                      } else {
                        this.props.history.push('/orderx/store-list')
                      }
                    }
                  })
                  .catch(e => {
                    this.setState({ saveStoreDetails: false })
                    console.log(e, 'addStaffMemberToStoreResponse error')
                  })
              })
              .catch(e => {
                this.setState({ saveStoreDetails: false })
                console.log(e, 'addStaffResponse error')
              })
          })
          .catch(e => {
            this.setState({ saveStoreDetails: false })
            console.log(e, 'addUserToStoreResponse error')
          })
      })
      .catch(e => {
        this.setState({ saveStoreDetails: false })
        console.log(e, 'addUserToOrganisationResponse error')
      })
  }

  onSaveEditing = async () => {
    if (this.isBMSDealsOnlyView) {
      this.updateBMSStore()

      return
    }
    const { storeFormDetails, mapData, roles, streetAddressText, searchedLocationText, address_components, editPassword, serviceabilityRadius, storeServiceAreaId } = this.state
    const address = getCityStateCountryZip(address_components, searchedLocationText)

    this.setState({ saveStoreDetails: true })
    const updateStoreInput = {
      id: this.urlDetails[3],
      name: storeFormDetails['storeName'],
      organizationId: this.org_id,
      latitude: `${mapData.center.lat}`,
      longitude: `${mapData.center.lng}`,
      addressLine1: streetAddressText,
      addressLine2: searchedLocationText,
      ...address
    }
    const editStaffInput = {
      id: storeFormDetails['storeManagerId'],
      phone: formatMobileNumber(storeFormDetails['phone']),
      staff_role: ROLE_TYPE.STORE_MANAGER,
      whatsappSupport: storeFormDetails['whatsappSupport'],
    }
    const updateStoreServiceAreaInput = {
      id: storeServiceAreaId,
      organizationId: this.org_id,
      serviceAreaValue: (Number(serviceabilityRadius) * 1000).toString(),
      serviceAreaType: RADIUS
    }
    let updateServiceAreaRes

    try {
      updateServiceAreaRes = await updateStoreServiceArea(this.props.client, updateStoreServiceAreaInput)
    } catch (err) {
      console.log('Update service area error', err)
      this.setState({ saveStoreDetails: false })
    }

    if (!(updateServiceAreaRes && updateServiceAreaRes.data && updateServiceAreaRes.data.updateStoreServiceArea)) {
      return
    }

    updateStore(this.props.client, updateStoreInput).then(updateStoreResponse => {
      if (this.state.fleetOption === 'yes') {
        if (mapData.polygonGeoJSON.id) {
          const updateDeliveryAreaInput = {
            id: mapData.polygonGeoJSON.id,
            organizationId: this.org_id,
            deliveryAreaType: 'GEO_AREA',
            deliveryAreaValue: JSON.stringify(mapData.polygonGeoJSON.area)
          }

          updateDeliveryArea(this.props.client, updateDeliveryAreaInput).then(updateDeliveryAreaResponse => {
            this.setState({ saveStoreDetails: false })
            localStorage.removeItem('isUnsavedStoreDetails')
            this.props.history.push('/orderx/store-list')
          })
        } else {
          const addDeliveryAreaInput = {
            organizationId: this.org_id,
            deliveryAreaType: 'GEO_AREA',
            deliveryAreaValue: JSON.stringify(mapData.polygonGeoJSON.area),
            storeId: this.urlDetails[3]
          }

          addDeliveryArea(this.props.client, addDeliveryAreaInput).then(addDeliveryAreaResponse => {
            this.setState({ saveStoreDetails: false })
            localStorage.removeItem('isUnsavedStoreDetails')
            this.props.history.push('/orderx/store-list')
          })
        }
      } else {
        this.setState({ saveStoreDetails: false })
        localStorage.removeItem('isUnsavedStoreDetails')
        this.props.history.push('/orderx/store-list')
      }
      if (!storeFormDetails['managerId']) {
        const addStaffInput = {
          staff_role: ROLE_TYPE.STORE_MANAGER,
          name: storeFormDetails['userName'],
          phone: formatMobileNumber(storeFormDetails['phone']),
          whatsappSupport: storeFormDetails['whatsappSupport']
        }

        createStaff(this.props.client, addStaffInput).then(addStaffResponse => {
          const addStaffMemberToStoreInput = {
            staffMemberId: addStaffResponse.data.addStaff.id,
            storeId: this.urlDetails[3]
          }

          addStaffMemberToStore(this.props.client, addStaffMemberToStoreInput).then(addStaffMemberToStoreResponse => {
            this.setState({ saveStoreDetails: false })
          })
        })
      } else if (storeFormDetails['phone']) {
        updateDeliveryAgent(this.props.client, editStaffInput).then(updateStaffResponse => {
          this.setState({ saveStoreDetails: false })
        })
      }

      if (!storeFormDetails['managerId']) {
        const role_id = roles.filter((role, roleIndex) => role.name === ROLE_TYPE.MANAGER)[0].id
        const addUserToOrganisationInput = {
          organization_id: this.org_id,
          role_id,
          userData: {
            userName: storeFormDetails['userName'],
            firstName: storeFormDetails['userName'],
            password: storeFormDetails['password']
          }
        }

        addUserToOrganiser(this.props.client, addUserToOrganisationInput)
          .then(addUserToOrganisationResponse => {
            const userId = addUserToOrganisationResponse.data.addUserToOrganization.id

            addUserToStore(this.props.client, userId, this.urlDetails[3])
              .then(addUserToStoreRes => {
                this.setState({ saveStoreDetails: false })
              })
              .catch(e => {
                this.setState({ saveStoreDetails: false })
              })
          })
          .catch(e => {
            this.setState({ saveStoreDetails: false })
          })
      } else if (editPassword) {
        const updateUserPasswordInput = {
          userId: storeFormDetails['managerId'],
          password: storeFormDetails['password']
        }

        updateUserPassword(this.props.client, updateUserPasswordInput)
          .then(updateUserResponse => {
            this.setState({ saveStoreDetails: false })
            if (updateUserResponse.data.updateUserPassword.updated) {
              message.success(
                intlAlertMessage({
                  id: 'store.message.password.update.success',
                  intl: this.props.intl
                })
              )
            }
          })
          .catch(e => {
            console.log('Update store manager password error', e)
          })
      }
    })
    this.addStoreTiming(this.urlDetails[3])
    if (this.state.fleetOption === 'yes') {
      this.addDeliveryAgentToStore(this.urlDetails[3])
      this.addDeliveryCharge(this.urlDetails[3])
    } else {
      this.setState({ deliveryChargePerKm: '0' }, () => {
        this.deleteAgentDetailsAndDeliveryArea()
      })
    }
  }

  addDeliveryCharge = storeId => {
    const { deliveryChargePerKm, deliveryChargeId } = this.state

    if (!storeId || !deliveryChargePerKm) {
      return
    }
    const deliveryChargeInput = {
      chargeType: StoreChargeTypeEnum.DELIVERY_CHARGE,
      chargeValueType: 'ABSOLUTE',
      storeId,
      chargeValue: parseFloat(deliveryChargePerKm)
    }

    storeCharges(this.props.client, deliveryChargeId, deliveryChargeInput)
  }

  addDeliveryAgentToStore = storeId => {
    let { agentDetails } = this.state

    agentDetails = agentDetails.filter(agent => !agent.id)
    const staffDetails = agentDetails.map(agent => {
      return {
        staff_role: agent.staff_role,
        name: agent.name,
        phone: formatMobileNumber(`${agent.phone}`)
      }
    })

    if (!staffDetails.length) return
    createBulkStaffMembers(this.props.client, staffDetails)
      .then(createBulkStaffResponse => {
        const addStaffMembersToStoreInput = {
          storeId,
          staffMemberIds: createBulkStaffResponse.data.addBulkStaffMembers.map(staff => staff.id)
        }

        addStaffMembersToStore(this.props.client, addStaffMembersToStoreInput)
      })
      .catch(error => {
        console.log(error)
      })
  }

  onChange = (type: string, value: any, isFromDeliveryCDM?: boolean) => {
    if (!isFromDeliveryCDM) {
      isUnSavedStore()
    }
    this.setState((prevState: Readonly<iState>, props: Readonly<iProps>) => {
      return {
        ...prevState,
        [type]: value
      }
    })
  }

  toggleAddAgent = () => {
    let { deliveryAgentFormDetails, isAddAgent } = this.state

    deliveryAgentFormDetails = {
      name: '',
      phone: ''
    }
    isAddAgent = !this.state.isAddAgent
    this.setState({
      isAddAgent,
      deliveryAgentFormDetails
    })
  }

  onAgentFieldChange = (type: string, value: any) => {
    this.setState((prevState: Readonly<iState>, props: Readonly<iProps>) => {
      return {
        ...prevState,
        [type]: value
      }
    })
  }

  editButtonFormAction = editIndex => {
    return (
      <Button
        type="link"
        onClick={() => {
          this.editDeliveryAgentHandler(editIndex)
        }}>
        <IntlMessages id="store.edit" />
      </Button>
    )
  }

  deliveryAgentStatusAction = (activeStatus, deleteIndex) => {
    return (
      <Switch
        checked={activeStatus === 'ACTIVE'}
        onChange={status => {
          this.onStoreRiderStatusChanged(status, deleteIndex)
        }}
      />
    )
  }

  deleteButtonFormAction = (index) => {
    return (
      <Button
        type="link"
        style={{ color: COLORS.DARK_TEXT }}
        onClick={() => {
          this.onAgentFieldChange('showDeleteAgentDialog', true)
          this.onAgentFieldChange('deleteAgentIndex', index)
        }}>
        <IntlMessages id="button.delete" />
      </Button>
    )
  }


  buildDeliveryAgentColumnData = () => {
    const { agentDetails } = this.state
    const columnOrders = agentDetails.map((agent, index) => {

      const editButtonAction = this.editButtonFormAction(index)
      const deleteButtonAction = this.deleteButtonFormAction(index)
      const activeStatusAction = this.deliveryAgentStatusAction(agent.status, index)

      return {
        deleteIndex: index,
        name: agent.name,
        phone: agent.phone,
        activeStatusAction,
        editButtonAction,
        deleteButtonAction,
      }
    })

    return columnOrders
  }

  addAgentsHandler = () => {
    const { agentDetails, deliveryAgentFormDetails, isAddAgent } = this.state

    if (deliveryAgentFormDetails['name'] === '' || deliveryAgentFormDetails['phone'] === '') {
      setErrMsg('category.errMsg.fillAllTheFields', this.props.intl)

      return
    } else if (!PHONE_NUMBER_REGEX.test(deliveryAgentFormDetails['phone'])) {
      setErrMsg('store.validMobileNum', this.props.intl)

      return
    }
    const newAgent = {
      staff_role: 'DELIVERY',
      name: deliveryAgentFormDetails['name'],
      phone: deliveryAgentFormDetails['phone'],
      status: 'ACTIVE'
    }

    agentDetails.push(newAgent)

    isUnSavedStore()
    this.setState({
      agentDetails, isAddAgent: !isAddAgent, deliveryAgentFormDetails: {
        name: '',
        phone: ''
      },
      isAgentDetailsModalVisible: !this.state.isAgentDetailsModalVisible
    })
  }

  editDeliveryAgentHandler = agentIndex => {
    const { editingAgentDetailsIndex, agentDetails, isAgentDetailsModalVisible } = this.state

    if (editingAgentDetailsIndex !== null) {
      const deliveryAgent = {
        name: agentDetails[agentIndex].name,
        phone: getTenDigitNumber(agentDetails[agentIndex].phone)
      }

      if (agentDetails[agentIndex].id) {
        deliveryAgent['id'] = agentDetails[agentIndex].id
      }
      else {
        deliveryAgent['staff_role'] = 'DELIVERY'
      }
      isUnSavedStore()
      this.setState({
        deliveryAgentFormDetails: deliveryAgent,
        isAddAgent: true,
        editingAgentDetailsIndex: agentIndex,
        isAgentDetailsModalVisible: !isAgentDetailsModalVisible
      })
    } else {
      this.onChange('editingAgentDetailsIndex', agentIndex)
    }
  }

  updateAgentsHandler = agentId => {
    const { editingAgentDetailsIndex, agentDetails, deliveryAgentFormDetails } = this.state
    const agentIndex = agentDetails.findIndex(agent => agent.id === agentId)

    if (deliveryAgentFormDetails['name'] === '' || deliveryAgentFormDetails['phone'] === '') {
      setErrMsg('category.errMsg.fillAllTheFields', this.props.intl)
    } else if (!PHONE_NUMBER_REGEX.test(deliveryAgentFormDetails['phone'])) {
      setErrMsg('store.validMobileNum', this.props.intl)
    } else if (agentDetails[agentIndex].id) {
      const editDeliveryAgentInput = {
        id: agentDetails[agentIndex].id,
        name: deliveryAgentFormDetails['name'],
        phone: formatMobileNumber(`${deliveryAgentFormDetails['phone']}`)
      }

      this.setState({ isUpdatingAgent: true })
      updateDeliveryAgent(this.props.client, editDeliveryAgentInput)
        .then(editDeliveryAgentResponse => {
          agentDetails[agentIndex] = editDeliveryAgentResponse.data.editStaff
          this.setState(
            {
              agentDetails,
              editingAgentDetailsIndex,
              isAddAgent: false,
              isUpdatingAgent: false,
              isAgentDetailsModalVisible: false
            },
            () => this.toggleAddAgent()
          )
        })
        .catch(e => {
          this.setState({ isUpdatingAgent: false, isAgentDetailsModalVisible: false })
          console.log(e)
        })
    } else {
      const createDeliveryAgentInput = {
        staff_role: 'DELIVERY',
        name: agentDetails[editingAgentDetailsIndex].name,
        phone: agentDetails[editingAgentDetailsIndex].phone
      }

      agentDetails[editingAgentDetailsIndex] = createDeliveryAgentInput
      isUnSavedStore()
      this.setState({ agentDetails, editingAgentDetailsIndex, isAddAgent: false }, () => this.toggleAddAgent())
    }
  }

  deleteAgent = agentIndex => {
    const { agentDetails, editingAgentDetailsIndex } = this.state

    if (agentDetails[agentIndex].id) {
      const removeDeliveryAgentInput = {
        id: agentDetails[agentIndex].id
      }

      onDeleteAgent(this.props.client, removeDeliveryAgentInput)
        .then(removeDeliveryAgentResponse => {
          agentDetails.splice(agentIndex, 1)
          this.setState({ agentDetails })
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      isUnSavedStore()
      agentDetails.splice(agentIndex, 1)
    }
    let editAgentIndex = editingAgentDetailsIndex

    if (editingAgentDetailsIndex === agentIndex) {
      editAgentIndex = 0
    } else if (editingAgentDetailsIndex > agentIndex) {
      editAgentIndex -= 1
    }
    this.setState({
      agentDetails,
      editingAgentDetailsIndex: editAgentIndex,
      isAddAgent: false,
      showDeleteAgentDialog: false
    })
  }

  toggleAddSlot = day => {
    const { showAddSlotDialog, storeTiming, storeFormDetails } = this.state

    storeTiming.days = day
    storeTiming.data = []

    storeFormDetails['openTime'] = moment(padWithZeroes(openTime), HH_MM_FORMAT)
    storeFormDetails['closeTime'] = moment(padWithZeroes(closeTime), HH_MM_FORMAT)
    this.setState({
      showAddSlotDialog: !showAddSlotDialog,
      storeTiming,
      storeFormDetails,
      isNewTimeSlot: true,
      isApplyAllDays: false
    })
  }

  closeAddSlotModal = () => {
    this.setState({
      showAddSlotDialog: false,
      showDeleteSlotDialog: false
    })
  }

  onCancelAddSlotDialog = () => {
    this.setState({ showAddSlotDialog: false })
  }

  // NOT USED
  onDayClickHandler = selectedDay => {
    const { days, daysLength } = this.state

    const selectedDayIndex = days.findIndex(day => day.name === selectedDay)

    if (!days[selectedDayIndex].isActive) {
      days[selectedDayIndex].isActive = true
      this.setState({ days, daysLength: daysLength + 1, selectedDay })
    }
    else {
      this.setState({ selectedDay, isDayClose: true, showDeleteSlotDialog: true })
    }
  }

  // NOT USED
  closeDay = () => {
    const { days, daysLength, selectedDay } = this.state

    const newDaysLength = daysLength - 1

    days.forEach((day, i) => {
      if (day.name === selectedDay) {
        const { isActive } = days[i]

        if (isActive) {
          days[i].isActive = false
          day.data.forEach((slot, j) => {
            if (slot.id) {
              const removeStoreTimingInput = {
                storeId: this.urlDetails[3],
                id: slot.id,
                organizationId: this.org_id
              }

              this.deleteSlotWithId(removeStoreTimingInput)
            } else {
              let { storeTiming } = this.state
              const slotTime = []

              slotTime.push(slot)
              const slotData = {
                days: day.name,
                data: slotTime
              }

              storeTiming = slotData
              this.setState({ storeTiming }, () => {
                this.deleteSlotWithoutId()
              })
            }
          })
          this.setState({ daysLength: newDaysLength, isDayClose: false, days, showDeleteSlotDialog: false })
        }
      }
    })
  }

  onUpdateSlotClickHandler = () => {
    const { storeTiming, storeTimings, storeFormDetails, days, selectedSlotIndex } = this.state
    const data = {
      openTime: Number(storeFormDetails['openTime'].format(HH_MM_FORMAT)),
      closeTime: Number(storeFormDetails['closeTime'].format(HH_MM_FORMAT))
    }

    if (data.openTime === data.closeTime) {
      setErrMsg('store.errMsg.openTimeAndCloseTimeShouldNotBeSame', this.props.intl)
    } else if (data.closeTime < data.openTime) {
      setErrMsg('store.errMsg.closeTimeShouldGreaterThemOpenTime', this.props.intl)
    } else {
      let isDuplicateSlot = false
      let isSlotOverlap = false

      days.forEach((day, i) => {
        if (storeTiming.days === day.name) {
          const slots = day.data.filter((d, k) => k !== selectedSlotIndex)

          slots.forEach((slot, j) => {
            if (slot.openTime === data.openTime && slot.closeTime === data.closeTime) {
              isDuplicateSlot = true

              return
            }
            if ((data.openTime >= slot.openTime && data.openTime <= slot.closeTime) || (data.closeTime >= slot.openTime && data.closeTime <= slot.closeTime) || (data.openTime <= slot.openTime && data.closeTime >= slot.closeTime)) {
              isSlotOverlap = true
            }
          })
        }
      })
      if (!isDuplicateSlot && !isSlotOverlap) {
        if (storeTiming.data[0].id) {
          const removeStoreTimingInput = {
            storeId: this.urlDetails[3],
            id: storeTiming.data[0].id,
            organizationId: this.org_id
          }

          removeStoreTimings(this.props.client, removeStoreTimingInput).then(removeStoreTimingResponse => {
            const slotData = {
              days: storeTiming.days,
              data: [data]
            }
            const addStoreTimingInput = {
              organizationId: this.org_id,
              storeId: this.urlDetails[3],
              storeTimings: [slotData]
            }

            addBulkStoreOpenTiming(this.props.client, addStoreTimingInput)
              .then(addStoreTimingResponse => {
                const addStoreOpenTiming = addStoreTimingResponse.data.addBulkStoreOpenTiming[0]
                const resDayName = JSON.parse(addStoreOpenTiming.days)[0]
                const storeResTimingSlot = {
                  id: addStoreOpenTiming.data[0].id,
                  openTime: addStoreOpenTiming.data[0].openTime,
                  closeTime: addStoreOpenTiming.data[0].closeTime
                }
                let dayIndex
                let slotIndex

                days.forEach((day, i) => {
                  const dayName = day.name

                  if (resDayName === dayName) {
                    dayIndex = i
                    slotIndex = day.data.findIndex(data => data.id && data.id === removeStoreTimingInput.id)
                  }
                })
                days[dayIndex].data.splice(slotIndex, 1, storeResTimingSlot)
                this.setState({ days, storeFormDetails, storeTimings })
              })
              .catch(err => console.log(err))
          })
        } else {
          let slotIndex = -1
          let dayIndex = -1
          let storeTimingsSlotIndex = -1
          let storeTimingsDayIndex = -1

          days.forEach((day, i) => {
            if (storeTiming.days === day.name) {
              dayIndex = i
              day.data.forEach((slot, j) => {
                const storeTimingSlot = storeTiming.data[0]

                if (slot === storeTimingSlot) {
                  slotIndex = j
                }
              })
            }
          })

          storeTimings.forEach((storeTime, i) => {
            if (storeTime.days === storeTiming.days) {
              storeTimingsDayIndex = i
              storeTime.data.forEach((slot, j) => {
                const storeTimingSlot = storeTiming.data[0]

                if (slot === storeTimingSlot) {
                  storeTimingsSlotIndex = j
                }
              })
            }
          })
          days[dayIndex].data[slotIndex].openTime = data.openTime
          days[dayIndex].data[slotIndex].closeTime = data.closeTime
          storeTimings[storeTimingsDayIndex].data[storeTimingsSlotIndex].openTime = data.openTime
          storeTimings[storeTimingsDayIndex].data[storeTimingsSlotIndex].closeTime = data.closeTime
          storeFormDetails['openTime'] = null
          storeFormDetails['closeTime'] = null
          isUnSavedStore()
          this.setState({ days, storeFormDetails, storeTimings })
        }
        this.closeAddSlotModal()
      } else if (isDuplicateSlot) {
        message.error(intlAlertMessage({ id: 'slotAlreadyExists', intl: this.props.intl }, { slot: storeTiming.days.toLowerCase() }))
      } else if (isSlotOverlap) {
        setErrMsg('store.errMsgOverlappingStore', this.props.intl)
      }
    }
  }

  onAddSlotCreation = () => {
    const { storeTiming, storeTimings, storeFormDetails, days, isApplyAllDays } = this.state
    const data = {
      openTime: Number(storeFormDetails['openTime'].format(HH_MM_FORMAT)),
      closeTime: Number(storeFormDetails['closeTime'].format(HH_MM_FORMAT))
    }

    if (data.openTime === data.closeTime) {
      setErrMsg('store.errMsg.openTimeAndCloseTimeShouldNotBeSame', this.props.intl)
    } else if (data.closeTime < data.openTime) {
      setErrMsg('store.errMsg.closeTimeShouldGreaterThemOpenTime', this.props.intl)
    } else {
      storeTiming.data.push(data)
      const slotData = {
        days: storeTiming.days,
        data: storeTiming.data
      }
      let dayIndex = -1
      let slotIndex = -1
      let isDuplicateSlot = false
      let isSlotOverlap = false
      const duplicateSlotArr = []

      days.forEach((day, i) => {
        if (slotData.days === day.name || isApplyAllDays) {
          day.data.forEach((slot, j) => {
            if (slot.openTime === data.openTime && slot.closeTime === data.closeTime) {
              duplicateSlotArr.push(day)
              isDuplicateSlot = true

              return
            }
            if ((data.openTime >= slot.openTime && data.openTime <= slot.closeTime) || (data.closeTime >= slot.openTime && data.closeTime <= slot.closeTime) || (data.openTime <= slot.openTime && data.closeTime >= slot.closeTime)) {
              isSlotOverlap = true
            }
          })
          slotIndex = i
        }
      })

      if (!isDuplicateSlot && !isSlotOverlap) {
        if (isApplyAllDays) {
          days.forEach(day => {
            day.data.push(data)
          })
        } else {
          days[slotIndex].data.push(data)
        }
        if (isApplyAllDays) {
          if (storeTimings.length > 0) {
            days.forEach(day => {
              let matchedDayIndex = -1

              storeTimings.forEach((storeTime, i) => {
                if (day.name === storeTime.days) {
                  matchedDayIndex = i
                }
              })
              if (matchedDayIndex !== -1) {
                const sltData = [...storeTimings[matchedDayIndex].data]

                sltData.push(data)
                storeTimings[matchedDayIndex].data = sltData
              } else {
                const slData = { ...slotData }

                slData.days = day.name
                storeTimings.push(slData)
              }
            })
          } else {
            days.forEach(day => {
              const slData = { ...slotData }

              slData.days = day.name
              storeTimings.push(slData)
            })
          }
        } else if (storeTimings.length > 0) {
          storeTimings.forEach((storeTime, i) => {
            if (storeTime.days === storeTiming.days) {
              dayIndex = i
            }
          })
          if (dayIndex !== -1) {
            const sltData = [...storeTimings[dayIndex].data]

            sltData.push(data)
            storeTimings[dayIndex].data = sltData
          } else {
            storeTimings.push({ ...slotData })
          }
        } else {
          storeTimings.push({ ...slotData })
        }

        this.closeAddSlotModal()
        isUnSavedStore()
        this.setState({
          storeTimings,
          days
        })
      } else if (isDuplicateSlot) {
        message.error(intlAlertMessage({ id: 'slotAlreadyExists', intl: this.props.intl }, { slot: duplicateSlotArr.map(val => val.name.toLowerCase()) }))
      } else if (isSlotOverlap) {
        setErrMsg('store.errMsgOverlappingStore', this.props.intl)
      }
    }
  }

  slotOnChangeHandler = (day, slot, slotIndex) => {
    const { showAddSlotDialog, storeFormDetails } = this.state

    const slotTime = []

    slotTime.push(slot)
    const slotData = {
      days: day,
      data: slotTime
    }

    storeFormDetails['openTime'] = moment(padWithZeroes(slot.openTime), HH_MM_FORMAT)
    storeFormDetails['closeTime'] = moment(padWithZeroes(slot.closeTime), HH_MM_FORMAT)
    this.setState({
      showAddSlotDialog: !showAddSlotDialog,
      storeFormDetails,
      storeTiming: slotData,
      isNewTimeSlot: false,
      selectedSlotIndex: slotIndex
    })
  }

  deleteSlotWithId = removeStoreTimingInput => {
    const { days } = this.state
    let slotIndex
    let dayIndex = -1

    removeStoreTimings(this.props.client, removeStoreTimingInput).then(removeStoreTimingResponse => {
      const { removeStoreOpenTiming } = removeStoreTimingResponse.data
      const resDayName = JSON.parse(removeStoreOpenTiming.days)[0].toLowerCase()
      const storeResTimingSlot = {
        openTime: removeStoreOpenTiming.openTime,
        closeTime: removeStoreOpenTiming.closeTime
      }

      days.forEach((day, i) => {
        const dayName = day.name.toLowerCase()

        if (resDayName === dayName) {
          dayIndex = i
          slotIndex = day.data.findIndex(data => data.openTime === storeResTimingSlot.openTime && data.closeTime === storeResTimingSlot.closeTime)
        }
      })

      days[dayIndex].data.splice(slotIndex, 1)
      this.closeAddSlotModal()
      this.setState({ days })
    })
  }

  deleteSlotWithoutId = () => {
    const { storeTiming, storeTimings, storeFormDetails, days } = this.state
    let slotIndex
    let dayIndex = -1
    let storeTimingsSlotIndex = -1
    let storeTimingsDayIndex = -1

    days.forEach((day, i) => {
      if (storeTiming.days === day.name) {
        dayIndex = i
        day.data.forEach((slot, j) => {
          const storeTimingSlot = storeTiming.data[0]

          if (slot === storeTimingSlot) {
            slotIndex = j
          }
        })
      }
    })

    storeTimings.forEach((storeTime, i) => {
      if (storeTime.days === storeTiming.days) {
        storeTimingsDayIndex = i
        storeTime.data.forEach((slot, j) => {
          const storeTimingSlot = storeTiming.data[0]

          if (slot === storeTimingSlot) {
            storeTimingsSlotIndex = j
          }
        })
      }
    })

    days[dayIndex].data.splice(slotIndex, 1)
    storeTimings[storeTimingsDayIndex].data.splice(storeTimingsSlotIndex, 1)
    this.closeAddSlotModal()
    isUnSavedStore()
    this.setState({ days, storeFormDetails, storeTimings })
  }

  onDeleteSlotClickHandler = () => {
    const { storeTiming } = this.state

    if (storeTiming.data[0].id) {
      const removeStoreTimingInput = {
        storeId: this.urlDetails[3],
        id: storeTiming.data[0].id,
        organizationId: this.org_id
      }

      this.deleteSlotWithId(removeStoreTimingInput)
    } else {
      this.deleteSlotWithoutId()
    }
  }

  clearAllSlots = () => {
    this.setState({
      showClearAllSlotsDialog: !this.state.showClearAllSlotsDialog
    })
  }

  onClearSlotHandler = async () => {
    const { days } = this.state

    if (this.urlDetails[3]) {
      const slotsIds = []

      days.forEach(day => {
        day.data.filter(time => time.id && slotsIds.push(time.id))
      })
      const removeStoreTimingInput = {
        storeId: this.urlDetails[3],
        id: slotsIds,
        organizationId: this.org_id
      }

      if (slotsIds.length > 0) {
        try {
          await removeStoreSlotTimings(this.props.client, removeStoreTimingInput)
          this.clearSlots()
        } catch (err) {
          console.log(err)
        }
      } else {
        this.clearSlots()
      }
    } else {
      this.clearSlots()
    }
  }

  clearSlots = () => {
    const { days, storeTimings } = this.state

    days.forEach(val => {
      val.data.splice(0, val.data.length)
    })
    storeTimings.forEach((storeTime, i) => {
      storeTime.data.splice(0, storeTime.data.length)
    })
    this.setState({ days, showClearAllSlotsDialog: false, storeTimings })
  }

  addStoreTiming = storeId => {
    const { storeTimings } = this.state

    storeTimings.forEach((dayStoreTime, index) => {
      storeTimings[index].data = dayStoreTime.data.filter(storeTime => !storeTime.id)
    })
    const filteredStoreTimings = storeTimings.filter(dayStoreTime => dayStoreTime.data.length > 0)
    const addStoreTimingInput = {
      organizationId: this.org_id,
      storeId,
      storeTimings: filteredStoreTimings
    }

    if (!filteredStoreTimings.length) return
    addBulkStoreOpenTiming(this.props.client, addStoreTimingInput)
  }

  isNoSlotAdded = () => {
    let daysWithoutSlot = 0
    const { days, daysLength } = this.state

    days.forEach(day => {
      if (day.data.length === 0) {
        daysWithoutSlot += 1
      }
    })

    return daysWithoutSlot === daysLength
  }

  onValidateFields = async () => {
    const { mapData, storeFormDetails, deliveryChargePerKm, stores, fleetOption, storeGeoLocation, searchedLocationText, agentDetails, address_components, editPassword } = this.state

    if (!storeFormDetails['storeName'] || !mapData.center.lat || !mapData.center.lng) {
      setErrMsg('category.errMsg.fillAllTheFields', this.props.intl)

      return true
    }
    if (!storeGeoLocation || !searchedLocationText) {
      setErrMsg('store.errMsg.setYourStoreLocationFirst', this.props.intl)

      return true
    }
    const address = getCityStateCountryZip(address_components, searchedLocationText)

    if (address && (!address.city || !address.country || !address.pinCode || !address.state)) {
      setErrMsg('store.errMsg.enterValidAddress', this.props.intl)

      return true
    } else if (address && (address.pinCode.length !== 6 || isNaN(Number(address.pinCode)))) {
      setErrMsg('store.errMsg.enterValidPincode', this.props.intl)

      return true
    }
    if (this.FormType !== 'edit') {
      if (!(storeFormDetails['password'] && storeFormDetails['userName'])) {
        setErrMsg('category.errMsg.fillAllTheFields', this.props.intl)

        return true
      }
      if (!USER_NAME_REGEX.test(String(storeFormDetails['userName']).toLowerCase())) {
        setErrMsg('store.errMsg.enterValidUserName', this.props.intl)

        return true
      }
      if (!PASSWORD_REGEX.test(String(storeFormDetails['password']))) {
        setErrMsg('store.errMsg.enterValidPassword', this.props.intl)

        return true
      }
      const userNameCheck = await this.onCheckUsernameAvailability()

      if (!userNameCheck) {
        setErrMsg('store.errMsg.duplicateUserName', this.props.intl)

        return true
      }
    }
    if (editPassword) {
      if (!storeFormDetails['password']) {
        setErrMsg('store.errMsg.enterPassword', this.props.intl)

        return true
      }
      if (!PASSWORD_REGEX.test(String(storeFormDetails['password']))) {
        setErrMsg('store.errMsg.enterValidPassword', this.props.intl)

        return true
      }
    }

    if (storeFormDetails['storeName'] && stores.indexOf(storeFormDetails['storeName'].toLocaleLowerCase()) !== -1) {
      setErrMsg('store.storeNameIsUnique', this.props.intl)

      return true
    }
    if (this.FormType !== 'edit' && !PHONE_NUMBER_REGEX.test(String(storeFormDetails['phone']).toLowerCase())) {
      setErrMsg('store.validMobileNum', this.props.intl)

      return true
    }
    if (this.isBMSDealsOnlyView) return false // Don't check below fields if its a BMS Store

    if (this.FormType !== 'edit' && fleetOption === '') {
      setErrMsg('store.selectDeliveryFleet', this.props.intl)

      return true
    }
    if (fleetOption === 'yes') {
      if (!agentDetails.length) {
        setErrMsg('store.errMsg.oneDeliveryAgentNeeded', this.props.intl)

        return true
      }
      if (!agentDetails.filter(agent => agent.status === 'ACTIVE').length) {
        setErrMsg('store.errMsg.markOneDeliveryAgentActive', this.props.intl)

        return true
      }
      if (!(mapData.polygonArray.length > 1)) {
        setErrMsg('store.errMsg.defineDeliveryArea', this.props.intl)

        return true
      }

      if (!deliveryChargePerKm && parseFloat(deliveryChargePerKm) !== 0) {
        setErrMsg('store.errMsg.enterDeliveryCharge', this.props.intl)

        return true
      } else if (!DELIVERY_CHARGE_REGEX.test(String(deliveryChargePerKm).toLocaleLowerCase())) {
        setErrMsg('store.errMsg.invalidDeliveryCharges', this.props.intl)

        return true
      }
    }
    if (this.isNoSlotAdded()) {
      setErrMsg('store.oneTimeSlotRequired', this.props.intl)

      return true
    }

    return false
  }

  removeDeliveryArea = storeId => {
    const { mapData } = this.state
    const deliveryChargeInput = {
      deliveryAreaId: mapData.polygonGeoJSON.id,
      storeId,
      organizationId: this.org_id
    }

    removeDeliveryArea(this.props.client, deliveryChargeInput).then(res => {
      mapData.polygonArray = []
      mapData.polygonGeoJSON = {}
    })
  }

  onFleetChange = e => {
    const { mapData, agentDetails, deliveryChargePerKm } = this.state

    if (e.target.value === 'yes' && mapData.center && mapData.center.lat && mapData.center.lng) {
      this.setState({ fleetOption: e.target.value, deliveryChargePerKm })
      isUnSavedStore()
    } else if (e.target.value === 'no') {
      this.setState({ fleetOption: e.target.value })
      if ((agentDetails && agentDetails.length > 0) || this.urlDetails[3]) {
        this.setState({ showDeliveryFleet: true })
      }
    } else {
      setErrMsg('store.setYourLocationFirst', this.props.intl)
    }
  }

  toggleLocationPopup = e => {
    const { showLocationModal } = this.state

    if (showLocationModal && e && e.currentTarget && e.currentTarget.className && e.currentTarget.className.trim() === 'ant-modal-wrap') {
      return
    }
    this.setState({ showLocationModal: !showLocationModal })
  }

  onCancelDeliverFleet = () => {
    const { deliveryChargePerKm } = this.state

    this.setState({
      showDeliveryFleet: false,
      fleetOption: 'yes',
      deliveryChargePerKm
    })
  }

  deleteAgentDetailsAndDeliveryArea = () => {
    const { agentDetails, mapData } = this.state
    const agentDetailsCopy = [...agentDetails]

    if (mapData.polygonGeoJSON && mapData.polygonGeoJSON.id) {
      this.removeDeliveryArea(this.urlDetails[3])
    }
    if (agentDetails.length > 0) {
      agentDetailsCopy.forEach((agent, index) => {
        if (agent.id) {
          onDeleteAgent(this.props.client, { id: agent.id }).then(res => {
            const indx = agentDetails.findIndex(agent => agent.id === res.data.inactiveStaffMember.id)

            agentDetails.splice(indx, 1)
          })
        } else {
          agentDetails.splice(index, 1)
        }
      })
    }
  }

  deleteBulkActiveAgent = () => {
    this.setState({ showDeliveryFleet: false })
    isUnSavedStore()
  }

  getSearchedLocation = latLng => {
    let searchedLoc = ''

    this.geocoder.geocode(
      {
        location: latLng
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          searchedLoc = results[0]?.formatted_address
        }
      }
    )

    return searchedLoc
  }

  onCheckUsernameAvailability = async () => {
    try {
      const response = await checkUserName(this.props.client, this.state.storeFormDetails)

      return response.data.checkUserNameAvailability
    } catch (err) {
      console.error('Username availability check error')

      return false
    }
  }

  toggleEditPassword = () => {
    const { editPassword } = this.state

    this.setState({ editPassword: !editPassword })
  }

  updateDeliveryDiscountStatus = (val, status) => {
    const newStatus = !status ? defaultDeliveryDiscountStatus.NO : defaultDeliveryDiscountStatus.YES

    this.setState({
      deliveryDiscountStatus: newStatus,
      deliveryDiscount: val
    })
  }

  createOrUpdateStore = async (val, status) => {
    const { storeDetails } = this.state

    this.setState({
      deliveryDiscount: val
    })
    if (val && val >= 3 && val <= 90) {
      if (storeDetails?.code) {
        const newStatus = status ? defaultDeliveryDiscountStatus.YES : defaultDeliveryDiscountStatus.NO

        this.updateDeliveryDiscount(val, newStatus, storeDetails)
      } else {
        this.updateDeliveryDiscountStatus(val, status)
      }
    } else {
      message.error(
        intlAlertMessage({
          id: 'store.deliveryDiscount.ErrMsg',
          intl: this.props.intl
        })
      )
    }
  }

  updateDeliveryDiscount = async (val, status, storeDetails) => {
    const discountInput = {
      wcoreStoreId: storeDetails.id,
      name: storeDetails.name,
      code: storeDetails.code,
      discounts: [
        {
          code: 'DDIS',
          discountValue: Number(val),
          appliedOn: 'Delivery',
          description: '',
          status
        }
      ]
    }
    // CREATE_OR_UPDATE_STORE
    const updateRes = await createUpdatePMSStore(this.pmsClient, discountInput)

    console.log(updateRes, '-updateRes-')
    this.getPMSStoreDetails(storeDetails.id)
  }

  onStoreRiderStatusChanged = async (status, index) => {
    const { agentDetails } = this.state

    const newAgentDetails = {
      staffRole: 'DELIVERY',
      name: agentDetails[index].name,
      phone: agentDetails[index].phone,
      id: agentDetails[index].id,
      status: status ? 'ACTIVE' : 'INACTIVE'
    }

    agentDetails[index] = newAgentDetails
    this.setState({
      agentDetails
    })
    if (agentDetails[index].id) {
      const deliveryAgentInput = {
        id: agentDetails[index].id
      }

      if (status) {
        try {
          const res = await activeDeliveryAgent(this.props.client, deliveryAgentInput)

          console.log(res, 'active staff member')
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const res = await onDeleteAgent(this.props.client, deliveryAgentInput)

          console.log(res, 'status change response')
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  isAgentDetailsModalVisibleHandler = () => {
    const deliveryAgentFormDetails = {
      name: '',
      phone: ''
    }
    
    this.setState({
      isAgentDetailsModalVisible: !this.state.isAgentDetailsModalVisible,
      deliveryAgentFormDetails
    })
  }

  render() {
    const { isFetching, fleetOption, showLocationModal, saveStoreDetails, isUnsavedChanges, storeGeoLocation, editPassword, storeFormDetails } = this.state

    if (isFetching) {
      return <Loader intl={this.props.intl} />
    }

    return (
      <div className="main">
        <Row>
          <Col span={22}>
            <Title level='h4' style={{ margin: '0 25px' }}>
              <Icon
                className="backIcon"
                type="arrow-left"
                onClick={() => {
                  if (this.isOnboarding) {
                    if (localStorage.getItem('isUnsavedStoreDetails')) {
                      this.props.history.push('/orderx/onboarding')
                    }
                    this.goBackToOnboarding()
                  } else {
                    this.props.history.push('/orderx/store-list')
                  }
                }}
              />
              {this.FormType === 'edit' ? storeFormDetails['storeName'] : <IntlMessages id="store.createNewStore" />}
            </Title>
          </Col>
          <Col span={2}>
            <a
              className="menu-help"
              href={STORE_HELP_URL}
              target="_blank"
              rel="noopener noreferrer">
              <IntlMessages id="menu.help" />
            </a>
          </Col>
        </Row>
        <Divider />

        <Row style={{ margin: '0 100px' }}>
          <StoreForm
            isEdit={this.FormType === 'edit'}
            {...this.state}
            {...this.props}
            addServiceabilityArea={this.addServiceabilityArea}
            onChange={this.onChange}
            toggleAddSlot={this.toggleAddSlot}
            onCancelAddSlotDialog={this.onCancelAddSlotDialog}
            onDayClickHandler={this.onDayClickHandler}
            onAddSlotCreation={this.onAddSlotCreation}
            onDeleteSlotClickHandler={this.onDeleteSlotClickHandler}
            onUpdateSlotClickHandler={this.onUpdateSlotClickHandler}
            slotOnChangeHandler={this.slotOnChangeHandler}
            closeDay={this.closeDay}
            isOnboarding={this.isOnboarding}
            goBackToOnboarding={this.goBackToOnboarding}
            onFleetChange={this.onFleetChange}
            toggleLocationPopup={this.toggleLocationPopup}
            onCancelDeliverFleet={this.onCancelDeliverFleet}
            deleteBulkActiveAgent={this.deleteBulkActiveAgent}
            intl={this.props.intl}
            clearAllSlots={this.clearAllSlots}
            onCheckUsernameAvailability={this.onCheckUsernameAvailability}
            editPassword={editPassword}
            toggleEditPassword={this.toggleEditPassword}
            createOrUpdateStore={this.createOrUpdateStore}
            buildDeliveryAgentColumnData={this.buildDeliveryAgentColumnData}
            isAgentDetailsModalVisibleHandler={() => this.isAgentDetailsModalVisibleHandler()}
          />
          {!this.isBMSDealsOnlyView && fleetOption === 'yes' && <DeliveryAgent {...this.state} addAgentsHandler={this.addAgentsHandler} toggleAddAgent={this.toggleAddAgent} onAgentFieldChange={this.onAgentFieldChange} updateAgentsHandler={this.updateAgentsHandler} deleteAgent={this.deleteAgent} intl={this.props.intl} client={this.props.client} isAgentDetailsModalVisibleHandler={() => this.isAgentDetailsModalVisibleHandler()} />}
          {!this.isBMSDealsOnlyView && fleetOption === 'yes' && <DeliveryArea {...this.state} org_id={this.org_id} onDataChange={this.onChange} intl={this.props.intl} key={storeGeoLocation} />}
          <Col className={'agentSectionContainer bpQWIR'} span={20}>
            <Button type="primary" loading={saveStoreDetails} style={{ margin: '40px 0 0 0' }} onClick={this.saveChanges}>
              <IntlMessages id="store.saveChanges" />
            </Button>
          </Col>
        </Row>
        <Row>{showLocationModal && <StoreLocation {...this.state} onLocationChange={this.onLocationChange} toggleLocationPopup={this.toggleLocationPopup} intl={this.props.intl} />}</Row>
        <ConfirmationModal isUnsavedChanges={isUnsavedChanges} onClick={() => this.setState({ isUnsavedChanges: false })} goToTargetLocation={() => this.goToTargetLocation()} stayAction={() => this.setState({ isUnsavedChanges: false })} />
        <Modal
          width={450}
          style={{ fontSize: '20px' }}
          visible={this.state.showClearAllSlotsDialog}
          title={<IntlMessages id="sweetAlerts.areYouSure" />}
          onCancel={() => {
            this.setState({ showClearAllSlotsDialog: false })
          }}
          footer={null}>
          <div style={{ marginTop: '-16px', fontSize: '13px' }}>
            <IntlMessages id="store.wouldYouLikeToRemoveAll" />
          </div>
          <Button
            style={{
              display: 'flex',
              justifySelf: 'flex-start',
              margin: '16px 0 0 0',
              borderRadius: '6px'
            }}
            onClick={() => {
              this.onClearSlotHandler()
            }}>
            <IntlMessages id="store.yesContinue" />
          </Button>
          ,
        </Modal>
      </div>
    )
  }
}
// @ts-ignore
export default injectIntl(withApollo(Store))
