import React from 'react'

import CommonOnboardingCard from './commonOnboardingCard'
// Import { STORE_HELP_URL } from 'constants/Urls';

interface OutletInfoCardProps {
  intl: any
  pending: boolean
  onButtonClick: any
  disable: boolean
}
interface OutletInfoCardState {}

class OutletInfoCard extends React.Component<OutletInfoCardProps, OutletInfoCardState> {
  render() {
    return (
      <CommonOnboardingCard
        {...this.props}
        title="onboarding.outletInfo"
        description={this.props.pending ? 'onboarding.outletInfoDesc' : 'onboarding.outletInfoDesc.success'}
        buttonText="configureYourStore"
        // HelpUrl={STORE_HELP_URL}
        required={true}
      />
    )
  }
}
export default OutletInfoCard
