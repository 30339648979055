import './index.css'

import { Icon } from '@ant-design/compatible'
import { Query } from '@apollo/client/react/components'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import {
  Col,
  Divider,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Typography
} from 'antd'
import { IMAGES } from 'assets'
import { Button, IntlMessages, Switch, Text as NewText } from 'components'
import Loader from 'components/simple/loader'
import { History } from 'history'
import jwt from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { injectIntl } from 'react-intl'
import {
  getStore,
  getStoreStaff,
  getStoreStaffStatus,
  onToggleStore
} from 'services'
import { GET_RESTAURANTS_ORDERS, MAKE_All_STORE_STAFF_BUSY_STATUS,PROCESS_DELIVERY_ACCEPTANCE } from 'services/query'
import styled from 'styled-components'
import { HeaderText } from 'styles'
import {
  getOrgDetails,
  getUserDetails,
  intlAlertMessage,
  isManager,
  showSideBar
} from 'Utils'
import { COLORS, DEFAULT_PAGE_NUMBER, MAX_ITEMS_PER_PAGE, ORDER_STATUS } from 'Utils/constants'

const OrderTable = React.lazy(() => import('./components/orderTable'))

/*
 * Uncomment below 3 lines to make FCM Notification work
 * import FbNotificationManager from 'firebaseInit';
 * import browserSignature from 'browserSignature';
 *  const signature = browserSignature();
 */
const { Text } = Typography
const { Search } = Input
const EXTERNAL_DELIVERY_LABEL = 'Delivery by:'

interface IOrders extends ApolloProviderProps<any> {
  pickUpOrder?: any
  makeAllStoreStaffBusyStatus: any
  history: History
  intl: any
}

interface IOrdersState {
  tableData: any[]
  viewOrder: boolean
  viewOrderData: any
  refetchData: boolean
  isManager: boolean
  stores: any
  showOrderModal: boolean
  filter: string
  isRiderBusy: boolean
  showRiderBusy: boolean
  isOpen: boolean
  loadingStoreStatus: boolean
}

const UrlBlock = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  opacity: 1;
  width: 461px;
  padding: 7px;
  margin: 7px 0;
`
const Header = styled.div`
  margin: 8px 0;
`

class Orders extends React.Component<IOrders, IOrdersState> {
  static propTypes: { intl: PropTypes.Validator<any> }

  unblock: any

  /*
   * Uncomment below 4 lines to make FCM Notification work
   *  notificationManager: any;
   *  firebaseToken: string;
   *  deviceId: string;
   *  clientManager: any;
   */
  constructor(props: Readonly<IOrders>) {
    super(props)
    showSideBar()
    const userData = getUserDetails()
    const stores = userData ? userData.store : []
    const isOpen = userData && userData.store && userData.store[0]?.enable
    const isMgr = isManager()
    let showOrderModal = false

    /*
     * Uncomment below 2 lines to make FCM Notification work
     *  this.notificationManager = FbNotificationManager.getInstance();
     *  this.deviceId = signature;
     */
    if (
      this.props.history.location.state &&
      this.props.history.location.state.isOnboarding
    ) {
      showOrderModal = true
    }
    this.state = {
      tableData: [],
      viewOrder: false,
      viewOrderData: null,
      refetchData: false,
      isManager: isMgr,
      stores,
      showOrderModal,
      filter: '',
      isRiderBusy: false,
      showRiderBusy: false,
      isOpen,
      loadingStoreStatus: false
    }
    /*
     * Uncomment below codes to make FCM Notification work
     *  this.notificationManager
     *    .getFirebaseNotificationToken()
     *    .then(firebaseToken => {
     *      // eslint-disable-next-line no-console
     *      this.firebaseToken = firebaseToken;
     *      this.registerDeviceId();
     *    })
     *    .catch(err => {
     *      console.log('fcm token error', err);
     *    });
     */
  }

  componentDidMount() {
    const { isManager } = this.state

    if (isManager) {
      this.getStoreStaff()
      this.getStoreDetails()
    }
    this.unblock = this.props.history.block(targetLocation => {
      if (
        targetLocation.pathname === '/orderx/onboarding' ||
        targetLocation.pathname === '/orderx/signin' ||
        targetLocation.pathname === '/changePassword'
      ) {
        return false
      }
    })
  }

  /*
   * RegisterDeviceId = async () => {
   *   console.log('Firebase Token', this.firebaseToken);
   *   console.log('Device Id', this.deviceId);
   *   const deviceInfoInput = {
   *     input: {
   *       deviceId: this.deviceId,
   *       fcmToken: this.firebaseToken,
   *       userId: getUserDetails().id,
   *     },
   *   };
   *   try {
   *     await this.props.client.mutate({
   *       mutation: ADD_DEVICE_INFO,
   *       variables: deviceInfoInput,
   *     });
   *   } catch (err) {
   *     console.log('Add device info error', err);
   *   }
   * };
   */

  getStoreDetails = async () => {
    const storeId = this.state.stores[0].id

    try {
      const res = await getStore(this.props.client, storeId)

      this.setState({
        isOpen: res.data.store.enable
      })
    } catch (err) {
      console.log(err)
    }
  }

  componentWillUnmount() {
    this.unblock && this.unblock()
  }

  getStoreStaff = async () => {
    const storeStaffInput = {
      storeId: this.state.stores[0].id,
      staffRole: 'DELIVERY',
      pageOptions: { page: 1, pageSize: 100 }
    }

    try {
      const staffStatusResponse = await getStoreStaff(
        this.props.client,
        storeStaffInput
      )
      const showRiderBusy =
        staffStatusResponse.data.getStaffMembers.data.filter(
          staff => staff.status === 'ACTIVE'
        ).length > 0

      if (showRiderBusy) {
        this.getStoreStaffBusyStatusFun()
      }
    } catch (error) {
      console.log(error)
    }
  }

  getStoreStaffBusyStatusFun = async () => {
    const staffStatusInput = {
      organizationId: jwt.decode(localStorage.getItem('jwt')).org_id,
      storeId: this.state.stores[0].id,
      staffRole: 'DELIVERY'
    }

    try {
      const riderBusyRes = await getStoreStaffStatus(
        this.props.client,
        staffStatusInput
      )

      this.setState({
        isRiderBusy: riderBusyRes.data.getStoreStaffBusyStatus,
        showRiderBusy: true
      })
    } catch (error) {
      console.log(error)
    }
  }

  closeModal = () => {
    this.setState({
      refetchData: true
    })
    this.setState({
      viewOrder: false,
      refetchData: false
    })
  }

  processOrderAcceptance = async (orderId: string) => {
    const { pickUpOrder } = this.props

    try {
      await pickUpOrder({
        variables: {
          id: orderId
        }
      })
      message.success(
        intlAlertMessage({
          id: 'orders.orderMarkedForPickup',
          intl: this.props.intl
        })
      )
      this.setState({
        refetchData: true
      })
      this.setState({
        refetchData: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  processRiderBusyEnableDisable = async e => {
    const { makeAllStoreStaffBusyStatus } = this.props
    const riderBusyEnableDisableInput = {
      busy: e.target.value,
      storeId: this.state.stores[0].id,
      staffRole: 'DELIVERY'
    }

    this.setState({
      isRiderBusy: e.target.value
    })
    try {
      await makeAllStoreStaffBusyStatus({
        variables: {
          input: riderBusyEnableDisableInput
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({ isRiderBusy: !e.target.value })
    }
  }

  viewOrderData = order => {
    const orderItems = order.product
    const totalAmount = order.totalOrderAmountNonRoundOff
    const viewOrderData = {
      id: order.id,
      orderItems,
      totalAmount
    }

    this.setState({
      viewOrder: true,
      viewOrderData
    })
  }

  showPreviousOrders = () => {
    this.props.history.push('/orderx/order-history')
  }

  storeToggleHandler = async () => {
    const { isOpen, stores } = this.state
    const organizationId = jwt.decode(localStorage.getItem('jwt')).org_id
    const storeId = stores && stores[0].id

    this.setState({ isOpen: !isOpen, loadingStoreStatus: true })

    const storeEnableDisableDetailsInput = {
      storeId,
      organizationId
    }

    try {
      const res = await onToggleStore(
        this.props.client,
        storeEnableDisableDetailsInput,
        isOpen
      )
      const userData = JSON.parse(localStorage.getItem('userData'))

      userData.store[0].enable = res.data.enableStore
        ? res.data.enableStore.enable
        : res.data.disableStore.enable
      localStorage.setItem('userData', JSON.stringify(userData))
      this.setState({ loadingStoreStatus: false })
    } catch (err) {
      this.setState({ isOpen: !isOpen, loadingStoreStatus: false })
      console.log('Store status change error:', err)
    }
  }

  tableChangeHandler = pagination => {
    console.log(pagination)
  }

  render() {
    const {
      isManager,
      stores,
      showOrderModal,
      filter,
      isRiderBusy,
      showRiderBusy,
      isOpen,
      loadingStoreStatus,
    } = this.state
    const { org_id }: any = jwt.decode(localStorage.getItem('jwt'))
    const getAllOrdersInput = {
      orderStatus: [
        ORDER_STATUS.ORDER_CREATED,
        ORDER_STATUS.ORDER_DELIVERY_FOUND,
        ORDER_STATUS.ORDER_SHIPPED,
        ORDER_STATUS.ORDER_ACCEPTED,
        ORDER_STATUS.ORDER_READY
      ],
      /*
       * DateStart: moment().format('YYYY-MM-DD'),
       * dateStart: moment()
       *   .subtract(60, 'days')
       *   .format('YYYY-MM-DD'),
       */
      organizationId: org_id,
      pageOptions: {
        page: DEFAULT_PAGE_NUMBER,
        pageSize: MAX_ITEMS_PER_PAGE
      }
    }
    let miniAppUrl = ''
    // Let whatsappUrl = '';
    const orgDetails = getOrgDetails()

    if (showOrderModal && orgDetails && orgDetails.miniAppUrlLink) {
      miniAppUrl = orgDetails.miniAppUrlLink
      // WhatsappUrl = WHATSAPP_BOT_URL + orgDetails.code;
    }

    if (isManager) getAllOrdersInput['storeId'] = stores[0].id

    return (
      <div className="main">
        <div style={{ minHeight: '650px' }}>
          <div className="navbar-block">
            <Col>
              <HeaderText type="H1">
                <IntlMessages id="orders.todaysOrders" />
              </HeaderText>
            </Col>

            <div className="help-button">
              <div>
                {' '}
                <a
                  className="menu-help"
                  href="https://help.peppo.co.in/collections/live-order"
                  target="_blank"
                  rel="noopener noreferrer">
                  <IntlMessages id="menu.help" />
                </a>
              </div>
              {isManager ? (
                <Col
                  className="toggle-button-button"
                  style={{
                    backgroundColor: COLORS.BG_WHITE
                  }}>
                  <div style={{ paddingRight: '15px' }}>
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: isOpen ? COLORS.PRIMARY : COLORS.ERROR_DARK
                      }}>
                      {isOpen ? (
                        <IntlMessages id="store.enabled" />
                      ) : (
                        <IntlMessages id="store.disabled" />
                      )}
                    </div>
                    <div
                      className="current-store-status-text"
                      style={{
                        color: COLORS.DARK_TEXT
                      }}>
                      {isOpen ? (
                        <IntlMessages id="orders.receivingOrders" />
                      ) : (
                        <IntlMessages id="orders.notReceivingOrders" />
                      )}
                    </div>
                  </div>
                  <Switch
                    loading={loadingStoreStatus}
                    checked={isOpen}
                    onChange={() => this.storeToggleHandler()}
                  />
                </Col>
              ) : (
                <div />
              )}
            </div>
          </div>
          <Divider />
          <Row
            style={{
              display: 'flex',
              padding: '0px 0px 12px 32px'
            }}>
            <Col style={{ width: '67%' }}>
              {isManager && showRiderBusy && (
                <div style={{ marginLeft: 10 }}>
                  <div style={{ fontSize: '13px' }}>
                    {EXTERNAL_DELIVERY_LABEL}
                    <Radio.Group
                      onChange={this.processRiderBusyEnableDisable}
                      defaultValue={isRiderBusy}
                      className="grey-green-radio ml-1">
                      <Radio.Button value={false}>
                        <IntlMessages id="orders.ownFleet" />
                      </Radio.Button>
                      <Radio.Button value={true}>
                        <IntlMessages id="orders.thirdParty" />
                      </Radio.Button>
                    </Radio.Group>
                    {isRiderBusy && (
                      <span style={{ marginLeft: '10px  ' }}>
                        <Icon type="check-circle" className="fleet-msg-icon" />
                        <Text className="fleet-msg-text">
                          <IntlMessages id="orders.deliveryMessageForUpcomingOrders" />
                        </Text>
                      </span>
                    )}
                  </div>
                </div>
              )}
            </Col>
            <Col
              style={{
                width: '30%'
              }}>
              <Search
                placeholder={intlAlertMessage({
                  id: 'orderHistory.searchByCustomerNo',
                  intl: this.props.intl
                })}
                onChange={e => {
                  !e.target.value && this.setState({ filter: e.target.value })
                }}
                onSearch={value => {
                  this.setState({ filter: value })
                }}
                style={{ width: '100%', borderRadius: '50%', marginBottom: 0 }}
                className="order-search-input"
              />
            </Col>
          </Row>

          <Row>
            <Suspense fallback={ <Loader  intl={this.props.intl}/>}>
              <Col
                style={{
                  width: '100%',
                  margin: '0px 40px 40px 40px',
                  minHeight: '60vh'
                }}>
                <Query
                  query={GET_RESTAURANTS_ORDERS}
                  fetchPolicy={'network-only'}
                  pollInterval={10000}
                  variables={getAllOrdersInput}>
                  {queryResult => (
                    <OrderTable
                      error={queryResult.error}
                      loading={queryResult.loading}
                      data={queryResult.data?.orders || {}}
                      pickUpOrder={this.props.pickUpOrder}
                      fetchMore={queryResult.fetchMore}
                      refetch={queryResult.refetch}
                      filter={filter}
                      isManager={isManager}
                    />
                  )}
                </Query>
              </Col>
            </Suspense>
          </Row>
        </div>
        <Modal
          visible={showOrderModal}
          onCancel={() => {
            this.setState({ showOrderModal: false })
          }}
          footer={null}
          className="order-popup">
          <div className="popup-banner">
            <img src={IMAGES.banner_img} alt="Peppo banner" />
          </div>
          <div className="popup-text-wrapper">
            <Header>
              <NewText level={'body-1'}>
                <IntlMessages id="orders.AllSetUpMessage" />
              </NewText>
            </Header>
            <div>
              <NewText level={'body-2'}>
                <IntlMessages id="orders.storeReadyToPlaceOrderMessage" />
              </NewText>
            </div>
            <div>
              <NewText level={'body-2'}>
                <IntlMessages id="orders.storeReadyToPlaceOrderMessage2" />
              </NewText>
            </div>
            <UrlBlock>
              <div>
                <NewText level={'body-2'} style={{ fontSize: '12px' }}>
                  <IntlMessages id="miniApp" />{' '}
                </NewText>
                <Button
                  type="link"
                  style={{
                    marginBottom: 0,
                    lineHeight: '33px',
                    paddingLeft: 0,
                    fontSize: '12px'
                  }}>
                  <a
                    href={miniAppUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    {miniAppUrl}
                  </a>
                </Button>
                {/* <a
                  href={miniAppUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <NewText level={'body-2'}>{miniAppUrl}</NewText>
                </a> */}
              </div>
            </UrlBlock>
          </div>
        </Modal>
      </div>
    )
  }
}



const ActiveOrders = injectIntl(
  compose(
    graphql(PROCESS_DELIVERY_ACCEPTANCE, {
      name: 'pickUpOrder'
    }),
    graphql(MAKE_All_STORE_STAFF_BUSY_STATUS, {
      name: 'makeAllStoreStaffBusyStatus'
    }),
    withApollo
  )(Orders)
)

export default ActiveOrders
