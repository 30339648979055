import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Col, message, Row, Spin } from 'antd'
import { Button, IntlMessages, Title, WeekTimeSlots } from 'components'
import * as compose from 'lodash/flowRight'
import moment from 'moment'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { addMenuTimings, createMenuTimings, getMenuTimings, removeMenuTimings, removeMenuTimingsById, updateMenuTimings } from 'services'
import { getTimeSlotWithPaddingZero, getUniqueCode, intlAlertMessage, isDuplicateCode } from 'Utils'
import { COLORS, DEFAULT_TIME_SLOT, HH_MM_FORMAT } from 'Utils/constants'

import { addToSavedTimeGroups, checkForDuplicateAndOverlapTimeSlot, getAllTimeSlotIds, getModifiedTimeSlots, getNewTimeSlots, getParsedMenuTimings, getSortedWeekDays, getTimeGroupsWithRemovedGroup, getTimeGroupWithModifiedTime, getTimeGroupWithRemovedTime, getUpdatedTimeGroups, isNewTimeGroup, isNoTimeSlot, removeFromSavedTimeGroups, republishCatalog, updateTimeGroupTimings, updateTimeGroupWithAddedTimeSlot } from '../../../Utils/menuUtils'
import { AddItemStyle, ClearAllSlotsContainer, GroupItemStyle, ItemBoxStyle, SaveButtonContainer, ScrollableListContainer, StyledContainer } from '../../../Utils/menuUtils/styleConstants'
import { DeleteModal, SlotTimingModal } from '../components'
import EmptyTimeSlotsTable from '../components/EmptyTimeSlotsTable'

interface IMenuTimingsProps extends ApolloProviderProps<any> {
  intl: any
}

interface IMenuTimingsState {
  timeGroups: any
  timeSlotGroupName: string
  selectedTimeGroup: any
  isUpdateSlot: boolean
  isAddSlot: boolean
  timeSlot: any
  isApplyAllDays: boolean
  selectedTimeSlotIndex: number
  savingTimings: boolean
  showDeleteSlotModal: boolean
  showClearAllSlotModal: boolean
  showDeleteGroupModal: boolean
  fetchingTimeGroups: boolean
  totalTimeSlot: any
}

class MenuTimings extends Component<IMenuTimingsProps, IMenuTimingsState> {
  timeSlotIdsToDelete: any[]

  savedTimeGroups: any[]

  constructor(props) {
    super(props)
    this.timeSlotIdsToDelete = []
    this.savedTimeGroups = []
    this.state = {
      timeGroups: [],
      timeSlotGroupName: '',
      selectedTimeGroup: null,
      isUpdateSlot: false,
      isAddSlot: false,
      timeSlot: {
        days: '',
        data: {}
      },
      isApplyAllDays: false,
      selectedTimeSlotIndex: null,
      savingTimings: false,
      showDeleteSlotModal: false,
      showClearAllSlotModal: false,
      showDeleteGroupModal: false,
      fetchingTimeGroups: false,
      totalTimeSlot: 0
    }
  }

  componentDidMount() {
    this.getMenuTimings()
  }

  getMenuTimings = async () => {
    const { client } = this.props

    this.setState({ fetchingTimeGroups: true })
    const menuTimingsResponse = await getMenuTimings(client).catch(err => {
      console.error('get time groups error', err)
    })

    this.setState({ fetchingTimeGroups: false })
    const timeGroups = menuTimingsResponse?.data?.getMenuTimings

    if (timeGroups && timeGroups.length) {
      this.savedTimeGroups = timeGroups.map(timeGroup => timeGroup.code)
      timeGroups[0].timings = getParsedMenuTimings(timeGroups[0].timings)
      timeGroups[0].timings = getSortedWeekDays(timeGroups[0].timings)
      this.setState({
        timeGroups,
        selectedTimeGroup: { ...timeGroups[0] }
      })
    }
  }

  createMenuTimings = async () => {
    const { client } = this.props
    const { selectedTimeGroup } = this.state
    let menuTimings = selectedTimeGroup.timings.filter(time => time.data.length > 0)

    menuTimings = menuTimings.map(time => {
      time.days = time.days[0]

      return time
    })
    const createMenuTimingsInput = {
      name: selectedTimeGroup.name,
      code: selectedTimeGroup.code,
      menuTimings
    }
    const createMenuTimingsResponse = await createMenuTimings(client, createMenuTimingsInput).catch(err => {
      this.setState({ savingTimings: false })
      console.error('create time group error', err)
    })
    let newTimeGroup = createMenuTimingsResponse?.data?.createMenuTimings

    if (newTimeGroup) {
      this.savedTimeGroups = addToSavedTimeGroups(this.savedTimeGroups, newTimeGroup.code)
      newTimeGroup = updateTimeGroupTimings(newTimeGroup)
      this.setState({
        selectedTimeGroup: newTimeGroup,
        savingTimings: false
      })
    }
  }

  addMenuTimings = async newTimings => {
    const { client } = this.props
    const { selectedTimeGroup } = this.state
    const addMenuTimingsInput = {
      code: selectedTimeGroup.code,
      menuTimings: newTimings
    }
    const addMenuTimingsResponse = await addMenuTimings(client, addMenuTimingsInput).catch(err => {
      console.error('add time slots to selected time group error', err)
    })
    let timeGroupWithAddedTiming = addMenuTimingsResponse?.data?.addMenuTimings

    if (timeGroupWithAddedTiming) {
      timeGroupWithAddedTiming = updateTimeGroupTimings(timeGroupWithAddedTiming)
      const selectedTimeGroupWithAddedTimeSlot = updateTimeGroupWithAddedTimeSlot(selectedTimeGroup, timeGroupWithAddedTiming)

      this.setState({
        selectedTimeGroup: selectedTimeGroupWithAddedTimeSlot,
        savingTimings: false
      })
    }
  }

  updateMenuTimings = async modifiedTimings => {
    const { client } = this.props
    const { selectedTimeGroup } = this.state
    const updateMenuTimingsInput = {
      code: selectedTimeGroup.code,
      menuTimings: modifiedTimings
    }

    await updateMenuTimings(client, updateMenuTimingsInput).catch(err => {
      console.error('Update time slot error', err)
    })
  }

  removeMenuTimings = async () => {
    const { client } = this.props
    const { selectedTimeGroup, timeGroups } = this.state

    if (this.savedTimeGroups.indexOf(selectedTimeGroup.code) !== -1) {
      const removeMenuTimingsInput = {
        code: selectedTimeGroup.code
      }
      const removeMenuTimingsResponse = await removeMenuTimings(client, removeMenuTimingsInput).catch(err => {
        console.error('remove time group error', err)
      })
      const removedMenuTimings = removeMenuTimingsResponse?.data?.removeMenuTimings

      if (removedMenuTimings) {
        this.savedTimeGroups = removeFromSavedTimeGroups([...this.savedTimeGroups], selectedTimeGroup.code)
        republishCatalog(this.props.client)
      }
    }
    const updatedTimegroups = getTimeGroupsWithRemovedGroup([...timeGroups], selectedTimeGroup)

    if (updatedTimegroups.length) {
      this.onGroupChange({ ...updatedTimegroups[0] })
    } else {
      this.setState({
        timeGroups: updatedTimegroups,
        selectedTimeGroup: null
      })
    }
    this.setState({
      timeGroups: updatedTimegroups,
      showDeleteGroupModal: false
    })
  }

  removeMenuTimingsById = async () => {
    const { client } = this.props
    const { selectedTimeGroup } = this.state
    const removeMenuTimingsByIdInput = {
      code: selectedTimeGroup.code,
      id: this.timeSlotIdsToDelete
    }
    const removeMenuTimingsResponse = await removeMenuTimingsById(client, removeMenuTimingsByIdInput).catch(err => {
      console.log('Remove time slots  error', err)
    })

    return removeMenuTimingsResponse?.data?.removeMenuTimingsById
  }

  onGroupChange = newSelectedTimeGroup => {
    const { selectedTimeGroup } = this.state

    if (selectedTimeGroup.code === newSelectedTimeGroup.code) return
    const updatedTimeGroup = updateTimeGroupTimings(newSelectedTimeGroup)

    this.setState({
      selectedTimeGroup: updatedTimeGroup
    })
  }

  addTimeGroup = () => {
    const { timeSlotGroupName, timeGroups, selectedTimeGroup } = this.state

    const formattedTimeSlotGroupName = timeSlotGroupName.trim()
    const newTimeGroupCode = getUniqueCode(formattedTimeSlotGroupName)

    if (newTimeGroupCode === selectedTimeGroup?.code) return
    if (
      isDuplicateCode(
        newTimeGroupCode,
        timeGroups.map(timeGroup => timeGroup.code)
      )
    ) {
      message.error(
        intlAlertMessage({
          id: 'menuTimings.duplicateCode',
          intl: this.props.intl
        })
      )
    } else {
      const newTimeGroup = {
        name: formattedTimeSlotGroupName,
        code: newTimeGroupCode,
        timings: getParsedMenuTimings([])
      }

      timeGroups.unshift(newTimeGroup)
      this.setState({
        timeGroups,
        selectedTimeGroup: newTimeGroup,
        timeSlotGroupName: ''
      })
    }
  }

  toggleAddSlot = day => {
    const { isAddSlot, timeSlot } = this.state

    timeSlot.days = day
    timeSlot.data = {
      openTime: DEFAULT_TIME_SLOT.OPEN,
      closeTime: DEFAULT_TIME_SLOT.CLOSE
    }
    this.setState({
      isAddSlot: !isAddSlot,
      isUpdateSlot: false,
      timeSlot,
      isApplyAllDays: false
    })
  }

  toggleUpdateSlot = (day, slot, slotIndex) => {
    const { isUpdateSlot, timeSlot } = this.state

    timeSlot.days = day
    timeSlot.data = { ...slot }
    this.setState({
      isAddSlot: false,
      isUpdateSlot: !isUpdateSlot,
      timeSlot,
      selectedTimeSlotIndex: slotIndex
    })
  }

  addTimeSlot = () => {
    const { isApplyAllDays, timeSlot, selectedTimeGroup, timeGroups } = this.state
    const data = {
      openTime: Number(timeSlot.data.openTime),
      closeTime: Number(timeSlot.data.closeTime)
    }

    if (data.openTime === data.closeTime) {
      message.error(
        intlAlertMessage({
          id: 'store.errMsg.openTimeAndCloseTimeShouldNotBeSame',
          intl: this.props.intl
        })
      )
    } else if (data.closeTime < data.openTime) {
      message.error(
        intlAlertMessage({
          id: 'store.errMsg.closeTimeShouldGreaterThemOpenTime',
          intl: this.props.intl
        })
      )
    } else {
      let slotIndex = -1
      let isDuplicateSlot = false
      let isSlotOverlap = false
      const duplicateSlotArr = []

      selectedTimeGroup.timings.forEach((day, i) => {
        if (timeSlot.days.toLowerCase() === day.days[0].toLowerCase() || isApplyAllDays) {
          day.data.forEach((slot, j) => {
            if (slot.openTime === data.openTime && slot.closeTime === data.closeTime) {
              duplicateSlotArr.push(day.days[0])
              isDuplicateSlot = true

              return
            }
            if ((data.openTime >= slot.openTime && data.openTime <= slot.closeTime) || (data.closeTime >= slot.openTime && data.closeTime <= slot.closeTime) || (data.openTime <= slot.openTime && data.closeTime >= slot.closeTime)) {
              isSlotOverlap = true
            }
          })
          slotIndex = i
        }
      })

      if (!isDuplicateSlot && !isSlotOverlap) {
        if (isApplyAllDays) {
          selectedTimeGroup.timings.forEach(day => {
            day.data.push({ ...data })
          })
        } else {
          selectedTimeGroup.timings[slotIndex].data.push(data)
        }
        const updatedTimeGroups = getUpdatedTimeGroups(timeGroups, selectedTimeGroup)

        this.setState({
          selectedTimeGroup,
          isUpdateSlot: false,
          isAddSlot: false,
          timeGroups: updatedTimeGroups
        })
      } else if (isDuplicateSlot) {
        message.error(
          intlAlertMessage(
            { id: 'slotAlreadyExists', intl: this.props.intl },
            { slot: duplicateSlotArr.map(val => val.toLowerCase()) }
          ))
      } else if (isSlotOverlap) {
        message.error(
          intlAlertMessage({
            id: 'store.errMsgOverlappingStore',
            intl: this.props.intl
          })
        )
      }
    }
  }

  updateTimeSlot = () => {
    const { selectedTimeSlotIndex, selectedTimeGroup, timeSlot } = this.state

    if (timeSlot.data.openTime === timeSlot.data.closeTime) {
      message.error(
        intlAlertMessage({
          id: 'store.errMsg.openTimeAndCloseTimeShouldNotBeSame',
          intl: this.props.intl
        })
      )
    } else if (timeSlot.data.closeTime < timeSlot.data.openTime) {
      message.error(
        intlAlertMessage({
          id: 'store.errMsg.closeTimeShouldGreaterThemOpenTime',
          intl: this.props.intl
        })
      )
    } else {
      const timeSlotStatus = checkForDuplicateAndOverlapTimeSlot(selectedTimeGroup, timeSlot, selectedTimeSlotIndex)

      if (!timeSlotStatus.isDuplicate && !timeSlotStatus.isOverlap) {
        const selectedTimeGroupWithModifiedTime = getTimeGroupWithModifiedTime(selectedTimeGroup, timeSlot, selectedTimeSlotIndex)

        this.setState({ selectedTimeGroup: selectedTimeGroupWithModifiedTime })
        this.closeAddSlotModal()
      } else if (timeSlotStatus.isDuplicate) {
        message.error(
          intlAlertMessage(
            { id: 'slotAlreadyExists', intl: this.props.intl },
            { slot: timeSlot.days.toLowerCase() }
          ))
      } else if (timeSlotStatus.isOverlap) {
        message.error(
          intlAlertMessage({
            id: 'store.errMsgOverlappingStore',
            intl: this.props.intl
          })
        )
      }
    }
  }

  deleteTimeSlot = () => {
    const { timeSlot, selectedTimeGroup, selectedTimeSlotIndex, totalTimeSlot } = this.state

    if (timeSlot.data.id) {
      this.timeSlotIdsToDelete.push(timeSlot.data.id)
    }
    const timeGroupWithRemovedTimeSlot = getTimeGroupWithRemovedTime(selectedTimeGroup, timeSlot, selectedTimeSlotIndex)

    this.closeAddSlotModal()
    if (totalTimeSlot <= 1) {
      this.removeMenuTimings()
      this.setState({
        showDeleteSlotModal: false
      })
    } else {
      this.setState({
        selectedTimeGroup: { ...timeGroupWithRemovedTimeSlot },
        showDeleteSlotModal: false
      })
    }
  }

  deleteAllTimeSlots = async () => {
    const { selectedTimeGroup } = this.state

    this.timeSlotIdsToDelete = getAllTimeSlotIds({ ...selectedTimeGroup })
    let removeMenuTimingsRes

    if (this.timeSlotIdsToDelete.length) {
      removeMenuTimingsRes = await this.removeMenuTimingsById()
      if (!removeMenuTimingsRes) {
        this.setState({
          showClearAllSlotModal: false
        })

        return
      }
    }
    if ((this.timeSlotIdsToDelete.length && Boolean(removeMenuTimingsRes)) || !this.timeSlotIdsToDelete.length) {
      selectedTimeGroup.timings = selectedTimeGroup.timings.map(time => {
        return {
          days: time.days,
          data: []
        }
      })
      this.savedTimeGroups = removeFromSavedTimeGroups([...this.savedTimeGroups], selectedTimeGroup.code)
      this.timeSlotIdsToDelete = []
    }
    this.setState(
      {
        selectedTimeGroup,
        showClearAllSlotModal: false
      },
      () => this.updateTimeGroups()
    )
  }

  saveTimings = async () => {
    const { selectedTimeGroup } = this.state

    this.setState({ savingTimings: true })
    if (isNewTimeGroup(this.savedTimeGroups, selectedTimeGroup.code)) {
      await this.createMenuTimings()
    } else {
      const modifiedTimings = getModifiedTimeSlots(selectedTimeGroup)

      if (modifiedTimings.length) {
        await this.updateMenuTimings(modifiedTimings)
      }
      const newTimings = getNewTimeSlots(selectedTimeGroup)

      if (newTimings.length) {
        await this.addMenuTimings(newTimings)
      }
      republishCatalog(this.props.client)
    }
    message.success(
      intlAlertMessage({
        id: 'menuTimings.savedSuccessfully',
        intl: this.props.intl
      })
    )
    this.updateTimeGroups()
  }

  updateTimeGroups = () => {
    const { selectedTimeGroup, timeGroups } = this.state
    const updatedTimeGroups = getUpdatedTimeGroups(timeGroups, selectedTimeGroup)

    this.setState({
      timeGroups: updatedTimeGroups,
      savingTimings: false
    })
  }

  closeAddSlotModal = () => {
    this.setState({
      isAddSlot: false,
      isUpdateSlot: false
    })
  }

  onDeleteSlot = () => {
    const { selectedTimeGroup } = this.state
    let totalTimeSlot = 0

    selectedTimeGroup?.timings.map(elem => {
      totalTimeSlot += elem.data.length
    })
    this.setState({ showDeleteSlotModal: true, totalTimeSlot })
  }

  render() {
    const { timeGroups, timeSlotGroupName, selectedTimeGroup, isAddSlot, isUpdateSlot, timeSlot, isApplyAllDays, savingTimings, showDeleteSlotModal, showClearAllSlotModal, showDeleteGroupModal, fetchingTimeGroups, totalTimeSlot } = this.state
    const openTime = moment(getTimeSlotWithPaddingZero(timeSlot.data.openTime), HH_MM_FORMAT)
    const closeTime = moment(getTimeSlotWithPaddingZero(timeSlot.data.closeTime), HH_MM_FORMAT)

    let isNoSlot = true

    if (Boolean(selectedTimeGroup) && Boolean(selectedTimeGroup.timings)) {
      isNoSlot = isNoTimeSlot(selectedTimeGroup.timings)
    }

    return (
      <>
        <Col span={6} className="list-wrapper">
          <Title>
            <IntlMessages id="menuTiming.timeGroups" />
          </Title>
          <ItemBoxStyle>
            <AddItemStyle
              placeholder={intlAlertMessage({
                id: 'menuTiming.groupNamePlaceholderText',
                intl: this.props.intl
              })}
              value={timeSlotGroupName}
              onChange={e => {
                this.setState({
                  timeSlotGroupName: e.target.value
                })
              }}></AddItemStyle>
            <Button
              type="primary"
              disabled={!timeSlotGroupName.length}
              onClick={() => {
                this.addTimeGroup()
              }}
              loading={false}>
              <IntlMessages id="button.+add" />
            </Button>
          </ItemBoxStyle>
          <ScrollableListContainer>
            {fetchingTimeGroups && (
              <div className="divCenter" style={{ marginTop: '-100px' }}>
                <Spin size="default" />
              </div>
            )}
            {Boolean(timeGroups.length) &&
              timeGroups.map(timeGroup => (
                <GroupItemStyle
                  key={timeGroup.code}
                  style={
                    timeGroup.code === selectedTimeGroup?.code
                      ? {
                          backgroundColor: COLORS.BG_SHADE,
                          color: COLORS.PRIMARY,
                          padding: '10px 12px',
                          fontWeight: '600'
                        }
                      : {}
                  }
                  onClick={() => this.onGroupChange(timeGroup)}>
                  {timeGroup.name}
                </GroupItemStyle>
              ))}
          </ScrollableListContainer>
        </Col>
        {!timeGroups.length ? (
          <EmptyTimeSlotsTable />
        ) : (
          <StyledContainer span={17}>
            <ClearAllSlotsContainer>
              <Title>{selectedTimeGroup.name}</Title>
              <div>
                <Button
                  disabled={isNoSlot}
                  style={{ minWidth: '120px' }}
                  onClick={() => {
                    this.setState({ showClearAllSlotModal: true })
                  }}
                  size="small">
                  <IntlMessages id="button.clearAllSlots" />
                </Button>
                <Button
                  style={{ minWidth: '120px' }}
                  onClick={() => {
                    this.setState({ showDeleteGroupModal: true })
                  }}
                  size="small">
                  <IntlMessages id="button.deleteGroup" />
                </Button>
              </div>
            </ClearAllSlotsContainer>
            <WeekTimeSlots
              dayWiseSlots={selectedTimeGroup.timings}
              onToggleAddSlot={this.toggleAddSlot}
              onToggleUpdateSlot={this.toggleUpdateSlot}
            />
            <SaveButtonContainer>
              <Row>
                <Col span={23} style={{ padding: 0 }}>
                  <Button
                    type="primary"
                    disabled={isNoSlot}
                    style={{ minWidth: '120px' }}
                    onClick={() => {
                      this.saveTimings()
                    }}
                    loading={savingTimings}>
                    <IntlMessages id="button.save" />
                  </Button>
                </Col>
              </Row>
            </SaveButtonContainer>
          </StyledContainer>
        )}

        <SlotTimingModal
          isUpdateSlot={isUpdateSlot}
          onClose={() => {
            this.setState({
              isAddSlot: false,
              isUpdateSlot: false
            })
          }}
          isAddSlot={isAddSlot}
          openTime={openTime}
          closeTime={closeTime}
          isApplyToAllDays={isApplyAllDays}
          onSlotOpenTimeChange={time => {
            timeSlot.data.openTime = Number(time.format(HH_MM_FORMAT))
            this.setState({ timeSlot })
          }}
          onSlotCloseTimeChange={time => {
            timeSlot.data.closeTime = Number(time.format(HH_MM_FORMAT))
            this.setState({ timeSlot })
          }}
          onApplyAllDaysChange={applyAllDayValue => {
            this.setState({ isApplyAllDays: applyAllDayValue })
          }}
          onAddTimeSlot={this.addTimeSlot}
          onUpdateTimeSlot={this.updateTimeSlot}
          onDeleteSlot={() => {
            this.onDeleteSlot()
          }}
        />

        <DeleteModal
          visible={showDeleteSlotModal}
          onCancel={() => this.setState({ showDeleteSlotModal: false })}
          onConfirm={() => {
            this.deleteTimeSlot()
          }}
          title={
            totalTimeSlot > 1 ? (
              <IntlMessages id="store.confirmationMsg.aboutDeleteSelectedSlot" />
            ) : (
              <IntlMessages id="menuTimings.deleteGroupConfirmationMsg" />
            )
          }
        />
        <DeleteModal
          visible={showClearAllSlotModal}
          onCancel={() => this.setState({ showClearAllSlotModal: false })}
          onConfirm={() => {
            this.deleteAllTimeSlots()
          }}
          title={
            <IntlMessages id="menuTimings.confirmationMsg.aboutDeleteAllSlot" />
          }
        />
        <DeleteModal
          visible={showDeleteGroupModal}
          onCancel={() => this.setState({ showDeleteGroupModal: false })}
          onConfirm={() => {
            this.removeMenuTimings()
          }}
          title={
            <IntlMessages id="menuTimings.confirmationMsg.aboutDeleteGroup" />
          }
        />
      </>
    )
  }
}

const MenuTimingsComponent = compose(withRouter, withApollo)(MenuTimings)

export { MenuTimingsComponent }
