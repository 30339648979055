import { message } from 'antd'
import { sessionExpired } from 'i18n/locales/en_US.json'
import { BLACKLIST_KEYWORDS, PROMOTIONS_API_STATUS, PROMOTIONS_ERROR_CODE } from 'Utils/constants'
import { getAuthToken } from 'Utils/localStorageHandlers'


const baseUrl = process.env.REACT_APP_PROMOTION_URL


const config = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'wcore-api-key': process.env.REACT_APP_WCORE_API_KEY
  }
}


async function promotionClient(endpoint: string, payload: any) {
  const { headers, ...rest } = config
  const token = getAuthToken()
  const response = await fetch(`${baseUrl}${endpoint}`,
    {
      ...rest,
      headers: { ...headers, 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(payload)
    })

  const { data, meta } = await response.json()

  if (meta.status !== PROMOTIONS_API_STATUS.ALL_OK && meta.errors.length) {
    const [error] = meta.errors

    if (error.error_code === PROMOTIONS_ERROR_CODE.NOT_AUTHORIZED) {
      message.warn(sessionExpired)
      setTimeout(() => {
        localStorage.clear()
        sessionStorage.clear()
        location.reload()
      }, 1000)
    }
  }

  return data


}

async function getBlackListNames() {
  const URL = BLACKLIST_KEYWORDS
  const response = await fetch(URL)

  return response.json()
}

export { promotionClient, getBlackListNames }