import { IMAGES } from 'assets'
import React from 'react'
import { Link } from 'react-router-dom'
import { getOrgDetails, getViewType } from 'Utils'
import { LOGO_TYPE, ORG_STATUS, VIEW_TYPES } from 'Utils/constants'

const VIEW_TYPE_LOGO_URL = {
  [VIEW_TYPES.PEPPO_ONLY]: IMAGES.peppo_logo_transparent,
  [VIEW_TYPES.BMS_DEALS_ONLY]: IMAGES.peppo_bms_logo
}


function SidebarLogo() {
  const orgDetails = getOrgDetails()
  const viewType = getViewType()
  const isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY

  let path

  if (orgDetails.onboardingStatus === ORG_STATUS.INITIATED) {
    path = '/orderx/onboarding'
  }
  else if (isBMSDealsOnlyView) {
    path = '/orderx/reports'
  }
  else {
    path = '/orderx/orders'
  }

  const logoUrl = VIEW_TYPE_LOGO_URL[viewType]

  return (
    <div className={`orderX-Logo ${isBMSDealsOnlyView ? 'peppo-bms-logo' : ''}`}>
      <Link to={path}>
        <img style={{ marginBottom: '2px' }} alt="product_logo" src={logoUrl} />
      </Link>
    </div>
  )
}

export default SidebarLogo
