import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Input, message } from 'antd'
import { Button, FileUpload, IntlMessages, Modal, Text, Title } from 'components'
import { History } from 'history'
import jwt from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { addTaxes, createCategory, createProduct, createProductDiscountValue, createProductPrice, getAllProductCategory, getPriceValues, getProductDiscountValue, getProducts, updateDiscountForProducts, updateProduct, updateProductPrice } from 'services'
import { getStrikeoutDiscountType, intlAlertMessage, randomString } from 'Utils'
import { COLORS, DISCOUNT_VALUE_TYPE_ENUM, ORG_STATUS, PRICE_TYPE, PRODUCT_TAG_ENUM, PRODUCT_TYPE_ENUM } from 'Utils/constants'
import { getFileSystemId } from 'Utils/localStorageHandlers'

import { getItemCode, republishCatalog, setSuccessMessage } from '../../../Utils/menuUtils/commonFunc'
import { prepareFormatsChannels } from '../../../Utils/menuUtils/productsHelper'
import AddonForm from '../components/AddonForm'

interface IAddonsProps extends ApolloProviderProps<any> {
  intl: any
  history: History
  onAddonsChange: any
}

interface IAddonsState {
  isOpen: boolean
  selectedCategory: any
  isModalOpen: boolean
  customizationName: string
  customizationGroupData: any
  showUploader: boolean
  imageUrl: string
  productsList: any
  productItemName: string
  selectedProductItem: any
  selectedProductItemTagCode: string
  basePrice: any
  dealPrice: any
  discountValue: any
  isNoExtraCostEnabled: boolean
  productDiscountValueId: any
  strikeOutDiscountValueData: any
  selectedItemName: string
  isLoading: boolean
  updateProductLoading: boolean
  isCatagoriesLoading: boolean
  isProductsLoading: boolean
}
class Addons extends Component<IAddonsProps, IAddonsState> {
  imageData: object

  formatsChannels: any[]

  org_id: string

  catalog: {}

  fileSystemId: string

  constructor(props) {
    super(props)
    this.formatsChannels = prepareFormatsChannels(props)
    this.imageData = {}
    this.catalog = {}
    this.org_id = ''
    this.state = {
      isOpen: false,
      selectedCategory: '',
      isModalOpen: false,
      customizationName: '',
      showUploader: false,
      imageUrl: '',
      customizationGroupData: [],
      productsList: [],
      productItemName: '',
      selectedProductItem: null,
      selectedItemName: '',
      selectedProductItemTagCode: '',
      basePrice: 0,
      dealPrice: 0,
      discountValue: '',
      isNoExtraCostEnabled: false,
      productDiscountValueId: '',
      strikeOutDiscountValueData: null,
      isLoading: false,
      updateProductLoading: false,
      isProductsLoading: false,
      isCatagoriesLoading: false
    }
  }

  componentDidMount() {
    this.catalog = this.props.history.location.state ? this.props.history.location.state.catalog : {}
    const jwtToken: any = localStorage.getItem('jwt')

    this.fileSystemId = getFileSystemId()
    const { org_id }: any = jwt.decode(jwtToken)

    this.org_id = org_id
    if (this.catalog) {
      this.getCategory(this.catalog, false, '')
    }
  }

  getCategory = async (catalog, isNewCategoryAdded, customizationName) => {
    try {
      this.setState({ isCatagoriesLoading: true })
      const res = await getAllProductCategory(this.props.client, catalog)
      const newCategoryList = res ? res?.data?.categories.filter(val => val.productType === PRODUCT_TYPE_ENUM.ADDON) : null
      const newSelectedCategory = newCategoryList.find(elem => elem.code === getItemCode(customizationName))

      if (res.data) {
        this.getProducts(isNewCategoryAdded ? newSelectedCategory : newCategoryList[0], false)
        this.setState({
          customizationGroupData: newCategoryList,
          isCatagoriesLoading: false,
          selectedCategory: isNewCategoryAdded ? newSelectedCategory : newCategoryList[0]
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onSubmit = async () => {
    const { customizationName } = this.state

    this.setState({
      customizationName: '',
      isModalOpen: false,
      updateProductLoading: true
    })
    const createCategoryInput = {
      name: customizationName,
      description: '',
      status: ORG_STATUS.ACTIVE,
      catalogId: this.catalog['id'],
      organizationId: this.org_id,
      code: getItemCode(customizationName),
      productType: PRODUCT_TYPE_ENUM.ADDON
    }
    const categoryRes = await createCategory(this.props.client, createCategoryInput)

    if (categoryRes) {
      setSuccessMessage('category.message.ItemCreatedSuccessfully', this.props.intl)
      this.setState({
        updateProductLoading: false
      })
      this.props.onAddonsChange()
    }
    this.getCategory(this.catalog, true, customizationName)
  }

  showImageUpload = () => {
    if (!this.fileSystemId) {
      message.warn(
        intlAlertMessage({
          id: 'category.warnMsg.imgMissingforFilesystem',
          intl: this.props.intl
        })
      )
    } else {
      this.setState({ showUploader: true })
    }
  }

  fileUploadSave = data => {
    this.imageData = {
      publicUrl: data
    }
    this.setState({ showUploader: false, imageUrl: data })
  }

  fileUploadCancel(data) {
    this.setState({ showUploader: false })
  }

  getProducts = async (category, isNewProductAdded) => {
    this.setState({
      selectedCategory: category,
      isOpen: false,
      isProductsLoading: true
    })
    try {
      const res = await getProducts(this.props.client, category, this.org_id)

      if (res.data) {
        const data = res.data.products

        this.onSelectedItem(isNewProductAdded ? data[data.length - 1] : data[0] || [])
        if (!isNewProductAdded && data.length) {
          this.getProductPrice(data[0].id)
        } else {
          this.setState({
            basePrice: 0,
            dealPrice: 0
          })
        }
        this.setState({
          productsList: res.data.products,
          isProductsLoading: false
        })
      }
    } catch (error) {
      console.log(error)
      this.setState({
        isProductsLoading: false
      })
    }
  }

  getProductPrice = async productId => {
    try {
      this.setState({
        basePrice: 0,
        dealPrice: 0
      })
      const productPriceValuesInput = {
        productId
      }
      const priceValueRes = await getPriceValues(this.props.client, productPriceValuesInput)
      const priceValues = priceValueRes.data.productPriceValues.data

      if (priceValues[0]?.priceValue === 0) {
        this.setState({
          isNoExtraCostEnabled: true
        })
      } else {
        this.setState({
          isNoExtraCostEnabled: false
        })
      }
      this.formatsChannels.forEach(formatChannel => {
        const priceValue = priceValues.filter(priceV => priceV.storeFormat.id === formatChannel.storeFormat.id && priceV.channel.id === formatChannel.channel.id)[0]

        formatChannel.priceValue = priceValues[0]?.priceValue
        formatChannel.productPriceValueId = priceValue && priceValue.id
        this.setState({
          basePrice: priceValues.length ? priceValues[0]?.priceValue : 0
        })
      })
      this.getDiscountValue(productId)
    } catch (error) {
      console.log(error)
    }
  }

  updateProduct = async () => {
    const { selectedItemName, selectedProductItemTagCode, selectedProductItem, productsList } = this.state

    this.setState({
      updateProductLoading: true
    })
    const extendObj: any = {
      extend_non_veg: Boolean(selectedProductItemTagCode && selectedProductItemTagCode.toLowerCase() === PRODUCT_TAG_ENUM.NON_VEG),
      extend_veg: Boolean(selectedProductItemTagCode && selectedProductItemTagCode.toLowerCase() === PRODUCT_TAG_ENUM.VEG)
    }
    const updateProductInput = {
      id: selectedProductItem?.id,
      name: selectedItemName,
      productType: PRODUCT_TYPE_ENUM.ADDON,
      organizationId: this.org_id,
      imageUrl: this.state.imageUrl,
      extend: extendObj,
      listable: true
    }

    try {
      const res = await updateProduct(this.props.client, updateProductInput)
      const productIndex = productsList.findIndex(elem => elem.id === selectedProductItem?.id)
      const productListCopy = [...productsList]

      productListCopy.splice(productIndex, 1, res?.data?.updateProduct)
      this.props.onAddonsChange()
      this.updatePriceAndCharge(res?.data?.updateProduct)
      this.setState({
        updateProductLoading: false,
        productsList: productListCopy
      })
    } catch (error) {
      console.log(error)
    }
  }

  updatePriceAndCharge = async selectedProductItem => {
    const { basePrice, strikeOutDiscountValueData, isNoExtraCostEnabled } = this.state

    try {
      this.formatsChannels &&
        this.formatsChannels.forEach(async formatChannel => {
          if (formatChannel.productPriceValueId) {
            const updatePriceInput = {
              productPriceValueId: formatChannel.productPriceValueId,
              storeFormat: formatChannel.storeFormat.id,
              channel: formatChannel.channel.id,
              productPrice: isNoExtraCostEnabled ? 0 : parseFloat(basePrice)
            }

            await updateProductPrice(this.props.client, updatePriceInput)
          } else {
            const createPriceInput = {
              productId: selectedProductItem?.id,
              storeFormat: formatChannel.storeFormat.id,
              channel: formatChannel.channel.id,
              productPrice: isNoExtraCostEnabled ? 0 : parseFloat(basePrice)
            }

            await createProductPrice(this.props.client, createPriceInput)
            this.createProductDiscountValue(createPriceInput)
          }
        })
      setSuccessMessage('category.successMsg.itemUpdatedSuccessfully', this.props.intl)
      if (strikeOutDiscountValueData) {
        await this.updateProductDiscountValue()
      }
      republishCatalog(this.props.client)
    } catch (error) {
      console.log(error)
    }
  }

  updateProductDiscountValue = async () => {
    const { discountValue, productDiscountValueId, isNoExtraCostEnabled } = this.state

    try {
      const payload = []

      productDiscountValueId.forEach(elem => {
        payload.push({
          productDiscountValueId: elem,
          discountValue: isNoExtraCostEnabled ? 0 : Math.round(discountValue)
        })
      })
      const productDiscountValueIdArr = []
      const updateRes = await updateDiscountForProducts(this.props.client, payload)

      updateRes.data?.updateDiscountValueForProducts.forEach(elem => {
        productDiscountValueIdArr.push(elem.id)
      })
      this.setState({
        productDiscountValueId: productDiscountValueIdArr
      })
    } catch (error) {
      console.log(error, 'error')
    }
  }

  createProductDiscountValue = async productDetails => {
    const { discountValue, selectedProductItem, isNoExtraCostEnabled } = this.state
    const strikeOutDiscountType = getStrikeoutDiscountType()

    try {
      const discountInput = {
        productId: productDetails.productId,
        storeFormat: productDetails.storeFormat,
        channel: productDetails.channel,
        discountValue: isNoExtraCostEnabled ? 0 : Math.round(discountValue),
        discountType: strikeOutDiscountType.id,
        discountValueType: DISCOUNT_VALUE_TYPE_ENUM.ABSOLUTE
      }
      const updateRes = await createProductDiscountValue(this.props.client, discountInput)

      this.setState({
        productDiscountValueId: updateRes?.data?.createDiscountValueForProduct?.id
      })
      this.getDiscountValue(selectedProductItem.id)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  getDiscountValue = productId => {
    const { basePrice } = this.state
    const strikeOutDiscountType = getStrikeoutDiscountType()
    const productDiscountValueIdArr = []

    this.formatsChannels.forEach(async formatChannel => {
      let newDealPrice = basePrice

      try {
        const payload = {
          storeFormat: formatChannel.storeFormat.id,
          channel: formatChannel.channel.id,
          productId,
          discountType: strikeOutDiscountType.id,
          discountValueType: DISCOUNT_VALUE_TYPE_ENUM.ABSOLUTE
        }

        getProductDiscountValue(this.props.client, payload)
          .then(res => {
            productDiscountValueIdArr.push(res.data?.productDiscountValue.id)
            newDealPrice = basePrice - res.data?.productDiscountValue.discountValue
            this.setState({
              productDiscountValueId: productDiscountValueIdArr,
              discountValue: res.data?.productDiscountValue.discountValue,
              dealPrice: newDealPrice,
              strikeOutDiscountValueData: res.data?.productDiscountValue
            })
          })
          .catch(() => {
            this.setState({
              dealPrice: basePrice,
              strikeOutDiscountValueData: null
            })
          })
      } catch (error) {
        console.log(error)
        this.setState({
          dealPrice: basePrice,
          basePrice,
          discountValue: 0
        })
      }
    })
  }

  createProduct = async () => {
    const { productItemName } = this.state
    const createProductInput = {
      productType: PRODUCT_TYPE_ENUM.ADDON,
      status: ORG_STATUS.ACTIVE,
      organizationId: this.org_id,
      categoryIds: [this.state.selectedCategory.id],
      listable: true,
      name: productItemName,
      code: getItemCode(productItemName)
    }

    try {
      this.setState({
        isLoading: true
      })
      const res = await createProduct(this.props.client, createProductInput)

      if (res.data) {
        const { createProduct } = res.data
        
        await addTaxes(this.props.client, createProduct.id)
        this.setState({
          isLoading: false,
          productItemName: ''
        })
        setSuccessMessage('category.successMsg.itemUpdatedSuccessfully', this.props.intl)
        this.getProducts(this.state.selectedCategory, true)
        this.props.onAddonsChange()
        republishCatalog(this.props.client)
      }
    } catch (error) {
      console.log(error)
      this.setState({
        isLoading: false
      })
    }
  }

  onPriceValueChanged = (newdealPrice, basePrice, fieldName) => {
    if (newdealPrice < 0 || basePrice < 0) {
      return
    }
    const discountValue = basePrice - newdealPrice

    if (isNaN(newdealPrice)) {
      this.setState({
        dealPrice: 0,
        discountValue: basePrice
      })
    }
    if (newdealPrice < basePrice && newdealPrice !== null) {
      this.setState({
        dealPrice: newdealPrice,
        discountValue,
        basePrice
      })
    } else if (newdealPrice >= basePrice) {
      if (fieldName === PRICE_TYPE.DEAL) {
        this.setState({
          dealPrice: newdealPrice,
          discountValue: 0,
          basePrice: newdealPrice
        })
      } else {
        this.setState({
          dealPrice: basePrice,
          discountValue: 0,
          basePrice
        })
      }
    }
  }

  onSelectedItem = item => {
    this.setState({
      selectedProductItem: item,
      selectedItemName: item?.name || '',
      imageUrl: item?.imageUrl || '',
      discountValue: 0,
      selectedProductItemTagCode: item?.extend ? (item?.extend?.extend_non_veg ? PRODUCT_TAG_ENUM.NON_VEG : PRODUCT_TAG_ENUM.VEG) : ''
    })
  }

  onMenuItemClickHandler = () => {
    this.setState({
      isModalOpen: true,
      isOpen: !this.state.isOpen
    })
  }

  onUserInputHandler = e => {
    this.setState({
      customizationName: e.target.value
    })
  }

  onDropdownOpenHandler = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onDealPriceHandler = value => {
    this.setState({
      dealPrice: value < 0 ? 0 : value,
      discountValue: 0
    })
  }

  onNoExtraCostInputHandler = () => {
    this.setState({
      isNoExtraCostEnabled: !this.state.isNoExtraCostEnabled,
      dealPrice: null,
      basePrice: null
    })
  }

  render() {
    const { isModalOpen, showUploader } = this.state

    return (
      <>
        <AddonForm
          {...this.state}
          intl={this.props.intl}
          onMenuItemClickHandler={this.onMenuItemClickHandler}
          createProduct={() => this.createProduct()}
          onPriceValueChanged={(newdealPrice, basePrice, fieldName) =>
            this.onPriceValueChanged(newdealPrice, basePrice, fieldName)
          }
          showImageUpload={() => this.showImageUpload()}
          getProducts={(category, isNewProductAdded) =>
            this.getProducts(category, isNewProductAdded)
          }
          onDropdownOpenHandler={() => this.onDropdownOpenHandler()}
          onSelectedItem={item => this.onSelectedItem(item)}
          getProductPrice={itemId => this.getProductPrice(itemId)}
          onProductItemNamehandler={e =>
            this.setState({ productItemName: e.target.value })
          }
          onSelectedItemNameHandler={e =>
            this.setState({ selectedItemName: e.target.value })
          }
          onSelectedProductItemTagCodeHandler={e =>
            this.setState({ selectedProductItemTagCode: e.target.value })
          }
          onBasePriceHandler={value => this.setState({ basePrice: value })}
          onDealPriceHandler={value => this.onDealPriceHandler(value)}
          updateProduct={() => this.updateProduct()}
          onNoExtraCostInputHandler={() => this.onNoExtraCostInputHandler()}
        />
        <Modal
          visible={isModalOpen}
          title=""
          width={450}
          onClose={() => this.setState({ isModalOpen: false })}>
          <Title
            level="h4"
            style={{
              marginTop: '-8px',
              color: COLORS.DARK_TEXT,
              fontWeight: '500'
            }}>
            <IntlMessages id="addons/combo.name" />
          </Title>
          <Text level="caption" style={{ marginBottom: '15px' }}>
            <IntlMessages id="addons/combo.enterName" />
          </Text>
          <Input
            placeholder={intlAlertMessage({
              id: 'addons/combo.enterName',
              intl: this.props.intl
            })}
            value={this.state.customizationName}
            style={{ marginTop: '5px' }}
            onChange={e => this.onUserInputHandler(e)}
          />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              type="primary"
              disabled={!this.state.customizationName}
              style={{ height: '35px', width: '154px', marginTop: '16px' }}
              onClick={() => this.onSubmit()}>
              <IntlMessages id="button.submit" />
            </Button>
          </div>
        </Modal>
        <FileUpload
          key={randomString(4)}
          title={<IntlMessages id="uploadImage" />}
          isVisible={showUploader}
          fileSystemId={this.fileSystemId}
          onSave={this.fileUploadSave.bind(this)}
          onCancel={this.fileUploadCancel.bind(this)}
          client={this.props.client}
          allowedSizeInMB={1}
          singleFile={true}
          imgDimension={'400x400 px'}
          fileSize={'1 MB'}
          imgProportion={'Square'}
          fileTypeList={['jpg', 'jpeg', 'png']}>
          {this.props.children}
        </FileUpload>
      </>
    )
  }
}

const AddonsComponent = compose(withRouter)(withApollo(Addons))

export default AddonsComponent
