import './styles.css'

import { Icon } from '@ant-design/compatible'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
// Import { Link } from 'react-router-dom';
import { Col, message, Modal, Row, Spin, Typography, Upload } from 'antd'
import * as jwt from 'jsonwebtoken'
import React, { ReactElement } from 'react'
import { injectIntl } from 'react-intl'
import { intlAlertMessage, randomString } from 'Utils'

import { Button, IntlMessages } from '../../components'

const Text = Typography

interface iProps extends ApolloProviderProps<any> {
  title: ReactElement
  isVisible: boolean
  fileSystemId: string
  itemName?: string
  onCancel: any
  onSave: any
  allowedSizeInMB?: number
  singleFile?: boolean
  imgDimension?: string
  fileSize?: string
  imgProportion?: string
  fileTypeList?: any
  accept?: any
  intl: any
}
interface iState {
  publicUrl: string
  isUploaded: boolean
  fileList: any
  uploadingFile: boolean
}

const { Dragger } = Upload

class FileUpload extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props)

    this.state = {
      publicUrl: null,
      isUploaded: false,
      fileList: [],
      uploadingFile: false
    }
  }

  // Async onFetchCall({ target: { validity, files } }) {
  async onFetchCall(options) {
    const { file, onSuccess, onError, onProgress } = options
    const { fileTypeList, intl } = this.props
    const ext = file.name
      .split('.')
      .pop()
      .toLowerCase()

    if (fileTypeList && fileTypeList.indexOf(ext) === -1) {
      message.warn(
        intlAlertMessage(
          { id: 'unsupportedFileUpload', intl },
          { fileTypes: this.getSupportedFileTypes() }
        )
      )
      this.setState({ fileList: [] })

      return false
    }
    const token = await localStorage.getItem('jwt')
    const { org_id }: any = jwt.decode(token)
    const { fileSystemId } = this.props
    const graphQLEndpoint = process.env.REACT_APP_WCORE_URL

    if (this.props.allowedSizeInMB !== undefined) {
      const sizeCheck = 1024 * 1024 * this.props.allowedSizeInMB
      const checkedSize = file.size / sizeCheck

      if (Math.floor(checkedSize) > 0) {
        message.warn(
          intlAlertMessage(
            { id: 'imageSizeWarning', intl },
            { size: this.props.allowedSizeInMB }
          )
        )
        onError('ok')

        return false
      }
    }

    const { name } = file
    const lastDot = name.lastIndexOf('.')
    const fileExtension = name.substring(lastDot + 1)
    const fileName = `menu_image_${randomString(16)}.${fileExtension}`
    const myHeaders = new Headers()

    myHeaders.append('accept', 'application/json')
    myHeaders.append('accept-encoding', 'gzip, deflate, br')
    myHeaders.append('connection', 'keep-alive')
    myHeaders.append('dnt', '1')
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('origin', graphQLEndpoint)
    const body: any = {
      query:
        'mutation UploadFile( $input: FileUploadInput!) { uploadFile(input: $input ){ id, name, mimeType, encoding , publicUrl}}',
      variables: {
        input: {
          file: null,
          description: `Image for ${this.props.itemName}`,
          fileSystemId: JSON.parse(fileSystemId),
          organizationId: org_id
        }
      }
    }
    const formData = new FormData()

    formData.append('operations', JSON.stringify(body))
    const map = { nFile: ['variables.input.file'] }

    formData.append('map', JSON.stringify(map))
    formData.append('nFile', file, fileName)
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData
    }

    onProgress('ok')
    this.setState({ uploadingFile: true })
    fetch(graphQLEndpoint, requestOptions)
      .then(response => response.text())
      .then(result => {
        const value = JSON.parse(result)

        this.setState({
          publicUrl: value.data.uploadFile?.publicUrl,
          isUploaded: true,
          uploadingFile: false
        })
        onSuccess('ok')
      })
      .catch(error => {
        console.log('error', error)
        this.setState({ uploadingFile: false })
        onError('ok')
      })
  }

  onChange = info => {
    const { fileTypeList } = this.props
    const ext = info.file.name
      .split('.')
      .pop()
      .toLowerCase()

    if (fileTypeList && fileTypeList.indexOf(ext) !== -1) {
      const files = info.fileList.length ? [info.file] : []

      this.setState({ fileList: files })
      if (!files.length) {
        this.setState({ isUploaded: false })
      }
    }
  }

  getSupportedFileTypes = () => {
    const { fileTypeList } = this.props
    let fileTypes = ''

    fileTypeList.forEach((type, i) => {
      if (i !== fileTypeList.length - 1 && i !== fileTypeList.length - 2) {
        fileTypes += `${type}, `
      } else if (i === fileTypeList.length - 2) {
        fileTypes += type
      } else {
        fileTypes += ` and ${type}`
      }
    })

    return fileTypes
  }

  render() {
    const {
      isVisible,
      title,
      singleFile,
      imgDimension,
      fileSize,
      imgProportion,
      fileTypeList,
      accept
    } = this.props
    const { fileList, uploadingFile } = this.state
    const props = {
      customRequest: this.onFetchCall.bind(this),
      name: 'imageUpload',
      multiple: !singleFile
    }

    if (singleFile) {
      props['onChange'] = this.onChange
      props['fileList'] = fileList
    }

    return (
      <Modal
        title={title}
        width="550px"
        visible={isVisible}
        onCancel={this.props.onCancel}
        footer={null}>
        <div>
          <Dragger {...props} accept={accept}>
            {uploadingFile ? (
              <div className="file-loader">
                <Spin size="large" />
              </div>
            ) : (
              <div>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  <IntlMessages id="component.clickOrDragFile" />
                </p>
                {this.props.allowedSizeInMB !== undefined && (
                  <p className="ant-upload-hint">
                    <IntlMessages id="imageUpload.uploadImgSize" />{' '}
                    {this.props.allowedSizeInMB} MB
                  </p>
                )}
              </div>
            )}
          </Dragger>
          <br />
          <Row>
            <Col span={10} style={{ borderRight: '1px solid #E6E6E6' }}>
              {imgDimension && (
                <Text className="img-info">
                  <IntlMessages id="imageUpload.imageDimensions" />:{' '}
                  {imgDimension}
                </Text>
              )}
              {fileSize && (
                <Text className="img-info">
                  <IntlMessages id="imageUpload.maximumFileSize" />: {fileSize}
                </Text>
              )}
            </Col>
            <Col span={14}>
              {imgProportion && (
                <Text className="img-info">
                  <IntlMessages id="component.imageUpload.imageProportion" />:{' '}
                  {imgProportion}
                </Text>
              )}
              {fileTypeList && (
                <Text className="img-info">
                  <IntlMessages id="component.imageUpload.supportImgFormat" />:{' '}
                  {this.getSupportedFileTypes()}
                </Text>
              )}
            </Col>
          </Row>
          <Row className="btnRow">
            <Button
              type='primary'
              style={{ width: '25%' }}
              disabled={!this.state.isUploaded || uploadingFile}
              onClick={() => {
                this.props.onSave(this.state.publicUrl)
              }}>
              <IntlMessages id="button.save" />
            </Button>
          </Row>
        </div>
      </Modal>
    )
  }
}
export default injectIntl(FileUpload)
