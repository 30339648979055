import { Icon } from '@ant-design/compatible'
import { Card, Col, Row, Typography } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'
const Text = Typography
const CatalogueCard = ({ catalogue, isAdmin }) => {
  return (
    <Card className="menu-card">
      <Row>
        <Col span={!catalogue.listable && isAdmin ? 15 : 22}>
          <Text style={{ fontWeight: 'bold' }}>{catalogue.name}</Text>
        </Col>
        {!catalogue.listable && isAdmin && (
          <Col span={7} className="no-pd-right">
            <Text className="not-verified-text">
              <IntlMessages id="catalogs.notVerified" />
            </Text>
            <Icon className="not-verified-icon" type="exclamation-circle" />
          </Col>
        )}
        <Col span={2} className="no-pd-left">
          <span>
            <Icon type="right" />
          </span>
        </Col>
      </Row>
    </Card>
  )
}

export default CatalogueCard
