import { Icon } from '@ant-design/compatible'
import { Button as AntdButton, Modal, Upload } from 'antd'
import { getOrientation } from 'get-orientation/browser'
import React, { useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import styled from 'styled-components'
import { randomString } from 'Utils'
import { COLORS } from 'Utils/constants'

import { Button, Text, Title } from '../index'
import { createImage, getCroppedImg, getRotatedImage, readFile } from './util'

interface UploadConfig {
  /**
   * Organization Id
   */
  organizationId: string
  /**
   * System Id
   */
  systemId: string
  /**
   * Graphql end point url
   */
  graphqlUrl: string
  /**
   * Image description
   */
  description?: string
  /**
   * Auth Token
   */
  authToken: string
}

export interface ImageUploadProps {
  /**
   * Width of cropped image
   */
  cropWidth?: number
  /**
   * Height of cropped image
   */
  cropHeight?: number
  /**
   * Aspect ratio of cropped image. The value is the ratio between its width and its height. The default value is 1/1, it will be ignored if cropWidth and cropHeight is provided
   */
  aspectRatio?: number
  /**
   * Callback function on upload
   */
  onUpload?: (string) => void
  /**
   * Callback function on file size error
   */
  onSizeError?: () => void
  /**
   * Callback function on file dimension error
   */
  onDimensionError?: () => void
  /**
   * Callback function on file type error
   */
  onFileTypeError: () => void
  /**
   * Supported file types to upload
   */
  supportedFileTypes?: Array<string>
  /**
   * How large should the file be?
   */
  allowedFileSize?: number
  /**
   * Apply custom css
   */
  style?: object
  /**
   * Original image dimension should be validated against crop width and height ?
   */
  validateDimension?: boolean
  /**
   * Output image dimension should be same as of cropWidth and cropHeight?
   */
  fixedDimension?: boolean
  /**
   * ReUpload image allowed ?
   */
  reUpload?: boolean
  /**
   * Configuration used for image upload
   */
  uploadApiConfig?: UploadConfig
  /**
   * Is crop feature required
   */
  isCrop?: boolean
  /**
   * Preview Url to display already uploaded image
   */
  previewUrl?: string
}

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90
}
const MODAL_WIDTH = 800
const SUPPORTED_FILE_TYPES = ['jpeg', 'png', 'jpg']
const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  .ant-btn-icon-only.ant-btn-sm {
    width: 36px;
    height: 36px;
  }
  .reactEasyCrop_CropArea {
    border: 1px dashed ${COLORS.PRIMARY};
  }
`
const RotationControlsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: ${MODAL_WIDTH - 128}px;
`
const ZoomControlsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: ${MODAL_WIDTH - 46}px;
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-btn-group .ant-btn + .ant-btn,
  .ant-btn + .ant-btn-group,
  .ant-btn-group span + .ant-btn,
  .ant-btn-group .ant-btn + span,
  .ant-btn-group > span + span,
  .ant-btn-group + .ant-btn,
  .ant-btn-group + .ant-btn-group {
    margin-top: -1px;
    margin-left: 0;
  }
`
const RotationButtonGroup = styled(AntdButton.Group)`
  .ant-btn {
    margin-bottom: 0;
  }
`
const IconButton = styled(AntdButton)`
  &.ant-btn-default {
    color: ${COLORS.GRAY_TEXT};
    &:hover {
      color: ${COLORS.PRIMARY};
      border-color: ${COLORS.PRIMARY};
    }
  }
`
const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    background-color: ${COLORS.BG_LIGHT};
    border-color: ${COLORS.BORDER_DARK};
    margin-right: 0px;
    margin-bottom: 0px;
    &:hover {
      border-color: ${COLORS.PRIMARY};
    }
  }
  .anticon {
    color: ${COLORS.BORDER_DARK};
  }
`
const CropModal = styled(Modal)`
  .ant-modal-footer {
    text-align: left;
  }
  &.ant-modal {
    top: 70px;
  }
`

export const ImageUpload: React.FC<ImageUploadProps> = ({
  validateDimension = false,
  cropWidth = 512,
  cropHeight = 512,
  aspectRatio = 1 / 1,
  fixedDimension = true,
  reUpload = false,
  supportedFileTypes = SUPPORTED_FILE_TYPES,
  isCrop = true,
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [visible, setVisible] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [croppedImage, setCroppedImage] = useState(props.previewUrl || null)
  const [fileList, setFileList] = useState(
    props.previewUrl && props.previewUrl.length > 5
      ? [
        {
          uid: '-1',
          name: 'image',
          status: 'done',
          url: props.previewUrl
        }
      ]
      : []
  )
  const [loading, setLoading] = useState(false)
  const [cropping, setCropping] = useState(false)
  const [file, setFile] = useState(null)
  const [originalFile, setOriginFile] = useState(null)
  const cropSize = { width: cropWidth, height: cropHeight }
  const { onUpload, uploadApiConfig } = props
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  useEffect(() => {
    if (!croppedImage) return
    const fileList = [
      {
        uid: '-1',
        name: 'image',
        status: 'done',
        url: croppedImage
      }
    ]

    setFileList(fileList)
    setCroppedImage(croppedImage)
    setLoading(false)
  }, [croppedImage])

  const updateCroppedImage = useCallback(async () => {
    try {
      setCropping(true)
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        fixedDimension ? cropSize : null,
        rotation
      )

      setCropping(false)
      setVisible(false)
      if (uploadApiConfig) {
        // Debugger;
        setFile(croppedImage)
      } else {
        setLoading(true)
        const imageUrl = URL.createObjectURL(croppedImage)

        setCroppedImage(imageUrl)
        const fileList = [
          {
            uid: '-3',
            name: originalFile.name,
            status: 'done',
            url: imageUrl
          }
        ]

        setLoading(false)
        setFileList(fileList)
        onUpload(imageUrl)
      }
    } catch (e) {
      console.error(e)
    }
  }, [
    imageSrc,
    croppedAreaPixels,
    rotation,
    cropSize,
    fixedDimension,
    onUpload,
    uploadApiConfig,
    originalFile
  ])
  const onClose = useCallback(() => {
    setCroppedImage(null)
    setVisible(false)
  }, [])
  const onFileChange = async info => {
    if (!beforeUpload(info.file)) return false
    const files = info.fileList.length ? [info.file] : []

    if (files && files.length > 0) {
      const file = files[0]

      if (!isCrop) {
        setFile(file)

        return
      }
      let imageDataUrl = await readFile(file.originFileObj)

      if (validateDimension && cropWidth && cropHeight) {
        const image = await createImage(imageDataUrl)
        const isLtCropDimension =
          image.width < cropWidth || image.height < cropHeight

        if (isLtCropDimension) {
          props.onDimensionError && props.onDimensionError()

          return false
        }
      }
      setOriginFile(file)

      // Apply rotation if needed
      const orientation = await getOrientation(file.originFileObj)
      const rotation = ORIENTATION_TO_ANGLE[orientation]

      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      setZoom(1)
      setRotation(0)
      setImageSrc(imageDataUrl)
      setVisible(true)
    }
  }
  const onRotationChange = rotationType => {
    const max = 360
    const min = 0
    const step = 1

    if (rotationType === 'clockwise') {
      if (rotation === max) return
      setRotation(rotation + step)
    } else {
      if (rotation === min) return
      setRotation(rotation - step)
    }
  }
  const onZoomChange = zoomType => {
    const max = 3
    const min = 1
    const step = 0.1

    if (zoomType === 'in') {
      if (zoom === max) return
      setZoom(zoom + step)
    } else {
      if (zoom === min) return
      setZoom(zoom - step)
    }
  }
  const handlePreview = () => {
    setPreviewVisible(true)
  }
  const handleCancel = () => {
    setPreviewVisible(false)
  }
  const handleRemove = () => {
    setCroppedImage(null)
    setFileList([])
  }
  const beforeUpload = file => {
    const fileTypes = supportedFileTypes.map(t => `image/${t}`)
    const isSupportedType = fileTypes.indexOf(file.type) !== -1

    if (!isSupportedType) {
      props.onFileTypeError()

      return false
    }
    if (props.allowedFileSize) {
      const isLtAllowed = file.size / 1024 / 1024 < props.allowedFileSize

      if (!isLtAllowed) {
        props.onSizeError()

        return false
      }
    }

    return true
  }

  useEffect(() => {
    if (!uploadApiConfig || !file) return
    const myHeaders = new Headers()

    myHeaders.append('accept', 'application/json')
    myHeaders.append('accept-encoding', 'gzip, deflate, br')
    myHeaders.append('connection', 'keep-alive')
    myHeaders.append('dnt', '1')
    myHeaders.append('Authorization', `Bearer ${uploadApiConfig.authToken}`)
    myHeaders.append('origin', uploadApiConfig.graphqlUrl)
    const body: any = {
      query:
        'mutation UploadFile( $input: FileUploadInput!) { uploadFile(input: $input ){ id, name, mimeType, encoding , publicUrl}}',
      variables: {
        input: {
          file: null,
          description: uploadApiConfig.description
            ? uploadApiConfig.description
            : '',
          fileSystemId: uploadApiConfig.systemId,
          organizationId: uploadApiConfig.organizationId
        }
      }
    }
    const formData = new FormData()

    formData.append('operations', JSON.stringify(body))
    const map = { nFile: ['variables.input.file'] }

    formData.append('map', JSON.stringify(map))
    formData.append('nFile', file, `image_${randomString(16)}`)
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData
    }

    setLoading(true)
    fetch(uploadApiConfig.graphqlUrl, requestOptions)
      .then(response => response.text())
      .then(result => {
        const value = JSON.parse(result)

        onUpload(value.data.uploadFile.publicUrl)
        setLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setLoading(false)
      })
  }, [
    file,
    uploadApiConfig?.organizationId,
    uploadApiConfig?.systemId,
    uploadApiConfig?.graphqlUrl,
    uploadApiConfig?.description,
    uploadApiConfig?.authToken,
    uploadApiConfig
  ])

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div>
        <Text level="caption">Upload</Text>
      </div>
    </div>
  )

  return (
    <div>
      <React.Fragment>
        <StyledUpload
          listType="picture-card"
          fileList={fileList}
          onChange={onFileChange}
          onPreview={handlePreview}
          showUploadList={{ showRemoveIcon: reUpload }}
          onRemove={handleRemove}
          accept="image/png, image/jpeg, image/jpg">
          {fileList.length >= 1 ? null : uploadButton}
        </StyledUpload>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={croppedImage} />
        </Modal>
        <CropModal
          visible={visible}
          title={<Title level="h4">Crop Image</Title>}
          onOk={updateCroppedImage}
          onCancel={onClose}
          width={MODAL_WIDTH}
          bodyStyle={{ padding: 0 }}
          footer={[
            <Button
              loading={cropping}
              key="submit"
              type="primary"
              onClick={updateCroppedImage}
              style={{ width: 116 }}>
              Save
            </Button>
          ]}>
          <CropContainer>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspectRatio}
              showGrid={false}
              cropSize={{ width: cropWidth, height: cropHeight }}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <RotationControlsContainer>
              <RotationButtonGroup>
                <IconButton
                  type="default"
                  size="small"
                  icon={<Icon type="undo" />}
                  onClick={() => onRotationChange('anticlockwise')}
                />
                <IconButton
                  type="default"
                  size="small"
                  icon={<Icon type="redo" />}
                  onClick={() => onRotationChange('clockwise')}
                />
              </RotationButtonGroup>
            </RotationControlsContainer>
            <ZoomControlsContainer>
              <RotationButtonGroup>
                <IconButton
                  type="default"
                  size="small"
                  icon={<Icon type="zoom-in" />}
                  onClick={() => onZoomChange('in')}
                />
                <IconButton
                  type="default"
                  size="small"
                  icon={<Icon type="zoom-out" />}
                  onClick={() => onZoomChange('out')}
                />
              </RotationButtonGroup>
            </ZoomControlsContainer>
          </CropContainer>
        </CropModal>
      </React.Fragment>
    </div>
  )
}
