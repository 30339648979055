import '../index.css'

import { Icon } from '@ant-design/compatible'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import {
  Affix,
  AutoComplete,
  Button as AntdButton,
  Col,
  Drawer,
  Input,
  InputNumber,
  Layout,
  List,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Typography
} from 'antd'
import {
  AutoCompleteSearch,
  Button,
  IntlMessages,
  Text,
  Title
} from 'components'
import { History } from 'history'
import jwt from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import {
  arrayMove,
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc'
import {
  addCatalogCharge,
  addCatalogTax,
  createChargeType,
  createDiscountForProducts,
  createTaxType,
  fetchStoreInventory,
  getCatalogDetails,
  getChargeTypes,
  getChargeValues,
  getDiscountValues,
  getPriceValues,
  getProductsByCategory,
  getTaxTypes,
  getTaxValues,
  removeFormatChannelCharge,
  removeFormatChannelTax,
  republishCatalog,
  storeProductsAvailability,
  updateCatalogCharge,
  updateCatalogTax,
  updateCategorySortSeq,
  updateChargeForProducts,
  updateDiscountForProducts,
  updatePriceForProducts
} from 'services'
import {
  addWindowEventListener,
  getStrikeoutDiscountType,
  getUserDetails,
  handleBeforeUnload,
  intlAlertMessage,
  parallelCall,
  removeWindowEventListener,
  setErrMsg } from 'Utils'
import { getAddonsCombosAtLast, getItemColumns } from 'Utils/catalogDetailsHelper'
import {
  ACTIONS,
  COLORS,
  CONSOLE_MENU_CATEGORY_STATUS,
  MENU_HELP_URL,
  ORG_CONSTANT,
  PRICE_TYPE,
  ROLE_TYPE,
  WINDOW_EVENTS
} from 'Utils/constants'
import { getAuthToken, getChannelsData, getStoreFormats, getUserData, setUserData } from 'Utils/localStorageHandlers'
import { fetchAndUpdateDiscountTypes, getNewDealPrice, getPriceDataWithProductId, getProductsToCreate, getProductsToUpdate, getProductsWithUpdatedPropertyId, getUpdateCategorySortSeqInput,getValidatedPrices, prepareChargeValueUpdateInput, prepareDiscountValueCreateInput, prepareDiscountValueUpdateInput, prepareFormatChannelPrices, prepareOriginalPrices, preparePriceValueUpdateInput, updateOriginalPricesWithDiscountValue, updateOriginalPricesWithPackagingPrice, updateOriginalPricesWithProductPrice } from 'Utils/menuUtils'

import {
  PRODUCT_AVAILABILITY_CHECK,
  UPDATE_CATALOG,
  UPDATE_CATEGORY,
  UPDATE_PRODUCT
} from '../../../services/query'
import {
  CategoryRow,
  // PercentChangeDealForm,
  PercentageChangeInput,
  StrikeOutConfirmationModal
} from '../components'
import {
  DraftModeContainer,
  Header,
  HelpButtonContainer,
  OnboardingTextContainer,
  SearchContainer,
  StyledDiv,
  UndoAllBtnContainer,
  VerifyButtonContainer
} from './style'
const { Text: AntdText } = Typography
const { Option } = AutoComplete
const { TabPane } = Tabs
const { Content } = Layout
// Const { SubMenu } = Menu;

interface CatalogDetailProps extends ApolloProviderProps<any> {
  history: History
  updateCatalog?: any
  updateProduct?: any
  updateCategory?: any
  storeProductAvailability?: any
  intl: any
}
interface CatalogDetailState {
  productDataSource: any
  isFetching: boolean
  rowIndex: number
  catalogId: any
  catalogName: string
  isAdmin: boolean
  catalog: object
  isCatalogNameEdit: boolean
  products: any
  channels: any
  storeFormats: any
  selectedChannelIndex: number
  selectedFormatIndex: number
  selectedFormatChannel: string
  selectedFormatChannelKey: string
  showTaxForm: boolean
  taxTypes: any
  taxTypeValues: any
  showChargesForm: boolean
  chargeTypes: any
  chargeTypeValues: any
  showPackagingChargeForm: boolean
  packagingCharge: any
  grossTax: number
  savingForm: boolean
  duplicateName: boolean
  isSamePackagingCharge: boolean
  selectedCategory: any
  categories: any
  storeManager: any
  storeInventory: any
  unavailableStoreItems: any
  catalogCount: number
  unavailableItemsCheck: boolean
  storeInventoryForSearch: any
  markAllAvailable: boolean
  itemAvailabilityChanged: boolean
  itemIndexWithUpdatedAvailability: any
  isConfirmProcessing: boolean
  isDraftMode: boolean
  showStrikeoutModal: boolean
  strikeoutModalTitle: any
  strikeoutModalDesc: any
  strikeoutModalCancelBtnText: any
  strikeoutModalOkBtnText: any
  strikeoutModalCancelBtnHandler: any
  strikeoutModalOkBtnHandler: any
  strikeoutModalOkBtnType: any
  publishingMenu: boolean
  menuHeaderOffSetTop: number
  selectedItemRowIndex: number
  isDrawerVisible: boolean
  showChangePrice: boolean
  isReordering: boolean
}

const SortableCategoryItem = SortableElement(CategoryRow)

const CategoryList = ({ intl, rowIndex, categories, isReordering, isAdmin, showCategoryProduct, onSwitchChange, onReorderCategory }) => {
  return (
    <div className="category-table-body-wrapper">
      <List dataSource={categories ? categories : []} loading={isReordering} renderItem={(category: any, index) => <SortableCategoryItem intl={intl} distance={1} key={category.id} index={index} elem={index} category={category} rowIndex={rowIndex} isAdmin={isAdmin} showCategoryProduct={showCategoryProduct} categories={categories} onSwitchChange={() => onSwitchChange(category, index)} onReorderCategory={onReorderCategory} />} />
    </div>
  )
}

const SortableCategoryList = SortableContainer(CategoryList)

class CatalogDetail extends React.Component<
  CatalogDetailProps,
  CatalogDetailState
> {
  processing: boolean

  isCategoryFetching: boolean

  isPriceFetch: boolean

  org_id: string | { [key: string]: any }

  taxTypeValues: any

  chargeTypeValues: any

  productDataSource: any

  categories: any

  filterOption: string

  selectedCatalogProducts: any

  isOnboarding: boolean

  roles: any

  stores: any

  packagingCharge: any

  catalogCategories: any

  searchedItemName: string

  categoriesWithProducts: any

  originalPrices: any

  unblock: any

  targetLocation: any

  updatedPrices: any

  discountType: any

  formatChannelPrices: any

  tempData: any

  constructor(props: CatalogDetailProps) {
    super(props)
    const userData = getUserDetails()
    const roles = userData ? userData.roles : []
    const stores = userData ? userData.store : []
    let isAdmin = false

    roles.forEach(element => {
      if (element.name === ROLE_TYPE.ADMIN) {
        isAdmin = true
      }
    })
    this.stores = stores
    this.roles = roles
    this.processing = false
    this.isCategoryFetching = false
    this.isPriceFetch = true
    this.productDataSource = []
    this.categories = []
    this.filterOption = ''
    this.searchedItemName = ''
    this.originalPrices = []
    this.discountType = {}
    this.formatChannelPrices = []
    this.state = {
      productDataSource: [],
      isFetching: false,
      catalogId: '',
      catalogName: '',
      isCatalogNameEdit: false,
      catalog: {},
      rowIndex: 0,
      isAdmin,
      products: [],
      channels: [],
      storeFormats: [],
      selectedChannelIndex: 0,
      selectedFormatIndex: 0,
      selectedFormatChannel: '',
      selectedFormatChannelKey: '',
      showTaxForm: false,
      grossTax: 0,
      taxTypes: [],
      taxTypeValues: [],
      showChargesForm: false,
      savingForm: false,
      duplicateName: false,
      chargeTypes: [],
      chargeTypeValues: [],
      showPackagingChargeForm: false,
      packagingCharge: {},
      isSamePackagingCharge: true,
      selectedCategory: {},
      categories: [],
      storeManager: stores[0],
      storeInventory: [],
      storeInventoryForSearch: {},
      catalogCount: 0,
      unavailableItemsCheck: false,
      unavailableStoreItems: [],
      markAllAvailable: false,
      itemAvailabilityChanged: false,
      itemIndexWithUpdatedAvailability: [],
      isConfirmProcessing: false,
      isDraftMode: false,
      showStrikeoutModal: false,
      strikeoutModalTitle: '',
      strikeoutModalDesc: '',
      strikeoutModalCancelBtnText: '',
      strikeoutModalOkBtnText: '',
      strikeoutModalCancelBtnHandler: null,
      strikeoutModalOkBtnHandler: null,
      strikeoutModalOkBtnType: '',
      publishingMenu: false,
      menuHeaderOffSetTop: 0,
      selectedItemRowIndex: null,
      isDrawerVisible: false,
      showChangePrice: false,
      isReordering: false
    }
  }

  componentDidMount() {
    const { selectedChannelIndex, selectedFormatIndex } = this.state
    const offsetTop = document.getElementsByClassName('OrderX-Topbar')[0]
      .clientHeight
    const jwtToken: any = getAuthToken()

    this.org_id = jwt.decode(jwtToken).org_id
    const urlDetails = this.props.history.location.pathname.split('/')
    const data = this.props.history.location.state
      ? this.props.history.location.state
      : {}
    const userData = getUserData()
    const roles = userData ? userData.roles : []
    const { catalog } = data
    const { catalogCount } = data

    this.isOnboarding = Boolean(data.isOnboarding)
    let isAdmin = false

    roles.forEach(element => {
      if (element.name === ROLE_TYPE.ADMIN) {
        isAdmin = true
      }
    })
    const channels = getChannelsData()
    const formats = getStoreFormats() || null

    let selectedFormatChannel = ''

    if (channels && channels.length && formats && formats.length) {
      selectedFormatChannel = `${formats[0].name}/${channels[0].name}`
    }
    this.formatChannelPrices = prepareFormatChannelPrices(
      formats,
      channels
    ).slice(1)
    this.setState(
      {
        catalogId: urlDetails[urlDetails.length - 2],
        catalog,
        catalogName: catalog ? catalog.name : null,
        channels,
        storeFormats: formats,
        selectedFormatChannel,
        selectedFormatChannelKey: `${selectedFormatIndex}-${selectedChannelIndex}`,
        catalogCount,
        isAdmin,
        menuHeaderOffSetTop: offsetTop
      },
      async () => {
        await fetchAndUpdateDiscountTypes(this.props.client)
        this.discountType = getStrikeoutDiscountType()
        this.getCatalogDetails()
      }
    )
    if (!isAdmin) {
      this.getStoreInventory()
    }
    this.unblock = this.props.history.block(targetLocation => {
      this.targetLocation = targetLocation
      if (this.state.isDraftMode) {
        this.launchLeavePageConfirmationModal(
          () => {
            this.goToTargetLocation()
          },
          () => {
            this.setState({ showStrikeoutModal: false })
          }
        )

        return false
      }
    })
  }

  goToTargetLocation = () => {
    if (this.state.isDraftMode) {
      removeWindowEventListener(
        WINDOW_EVENTS.BEFORE_UNLOAD,
        this.handleUnsavedMenu
      )
    }
    this.setState({ isDraftMode: false })
    if (this.unblock) this.unblock()
    if (this.targetLocation.pathname === '/orderx/onboarding') {
      this.goBackToOnboarding()
    } else {
      this.props.history.push({ pathname: this.targetLocation.pathname })
    }
  }

  componentWillUnmount = () => {
    if (
      this.isOnboarding &&
      this.props.history.location.pathname === '/orderx/onboarding'
    ) {
      this.goBackToOnboarding()
    }
    this.unblock && this.unblock()
    if (this.state.isDraftMode) {
      removeWindowEventListener(
        WINDOW_EVENTS.BEFORE_UNLOAD,
        this.handleUnsavedMenu
      )
    }
  }

  setStoreInventoryForSearch = categoriesWithProducts => {
    if (!categoriesWithProducts || !categoriesWithProducts.length) return
    this.categoriesWithProducts = categoriesWithProducts
    let products = categoriesWithProducts.flatMap(category => category.products)

    if (this.filterOption === 'listable') {
      products = products.filter(p => p.listable)
    }
    /*
     * If (this.filterOption === 'non-listable') {
     *   products = products.filter(p => !p.listable);
     * }
     */
    const mappedStoreInventory = {}

    products.forEach(item => {
      mappedStoreInventory[item.name] = item
    })
    this.setState({
      storeInventoryForSearch: mappedStoreInventory
    })
  }

  getCatalogDetails = async () => {
    const { catalogId, isAdmin } = this.state

    try {
      this.isPriceFetch = true
      const catalogDetailsRes = await getCatalogDetails(
        this.props.client,
        catalogId
      )
      const newCatalog = catalogDetailsRes?.data?.catalog

      this.setState({ catalog: newCatalog, catalogName: newCatalog.name })
      if (newCatalog?.categories?.length) {
        this.catalogCategories = catalogDetailsRes.data.catalog.categories
        const catalogProducts = catalogDetailsRes.data.catalog.categories.flatMap(
          category => category.products
        )

        this.selectedCatalogProducts = catalogProducts.map(p => p.id)
        this.originalPrices = prepareOriginalPrices(
          this.selectedCatalogProducts.map(selectedCatalogProduct => {
            return { id: selectedCatalogProduct, tag: '' }
          })
        )
        if (isAdmin) this.setStoreInventoryForSearch(this.catalogCategories)
      }
      this.getAllTaxTypes()
      this.getAllChargeTypes()
    } catch (err) {
      console.warn('Get Catalog Details error:', err)
    }
  }

  getAllTaxTypes = async () => {
    try {
      const taxTypesRes = await getTaxTypes(this.props.client, this.org_id)
      const taxTypes = taxTypesRes.data.taxTypes.data.map(taxType => {
        return {
          ...taxType,
          selected: false
        }
      })

      this.setState(
        prevState => {
          return {
            ...prevState,
            taxTypes
          }
        },
        () => this.getTaxValues(false)
      )
    } catch (err) {
      console.warn('Get Tax types error:', err)
    }
  }

  getAllChargeTypes = async () => {
    try {
      const chargeTypesRes = await getChargeTypes(this.props.client)
      const packagingChargeType = chargeTypesRes.data.chargeTypes.filter(
        chargeType => chargeType.chargeTypeCode === ORG_CONSTANT.CHARGE
      )[0]
      const additionalCharges = chargeTypesRes.data.chargeTypes.filter(
        chargeType => chargeType.chargeTypeCode !== ORG_CONSTANT.CHARGE
      )
      const chargeTypes = additionalCharges.map(chargeType => {
        return {
          ...chargeType,
          selected: false
        }
      })
      const packagingCharge = {
        chargeType: packagingChargeType,
        chargeValue: null
      }

      this.packagingCharge = { ...packagingCharge }
      this.setState(
        prevState => {
          return {
            ...prevState,
            chargeTypes,
            packagingCharge
          }
        },
        () => {
          this.getPrices()
          this.getChargeValues(false)
        }
      )
    } catch (err) {
      console.warn('Get charge types error:', err)
    }
  }

  getTaxValues = async closeTaxForm => {
    const {
      taxTypes,
      storeFormats,
      channels,
      selectedChannelIndex,
      selectedFormatIndex
    } = this.state
    const taxTypeValuesInput = {
      storeFormat: storeFormats[selectedFormatIndex].id,
      channel: channels[selectedChannelIndex].id,
      productId: this.selectedCatalogProducts,
      taxLevel: ''
    }
    const taxTypeValues = []
    let grossTax = 0

    /* eslint-disable no-await-in-loop */
    for (const taxType of taxTypes) {
      taxTypeValuesInput.taxLevel = taxType.id
      try {
        const taxTypeValuesRes = await getTaxValues(
          this.props.client,
          taxTypeValuesInput
        )
        const taxTypeValue = {
          taxType,
          taxValueId: '',
          taxValue: '',
          removed: false
        }

        if (
          taxTypeValuesRes &&
          taxTypeValuesRes.data &&
          taxTypeValuesRes.data.productTaxTypeValues &&
          taxTypeValuesRes.data.productTaxTypeValues.data &&
          taxTypeValuesRes.data.productTaxTypeValues.data.length
        ) {
          const value = taxTypeValuesRes.data.productTaxTypeValues.data[0]

          taxTypeValue.taxValueId = value.id
          taxTypeValue.taxValue = value.taxValue
          taxTypeValues.push(taxTypeValue)
          taxType.selected = true
          grossTax += value.taxValue
        }
      } catch (err) {
        console.warn('Get Tax Values error:', err)
      }
    }
    this.taxTypeValues = [...taxTypeValues]
    this.setState(
      {
        taxTypeValues: [...taxTypeValues],
        taxTypes,
        grossTax
      },
      () => {
        if (closeTaxForm) {
          this.setState({ savingForm: false })
          this.toggleTaxForm()
        }
      }
    )
  }

  getChargeValues = async closeChargeForm => {
    const {
      chargeTypes,
      storeFormats,
      channels,
      selectedChannelIndex,
      selectedFormatIndex
    } = this.state
    const chargeTypeValuesInput = {
      storeFormat: storeFormats[selectedFormatIndex].id,
      channel: channels[selectedChannelIndex].id,
      productId: this.selectedCatalogProducts,
      chargeType: ''
    }
    const chargeTypeValues = []

    /* eslint-disable no-await-in-loop */
    for (const chargeType of chargeTypes) {
      chargeTypeValuesInput.chargeType = chargeType.id
      try {
        const chargeTypeValuesRes = await getChargeValues(
          this.props.client,
          chargeTypeValuesInput
        )
        const chargeTypeValue = {
          chargeType,
          chargeValueId: '',
          chargeValue: '',
          removed: false
        }

        if (
          chargeTypeValuesRes?.data?.productChargeValues?.data?.length
        ) {
          const value = chargeTypeValuesRes.data.productChargeValues.data[0]

          chargeTypeValue.chargeValueId = value.id
          chargeTypeValue.chargeValue = value.chargeValue
          chargeTypeValues.push(chargeTypeValue)
          chargeType.selected = true
        }
      } catch (err) {
        console.warn('Get charge Values error:', err)
      }
    }
    this.chargeTypeValues = [...chargeTypeValues]
    this.setState(
      {
        chargeTypeValues: [...chargeTypeValues],
        chargeTypes
      },
      () => {
        if (closeChargeForm) {
          this.setState({ savingForm: false })
          this.toggleChargeForm()
        }
      }
    )
  }

  async getAllProductByCategory(category) {
    const { isAdmin, storeInventory } = this.state
    const productDataSource = []
    let filteredProductDataSource
    let products

    this.setState({ isFetching: true, selectedCategory: category })
    const productsByCategoryRes = await getProductsByCategory(
      this.props.client,
      category.id
    ).catch(err => {
      console.warn('Product List Error:', err)
      this.setState({ isFetching: false })
    })
    let items = productsByCategoryRes.data.productCategoriesByCategoryId

    if (!isAdmin) {
      items = items.filter(item => item.product.listable === true)
    }

    if (items.length <= 0) {
      this.setState({ isFetching: false, productDataSource: [] })
    } else {
      // eslint-disable-next-line
      items.forEach((product, productIndex) => {
        const productBasePrice = this.formProductBasePrice(product.product.id)
        const productDealPrice = this.formProductDealPrice(product.product.id)
        const productPackagingCharge = this.formProductPackagingCharge(
          product.product.id
        )

        if (isAdmin) {
          const productListableOrStockCheckActions = this.formProductListableCheckActions(
            category,
            product.product,
            productIndex
          )

          productDataSource.push({
            rowIndex: productIndex,
            key: product.product.id,
            items: this.formItemAction(product.product),
            isListable: product.product.listable,
            listable: productListableOrStockCheckActions,
            basePrice: productBasePrice,
            dealPrice: productDealPrice,
            packingCharge: productPackagingCharge,
            itemName: product.product.name
          })

          if (this.filterOption === 'listable') {
            filteredProductDataSource = productDataSource.filter(
              item => item.isListable
            )
          } else {
            filteredProductDataSource = productDataSource
          }
        } else {
          products = storeInventory.filter((storeItem: any) => {
            return product.product.id === storeItem.product.id
          })
          if (products.length) {
            const productListableOrStockCheckActions = this.formProductAvailabilityCheckAction(
              category,
              products[0],
              productIndex
            )

            productDataSource.push({
              rowIndex: productIndex,
              key: product.product.id,
              isInventoryAvailable: products[0].inventoryAvailable,
              items: product.product.name,
              availability: productListableOrStockCheckActions,
              basePrice: productBasePrice,
              dealPrice: productDealPrice,
              packingCharge: productPackagingCharge,
              itemName: product.product.name
            })
          }

          if (this.filterOption === 'available') {
            filteredProductDataSource = productDataSource.filter(
              item => item.isInventoryAvailable
            )
          } else if (this.filterOption === 'not-available') {
            filteredProductDataSource = productDataSource.filter(
              item => !item.isInventoryAvailable
            )
          } else {
            filteredProductDataSource = productDataSource
          }
        }
        this.productDataSource = [...productDataSource]
      })
      this.setState({
        productDataSource: filteredProductDataSource,
        products,
        isFetching: false
      })
    }
  }

  showCategoryProduct = async selectedCategory => {
    const categoryIndex = this.state.categories.findIndex(
      category => category.id === selectedCategory.id
    )

    this.setState(
      { rowIndex: categoryIndex, selectedItemRowIndex: null }
      /*
       * () => {
       *   setCategoryUpDownArrowPosition(categoryIndex);
       * }
       */
    )
    await this.getAllProductByCategory(selectedCategory)
  }

  formProductListableCheckActions = (category, product, productIndex) => {
    return (
      <Switch
        checkedChildren={CONSOLE_MENU_CATEGORY_STATUS.ACTIVE}
        unCheckedChildren={CONSOLE_MENU_CATEGORY_STATUS.INACTIVE}
        checked={category.listable && product.listable}
        disabled={!category.listable}
        onChange={() => {
          this.launchMenuNotSavedModal(
            () => {
              this.processProductOutOfStock(category, product, productIndex)
              republishCatalog(this.props.client)
            },
            () => {
              republishCatalog(this.props.client)
              this.setState({ showStrikeoutModal: false })
            }
          )
        }}
      />
    )
  }

  formProductAvailabilityCheckAction = (category, product, productIndex) => {
    if (product) {
      return (
        <Switch
          checkedChildren={CONSOLE_MENU_CATEGORY_STATUS.ACTIVE}
          unCheckedChildren={CONSOLE_MENU_CATEGORY_STATUS.INACTIVE}
          checked={
            product.inventoryAvailable ? product.inventoryAvailable : false
          }
          onChange={this.processProductOutOfStock.bind(
            this,
            category,
            product,
            productIndex
          )}
        />
      )
    }
    console.log(product)
  }

  formProductBasePrice = (productId, newPrice = null) => {
    const { isAdmin } = this.state
    const productPriceData = getPriceDataWithProductId(
      [...this.updatedPrices],
      productId
    )
    const price = newPrice || productPriceData?.priceValue

    if (isAdmin) {
      return (
        <InputNumber
          size="middle"
          value={price}
          disabled={price === undefined || price === null}
          min={1}
          onChange={value => {
            this.updateProductBasePrice(productId, value)
          }}
          onBlur={() => {
            this.validateAndUpdatePrices(productId, PRICE_TYPE.BASE)
          }}
        />
      )
    }

    return <span>{price ? `₹${price}` : '-- --'}</span>
  }

  formProductDealPrice = (productId, newPrice = null) => {
    const { isAdmin } = this.state
    const productPriceData = getPriceDataWithProductId(
      [...this.updatedPrices],
      productId
    )
    const price = newPrice || productPriceData?.dealPrice

    if (isAdmin) {
      return (
        <InputNumber
          size="middle"
          disabled={price === undefined || price === null}
          value={price}
          min={0}
          onChange={value => {
            this.updateProductDealPrice(productId, value)
          }}
          onBlur={() => {
            this.validateAndUpdatePrices(productId, PRICE_TYPE.DEAL)
          }}
        />
      )
    }

    return <span>{price ? `₹${price}` : '-- --'}</span>
  }

  formProductPackagingCharge = productId => {
    const { isAdmin } = this.state
    const productPriceData = getPriceDataWithProductId(
      [...this.updatedPrices],
      productId
    )
    const packagingCharge = productPriceData?.chargeValue

    if (isAdmin) {
      return (
        <InputNumber
          size="middle"
          disabled={packagingCharge === undefined || packagingCharge === null}
          value={packagingCharge}
          min={0}
          onChange={value => {
            this.updateProductPackagingCharge(productId, value)
          }}
        />
      )
    }

    return (
      <span>
        {' '}
        {packagingCharge || packagingCharge === 0
          ? `₹${packagingCharge}`
          : '-- --'}
      </span>
    )
  }

  validateAndUpdatePrices = (productId, priceType) => {
    const productData = getPriceDataWithProductId(
      [...this.updatedPrices],
      productId
    )
    const { productDataSource } = this.state
    const productDataSourceIndex = productDataSource.findIndex(
      element => element.key === productId
    )
    const updatedPricesIndex = this.updatedPrices.findIndex(
      e => e.productId === productId
    )
    const updatedProductPriceData = getValidatedPrices(
      [...this.originalPrices],
      productData,
      priceType
    )

    this.updatedPrices.splice(updatedPricesIndex, 1, updatedProductPriceData)
    if (productDataSourceIndex !== -1) {
      const updatedProductData = {
        ...productDataSource[productDataSourceIndex],
        dealPrice: this.formProductDealPrice(productId),
        basePrice: this.formProductBasePrice(productId)
      }

      productDataSource.splice(productDataSourceIndex, 1, updatedProductData)
      this.setState({
        productDataSource
      })
    }
  }

  updateProductDealPrice = (productId, value) => {
    if (isNaN(Math.round(value))) return
    const productPriceData = getPriceDataWithProductId(
      [...this.updatedPrices],
      productId
    )
    const { productDataSource } = this.state
    const productDataSourceIndex = productDataSource.findIndex(
      element => element.key === productPriceData.productId
    )
    const updatedPricesIndex = this.updatedPrices.findIndex(
      e => e.productId === productPriceData.productId
    )
    const updatedProductPriceData = {
      ...productPriceData,
      dealPrice: Math.round(value)
    }

    this.updatedPrices.splice(updatedPricesIndex, 1, updatedProductPriceData)
    const updatedProductData = {
      ...productDataSource[productDataSourceIndex],
      dealPrice: this.formProductDealPrice(productId)
    }

    const updatedProductDataSource = [...productDataSource ]

    updatedProductDataSource.splice(productDataSourceIndex, 1, updatedProductData)
    if (!this.state.isDraftMode) {
      addWindowEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, this.handleUnsavedMenu)
    }
    this.setState({
      productDataSource: updatedProductDataSource,
      isDraftMode: true
    })
  }

  updateProductBasePrice = (productId, value) => {
    if (isNaN(Math.round(value))) return
    const { productDataSource } = this.state
    const productPriceData = getPriceDataWithProductId(
      [...this.updatedPrices],
      productId
    )
    const productDataSourceIndex = productDataSource.findIndex(
      element => element.key === productPriceData.productId
    )
    const updatedPricesIndex = this.updatedPrices.findIndex(
      e => e.productId === productPriceData.productId
    )
    const updatedProductPriceData = {
      ...productPriceData,
      priceValue: Math.round(value) === 0 ? 1 : Math.round(value),
      priceValueChanged: true
    }

    this.updatedPrices.splice(updatedPricesIndex, 1, updatedProductPriceData)
    const updatedProductData = {
      ...productDataSource[productDataSourceIndex],
      basePrice: this.formProductBasePrice(productId)
    }

    const updatedProductDataSource = [...productDataSource ]

    updatedProductDataSource.splice(productDataSourceIndex, 1, updatedProductData)
    if (!this.state.isDraftMode) {
      addWindowEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, this.handleUnsavedMenu)
    }
    this.setState({
      productDataSource: updatedProductDataSource,
      isDraftMode: true
    })
  }

  updateProductPackagingCharge = (productId, value) => {
    const newPackagingCharge = Math.round(value)

    if (isNaN(newPackagingCharge)) return
    const { productDataSource } = this.state
    const productPackagingChargeData = getPriceDataWithProductId(
      [...this.updatedPrices],
      productId
    )
    const originalPackagingChargeData = getPriceDataWithProductId(
      [...this.originalPrices],
      productId
    )

    if (productPackagingChargeData.chargeValue === newPackagingCharge) {
      return
    }
    const productDataSourceIndex = productDataSource.findIndex(
      element => element.key === productId
    )
    const updatedPricesIndex = this.updatedPrices.findIndex(
      e => e.productId === productId
    )
    const updatedProductPackagingChargeData = {
      ...productPackagingChargeData,
      chargeValue: newPackagingCharge,
      chargeValueChanged:
        originalPackagingChargeData.chargeValue !== newPackagingCharge
    }

    this.updatedPrices.splice(
      updatedPricesIndex,
      1,
      updatedProductPackagingChargeData
    )

    const updatedProductData = {
      ...productDataSource[productDataSourceIndex],
      packingCharge: this.formProductPackagingCharge(productId)
    }

    const updatedProductDataSource = [...productDataSource ]

    updatedProductDataSource.splice(productDataSourceIndex, 1, updatedProductData)
    if (!this.state.isDraftMode) {
      addWindowEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, this.handleUnsavedMenu)
    }
    this.setState({
      productDataSource: updatedProductDataSource,
      isDraftMode: true
    })
  }

  formItemAction = product => {
    return (
      <div
        onClick={(event: any) => {
          this.launchMenuNotSavedModal(
            () => {
              this.undoAllPriceChanges(() => {
                this.goToItemForm(product)
              })
            },
            () => {
              this.setState({ showStrikeoutModal: false })
            }
          )
        }}>
        {product.name}
      </div>
    )
  }

  goToItemForm = product => {
    const {
      catalogId,
      catalog,
      packagingCharge,
      catalogCount,
      categories,
      selectedCategory,
      channels,
      storeFormats
    } = this.state
    const listedCategories = categories?.filter(val => val.listable === true)

    this.props.history.push({
      pathname: `/orderx/menu-list/${catalogId}/customize`,
      state: {
        catalogData: catalog,
        catalog,
        channels,
        storeFormats,
        roles: this.roles,
        store: this.stores,
        isOnboarding: this.isOnboarding,
        packagingChargeType: packagingCharge.chargeType,
        catalogCount,
        categories: listedCategories,
        selectedCategory,
        selectedProductId: product.id
      }
    })
  }

  goToEditMenu = () => {
    const { catalogId, catalog, packagingCharge, catalogCount, channels, storeFormats } = this.state

    this.props.history.push({
      pathname: `/orderx/menu-list/${catalogId}/customize`,
      state: {
        catalogData: catalog,
        catalog,
        channels,
        storeFormats,
        packagingChargeType: packagingCharge.chargeType,
        catalogCount,
        roles: this.roles,
        store: this.stores,
        isOnboarding: this.isOnboarding
      }
    })
  }

  onHandleCatalogChange = (e: any) => {
    this.setState({ catalogName: e.target.value })
  }

  handleCatalogEditSave = type => {
    const { catalogId, catalogName } = this.state

    if (type === 'edit') {
      this.setState({ isCatalogNameEdit: true })
    } else {
      const updateCatalogInput = {
        id: catalogId,
        name: catalogName
      }

      this.props
        .updateCatalog({
          variables: {
            input: updateCatalogInput
          }
        })
        .then(async data => {
          message.success(
            intlAlertMessage({
              id: 'menu.catalogNameUpdated',
              intl: this.props.intl
            })
          )
          const catalog = data.data.updateCatalog

          this.setState({
            isCatalogNameEdit: false,
            catalog,
            catalogName: catalog.name
          })
        })
        .catch(err => {
          console.warn('Update Catalog Error:', err)
        })
    }
  }

  processProductOutOfStock = (category, product, productIndex) => {
    const {
      isAdmin,
      productDataSource,
      storeManager,
      storeInventory
    } = this.state
    let updatedAdminProduct
    let updatedManagerProduct
    let updatedProductDataSource

    const prevProductDataSourceIndex = productDataSource.findIndex(
      product => product.rowIndex === productIndex
    )
    const prevProductDataSource = productDataSource[prevProductDataSourceIndex]

    if (this.processing) return
    this.processing = true

    if (isAdmin) {
      updatedAdminProduct = {
        ...product,
        listable: !product.listable
      }

      const productListableOrStockCheckActions = this.formProductListableCheckActions(
        category,
        updatedAdminProduct,
        productIndex
      )

      updatedProductDataSource = {
        ...prevProductDataSource,
        listable: productListableOrStockCheckActions
      }

      const newProductDataSource = [...productDataSource]

      newProductDataSource.splice(
        prevProductDataSourceIndex,
        1,
        updatedProductDataSource
      )
      this.setState({ productDataSource: newProductDataSource }, () => {
        const { isDraftMode } = this.state

        isDraftMode && this.undoAllPriceChanges()
      })
      message.loading(
        intlAlertMessage({ id: 'menu.saving', intl: this.props.intl }),
        2
      )

      const adminProductListableCheckInput = {
        id: product.id,
        listable: !product.listable,
        name: product.name,
        organizationId: this.org_id
      }

      this.props
        .updateProduct({
          variables: { input: adminProductListableCheckInput },
          fetchPolicy: 'no-cache'
        })
        .then(res => {
          this.processing = false
        })
        .catch(err => {
          this.processing = false
          productDataSource.splice(
            prevProductDataSourceIndex,
            1,
            prevProductDataSource
          )
          this.setState({ productDataSource }, () => {
            const { isDraftMode } = this.state

            isDraftMode && this.undoAllPriceChanges()
          })
          console.warn('Product stock status change error:', err)
        })
    } else {
      updatedManagerProduct = {
        ...product,
        inventoryAvailable: !product.inventoryAvailable
      }

      const storeProductAvailabilityInput = {
        productId: product.product.id,
        available: !product.inventoryAvailable,
        storeId: storeManager ? storeManager.id : null
      }
      const productListableOrStockCheckActions = this.formProductAvailabilityCheckAction(
        category,
        updatedManagerProduct,
        productIndex
      )

      updatedProductDataSource = {
        ...prevProductDataSource,
        availability: productListableOrStockCheckActions,
        isInventoryAvailable: updatedManagerProduct.inventoryAvailable
      }
      const productDataSourceCopy = [...productDataSource]

      productDataSourceCopy.splice(
        prevProductDataSourceIndex,
        1,
        updatedProductDataSource
      )
      // eslint-disable-next-line
      const updatedStoreInventory = storeInventory.map(inventoryItem => {
        if (product.product.id === inventoryItem.product.id) {
          return { ...inventoryItem, inventoryAvailable: !product.inventoryAvailable }
        }

        return { ...inventoryItem }
      })

      this.setState({ productDataSource: productDataSourceCopy, storeInventory: updatedStoreInventory })

      message.loading(
        intlAlertMessage({ id: 'menu.saving', intl: this.props.intl }),
        2
      )

      this.props
        .storeProductAvailability({
          variables: { input: storeProductAvailabilityInput },
          fetchPolicy: 'no-cache'
        })
        .then(res => {
          this.processing = false
          republishCatalog(this.props.client)
        })
        .catch(err => {
          this.processing = false
          this.setState({ productDataSource })
          console.warn('Product stock status change error:', err)
        })
    }
  }

  /*
   *- Update availability of all unavailable items
   */
  processAllUnavailableProducts = () => {
    if (this.processing) return
    const { unavailableStoreItems, markAllAvailable } = this.state

    this.setState(
      {
        markAllAvailable: !markAllAvailable,
        itemIndexWithUpdatedAvailability: []
      },
      () => {
        unavailableStoreItems.forEach((product, productIndex) => {
          this.processProductInStock(productIndex, true)
        })
      }
    )
  }

  /*
   *- Call api to update all modified items availability in db
   */
  processAllProductsInStock = async () => {
    const {
      storeManager,
      unavailableStoreItems,
      itemIndexWithUpdatedAvailability
    } = this.state
    const storeProductsAvailabilityInput = {
      storeInventory: [],
      storeId: storeManager ? storeManager.id : null
    }

    itemIndexWithUpdatedAvailability.forEach(itemIndex => {
      const storeInventory = {}

      storeInventory['productId'] = unavailableStoreItems[itemIndex].product.id
      storeInventory['available'] =
        unavailableStoreItems[itemIndex].inventoryAvailable
      storeProductsAvailabilityInput.storeInventory.push(storeInventory)
    })
    try {
      this.setState({ savingForm: true })
      await storeProductsAvailability(
        this.props.client,
        storeProductsAvailabilityInput
      )
      this.setState({ savingForm: false, unavailableItemsCheck: false })
      this.getAllProductByCategory(this.state.selectedCategory)
    } catch (err) {
      this.setState({ savingForm: false })
      console.warn('Update items availability error:', err)
    }
  }

  /*
   *- Update availability of individual item and retain them in an array to update the db on save button click
   */
  processProductInStock = (productIndex, callingFromProcessAll) => {
    if (this.processing) return
    this.processing = true
    const {
      unavailableStoreItems,
      itemIndexWithUpdatedAvailability,
      markAllAvailable
    } = this.state
    const updatedItem = unavailableStoreItems[productIndex]

    updatedItem.inventoryAvailable = callingFromProcessAll
      ? markAllAvailable
      : !updatedItem.inventoryAvailable
    unavailableStoreItems[productIndex] = updatedItem
    itemIndexWithUpdatedAvailability.push(productIndex)
    this.setState({
      unavailableStoreItems,
      itemAvailabilityChanged: true,
      itemIndexWithUpdatedAvailability
    })
    this.processing = false
  }

  processCategoryListableCheck = (category, categoryIndex) => {
    const { categories } = this.state

    if (this.processing) return
    this.processing = true
    const CategoryListableCheckInput = {
      id: category.id,
      listable: !category.listable,
      organizationId: this.org_id
    }

    message.loading(
      intlAlertMessage({ id: 'menu.saving', intl: this.props.intl }),
      2
    )
    this.props
      .updateCategory({
        variables: { input: CategoryListableCheckInput },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.processing = false
        const catIndex = categories.findIndex(
          category => category.id === res.data.updateCategory.id
        )

        categories[catIndex].listable = res.data.updateCategory.listable
        this.categories[
          this.categories.findIndex(c => c.id === res.data.updateCategory.id)
        ].listable = res.data.updateCategory.listable
        this.catalogCategories[
          this.catalogCategories.findIndex(
            c => c.id === res.data.updateCategory.id
          )
        ].listable = res.data.updateCategory.listable

        /*
         * Commenting below code while resolving merge conflict of antd commit to apollo client commit. Below code can be removed once tested & verified - assigned to Chinmay
         * categories[catIndex] = {
         *   ...categories[catIndex],
         *   listable: res.data.updateCategory.listable
         * }
         * this.categories[this.categories.findIndex(c => c.id === res.data.updateCategory.id)].listable = res.data.updateCategory.listable
         * this.catalogCategories[this.catalogCategories.findIndex(c => c.id === res.data.updateCategory.id)].listable = res.data.updateCategory.listable
         */
        this.setState({ categories }, () => {
          this.handleFilterChange(this.filterOption, categoryIndex)
        })
      })
      .catch(err => {
        this.processing = false
        console.warn(
          'Category listable or availability status change error:',
          err
        )
      })
  }

  goBackToOnboarding = () => {
    const { catalog } = this.state
    const user = getUserData()

    user.catalogs = [catalog]
    localStorage.removeItem('isOnboarding')
    setUserData(user)
    this.props.history.push({
      pathname: '/orderx/onboarding',
      state: {
        catalog,
        catalogs: [catalog],
        isOnboarding: false,
        roles: this.roles,
        store: this.stores,
        fromStoreOrCatalog: true
      }
    })
  }

  handleConfirmCatalogClick = () => {
    const { catalog } = this.state
    const updatedCatalogInput = {
      id: catalog['id'],
      name: catalog['name'],
      listable: true
    }

    this.setState({ isConfirmProcessing: true })
    this.props
      .updateCatalog({
        variables: { input: updatedCatalogInput },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const catalog = res.data.updateCatalog

        message.success(
          <span style={{ width: '400px', display: 'block' }}>
            {intlAlertMessage({
              id: 'menu.verification.success',
              intl: this.props.intl
            })}
          </span>,
          5
        )
        this.setState({ catalog, isConfirmProcessing: false }, () => {
          if (this.isOnboarding) {
            this.goBackToOnboarding()
          }
        })
      })
      .catch(err => {
        this.setState({ isConfirmProcessing: false })
        console.warn('Catalog confirm Error:', err)
      })
  }

  handleFilterChange(value, catIndex) {
    const { isAdmin } = this.state
    let categories
    let productDataSource
    let categoriesWithProducts = []

    this.filterOption = value
    if (isAdmin) {
      if (value === 'listable') {
        categories = this.categories.filter(category => category.listable)
        categoriesWithProducts = this.catalogCategories.filter(
          category => category.listable
        )
      } else if (value === 'non-listable') {
        categories = this.categories.filter(category => !category.listable)
        categoriesWithProducts = this.catalogCategories.filter(
          category => !category.listable
        )
      } else {
        categories = this.categories
        categoriesWithProducts = [...this.catalogCategories]
      }
      this.setStoreInventoryForSearch(categoriesWithProducts)
      const categoryIndex = categories.findIndex(
        category => category.id === categories[catIndex].id
      )

      if (categories && categories.length > 0) {
        this.getAllProductByCategory(categories[categoryIndex] || categories[0])
      } else {
        this.setState({
          productDataSource: []
        })
      }
      this.setState({
        categories,
        rowIndex: categoryIndex
      })
    } else {
      if (value === 'available') {
        productDataSource = this.productDataSource.filter(
          product => product.isInventoryAvailable
        )
      } else if (value === 'not-available') {
        productDataSource = this.productDataSource.filter(
          product => !product.isInventoryAvailable
        )
      } else {
        productDataSource = this.productDataSource
      }

      this.setState({
        productDataSource
      })
    }
  }

  getCategories = async () => {
    const { rowIndex } = this.state
    let filteredCategories = this.catalogCategories

    if (!this.state.isAdmin) {
      filteredCategories = this.catalogCategories.filter(category => category.listable)
    }
    const categories = getAddonsCombosAtLast(filteredCategories)

    this.getAllProductByCategory(categories[rowIndex] || categories[0])
    this.isPriceFetch = false
    this.categories = categories
    this.setState({
      categories
    })
  }

  onFormatChannelChange = e => {
    const {
      selectedChannelIndex,
      selectedFormatIndex,
      channels,
      storeFormats
    } = this.state
    const selectedKeys = e.key.split('-')

    if (
      selectedChannelIndex === Number(selectedKeys[1]) &&
      selectedFormatIndex === Number(selectedKeys[0])
    ) {
      return
    }
    this.setState(
      prevState => {
        return {
          ...prevState,
          selectedChannelIndex: Number(selectedKeys[1]),
          selectedFormatIndex: Number(selectedKeys[0]),
          selectedFormatChannel: `${storeFormats[selectedFormatIndex].name}/${channels[selectedChannelIndex].name}`,
          selectedFormatChannelKey: e.key
        }
      },
      () => {
        this.getPrices()
        this.getTaxValues(false)
        this.getChargeValues(false)
      }
    )
  }

  toggleTaxForm = () => {
    const { showTaxForm } = this.state

    if (showTaxForm) {
      this.setState(
        prevState => {
          return {
            ...prevState,
            taxTypeValues: [...this.taxTypeValues],
            showTaxForm: !showTaxForm
          }
        },
        () => this.calculateGrossTax()
      )
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          showTaxForm: !showTaxForm
        }
      })
    }
  }

  toggleChargeForm = () => {
    const { showChargesForm } = this.state

    if (showChargesForm) {
      this.setState(prevState => {
        return {
          ...prevState,
          chargeTypeValues: [...this.chargeTypeValues],
          showChargesForm: !showChargesForm
        }
      })
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          showChargesForm: !showChargesForm
        }
      })
    }
  }

  togglePackagingChargeForm = () => {
    const { showPackagingChargeForm } = this.state

    if (showPackagingChargeForm) {
      this.setState(prevState => {
        return {
          ...prevState,
          packagingCharge: { ...this.packagingCharge },
          showPackagingChargeForm: !showPackagingChargeForm
        }
      })
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          showPackagingChargeForm: !showPackagingChargeForm
        }
      })
    }
  }

  calculateGrossTax = () => {
    const { taxTypeValues } = this.state
    let totalTax = 0

    taxTypeValues.forEach(taxTypeValue => {
      if (!taxTypeValue.removed && taxTypeValue.taxValue)
        totalTax += parseFloat(taxTypeValue.taxValue)
    })
    if (totalTax < 5) {
      setErrMsg('menu.grossTaxErrorMessage', this.props.intl)
    }
    this.setState({
      grossTax: totalTax
    })
  }

  onRemoveTaxTypeValue = taxTypeValueIndex => {
    const { taxTypeValues } = this.state
    const ttvs = [...taxTypeValues]

    if (ttvs[taxTypeValueIndex].taxValueId) {
      ttvs[taxTypeValueIndex] = { ...ttvs[taxTypeValueIndex], removed: true }
    } else {
      ttvs.splice(taxTypeValueIndex, 1)
    }
    this.setState(
      prevState => {
        return {
          ...prevState,
          taxTypeValues: ttvs
        }
      },
      () => this.calculateGrossTax()
    )
  }

  onRemoveChargeTypeValue = chargeTypeValueIndex => {
    const { chargeTypeValues } = this.state
    const ctvs = [...chargeTypeValues]

    if (ctvs[chargeTypeValueIndex].chargeValueId) {
      ctvs[chargeTypeValueIndex] = {
        ...ctvs[chargeTypeValueIndex],
        removed: true
      }
    } else {
      ctvs.splice(chargeTypeValueIndex, 1)
    }
    this.setState(prevState => {
      return {
        ...prevState,
        chargeTypeValues: ctvs
      }
    })
  }

  onAddNewTaxTypeValue = () => {
    const { taxTypeValues } = this.state
    const taxTypeValue = {
      taxType: { id: '', name: '', taxTypeCode: '' },
      taxValueId: '',
      taxValue: 0,
      removed: false
    }

    taxTypeValues.push(taxTypeValue)
    this.setState({ taxTypeValues })
  }

  onAddNewChargeTypeValue = () => {
    const { chargeTypeValues } = this.state
    const chargeTypeValue = {
      chargeType: { id: '', name: '', chargeTypeCode: '' },
      chargeValueId: '',
      chargeValue: 0,
      removed: false
    }

    chargeTypeValues.push(chargeTypeValue)
    this.setState({ chargeTypeValues })
  }

  onTaxValueChange = (index, taxValue) => {
    const { taxTypeValues } = this.state

    taxTypeValues[index] = { ...taxTypeValues[index], taxValue }
    this.setState({ taxTypeValues }, () => {
      this.calculateGrossTax()
    })
  }

  onChargeValueChange = (index, chargeValue) => {
    const { chargeTypeValues } = this.state

    chargeTypeValues[index] = {
      ...chargeTypeValues[index],
      chargeValue
    }
    this.setState({ chargeTypeValues })
  }

  createUpdateTaxes = async () => {
    const {
      taxTypeValues,
      catalogId,
      storeFormats,
      selectedFormatIndex,
      channels,
      // SelectedChannelIndex,
      taxTypes
    } = this.state
    const createUpdateTtvs = taxTypeValues.filter(ttv => !ttv.removed)
    const removeTtvs = taxTypeValues.filter(ttv => ttv.removed)

    this.setState({ savingForm: true })
    if (removeTtvs.length) {
      await this.removeTaxValue(removeTtvs)
    }
    const taxTypeValuesInput = {
      catalogId,
      storeFormat: storeFormats[selectedFormatIndex].id,
      channel: '',
      taxLevel: '',
      taxValue: 0
    }
    const ttys = [...taxTypes]
    /* eslint-disable no-await-in-loop */

    for (const taxTypeValue of createUpdateTtvs) {
      if (taxTypeValue.taxValueId) {
        taxTypeValuesInput.taxLevel = taxTypeValue.taxType.id
        taxTypeValuesInput.taxValue = parseFloat(taxTypeValue.taxValue)
        try {
          // Update same tax for all channels
          await parallelCall(this.props.client, taxTypeValuesInput, updateCatalogTax, channels)
          
        } catch (err) {
          this.setState({ savingForm: false })
          console.warn('Update Tax Values error:', err)
        }
      } else if (taxTypeValue.taxType.id) {
        taxTypeValuesInput.taxLevel = taxTypeValue.taxType.id
        taxTypeValuesInput.taxValue = parseFloat(taxTypeValue.taxValue)
        try {
          // Add same tax for all channels
          await parallelCall(this.props.client, taxTypeValuesInput, addCatalogTax, channels)
        } catch (err) {
          this.setState({ savingForm: false })
          console.warn('Update Tax Values error:', err)
        }
      } else {
        const taxTypeCode = taxTypeValue.taxType.name.replace(/\s+/g, '_')
        const createTaxTypeInput = {
          name: taxTypeValue.taxType.name,
          taxTypeCode: taxTypeCode.toLocaleLowerCase()
        }

        try {
          const createdTaxType = await createTaxType(
            this.props.client,
            createTaxTypeInput
          )
          const newTaxType = {
            ...createdTaxType.data.createTaxType,
            selected: false
          }

          ttys.push(newTaxType)
          taxTypeValuesInput.taxLevel = newTaxType.id
          taxTypeValuesInput.taxValue = parseFloat(taxTypeValue.taxValue)
          // Add same tax for all channels
          await parallelCall(this.props.client, taxTypeValuesInput, addCatalogTax, channels)
        } catch (err) {
          this.setState({ savingForm: false })
          console.warn('Add Tax Values error:', err)
        }
      }
    }
    republishCatalog(this.props.client)
    this.setState(
      prevState => {
        return {
          ...prevState,
          taxTypes: ttys
        }
      },
      () => this.getTaxValues(true)
    )
  }

  createUpdateCharges = async () => {
    const {
      chargeTypeValues,
      catalogId,
      storeFormats,
      selectedFormatIndex,
      channels,
      // SelectedChannelIndex,
      chargeTypes
    } = this.state
    const createUpdateTtvs = chargeTypeValues.filter(ctv => !ctv.removed)
    const removeCtvs = chargeTypeValues.filter(ctv => ctv.removed)

    this.setState({ savingForm: true })
    if (removeCtvs.length) {
      await this.removeChargeValue(removeCtvs)
    }
    const chargeTypeValuesInput = {
      catalogId,
      storeFormat: storeFormats[selectedFormatIndex].id,
      channel: '',
      chargeType: '',
      chargeValue: 0
    }
    const ttys = [...chargeTypes]

    for (const chargeTypeValue of createUpdateTtvs) {
      if (chargeTypeValue.chargeValueId) {
        chargeTypeValuesInput.chargeType = chargeTypeValue.chargeType.id
        chargeTypeValuesInput.chargeValue = parseFloat(
          chargeTypeValue.chargeValue
        )
        try {
          // Update same charge for all channels
          await parallelCall(this.props.client, chargeTypeValuesInput, updateCatalogCharge, channels)
        } catch (err) {
          this.setState({ savingForm: false })
          console.warn('Update charge values error:', err)
        }
      } else if (chargeTypeValue.chargeType.id) {
        chargeTypeValuesInput.chargeType = chargeTypeValue.chargeType.id
        chargeTypeValuesInput.chargeValue = parseFloat(
          chargeTypeValue.chargeValue
        )
        try {
          // Add same charge for all channels
          await parallelCall(this.props.client, chargeTypeValuesInput, addCatalogCharge, channels)
        } catch (err) {
          this.setState({ savingForm: false })
          console.warn('Update charge values error:', err)
        }
      } else {
        const chargeTypeCode = chargeTypeValue.chargeType.name.replace(
          /\s+/g,
          '_'
        )
        const createChargeTypeInput = {
          name: chargeTypeValue.chargeType.name,
          chargeTypeCode: chargeTypeCode.toLocaleLowerCase()
        }

        try {
          const createdChargeType = await createChargeType(
            this.props.client,
            createChargeTypeInput
          )
          const newChargeType = {
            ...createdChargeType.data.createChargeType,
            selected: false
          }

          ttys.push(newChargeType)
          chargeTypeValuesInput.chargeType = newChargeType.id
          chargeTypeValuesInput.chargeValue = parseFloat(
            chargeTypeValue.chargeValue
          )
          // Add same charge for all channels
          await parallelCall(this.props.client, chargeTypeValuesInput, addCatalogCharge, channels)
        } catch (err) {
          this.setState({ savingForm: false })
          console.warn('Add charge values error:', err)
        }
      }
    }
    republishCatalog(this.props.client)
    this.setState(
      prevState => {
        return {
          ...prevState,
          chargeTypes: ttys
        }
      },
      () => this.getChargeValues(true)
    )
  }

  updatePackagingCharge = async () => {
    const {
      packagingCharge,
      catalogId,
      storeFormats,
      selectedFormatIndex,
      // SelectedChannelIndex,
      channels,
      selectedCategory
    } = this.state
    /*
     * Let packagingChargeUpdateInput = {
     *   catalogId: catalogId,
     *   storeFormat: storeFormats[selectedFormatIndex].id,
     *   channel: channels[selectedChannelIndex].id,
     *   chargeType: packagingCharge.chargeType.id,
     *   chargeValue: parseFloat(packagingCharge.chargeValue),
     * };
     */
    const packagingChargeUpdateInput = {
      catalogId,
      storeFormat: storeFormats[selectedFormatIndex].id,
      channel: '',
      chargeType: packagingCharge.chargeType.id,
      chargeValue: parseFloat(packagingCharge.chargeValue)
    }

    // Update same packaging charge for all channels
    this.setState({ savingForm: true })
    try {
      const response: any = await parallelCall(this.props.client, packagingChargeUpdateInput, updateCatalogCharge, channels)
      const packagingCharges = response[0].data.updateProductChargeForCatalog

      this.originalPrices = updateOriginalPricesWithPackagingPrice(
        [...this.originalPrices],
        packagingCharges
      )
      this.updatedPrices = updateOriginalPricesWithPackagingPrice(
        [...this.updatedPrices],
        packagingCharges
      )
      this.updatedPrices = this.updatedPrices.map(p => {
        p.chargeValueChanged = false

        return p
      })
      republishCatalog(this.props.client)
      this.setState(
        {
          savingForm: false,
          isSamePackagingCharge: true
        },
        () => {
          this.getAllProductByCategory(selectedCategory)
          this.packagingCharge = packagingCharge
          this.togglePackagingChargeForm()
          republishCatalog(this.props.client)
        }
      )
    } catch (err) {
      this.setState({ savingForm: false })
      console.warn('Update packaging charge value error:', err)
    }
  }

  removeTaxValue = async removeTtvs => {
    const {
      storeFormats,
      selectedFormatIndex,
      channels,
      // SelectedChannelIndex,
      taxTypeValues,
      taxTypes
    } = this.state
    const txTypes = [...taxTypes]
    const taxTValues = [...taxTypeValues]

    for (const taxTypeValue of removeTtvs) {
      if (taxTypeValue.taxValueId) {
        const removeTaxValueInput = {
          storeFormat: storeFormats[selectedFormatIndex].id,
          channel: '',
          taxLevel: taxTypeValue.taxType.id
        }

        try {
          // Remove tax for all channels
          await parallelCall(this.props.client, removeTaxValueInput, removeFormatChannelTax, channels)
          const removeIndex = txTypes.findIndex(
            tty => tty.id === taxTypeValue.taxType.id
          )

          if (removeIndex !== -1) {
            txTypes[removeIndex].selected = false
          }
        } catch (err) {
          console.error('Remove tax value error', err)
        }
      } else {
        const removeIndex = taxTValues.findIndex(
          ttv => ttv.taxType.name === taxTypeValue.taxType.name
        )

        if (removeIndex !== -1) {
          taxTValues.splice(removeIndex, 1)
        }
      }
    }
    this.setState({ taxTypeValues: taxTValues, taxTypes: txTypes })
  }

  removeChargeValue = async removeCtvs => {
    const {
      storeFormats,
      selectedFormatIndex,
      channels,
      // SelectedChannelIndex,
      chargeTypeValues,
      chargeTypes
    } = this.state
    const chTypes = [...chargeTypes]
    const chargeTValues = [...chargeTypeValues]

    for (const chargeTypeValue of removeCtvs) {
      if (chargeTypeValue.chargeValueId) {
        const removeChargeValueInput = {
          storeFormat: storeFormats[selectedFormatIndex].id,
          channel: '',
          chargeType: chargeTypeValue.chargeType.id
        }

        try {
          // Remove charge value on all channels
          await parallelCall(this.props.client, removeChargeValueInput, removeFormatChannelCharge, channels)
          const removeIndex = chTypes.findIndex(
            cty => cty.id === chargeTypeValue.chargeType.id
          )

          if (removeIndex !== -1) {
            chTypes[removeIndex].selected = false
          }
        } catch (err) {
          console.error('Remove charge value error', err)
        }
      } else {
        const removeIndex = chargeTValues.findIndex(
          ctv => ctv.chargeType.name === chargeTypeValue.chargeType.name
        )

        if (removeIndex !== -1) {
          chargeTValues.splice(removeIndex, 1)
        }
      }
    }
    this.setState({ chargeTypeValues: chargeTValues, chargeTypes: chTypes })
  }

  validateForm = formName => {
    const { taxTypeValues, chargeTypeValues, packagingCharge } = this.state
    const ttvs = taxTypeValues.filter(ttv => !ttv.removed)
    const ctvs = chargeTypeValues.filter(ctv => !ctv.removed)
    const valueRegex = /^(?:[0-9]\d*)?(?:\.\d+)?$/
    let invalidValue = false
    let pChargeInvalidValue = false
    let pChargeValueRequired = false
    let valueRequired = false
    let valueTypeRequired = false

    if (formName === 'tax') {
      for (let i = 0; i < ttvs.length; i++) {
        const { taxType } = ttvs[i]

        if (!taxType.name) {
          valueTypeRequired = true
          break
        } else if (!ttvs[i].taxValue) {
          valueRequired = true
          break
        } else if (
          !valueRegex.test(String(ttvs[i].taxValue).toLocaleLowerCase()) ||
          ttvs[i].taxValue === '0.0'
        ) {
          invalidValue = true
          break
        }
      }
    } else if (formName === 'charge') {
      for (let i = 0; i < ctvs.length; i++) {
        const { chargeType } = ctvs[i]

        if (!chargeType.name) {
          valueTypeRequired = true
          break
        } else if (!ctvs[i].chargeValue) {
          valueRequired = true
          break
        } else if (
          !valueRegex.test(String(ctvs[i].chargeValue).toLocaleLowerCase()) ||
          ctvs[i].chargeValue === '0.0'
        ) {
          invalidValue = true
          break
        }
      }
    } else if (
      !packagingCharge.chargeValue &&
      packagingCharge.chargeValue !== 0
    ) {
      pChargeValueRequired = true
    } else if (
      !valueRegex.test(String(packagingCharge.chargeValue).toLocaleLowerCase())
    ) {
      pChargeInvalidValue = true
    }

    if (valueTypeRequired) {
      const errMsg =
        formName === 'tax' ? (
          <IntlMessages id="menu.errMsg.pleaseEnterTaxName" />
        ) : (
          <IntlMessages id="menu.errMsg.pleaseEnterChargeName" />
        )

      message.error(errMsg)

      return true
    } else if (valueRequired) {
      const errMsg =
        formName === 'tax'
          ? intlAlertMessage({
            id: 'menu.errMsg.pleaseEnterTaxValue',
            intl: this.props.intl
          })
          : intlAlertMessage({
            id: 'menu.errMsg.pleaseEnterChargeValue',
            intl: this.props.intl
          })

      message.error(errMsg)

      return true
    } else if (invalidValue) {
      const errMsg = intlAlertMessage({
        id: 'menu.errMsg.pleaseEnterValidNumberValue',
        intl: this.props.intl
      })

      message.error(errMsg)

      return true
    } else if (pChargeValueRequired) {
      message.error(
        intlAlertMessage({
          id: 'menu.errMsg.pleaseEnterPackagingChargeValue',
          intl: this.props.intl
        })
      )

      return true
    } else if (pChargeInvalidValue) {
      message.error(
        intlAlertMessage({
          id: 'menu.errMsg.pleaseEnterValidNumberValue',
          intl: this.props.intl
        })
      )

      return true
    }

    return false
  }

  getTaxOptions = () => {
    const availableTaxTypes = this.state.taxTypes.filter(
      taxType => !taxType.selected
    )
    const taxOptions = availableTaxTypes.map(opt => (
      <Option key={opt.id} value={opt.name}>
        {opt.name}
      </Option>
    ))

    return taxOptions
  }

  getChargeOptions = () => {
    const availableChargeTypes = this.state.chargeTypes.filter(
      chargeType => !chargeType.selected
    )
    const chargeOptions = availableChargeTypes.map(opt => (
      <Option key={opt.id} value={opt.name}>
        {opt.name}
      </Option>
    ))

    return chargeOptions
  }

  onTaxOptionChange = (value, taxTypeValueIndex) => {
    const { taxTypeValues, taxTypes } = this.state

    taxTypeValues[taxTypeValueIndex].taxType = {
      ...taxTypeValues[taxTypeValueIndex].taxType,
      name: value
    }
    this.setState({ taxTypeValues })
    let duplicateTaxName = false

    for (let index = 0; index < taxTypeValues.length; index++) {
      if (
        taxTypeValueIndex !== index &&
        value.length &&
        taxTypeValues[index].taxType.name.toLocaleLowerCase() ===
        value.toLocaleLowerCase()
      ) {
        message.error(
          intlAlertMessage({
            id: 'menu.errMsg.taxNameIsNotUnique',
            intl: this.props.intl
          })
        )
        duplicateTaxName = true
        break
      }
    }
    this.setState({ duplicateName: duplicateTaxName })
    if (duplicateTaxName) {
      return
    }
    const matchedTaxType = taxTypes.filter(taxType => {
      if (
        taxType.name.toLocaleLowerCase() === value.toLocaleLowerCase() &&
        !taxType.selected
      ) {
        return true
      }

      return false
    })

    if (matchedTaxType.length) {
      taxTypeValues[taxTypeValueIndex].taxType = { ...matchedTaxType[0] }
    }

    this.setState({ taxTypeValues, taxTypes })
  }

  onChargeOptionChange = (value, chargeTypeValueIndex) => {
    const { chargeTypeValues, chargeTypes } = this.state

    chargeTypeValues[chargeTypeValueIndex].chargeType = {
      ...chargeTypeValues[chargeTypeValueIndex].chargeType,
      name: value
    }
    this.setState({ chargeTypeValues })
    let duplicateChargeName = false

    for (let index = 0; index < chargeTypeValues.length; index++) {
      if (
        chargeTypeValueIndex !== index &&
        value.length &&
        chargeTypeValues[index].chargeType.name.toLocaleLowerCase() ===
        value.toLocaleLowerCase()
      ) {
        message.error(
          intlAlertMessage({
            id: 'menu.errMsg.chargeNameIsNotUnique',
            intl: this.props.intl
          })
        )
        duplicateChargeName = true
        break
      }
    }
    this.setState({ duplicateName: duplicateChargeName })
    if (duplicateChargeName) {
      return
    }
    const matchedChargeType = chargeTypes.filter(chargeType => {
      if (
        chargeType.name.toLocaleLowerCase() === value.toLocaleLowerCase() &&
        !chargeType.selected
      ) {
        return true
      }

      return false
    })

    if (matchedChargeType.length) {
      chargeTypeValues[chargeTypeValueIndex].chargeType = {
        ...matchedChargeType[0]
      }
    }

    this.setState({ chargeTypeValues, chargeTypes })
  }

  async getStoreInventory() {
    const { storeManager, isAdmin } = this.state
    const payload = { storeId: storeManager ? storeManager.id : null }

    try {
      const res = await fetchStoreInventory(this.props.client, payload)
      const data = res.data.storeInventory
      const inventoryListedItems = data.filter((storeItem: any) => {
        return storeItem.product.listable
      })
      const mappedStoreInventory = {}

      inventoryListedItems.forEach(item => {
        mappedStoreInventory[item.product.name] = item.product
      })

      this.setState({
        storeInventory: isAdmin ? data : inventoryListedItems,
        storeInventoryForSearch: mappedStoreInventory
      })
    } catch (err) {
      console.error('Store inventory fetch error', err)
    }
  }

  async getPrices() {
    const { selectedFormatIndex, selectedChannelIndex, channels, storeFormats } = this.state

    // This.isPriceFetch = true;
    this.setState({ isFetching: true })
    const payload = {
      storeFormat: storeFormats[selectedFormatIndex].id,
      channel: channels[selectedChannelIndex].id
    }

    try {
      const response = await getPriceValues(this.props.client, payload)
      const { data } = response.data.productPriceValues

      this.originalPrices = updateOriginalPricesWithProductPrice(
        this.originalPrices,
        [...data]
      )
      this.getPackagingCharges()
    } catch (err) {
      console.error(err)
      this.isPriceFetch = false
    }
  }

  getPackagingCharges = async () => {
    const {
      selectedFormatIndex,
      selectedChannelIndex,
      packagingCharge,
      channels,
      storeFormats,
      catalog
    } = this.state

    this.setState({ isFetching: true })
    try {
      const payload = {
        storeFormat: storeFormats[selectedFormatIndex].id,
        channel: channels[selectedChannelIndex].id,
        chargeType: packagingCharge.chargeType.id,
        productId: this.selectedCatalogProducts
      }
      const response = await getChargeValues(this.props.client, payload)
      const { data } = response.data.productChargeValues

      this.originalPrices = updateOriginalPricesWithPackagingPrice(
        [...this.originalPrices],
        data
      )
      let isSamePackagingCharge

      if (data.length) {
        const { chargeValue } = data[0]

        isSamePackagingCharge = data.every(charge => {
          if (
            (chargeValue || chargeValue === 0) &&
            (charge.chargeValue || charge.chargeValue === 0)
          ) {
            return Math.round(charge.chargeValue) === Math.round(chargeValue)
          }

          return true
        })
        if (isSamePackagingCharge) {
          packagingCharge.chargeValue = Math.round(chargeValue)
          this.packagingCharge = { ...packagingCharge }
        }
      } else {
        isSamePackagingCharge = false
      }
      const discountValueInput = {
        storeFormat: storeFormats[selectedFormatIndex].id,
        channel: channels[selectedChannelIndex].id,
        discountTypeId: this.discountType.id,
        productId: this.selectedCatalogProducts
      }
      const discountValuesRes = await getDiscountValues(
        this.props.client,
        discountValueInput
      )
      const discountValues = discountValuesRes.data.productDiscountValues.data

      this.originalPrices = updateOriginalPricesWithDiscountValue(
        [...this.originalPrices],
        discountValues
      )
      this.updatedPrices = this.originalPrices.map(op => {
        return { ...op }
      })
      await this.getPricesForOtherFormatAndChannel()
      this.setState({
        isSamePackagingCharge,
        packagingCharge
        // IsFetching: false,
      })
      if (catalog) {
        this.getCategories()
      }
    } catch (err) {
      console.error(err)
      this.setState({ isFetching: false })
    }
  }

  getPricesForOtherFormatAndChannel = async () => {
    const { packagingCharge } = this.state

    try {
      for await (const formatChannelPrice of this.formatChannelPrices) {
        const storeFormat = formatChannelPrice.format.id
        const channel = formatChannelPrice.channel.id
        const discountValueInput = {
          storeFormat,
          channel,
          discountTypeId: this.discountType.id,
          productId: this.selectedCatalogProducts
        }
        const discountValuesRes = await getDiscountValues(
          this.props.client,
          discountValueInput
        )
        const discountValues = discountValuesRes.data.productDiscountValues.data

        formatChannelPrice.prices = updateOriginalPricesWithDiscountValue(
          [...this.originalPrices],
          [...discountValues]
        )
        const priceResponse = await getPriceValues(this.props.client, {
          storeFormat,
          channel
        })
        const priceValues = priceResponse.data.productPriceValues.data

        formatChannelPrice.prices = updateOriginalPricesWithProductPrice(
          [...formatChannelPrice.prices],
          [...priceValues]
        )
        const chargeValueInput = {
          storeFormat,
          channel,
          chargeType: packagingCharge.chargeType.id,
          productId: this.selectedCatalogProducts
        }
        const packagingResponse = await getChargeValues(
          this.props.client,
          chargeValueInput
        )
        const chargeValues = packagingResponse.data.productChargeValues.data

        formatChannelPrice.prices = updateOriginalPricesWithPackagingPrice(
          [...formatChannelPrice.prices],
          [...chargeValues]
        )
      }
    } catch (err) {
      console.error('Get prices for all format channel error', err)
    }
  }

  /*
   *- Reset modified items availability to previous state before closing the modal
   */
  onCancelUnavailableItemsModal = () => {
    const {
      unavailableStoreItems,
      itemIndexWithUpdatedAvailability
    } = this.state

    itemIndexWithUpdatedAvailability.forEach(itemIndex => {
      unavailableStoreItems[itemIndex].inventoryAvailable = false
    })
    this.setState({ unavailableItemsCheck: false, unavailableStoreItems })
    this.getAllProductByCategory(this.state.selectedCategory)
  }

  /*
   *- Filter all unavailable items and reset all flags before opening modal
   */
  onOpenUnavailableItemsModal = () => {
    const { storeInventory, unavailableItemsCheck } = this.state
    const unavailableItems = storeInventory.filter((storeItem: any) => {
      return !storeItem.inventoryAvailable
    })

    this.setState({
      unavailableItemsCheck: !unavailableItemsCheck,
      unavailableStoreItems: unavailableItems,
      markAllAvailable: false,
      itemAvailabilityChanged: false,
      itemIndexWithUpdatedAvailability: []
    })
  }

  getRowClassNames = (record: any) => {
    const { selectedItemRowIndex } = this.state
    let classes = ''

    if (
      record.itemName === this.searchedItemName ||
      selectedItemRowIndex === record.rowIndex
    ) {
      classes = 'searched-item'
    }

    return classes
  }

  applyItemSearch = (itemName: string) => {
    const { storeInventoryForSearch, selectedCategory, isAdmin } = this.state

    if (!itemName) {
      this.searchedItemName = ''
      this.showCategoryProduct(selectedCategory)

      return
    }
    const searchedProduct = storeInventoryForSearch[itemName]

    if (!searchedProduct) {
      this.searchedItemName = ''

      return
    }
    this.searchedItemName = itemName
    const categories = isAdmin
      ? this.categoriesWithProducts
      : this.catalogCategories
    const selectedCat = categories.find(c => {
      let isItemFound = false

      c.products.forEach(p => {
        if (p.id === searchedProduct.id) {
          isItemFound = true
        }
      })

      return isItemFound
    })

    this.showCategoryProduct(selectedCat)
    // This.getAllProductByCategory(selectedCategory);
  }

  updateDealPriceChange = percentDealPriceChange => {
    this.togglePopover(false)
    const updatedPrices = this.updatedPrices.map(p => {
      p.dealPrice = getNewDealPrice(p.dealPrice, percentDealPriceChange)

      return p
    })

    this.updatedPrices = updatedPrices
    if (!this.state.isDraftMode) {
      addWindowEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, this.handleUnsavedMenu)
    }
    this.setState({
      isDraftMode: true
    })
    updatedPrices.forEach(element => {
      this.validateAndUpdatePrices(element.productId, PRICE_TYPE.DEAL)
    })
    this.tempData = null
  }

  showDealPriceChange = percentDealPriceChange => {
    const { productDataSource } = this.state

    if (!this.tempData) {
      this.tempData = [...productDataSource]
    }

    if (productDataSource.length) {
      const newPriceData = this.tempData.map(row => {
        const currentDealPrice = row.dealPrice.props.value || 0
        const currentBasePrice = row.basePrice.props.value || 0
        const newDealPrice = getNewDealPrice(
          currentDealPrice,
          percentDealPriceChange
        )
        const newBasePrice =
          newDealPrice > currentBasePrice ? newDealPrice : currentBasePrice

        return {
          ...row,
          dealPrice: this.formProductDealPrice(row.key, newDealPrice),
          basePrice: this.formProductBasePrice(row.key, newBasePrice)
        }
      })

      this.setState({ productDataSource: newPriceData })
    }
  }

  beforePopoverClose = () => {
    this.togglePopover(false)
    if (this.tempData) {
      this.setState({ productDataSource: this.tempData })
      this.tempData = null
    }
  }

  undoAllClickHandler = () => {
    this.setState({
      showStrikeoutModal: true,
      strikeoutModalTitle: <IntlMessages id="menu.discard.title" />,
      strikeoutModalDesc: <IntlMessages id="menu.discard.desc" />,
      strikeoutModalCancelBtnText: <IntlMessages id="button.goBack" />,
      strikeoutModalOkBtnText: <IntlMessages id="button.yesDiscardIt" />,
      strikeoutModalCancelBtnHandler: () => {
        this.setState({ showStrikeoutModal: false })
      },
      strikeoutModalOkBtnHandler: () => {
        // Call function to revert all packaging, price related changes
        this.undoAllPriceChanges()
      },
      strikeoutModalOkBtnType: 'primary'
    })
  }

  publishMenuClickHandler = () => {
    this.setState({
      showStrikeoutModal: true,
      strikeoutModalTitle: <IntlMessages id="menu.publish.menu.title" />,
      strikeoutModalDesc: <IntlMessages id="menu.publish.menu.desc" />,
      strikeoutModalCancelBtnText: <IntlMessages id="button.no" />,
      strikeoutModalOkBtnText: <IntlMessages id="button.yes" />,
      strikeoutModalCancelBtnHandler: () => {
        this.setState({ showStrikeoutModal: false })
      },
      strikeoutModalOkBtnHandler: () => {
        this.publishPrices()
        republishCatalog(this.props.client)
      },
      strikeoutModalOkBtnType: 'primary'
    })
  }

  publishPrices = async () => {
    this.setState({ publishingMenu: true })
    const {
      channels,
      storeFormats,
      selectedChannelIndex,
      selectedFormatIndex
    } = this.state
    const { client } = this.props
    const productsForPriceUpdate = getProductsToUpdate(
      [...this.updatedPrices],
      'priceValueChanged',
      'priceValueId'
    )
    const productsForChargeUpdate = getProductsToUpdate(
      [...this.updatedPrices],
      'chargeValueChanged',
      'chargeValueId'
    )
    const productsForDiscountUpdate = getProductsToUpdate(
      [...this.updatedPrices],
      'discountValueChanged',
      'discountValueId'
    )
    const productsForDiscountCreate = getProductsToCreate(
      [...this.updatedPrices],
      'discountValueChanged',
      'discountValueId'
    )
    let priceValueUpdateInput = preparePriceValueUpdateInput([
      ...productsForPriceUpdate
    ])
    let chargeValueUpdateInput = prepareChargeValueUpdateInput([
      ...productsForChargeUpdate
    ])
    let discountValueUpdateInput = prepareDiscountValueUpdateInput([
      ...productsForDiscountUpdate
    ])
    const discountValueCreateInput = prepareDiscountValueCreateInput(
      [...productsForDiscountCreate],
      storeFormats[selectedFormatIndex],
      channels[selectedChannelIndex],
      this.discountType
    )
    let totalDiscountValueCreateInput = [...discountValueCreateInput]

    try {
      if (discountValueCreateInput.length) {
        await createDiscountForProducts(client, discountValueCreateInput)
      }
      for await (const formatChannelPrice of this.formatChannelPrices) {
        const newProductsForPriceUpdate = getProductsWithUpdatedPropertyId(
          [...productsForPriceUpdate],
          formatChannelPrice.prices,
          'priceValueId',
          ACTIONS.UPDATE
        )
        const newProductsForChargeUpdate = getProductsWithUpdatedPropertyId(
          [...productsForChargeUpdate],
          formatChannelPrice.prices,
          'chargeValueId',
          ACTIONS.UPDATE
        )
        const newProductsForDiscountUpdate = getProductsWithUpdatedPropertyId(
          [...productsForDiscountUpdate],
          formatChannelPrice.prices,
          'discountValueId',
          ACTIONS.UPDATE
        )
        const newProductsForDiscountCreate = getProductsWithUpdatedPropertyId(
          [...productsForDiscountCreate],
          formatChannelPrice.prices,
          'discountValueId',
          ACTIONS.CREATE
        )
        const newPriceValueUpdateInput = preparePriceValueUpdateInput([
          ...newProductsForPriceUpdate
        ])

        priceValueUpdateInput = [
          ...priceValueUpdateInput,
          ...newPriceValueUpdateInput
        ]
        const newChargeValueUpdateInput = prepareChargeValueUpdateInput([
          ...newProductsForChargeUpdate
        ])

        chargeValueUpdateInput = [
          ...chargeValueUpdateInput,
          ...newChargeValueUpdateInput
        ]
        const newDiscountValueUpdateInput = prepareDiscountValueUpdateInput([
          ...newProductsForDiscountUpdate
        ])

        discountValueUpdateInput = [
          ...discountValueUpdateInput,
          ...newDiscountValueUpdateInput
        ]
        const newDiscountValueCreateInput = prepareDiscountValueCreateInput(
          [...newProductsForDiscountCreate],
          formatChannelPrice.format,
          formatChannelPrice.channel,
          this.discountType
        )

        totalDiscountValueCreateInput = [
          ...totalDiscountValueCreateInput,
          ...newDiscountValueCreateInput
        ]
        if (newDiscountValueCreateInput.length) {
          await createDiscountForProducts(client, newDiscountValueCreateInput)
        }
      }
      if (priceValueUpdateInput.length) {
        await updatePriceForProducts(client, priceValueUpdateInput)
      }
      if (chargeValueUpdateInput.length) {
        await updateChargeForProducts(client, chargeValueUpdateInput)
      }
      if (discountValueUpdateInput.length) {
        await updateDiscountForProducts(client, discountValueUpdateInput)
      }
    } catch (err) {
      console.error('Publish menu error', err)
    }
    if (this.state.isDraftMode) {
      removeWindowEventListener(
        WINDOW_EVENTS.BEFORE_UNLOAD,
        this.handleUnsavedMenu
      )
    }
    republishCatalog(this.props.client)
    this.setState({
      publishingMenu: false,
      showStrikeoutModal: false,
      isDraftMode: false
    })
    if (
      priceValueUpdateInput.length ||
      chargeValueUpdateInput.length ||
      discountValueUpdateInput.length ||
      totalDiscountValueCreateInput.length
    ) {
      this.getPrices()
    }
  }

  launchMenuNotSavedModal = (okBtnHandler, cancelBtnHandler) => {
    const { isDraftMode } = this.state

    if (isDraftMode) {
      this.setState({
        showStrikeoutModal: true,
        strikeoutModalTitle: <IntlMessages id="menu.not.saved.title" />,
        strikeoutModalDesc: <IntlMessages id="menu.unsaved.changes.desc" />,
        strikeoutModalCancelBtnText: <IntlMessages id="button.goBack" />,
        strikeoutModalOkBtnText: <IntlMessages id="button.yesDiscard" />,
        strikeoutModalCancelBtnHandler: cancelBtnHandler,
        strikeoutModalOkBtnHandler: okBtnHandler,
        strikeoutModalOkBtnType: 'danger'
      })
    } else {
      okBtnHandler()
    }
  }

  launchLeavePageConfirmationModal = (okBtnHandler, cancelBtnHandler) => {
    this.setState({
      showStrikeoutModal: true,
      strikeoutModalTitle: <IntlMessages id="menu.not.saved.title" />,
      strikeoutModalDesc: <IntlMessages id="menu.unsaved.changes.desc" />,
      strikeoutModalCancelBtnText: <IntlMessages id="stay" />,
      strikeoutModalOkBtnText: <IntlMessages id="leave" />,
      strikeoutModalCancelBtnHandler: cancelBtnHandler,
      strikeoutModalOkBtnHandler: okBtnHandler,
      strikeoutModalOkBtnType: 'danger'
    })
  }

  undoAllPriceChanges = (cb?: any) => {
    const { productDataSource } = this.state

    this.updatedPrices = this.originalPrices.map(op => {
      return { ...op }
    })
    const newProductDataSource = productDataSource.map(pdSource => {
      const productId = pdSource.key

      return {
        ...pdSource,
        basePrice: this.formProductBasePrice(productId),
        dealPrice: this.formProductDealPrice(productId),
        packingCharge: this.formProductPackagingCharge(productId)
      }
    })

    if (this.state.isDraftMode) {
      removeWindowEventListener(
        WINDOW_EVENTS.BEFORE_UNLOAD,
        this.handleUnsavedMenu
      )
    }
    this.setState(
      {
        isDraftMode: false,
        showStrikeoutModal: false,
        productDataSource: newProductDataSource
      },
      () => {
        cb && cb()
      }
    )
  }

  handleUnsavedMenu = e => {
    const message = <IntlMessages id="menu.unsaved.changes.desc" />

    handleBeforeUnload(e, message)
  }

  togglePopover = (toggle = true) => {
    this.setState({
      showChangePrice: toggle ? !this.state.showChangePrice : toggle
    })
  }

  updateReorderedCategory = categories => {
    const updateCategorySortSeqInput = getUpdateCategorySortSeqInput(
      categories,
      this.org_id
    )

    updateCategorySortSeq(this.props.client, updateCategorySortSeqInput)
    republishCatalog(this.props.client)
      .then(() => {
        republishCatalog(this.props.client)
        message.success(
          intlAlertMessage({
            id: 'category.successMsg.categoryOrderedSuccessfully',
            intl: this.props.intl
          })
        )
        this.setState({
          categories
        })
      })
      .catch(err => {
        console.warn('Order Category Error:', err)
      })
      .finally(() => {
        this.setState({ isReordering: false })
      })
  }

  onSortEnd = e => {
    const newCategories = arrayMove(
      this.state.categories,
      e.oldIndex,
      e.newIndex
    )

    this.setState({
      isReordering: true,
      categories: newCategories
    })
    this.updateReorderedCategory(newCategories)
  }

  onToggleCategory = (category, index) => {
    this.launchMenuNotSavedModal(
      () => {
        this.undoAllPriceChanges()
        this.processCategoryListableCheck(category, index)
        republishCatalog(this.props.client)
      },
      () => {
        republishCatalog(this.props.client)
        this.setState({
          showStrikeoutModal: false
        })
      }
    )
  }

  onReorderCategory = (newArr, newIndex) => {
    this.setState({
      categories: newArr,
      rowIndex: newIndex
    })
  }

  render() {
    const {
      productDataSource,
      catalog,
      catalogName,
      isAdmin,
      isCatalogNameEdit,
      showTaxForm,
      showChargesForm,
      grossTax,
      taxTypeValues,
      chargeTypeValues,
      savingForm,
      duplicateName,
      showPackagingChargeForm,
      packagingCharge,
      isSamePackagingCharge,
      categories,
      unavailableItemsCheck,
      unavailableStoreItems,
      storeInventoryForSearch,
      markAllAvailable,
      itemAvailabilityChanged,
      /*
       * Channels,
       * storeFormats,
       * selectedFormatChannelKey,
       * selectedFormatChannel,
       */
      isConfirmProcessing,
      isDraftMode,
      showStrikeoutModal,
      strikeoutModalTitle,
      strikeoutModalDesc,
      strikeoutModalCancelBtnText,
      strikeoutModalOkBtnText,
      strikeoutModalCancelBtnHandler,
      strikeoutModalOkBtnHandler,
      strikeoutModalOkBtnType,
      publishingMenu,
      menuHeaderOffSetTop,
      showChangePrice,
      isReordering,
      rowIndex
    } = this.state
    const itemNames = Object.keys(storeInventoryForSearch)
    /*
     * Const menu = (
     *   <Menu
     *     onClick={this.onFormatChannelChange}
     *     selectedKeys={[selectedFormatChannelKey]}
     *   >
     *     {storeFormats &&
     *       storeFormats.map((format, fIndex) => (
     *         <SubMenu key={fIndex} title={format.name}>
     *           {channels.map((channel, cIndex) => (
     *             <Menu.Item key={fIndex + '-' + cIndex}>
     *               {channel.name}
     *             </Menu.Item>
     *           ))}
     *         </SubMenu>
     *       ))}
     *   </Menu>
     * );
     */

    return (
      <div>
        {this.isPriceFetch ? (
          <div className="justifyContent-center">
            <Spin
              size="large"
              tip={intlAlertMessage({
                id: 'orderTable.message.consoleIsLoading',
                intl: this.props.intl
              })}
            />
          </div>
        ) : (
          <>
            <Affix offsetTop={menuHeaderOffSetTop + 1}>
              <Header isOnboarding={this.isOnboarding}>
                {/* {catalogCount > 1 && (
                <StyledDiv>
                  <Icon
                    className="backIcon"
                    type="arrow-left"
                    onClick={() => {
                      if (this.isOnboarding) {
                        this.goBackToOnboarding();
                      } else {
                        this.props.history.push('/orderx/menu-list');
                      }
                    }}
                  />1
                </StyledDiv>
              )} */}
                {!isCatalogNameEdit ? (
                  <StyledDiv>
                    <Title
                      level="h4"
                      style={{ display: 'inline', marginBottom: 0 }}>
                      <Tooltip title={catalogName}>{catalogName}</Tooltip>
                    </Title>
                    {isAdmin && (
                      <span className="editCatalog">
                        <Icon
                          type="edit"
                          onClick={() => this.handleCatalogEditSave('edit')}
                        />
                      </span>
                    )}
                    {isDraftMode && (
                      <DraftModeContainer>
                        <Text
                          level="body-2"
                          style={{ color: COLORS.SECONDARY }}>
                          <IntlMessages id="inDraftMode" />
                        </Text>
                      </DraftModeContainer>
                    )}
                  </StyledDiv>
                ) : (
                  <StyledDiv>
                    <Input
                      value={catalogName}
                      className="catalogNameInput"
                      onChange={this.onHandleCatalogChange}
                    />
                    <span className="editCatalog">
                      <Icon
                        type="save"
                        onClick={() => this.handleCatalogEditSave('save')}
                      />
                    </span>
                  </StyledDiv>
                )}
                {catalog && !catalog['listable'] && (
                  <OnboardingTextContainer>
                    <Text level="caption" style={{ color: COLORS.ERROR_DARK }}>
                      <IntlMessages id="menu.errMsg.menuNotVerifiedMessage" />
                    </Text>
                  </OnboardingTextContainer>
                )}
                <HelpButtonContainer>
                  <a
                    href={MENU_HELP_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: COLORS.NORMAL_TEXT }}>
                    <IntlMessages id="menu.help" />?
                  </a>
                </HelpButtonContainer>
                {!catalog['listable'] && (
                  <VerifyButtonContainer>
                    <Button
                      type={'danger'}
                      key="confirm-btn"
                      style={{ marginBottom: 0 }}
                      loading={isConfirmProcessing}
                      onClick={() => this.handleConfirmCatalogClick()}>
                      <IntlMessages id="menu.verify" />
                    </Button>
                  </VerifyButtonContainer>
                )}
                {isAdmin && isDraftMode && (
                  <UndoAllBtnContainer onClick={this.undoAllClickHandler}>
                    <Text level="body-2">
                      <IntlMessages id="button.discard" />
                    </Text>
                  </UndoAllBtnContainer>
                )}
                {isAdmin && (
                  <Button
                    type="primary"
                    disabled={!isDraftMode}
                    style={{
                      minWidth: '180px',
                      height: '50px',
                      borderRadius: '0',
                      marginBottom: 0
                    }}
                    onClick={this.publishMenuClickHandler}>
                    <Title
                      level="h6"
                      style={{
                        color: isDraftMode ? COLORS.BG_WHITE : COLORS.NORMAL_TEXT,
                        marginBottom: 0
                      }}>
                      <IntlMessages id="button.publishMenu" />
                    </Title>
                  </Button>
                )}
              </Header>
            </Affix>
            <Content className="view-menu">
              <div style={{ height: '14px' }} />
              <Row
                justify="space-between"
                style={{
                  background: COLORS.BG_WHITE,
                  padding: '8px 0',
                  margin: '0 0 5px 0'
                }}
                className="category-product-container">
                {isAdmin ? (
                  <Col span={3}>
                    <Button
                      style={{ marginBottom: 0 }}
                      type={'primary'}
                      key="customize-btn"
                      ghost
                      onClick={() => {
                        this.launchMenuNotSavedModal(
                          () => {
                            this.undoAllPriceChanges(() => {
                              this.goToEditMenu()
                            })
                          },
                          () => {
                            this.setState({
                              showStrikeoutModal: false
                            })
                          }
                        )
                      }}>
                      <IntlMessages id="menu.editMenu" />
                    </Button>
                  </Col>
                ) : (
                  <Col
                    span={5}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex' }}>
                      <IntlMessages id="menu.unavailableItems" /> -
                      <span
                        onClick={this.onOpenUnavailableItemsModal}
                        className="blue-text"
                        style={{ marginLeft: '10px', fontWeight: 600 }}>
                        <IntlMessages id="view.all" />
                      </span>
                    </div>
                  </Col>
                )}
                <Col style={{ display: 'flex' }}>
                  {isAdmin && (
                    <Select
                      defaultValue="All categories"
                      style={{ width: '178px' }}
                      size="middle"
                      onChange={val => {
                        this.handleFilterChange(val, 0)
                      }}>
                      <Option value="all">
                        <IntlMessages id="menu.allCategories" />
                      </Option>
                      <Option value={isAdmin ? 'listable' : 'available'}>
                        {isAdmin ? (
                          <IntlMessages id="menu.listedCategories" />
                        ) : (
                          <IntlMessages id="menu.available" />
                        )}
                      </Option>
                      <Option
                        value={isAdmin ? 'non-listable' : 'not-available'}>
                        {isAdmin ? (
                          <IntlMessages id="menu.unlistedCategories" />
                        ) : (
                          <IntlMessages id="menu.notAvailable" />
                        )}
                      </Option>
                    </Select>
                  )}
                  <SearchContainer>
                    {!isAdmin && <span></span>}
                    <AutoCompleteSearch
                      dataSource={itemNames}
                      onSelect={value => this.applyItemSearch(value)}
                      client={this.props.client}
                      width={368}
                      key={this.filterOption}>
                      {this.props.children}
                    </AutoCompleteSearch>
                  </SearchContainer>
                  {isAdmin && (
                    <Popover
                      content={
                        <PercentageChangeInput
                          onValueChange={this.showDealPriceChange}
                          onSubmit={this.updateDealPriceChange}
                        />
                      }
                      placement={'bottom'}
                      trigger="click"
                      overlayClassName="price-popover"
                      visible={showChangePrice}
                      onVisibleChange={visible =>
                        !visible && this.beforePopoverClose()
                      }
                      getPopupContainer={triggerNode =>
                        triggerNode.parentElement
                      }
                      destroyTooltipOnHide>
                      <Button
                        style={{
                          marginBottom: 0,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '160px'
                        }}
                        type="dark"
                        onClick={() => this.togglePopover()}>
                        <IntlMessages id="menu.percentChangePrice" />
                        <span>
                          <Icon type={showChangePrice ? 'up' : 'down'} />
                        </span>
                      </Button>
                    </Popover>
                  )}
                  <Button
                    style={{
                      margin: '0 5px 0 0',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '160px'
                    }}
                    type="dark"
                    onClick={() => this.setState({ isDrawerVisible: true })}>
                    <IntlMessages id={'menu.taxes&Charges'} />
                    <span>
                      <Icon style={{ fontSize: '18px' }} type={'menu-fold'} />
                    </span>
                  </Button>
                </Col>

                {/* <Row>
                        <Col span={24}>
                          {isAdmin ? (
                            <Row>
                              <Col
                                md={2}
                                lg={2}
                                sm={3}
                                style={{ paddingRight: 0 }}
                              >
                                <div>
                                  <AntdText>Format& Channel</AntdText>
                                </div>
                              </Col>
                              <Col md={5} lg={6} sm={7} className="pd-0">
                                <Dropdown overlay={menu}>
                                  <AntdButton>
                                    {selectedFormatChannel.length > 0
                                      ? selectedFormatChannel
                                      : 'Select'}
                                    <Icon type="down" />
                                  </AntdButton>
                                </Dropdown>
                              </Col>

                              <Col
                                sm={2}
                                md={2}
                                lg={1}
                                style={{
                                  padding: '10px 0',
                                  marginBottom: '15px',
                                  marginRight: '10px',
                                }}
                              >
                                <div style={{ marginLeft: '17px' }}>
                                  <IntlMessages id="menu.filter" />
                                </div>
                              </Col>
                              <Col md={4} className="pd-0">
                                <Select
                                  size={'default'}
                                  defaultValue="All categories"
                                  style={{ width: 150, marginLeft: '10px' }}
                                  onChange={val => {
                                    this.handleFilterChange(val, 0);
                                  }}
                                >
                                  <Option value="all">
                                    <IntlMessages id="menu.allCategories" />
                                  </Option>
                                  <Option
                                    value={isAdmin ? 'listable' : 'available'}
                                  >
                                    {isAdmin ? (
                                      <IntlMessages id="menu.listedCategories" />
                                    ) : (
                                      <IntlMessages id="menu.available" />
                                    )}
                                  </Option>
                                  <Option
                                    value={
                                      isAdmin ? 'non-listable' : 'not-available'
                                    }
                                  >
                                    {isAdmin ? (
                                      <IntlMessages id="menu.unlistedCategories" />
                                    ) : (
                                      <IntlMessages id="menu.notAvailable" />
                                    )}
                                  </Option>
                                </Select>
                              </Col>
                              <Col span={18} style={{ paddingRight: 0 }}>
                                <div
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  <AutoCompleteSearch
                                    dataSource={itemNames}
                                    onSelect={value =>
                                      this.applyItemSearch(value)
                                    }
                                    client={this.props.client}
                                    children={this.props.children}
                                    width={368}
                                    key={this.filterOption}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col span={7}>
                                <IntlMessages id="menu.unavailableItems" /> -
                                <span
                                  onClick={this.onOpenUnavailableItemsModal}
                                  className="blue-text"
                                  style={{ marginLeft: '10px', fontWeight: 600 }}
                                >
                                  <IntlMessages id="view.all" />
                                </span>
                              </Col>
                              {!isAdmin && (
                                <Col span={17}>
                                  <div
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '15px',
                                    }}
                                  >
                                    <AutoCompleteSearch
                                      dataSource={itemNames}
                                      onSelect={value =>
                                        this.applyItemSearch(value)
                                      }
                                      client={this.props.client}
                                      children={this.props.children}
                                      width={368}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          )}
                        </Col>
                      </Row>
                       */}
              </Row>
              <Row gutter={8} style={{ margin: '0px' }}>
                <Col
                  span={6}
                  className="category-list-wrapper no-pd-left no-pd-right">
                  <Row className="category-table-header">
                    <Col span={isAdmin ? 18 : 20} className={'no-pd-right'}>
                      <AntdText>
                        <IntlMessages id="menu.categories" />
                      </AntdText>
                    </Col>
                    {isAdmin && (
                      <Col span={6} className={'no-pd-right no-pd-left'}>
                        <AntdText>
                          <IntlMessages id="menu.listable" />
                        </AntdText>
                      </Col>
                    )}
                    <Col span={4} className={'no-pd-right no-pd-left'}></Col>
                  </Row>
                  <SortableCategoryList
                    intl={this.props.intl}
                    rowIndex={rowIndex}
                    categories={categories}
                    isReordering={isReordering}
                    isAdmin={isAdmin}
                    showCategoryProduct={this.showCategoryProduct}
                    onSwitchChange={this.onToggleCategory}
                    onReorderCategory={this.onReorderCategory}
                    onSortEnd={this.onSortEnd}
                    distance={1}
                  />
                </Col>
                <Col span={18} className="no-pd-left no-pd-right">
                  <Table
                    rowClassName={this.getRowClassNames}
                    // ClassName="product-table"
                    dataSource={productDataSource}
                    columns={getItemColumns(
                      this.state,
                      (updatedProductDataSource, newIndex) => {
                        this.setState({
                          productDataSource: updatedProductDataSource,
                          selectedItemRowIndex: newIndex
                        })
                      }
                    )}
                    pagination={false}
                    loading={this.state.isFetching}
                    scroll={{ y: '70vh' }}
                    style={{ cursor: 'pointer' }}
                    onRow={record => {
                      return {
                        onClick: event => {
                          this.setState(
                            { selectedItemRowIndex: record.rowIndex }
                            /*
                             * () => {
                             *   setItemUpDownArrowPosition(
                             *     record.rowIndex
                             *   );
                             * }
                             */
                          )
                        } // Click row
                      }
                    }}
                  />
                </Col>
              </Row>
              <Drawer
                title={<IntlMessages id={'menu.taxes&Charges'} />}
                closable
                getContainer={false}
                onClose={() => this.setState({ isDrawerVisible: false })}
                visible={this.state.isDrawerVisible}
                maskStyle={{ background: 'transparent' }}
                style={{ position: 'absolute' }}
                headerStyle={{ padding: '5px 0 0 0', border: 'none' }}
                className="tax-drawer"
                zIndex={10}>
                <div style={{ margin: '25px 0' }}>
                  <Text level="body-2">
                    <IntlMessages id="menu.message.taxes&ChargesMessage" />
                  </Text>
                </div>
                <div className="charge-wrapper">
                  <Row justify="space-between" className="charge-field">
                    <div>
                      <AntdText>
                        <IntlMessages id="menu.taxes" />
                      </AntdText>{' '}
                      <Icon
                        style={{ color: COLORS.NORMAL_TEXT }}
                        type="exclamation-circle"
                      />
                    </div>
                    {isAdmin && (
                      <span
                        className="action-text"
                        onClick={() => {
                          this.launchMenuNotSavedModal(
                            () => {
                              this.undoAllPriceChanges()
                              this.toggleTaxForm()
                            },
                            () => {
                              this.setState({
                                showStrikeoutModal: false
                              })
                            }
                          )
                        }}>
                        {taxTypeValues.length > 0 ? 'Modify' : 'Add'}
                      </span>
                    )}
                  </Row>
                  <Row
                    justify="space-between"
                    className="charge-amount-container">
                    {taxTypeValues.length > 0 ? (
                      <AntdText>
                        <IntlMessages id="menu.grossTax" />
                      </AntdText>
                    ) : (
                      <AntdText style={{ color: COLORS.GRAY_TEXT }}>
                        <IntlMessages id="menu.noTaxesAdded" />
                      </AntdText>
                    )}
                    {taxTypeValues.length > 0 && (
                      <AntdText>{grossTax}%</AntdText>
                    )}
                  </Row>
                </div>
                <div className="charge-wrapper">
                  <Row justify="space-between" className="charge-field">
                    <div>
                      <AntdText>
                        <IntlMessages id="menu.packagingCharge" />
                      </AntdText>{' '}
                      <Icon
                        style={{ color: COLORS.NORMAL_TEXT }}
                        type="exclamation-circle"
                      />
                    </div>

                    {isAdmin && (
                      <span
                        className="action-text"
                        onClick={() => {
                          this.launchMenuNotSavedModal(
                            () => {
                              this.undoAllPriceChanges()
                              this.togglePackagingChargeForm()
                            },
                            () => {
                              this.setState({
                                showStrikeoutModal: false
                              })
                            }
                          )
                        }}>
                        <IntlMessages id="menu.modify" />
                      </span>
                    )}
                  </Row>
                  <Row
                    justify="space-between"
                    className="charge-amount-container"
                    style={{ marginBottom: '5px' }}>
                    <AntdText
                      style={{
                        color: isSamePackagingCharge
                          ? COLORS.DARK_TEXT
                          : COLORS.GRAY_TEXT
                      }}>
                      <IntlMessages id="menu.defaultForAllTimes" />
                    </AntdText>
                    {isSamePackagingCharge ? (
                      <AntdText>₹ {packagingCharge.chargeValue}</AntdText>
                    ) : (
                      <AntdText style={{ color: COLORS.GRAY_TEXT }}>
                        _._
                      </AntdText>
                    )}
                  </Row>
                  {!isSamePackagingCharge && (
                    <span>
                      <Icon className="mr-1" type="exclamation-circle" />{' '}
                      <AntdText style={{ fontSize: '12px' }}>
                        <IntlMessages id="menu.message.customChargesMessage" />
                      </AntdText>
                    </span>
                  )}
                </div>
                <Row justify="space-between" className="charge-field">
                  <div>
                    <AntdText>
                      <IntlMessages id="menu.additionalCharges" />
                    </AntdText>{' '}
                    <Icon
                      style={{ color: COLORS.NORMAL_TEXT }}
                      type="exclamation-circle"
                    />
                  </div>

                  {isAdmin && (
                    <span
                      className="action-text"
                      onClick={() => {
                        this.launchMenuNotSavedModal(
                          () => {
                            this.undoAllPriceChanges()
                            this.toggleChargeForm()
                          },
                          () => {
                            this.setState({
                              showStrikeoutModal: false
                            })
                          }
                        )
                      }}>
                      {chargeTypeValues.length > 0 ? (
                        <IntlMessages id="menu.modify" />
                      ) : (
                        <IntlMessages id="add" />
                      )}
                    </span>
                  )}
                </Row>
                <>
                  {chargeTypeValues.map((chargeTypeValue, i) => (
                    <Row
                      justify="space-between"
                      className="charge-amount-container"
                      key={i}>
                      <AntdText>{chargeTypeValue.chargeType.name}</AntdText>

                      <AntdText>₹ {chargeTypeValue.chargeValue}</AntdText>
                    </Row>
                  ))}
                  {!chargeTypeValues.length && (
                    <AntdText style={{ color: COLORS.GRAY_TEXT }}>
                      <IntlMessages id="menu.noAdditionalCharges" />
                    </AntdText>
                  )}
                </>
              </Drawer>
              {isAdmin && showTaxForm && (
                <Modal
                  wrapClassName="custom-modal"
                  title={'Configure Taxes'}
                  visible={showTaxForm}
                  className={'add-slot-modal-styles'}
                  onCancel={() => {
                    this.toggleTaxForm()
                  }}
                  footer={[
                    <AntdButton
                      loading={savingForm}
                      key="createUpdateTax"
                      className={'createSlotBtn'}
                      disabled={duplicateName || grossTax < 5}
                      onClick={() => {
                        if (!this.validateForm('tax')) {
                          this.createUpdateTaxes()
                        }
                      }}>
                      <IntlMessages id="menu.submit" />
                    </AntdButton>
                  ]}>
                  <Col span={24}>
                    <Row>
                      <Col span={24} className="mb-2">
                        <IntlMessages id="menu.appliedForAllItems" />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={11} className="pd-right">
                        <IntlMessages id="menu.taxName" />
                      </Col>
                      <Col span={11} className="pd-left">
                        <IntlMessages id="menu.value" />
                      </Col>
                    </Row>
                    {taxTypeValues.map(
                      (taxTypeValue, index) =>
                        !taxTypeValue.removed && (
                          <Row key={taxTypeValue.taxValueId + index}>
                            <Col span={11} className="pd-right">
                              <AutoComplete
                                value={taxTypeValue.taxType.name}
                                onChange={value =>
                                  this.onTaxOptionChange(value, index)
                                }
                                className="certain-category-search"
                                dropdownClassName="certain-category-search-dropdown"
                                dropdownMatchSelectWidth={false}
                                size="large"
                                style={{ width: '100%' }}
                                dataSource={this.getTaxOptions()}
                                placeholder={intlAlertMessage({
                                  id: 'typeHere',
                                  intl: this.props.intl
                                })}
                                disabled={Boolean(taxTypeValue.taxValueId)}>
                                <Input suffix={<Icon type="down" />} />
                              </AutoComplete>
                            </Col>
                            <Col span={11} className="pd-left">
                              <InputNumber
                                prefix="%"
                                size="large"
                                value={taxTypeValue.taxValue}
                                style={{ width: '100%' }}
                                onChange={val => {
                                  this.onTaxValueChange(index, val)
                                }}
                              />
                            </Col>
                            <Col span={2} className="delete-icon">
                              <span>
                                <Icon
                                  type="delete"
                                  onClick={() => {
                                    this.onRemoveTaxTypeValue(index)
                                  }}
                                />
                              </span>
                            </Col>
                          </Row>
                        )
                    )}
                    <Row className="mt-1">
                      <Col span={11} className="pd-right">
                        <span
                          className="action-text"
                          onClick={() => {
                            this.onAddNewTaxTypeValue()
                          }}>
                          <IntlMessages id="menu.addNewTax" />
                        </span>
                      </Col>
                      <Col span={11} className="pd-left">
                        <IntlMessages id="menu.totalTax" />: {grossTax}%
                      </Col>
                    </Row>
                  </Col>
                </Modal>
              )}
              {isAdmin && showChargesForm && (
                <Modal
                  wrapClassName="custom-modal"
                  title={intlAlertMessage({
                    id: 'configureAdditionalCharges',
                    intl: this.props.intl
                  })}
                  visible={showChargesForm}
                  className={'add-slot-modal-styles'}
                  onCancel={() => {
                    this.toggleChargeForm()
                  }}
                  footer={[
                    <AntdButton
                      loading={savingForm}
                      key="createUpdateCharge"
                      className={'createSlotBtn'}
                      disabled={duplicateName}
                      onClick={() => {
                        if (!this.validateForm('charge')) {
                          this.createUpdateCharges()
                        }
                      }}>
                      <IntlMessages id="menu.submit" />
                    </AntdButton>
                  ]}>
                  <Col span={24}>
                    <Row>
                      <Col span={24} className="mb-2">
                        <IntlMessages id="menu.appliedForAllItems" />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={11} className="pd-right">
                        <IntlMessages id="menu.chargeName" />
                      </Col>
                      <Col span={11} className="pd-left">
                        <IntlMessages id="menu.value" />
                      </Col>
                    </Row>
                    {chargeTypeValues.map(
                      (chargeTypeValue, index) =>
                        !chargeTypeValue.removed && (
                          <Row key={chargeTypeValue.chargeValueId + index}>
                            <Col span={11} className="pd-right">
                              <AutoComplete
                                value={chargeTypeValue.chargeType.name}
                                onChange={value =>
                                  this.onChargeOptionChange(value, index)
                                }
                                className="certain-category-search"
                                dropdownClassName="certain-category-search-dropdown"
                                dropdownMatchSelectWidth={false}
                                size="large"
                                style={{ width: '100%' }}
                                dataSource={this.getChargeOptions()}
                                placeholder={intlAlertMessage({
                                  id: 'typeHere',
                                  intl: this.props.intl
                                })}
                                disabled={Boolean(
                                  chargeTypeValue.chargeValueId
                                )}>
                                <Input suffix={<Icon type="down" />} />
                              </AutoComplete>
                            </Col>
                            <Col span={11} className="pd-left">
                              <InputNumber
                                prefix={intlAlertMessage({
                                  id: 'rupeeSymbol',
                                  intl: this.props.intl
                                })}
                                size="large"
                                style={{ width: '100%' }}
                                value={chargeTypeValue.chargeValue}
                                onChange={val => {
                                  this.onChargeValueChange(
                                    index,
                                    val
                                  )
                                }}
                              />
                            </Col>
                            <Col span={2} className="delete-icon">
                              <span>
                                <Icon
                                  type="delete"
                                  onClick={() => {
                                    this.onRemoveChargeTypeValue(index)
                                  }}
                                />
                              </span>
                            </Col>
                          </Row>
                        )
                    )}
                    <Row className="mt-1">
                      <Col span={11}>
                        <span
                          className="action-text"
                          onClick={() => {
                            this.onAddNewChargeTypeValue()
                          }}>
                          <IntlMessages id="menu.addNewCharge" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Modal>
              )}
              {isAdmin && showPackagingChargeForm && (
                <Modal
                  wrapClassName="custom-modal"
                  title={intlAlertMessage({
                    id: 'menu.packagingCharge',
                    intl: this.props.intl
                  })}
                  visible={showPackagingChargeForm}
                  className={'add-slot-modal-styles'}
                  onCancel={() => {
                    this.togglePackagingChargeForm()
                  }}
                  footer={[
                    <AntdButton
                      loading={savingForm}
                      key="createUpdateCharge"
                      className={'createSlotBtn'}
                      // Disabled={savingForm}
                      onClick={() => {
                        if (!this.validateForm('packaging')) {
                          this.updatePackagingCharge()
                        }
                      }}>
                      <IntlMessages id="menu.submit" />
                    </AntdButton>
                  ]}>
                  <Col span={24}>
                    <Row>
                      <Col span={24} className="mb-2">
                        <IntlMessages id="menu.appliedForAllItems" />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <IntlMessages id="menu.value" />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <InputNumber
                          prefix={ intlAlertMessage({
                            id: 'rupeeSymbol',
                            intl: this.props.intl
                          }) }
                          size="large"
                          style={{ width: '100%' }}
                          value={packagingCharge.chargeValue}
                          onChange={val => {
                            let value = Math.round(parseFloat(val))

                            if (isNaN(value)) {
                              value = null
                            }
                            const pCharge = {
                              ...packagingCharge,
                              chargeValue: value
                            }

                            this.setState({ packagingCharge: pCharge })
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Modal>
              )}
            </Content>
          </>
        )}
        <Modal
          width="500px"
          title={intlAlertMessage({
            id: 'menu.unavailableItems',
            intl: this.props.intl
          })}
          visible={unavailableItemsCheck}
          className="unavailable-items-modal"
          onCancel={() => {
            this.onCancelUnavailableItemsModal()
          }}
          footer={[
            <AntdButton
              loading={savingForm}
              key="processAllProducts"
              className="ant-btn-blue ant-btn-primary"
              disabled={!itemAvailabilityChanged}
              onClick={() => {
                this.processAllProductsInStock()
              }}>
              <IntlMessages id="button.save" />
            </AntdButton>
          ]}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <IntlMessages id="menu.markAllAvailable" />
                  <Switch
                    checkedChildren={CONSOLE_MENU_CATEGORY_STATUS.ACTIVE}
                    unCheckedChildren={CONSOLE_MENU_CATEGORY_STATUS.INACTIVE}
                    checked={markAllAvailable}
                    onChange={() => {
                      this.processAllUnavailableProducts()
                    }}
                    style={{ marginLeft: '20px' }}
                  />
                </Col>
              </Row>
              <Row className="unavailable-item-header">
                <Col span={20} className={'no-pd-right'}>
                  <AntdText style={{ color: '#aaa' }}>
                    <IntlMessages id="menu.itemName" />
                  </AntdText>
                </Col>
                <Col span={4} className={'no-pd-right no-pd-left'}>
                  <AntdText style={{ color: '#aaa' }}>
                    <IntlMessages id="menu.availability" />
                  </AntdText>
                </Col>
              </Row>
              {unavailableStoreItems.length > 0 ? (
                <div className="unavailable-item-body-wrapper">
                  {unavailableStoreItems.map((item, index) => {
                    return (
                      <Row
                        key={item.product.id}
                        align="middle"
                        className="unavailable-item-row">
                        <Col span={20} className={'no-pd-right'}>
                          <div>{item.product.name}</div>
                        </Col>
                        <Col span={3} className={'no-pd-right no-pd-left'}>
                          <Switch
                            checkedChildren={
                              CONSOLE_MENU_CATEGORY_STATUS.ACTIVE
                            }
                            unCheckedChildren={
                              CONSOLE_MENU_CATEGORY_STATUS.INACTIVE
                            }
                            checked={
                              item.inventoryAvailable
                                ? item.inventoryAvailable
                                : false
                            }
                            onChange={() => {
                              this.processProductInStock(index, false)
                            }}
                          />
                        </Col>
                      </Row>
                    )
                  })}
                </div>
              ) : (
                <div className="no-item-table">
                  <IntlMessages id="menu.noData" />
                </div>
              )}
            </Col>
          </Row>
        </Modal>
        {isAdmin && showStrikeoutModal && (
          <StrikeOutConfirmationModal
            isStrikeOutVisible={showStrikeoutModal}
            title={strikeoutModalTitle}
            desc={strikeoutModalDesc}
            cancelButtonText={strikeoutModalCancelBtnText}
            okButtonText={strikeoutModalOkBtnText}
            cancelButtonAction={strikeoutModalCancelBtnHandler}
            okButtonAction={strikeoutModalOkBtnHandler}
            okButtonType={strikeoutModalOkBtnType}
            loading={Boolean(publishingMenu)}
          />
        )}
      </div>
    )
  }
}

const CatalogDetailComponent = injectIntl(
  compose(
    withRouter,
    graphql(UPDATE_CATALOG, { name: 'updateCatalog' }),
    graphql(UPDATE_PRODUCT, { name: 'updateProduct' }),
    graphql(UPDATE_CATEGORY, { name: 'updateCategory' }),
    graphql(PRODUCT_AVAILABILITY_CHECK, { name: 'storeProductAvailability' }),
    withApollo
  )(CatalogDetail)
)

export default CatalogDetailComponent
