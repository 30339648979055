import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { Layout } from 'antd'
import * as compose from 'lodash/flowRight'
// Import { Location } from 'history'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { getOrgDetails } from 'Utils'
import { ORG_STATUS } from 'Utils/constants'

import SidebarContent from '../SidebarContent'

const { Sider } = Layout

interface IState {
  collapse: boolean
  isOnboarding: boolean
}

interface IProps {
  toggleCollapsedSideNav?: any
  navCollapsed?: boolean
  location?: any
  width?: number
}
export class Sidebar extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      collapse: true,
      isOnboarding: false
    }
  }

  /*
   * OnToggleCollapsedNav = () => {
   *   this.props.toggleCollapsedSideNav({
   *     variables: { navCollapsed: !this.props.navCollapsed },
   *   });
   * };
   */
  componentDidMount() {
    const orgDetails = getOrgDetails()

    this.setState({
      isOnboarding: orgDetails.onboardingStatus === ORG_STATUS.INITIATED
    })
  }

  toggleSidebar = collapse => {
    this.setState({ collapse })
  }

  render() {
    const { isOnboarding } = this.state
    // Const { navCollapsed, width } = this.props;

    return (
      <Sider
        className={`gx-app-sidebar gx-layout-sider-dark ${isOnboarding &&
          'gx-d-none'}`}
        collapsible
        collapsed={this.state.collapse}
        onCollapse={this.toggleSidebar}
        width={280}
        collapsedWidth={48}>
        {/* {width < TAB_SIZE ? (
          <Drawer
            className={`gx-drawer-sidebar  gx-drawer-sidebar-dark ${hidebar}`}
            placement="left"
            closable={false}
            onClose={this.onToggleCollapsedNav.bind(this)}
            visible={navCollapsed}
          >
            <SidebarContent />
          </Drawer>
        ) : (
          <SidebarContent />
        )} */}
        <SidebarContent isCollapsed={this.state.collapse} />
      </Sider>
    )
  }
}
/*
 * Const UPDATE_WINDOW_WIDTH = gql`
 *   mutation updateWindowWidth($width: Int) {
 *     updateWindowWidth(width: $width) @client
 *   }
 * `;
 */

const GET_SETTINGS = gql`
  query settings {
    settings @client {
      navCollapsed
      width
    }
  }
`
const TOGGLE_COLLAPSED_SIDENAV = gql`
  mutation toggleSideNav($navCollapsed: Boolean) {
    toggleCollapsedSideNav(navCollapsed: $navCollapsed) @client
  }
`
const mapStateToProps = ({ settings }: any) => {
  const { navCollapsed, width } = settings.settings

  return { navCollapsed, width }
}

export default compose(
  withRouter,
  /*
   * Graphql(GET_SETTINGS, { name: 'settings', props: mapStateToProps }),
   * graphql(UPDATE_WINDOW_WIDTH, { name: "updateWindowWidth" }), 
   */
  graphql(TOGGLE_COLLAPSED_SIDENAV, { name: 'toggleCollapsedSideNav' })
)(Sidebar)
