import { Row } from 'antd'
import Styled from 'styled-components'
import { COLORS } from 'Utils/constants'

const Header = Styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 0 22px;
  background-color: ${props => (props.isOnboarding ? COLORS.ALERT_SHADE : COLORS.BG_WHITE)};
  justify-content: space-between;
  align-items: center;
  height: 50px;
`
const StyledDiv = Styled.div`
  flex-grow: 1; 
  display: flex;
`
const OnboardingTextContainer = Styled.div`
  flex-grow: 15;
  text-align: center;
`
const VerifyButtonContainer = Styled.div`
  flex-grow: 1;
  text-align: right;
`
const HelpButtonContainer = Styled.div`
  width: 59px;
  height: 24px;
  border: 1px solid ${COLORS.BORDER};
  text-align: center;
  margin-right: 40px;
  border-radius: 4px;
`
const FilterContainer = Styled(Row)`
  margin-left: 0;
  margin-right: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${COLORS.BORDER};
`
const SearchContainer = Styled.div`
  margin: 0 14px;
  display: flex;
  justify-content: space-between;
  .auto-complete-search-wrapper {
    margin-bottom: 0;
  }
`
const ChargeContainer = Styled.div`
  background: #fff;
  padding: 0 13px;
  height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
`
const ChangePriceContainer = Styled.div`
  padding: 0 13px;
`
const UndoAllBtnContainer = Styled.div`
  width: 144px;
  height: 50px;
  background-color: ${COLORS.BG_SHADE};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const DraftModeContainer = Styled.div`
  width: 102px;
  height: 25px;
  background: #ffe69d;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export { Header, StyledDiv, OnboardingTextContainer, VerifyButtonContainer, HelpButtonContainer, FilterContainer, SearchContainer, ChargeContainer, ChangePriceContainer, UndoAllBtnContainer, DraftModeContainer }
