import { History } from 'history'
import React from 'react'
import { getOrgDetails } from 'Utils'
import { openCreateWindowWithPost,updateGpayDetailsForm } from 'Utils/onboardingUtils'

import CommonOnboardingCard from './commonOnboardingCard'
import GpayDetailsForm from './GpayDetails'

interface GpayCardProps {
  intl: any;
  history: History;
  pending: boolean;
  disable: boolean;
  gpayDetails?: any;
  fileSystemId?: any;
}
interface GpayCardState {}

class GpayCard extends React.Component<GpayCardProps, GpayCardState> {
  goToGpayRegistration = () => {
    const { gpayDetails } = this.props

    if (!gpayDetails) {
      this.props.history.push({
        pathname: '/orderx/onboarding/gpay-registration',
        state: {
          fileSystemId: this.props.fileSystemId,
        },
      })

      return
    }
    const { featureImage, legalBusinessName, panNumber, merchantId } = gpayDetails

    if (featureImage && legalBusinessName && panNumber && !merchantId) {
      const orgDetails = getOrgDetails()

      updateGpayDetailsForm(orgDetails)
      openCreateWindowWithPost(this.props.history)
    } else {
      this.props.history.push({
        pathname: '/orderx/onboarding/gpay-registration',
        state: {
          fileSystemId: this.props.fileSystemId,
        },
      })
    }
  }

  render() {
    const { intl, pending, disable, gpayDetails } = this.props

    return (
      <div>
        <CommonOnboardingCard intl={intl} pending={pending} disable={disable} onButtonClick={() => this.goToGpayRegistration()} title="onboarding.registerOnGpay" description={pending ? 'onboarding.registerGpayInfo' : 'onboarding.registerGpayInfo.success'} buttonText="startRegistration" merchantId={gpayDetails?.merchantId} required={false} />
        <GpayDetailsForm />
      </div>
    )
  }
}
export default GpayCard
