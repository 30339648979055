import './style.css'

import { Icon } from '@ant-design/compatible'
import { ApolloClient } from '@apollo/client'
import { Input, Tooltip } from 'antd'
import { ClientManager } from 'appApollo/client'
import { IMAGES } from 'assets'
import { Button, Card, IntlMessages, Text, Title } from 'components'
import { History } from 'history'
import React from 'react'
import { injectIntl } from 'react-intl'
import { checkURLAvailability, updatePMSOrganization } from 'services'
import styled from 'styled-components'
import { getOrgDetails, intlAlertMessage, isAdmin, setOrgDetails } from 'Utils'
import { BLACKLIST_KEYWORDS, COLORS, ENVIRONMENT, MINI_URL_REGEX, ORG_STATUS, PWA_DOMAIN_NAME } from 'Utils/constants'

interface MiniAppUrlPageProps {
  history: History
  intl: any
}
interface MiniAppUrlPageState {
  isURLAvailable: boolean
  miniUrl: string
  savingData: boolean
  checkingAvailability: boolean
  isURLUsed: boolean
  blackListKeywords: any
  isValidURL: boolean
  isError: boolean
}
const Header = styled.div`
    display: flex;
    align-items: baseline;
    align-self: center;
    -webkit-align-self: initial;
    margin: 0 0;
  `
const LinkBoxStyle = styled.div`
    display: flex;
    align-items: baseline;
    padding: 30px 0 0 0;
  `
const InputBox = styled.div`
    padding: 0 5px;
    width: 30%;
  `
const BtnStyle = styled.div`
    padding-left: 25px;
  `
const SubmitButtonStyle = styled.div`
    margin: 2% 0;
  `
const ImageBlock = styled.div`
    width: 20%;
    float: right;
  `
const CardBlock = styled.div`
    float: left;
    width: 53%;
    margin-left: 5%;
  `
const Container = styled.div`
    margin: 37px 244px 0 154px;
    @media (min-width: 768px && max-width: 1024px) {
      margin: 37px 50px 0 50px;
    }
    @media (max-width: 728px) {
      margin: 37px 0 0 0;
    }
  `
const TooltipContainer = styled.div`
    background-color: #fff;
    width: 180px;
    margin: 7px 0;
  `
const BackButton = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${COLORS.NORMAL_TEXT};
  padding: 0px 5% 0 1%;
  cursor: pointer;
  width: 50%;
  display: contents;
`
const TooltipCon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `
const arr = [
  { label: 'startWithAlphabets', isValid: false, id: 2 },
  { label: 'spaceNotAllowed', isValid: false, id: 1 },
  { label: 'specialCharactersNotAllowed', isValid: false, id: 3 },
  { label: 'minThreeCharacters', isValid: false, id: 4 },
  { label: 'maxTwelveCharacters', isValid: false, id: 5 }
]
const TooltipComp = ({ intl }) => {
  return (
    <TooltipContainer>
      {arr.map(item => (
        <TooltipCon key={item.id}>
          {item.isValid ? <Icon type="check-circle" theme="filled" style={{ color: COLORS.SUCCESS, margin: '5px' }} /> : <Icon theme="filled" type="close-circle" style={{ color: COLORS.ERROR, margin: '5px' }} />}
          <div>
            <Text level={'caption'}>{intlAlertMessage({ id: item.label, intl })}</Text>
          </div>
        </TooltipCon>
      ))}
    </TooltipContainer>
  )
}

class MiniUrlPage extends React.Component<MiniAppUrlPageProps, MiniAppUrlPageState> {
  pmsClient: ApolloClient<any>

  constructor(props: MiniAppUrlPageProps) {
    super(props)
    this.state = {
      isURLAvailable: false,
      isURLUsed: false,
      miniUrl: '',
      savingData: false,
      checkingAvailability: false,
      blackListKeywords: [],
      isValidURL: false,
      isError: false
    }
    this.pmsClient = ClientManager.getInstance().pms
  }

  componentDidMount = () => {
    this.getBlackListKeyNames()
    const organization = getOrgDetails()

    if (!isAdmin() || (isAdmin() && organization && organization.onboardingStatus !== ORG_STATUS.INITIATED)) {
      this.props.history.push({ pathname: '/orderx/orders' })
    }
  }

  getBlackListKeyNames = async () => {
    const URL = BLACKLIST_KEYWORDS
    const response = await fetch(URL)
    const data = await response.json()

    this.setState({
      blackListKeywords: data.Sheet1
    })
  }

  onChangeHandler = e => {
    this.setState({
      miniUrl: e.target.value.trim().toLowerCase(),
      isURLAvailable: false,
      isURLUsed: false,
      isError: false
    })
    if (!e.target.value.trim()) {
      arr.forEach(val => (val.isValid = false))
    } else {
      arr.forEach(val => {
        if (val.id === 1) val.isValid = true
      })
    }
    this.validation(e.target.value.trim())
    this.isValidName(e.target.value.trim().toLowerCase())
  }

  isValidName = val => {
    const { blackListKeywords } = this.state
    let isValidURL = false

    blackListKeywords.forEach(keyword => {
      const isValid = val.includes(keyword.name.toLowerCase().trim())

      if (isValid) {
        isValidURL = true
      }
    })
    this.setState({
      isValidURL,
      isError: false
    })
  }

  validation = val => {
    const temp = val

    if (temp.length >= 3) {
      arr.forEach(val => {
        if (val.id === 4) val.isValid = true
      })
    } else {
      arr.forEach(val => {
        if (val.id === 4) val.isValid = false
      })
    }
    if (temp.length <= 12 && temp.length >= 3) {
      arr.forEach(val => {
        if (val.id === 5) val.isValid = true
      })
    } else if (temp.length > 12) {
      arr.filter(val => (val.id === 5 ? (val.isValid = false) : val.isValid))
    }
    if (val.length > 0 && isNaN(temp[0])) {
      arr.forEach(val => {
        if (val.id === 2) val.isValid = true
      })
    } else if (!isNaN(temp[0])) {
      arr.forEach(val => {
        if (val.id === 2) val.isValid = false
      })
    }
    if (MINI_URL_REGEX.test(temp)) {
      arr.forEach(val => {
        if (val.id === 3) val.isValid = true
      })
    } else if (!MINI_URL_REGEX.test(temp)) {
      arr.forEach(val => {
        if (val.id === 3) val.isValid = false
      })
    }
  }

  goBackToOnboarding = () => {
    localStorage.removeItem('isOnboarding')
    this.props.history.push({
      pathname: '/orderx/onboarding',
      state: {
        isOnboarding: false,
        fromStoreOrCatalog: true
      }
    })
  }

  checkAvailability = async () => {
    const { miniUrl } = this.state
    let isURLAvailable = false
    let isURLUsed = false

    try {
      this.setState({ checkingAvailability: true })
      const res = await checkURLAvailability(this.pmsClient, miniUrl)

      if (res && res.data && res.data.isCodeUnique.success) {
        isURLAvailable = true
      }
      if (res && res.data && !res.data.isCodeUnique.success) {
        isURLUsed = true
      }
      this.setState({
        checkingAvailability: false,
        isURLAvailable,
        isURLUsed,
        isError: false
      })
    } catch (error) {
      this.setState({
        isURLAvailable: false,
        isURLUsed: false
      })
    }
  }

  saveMiniUrl = async () => {
    const { miniUrl } = this.state
    const { id } = getOrgDetails()

    this.setState({ savingData: true })
    try {
      const saveMiniUrlInput = {
        id,
        code: miniUrl
      }
      const updateRes = await updatePMSOrganization(this.pmsClient, saveMiniUrlInput)

      if (updateRes && updateRes.data && updateRes.data.updateOrganization) {
        setOrgDetails(updateRes.data.updateOrganization.organization)
        this.goBackToOnboarding()
      }
      this.setState({ savingData: false })
    } catch (err) {
      this.setState({ savingData: false })
      console.log('Save MiniApp Url  error', err)
    }
  }

  errMsg = () => {
    const { isValidURL, isError } = this.state

    if (isValidURL && !isError) {
      this.setState({
        isError: true
      })
    }
  }

  render() {
    const { isURLAvailable, isURLUsed, savingData, checkingAvailability, miniUrl, isValidURL, isError } = this.state
    const isValid = arr.filter(val => val.isValid === false)

    if (isValid) {
      this.errMsg()
    }
    const env = process.env.REACT_APP_SENTRY_ENV

    return (
      <Container>
        <Header>
          <BackButton onClick={() => this.goBackToOnboarding()}>
            <Icon
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                color: COLORS.NORMAL_TEXT,
                padding: '0px 3% 0 1%'
              }}
              type="arrow-left"
            />
            <Title level="h2">
              <IntlMessages id="onboarding.createMini" />
            </Title>
          </BackButton>
        </Header>
        <CardBlock>
          <Card>
            <Title level="h5">
              <IntlMessages id="onboarding.createUniqueUrl" />
            </Title>
            <Text level={'body-2'}>
              <IntlMessages id="onboarding.createMiniInfo" />
            </Text>
            <LinkBoxStyle>
              <Text level={'body-2'}>
                <IntlMessages id="https://" />
              </Text>
              <InputBox>
                <Tooltip
                  placement={'bottomLeft'}
                  title={<TooltipComp intl={this.props.intl} />}
                  overlayClassName="custom-tooltip">
                  <Input
                    placeholder={intlAlertMessage({
                      id: 'tamak',
                      intl: this.props.intl
                    })}
                    onChange={e => this.onChangeHandler(e)}
                    value={miniUrl}
                  />
                  {isError && (
                    <Text level="caption" style={{ color: COLORS.ERROR }}>
                      <IntlMessages id="onbaording.miniUrl.errMsg" />
                    </Text>
                  )}
                </Tooltip>
              </InputBox>
              <Text level={'body-2'}>{PWA_DOMAIN_NAME[env]}</Text>
              <BtnStyle>
                <Button loading={checkingAvailability} ghost={false} onClick={() => this.checkAvailability()} disabled={!(isValid.length === 0 && !isValidURL)} size="default" type="secondary" style={{ marginBottom: 0 }}>
                  <IntlMessages id="onboarding.checkAvailability" />
                </Button>
              </BtnStyle>
            </LinkBoxStyle>
            {isURLAvailable && (
              <Text level={'overline'} style={{ color: COLORS.SUCCESS }}>
                <IntlMessages id="onboarding.availableURL" />
              </Text>
            )}
            {isURLUsed && (
              <div>
                <Text level={'overline'} style={{ color: COLORS.ERROR_DARK }}>
                  <IntlMessages id="sorry" />, {miniUrl}{' '}
                </Text>
                <Text level={'overline'} style={{ color: COLORS.ERROR_DARK }}>
                  <IntlMessages id="onboarding.usedURL" />
                </Text>
              </div>
            )}
            <div>
              <Icon
                type="exclamation-circle"
                theme="filled"
                style={{
                  color: COLORS.ALERT,
                  paddingRight: '8px',
                  paddingTop: '5px'
                }}
              />
              <Text level={'caption'}>
                <IntlMessages id="onboarding.nameCannotChangeLater" />
              </Text>
            </div>
          </Card>
          <SubmitButtonStyle>
            <Button
              ghost={false}
              disabled={!isURLAvailable}
              onClick={() => {
                this.saveMiniUrl()
              }}
              size="default"
              type="primary"
              loading={savingData}>
              <IntlMessages id="button.submit" />
            </Button>
          </SubmitButtonStyle>
        </CardBlock>
        <ImageBlock>
          <img src={IMAGES.sample_restaurant_img} alt="Pwa app" />
        </ImageBlock>
      </Container>
    )
  }
}
export default injectIntl(MiniUrlPage)
