import { Button as AntButton } from 'antd'
import React from 'react'
import styled from 'styled-components'
import theme from 'styled-theming'
import { COLORS } from 'Utils/constants'
// Import PropTypes from 'prop-types';

export interface ButtonProps {
  /**
   * Can be set to primary, secondary, link, text, default, danger˙
   */
  type?: 'primary' | 'danger' | 'link' | 'default' | 'secondary' | 'dark'
  /**
   * Disabled state of button
   */
  disabled?: boolean
  /**
   * Set the loading status of button
   */
  loading?: boolean
  /**
   * Set the icon of button
   */
  icon?: string
  /**
   * How large should the button be?
   */
  size?: 'small' | 'default' | 'large'
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * Make background transparent and invert text and border colors
   */
  ghost?: boolean
  /**
   * Apply custom css
   */
  style?: object
  /**
   * The default behavior of the button
   */
  htmlType?: 'submit' | 'reset' | 'button'
}

const backgroundColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.PRIMARY },
  danger: { light: COLORS.ERROR },
  default: { light: COLORS.BG_WHITE },
  link: { light: COLORS.TRANSPARENT },
  dark: { light: COLORS.BG_WHITE }
})
const borderColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.PRIMARY },
  danger: { light: COLORS.ERROR },
  default: { light: COLORS.BORDER },
  link: { light: COLORS.TRANSPARENT },
  dark: { light: COLORS.DARK_TEXT }
})
const hoverTextColor = theme.variants('mode', 'type', {
  /*
   * Primary: { light: COLORS.BG_WHITE },
   * danger: { light: COLORS.BG_WHITE },
   * default: { light: COLORS.PRIMARY },
   * link: {light: COLORS.PRIMARY },
   */
  dark: { light: COLORS.PRIMARY }
})
const hoverBgColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.PRIMARY_DARK },
  danger: { light: COLORS.ERROR_DARK },
  default: { light: COLORS.BG_WHITE }
})
const hoverBorderColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.PRIMARY },
  danger: { light: COLORS.ERROR },
  default: { light: COLORS.PRIMARY },
  dark: { light: COLORS.PRIMARY }
})
const textColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.BG_WHITE },
  secondary: { light: COLORS.PRIMARY },
  danger: { light: COLORS.BG_WHITE },
  default: { light: COLORS.GRAY_TEXT },
  link: { light: COLORS.PRIMARY },
  dark: { light: COLORS.DARK_TEXT }
})
const disableBgColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.DISABLED },
  danger: { light: COLORS.DISABLED },
  default: { light: COLORS.DISABLED },
  link: { light: COLORS.TRANSPARENT }
})
const disableBorderColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.BORDER },
  danger: { light: COLORS.BORDER },
  default: { light: COLORS.BORDER },
  link: { light: COLORS.TRANSPARENT }
})
const disableTextColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.GRAY_TEXT },
  danger: { light: COLORS.GRAY_TEXT },
  default: { light: COLORS.GRAY_TEXT },
  link: { light: COLORS.GRAY_TEXT }
})
const fontSize = theme.variants('mode', 'size', {
  small: { light: '13px' },
  large: { light: '16px' }
})
const buttonHeight = theme.variants('mode', 'size', {
  small: { light: '26px' },
  large: { light: '45px' }
})
const buttonPadding = theme.variants('mode', 'size', {
  small: { padding: '0 4px' },
  default: { light: '0 8px' },
  large: { light: '0 12px' }
})
const secondaryBgColor = theme('mode', {
  light: COLORS.PRIMARY_LIGHT
})
const secondaryHoverBgColor = theme('mode', {
  light: COLORS.PRIMARY_SHADE
})
const secondaryTextColor = theme('mode', {
  light: COLORS.PRIMARY
})
const StyledButton = styled(AntButton)`
    &.ant-btn {
      height: 35px;
      padding: ${buttonPadding};
      border-radius: 4px;
    }
    &.ant-btn > .ant-btn-loading-icon .anticon {
      padding-right: 8px;
    } 

    &.ant-btn-primary {
      background-color: ${backgroundColor};
      color: ${textColor};
      border-color: ${borderColor};
      &:hover {
        background-color: ${hoverBgColor};
        border-color: ${hoverBorderColor};
      }
      &:disabled {
        color: ${disableTextColor};
        background-color: ${disableBgColor};
        border-color: ${disableBorderColor};
      }
    }

    &.ant-btn-primary.btn-secondary {
      background-color: ${secondaryBgColor};
      color: ${secondaryTextColor};
      border-color: ${secondaryBgColor};
      &:hover {
        background-color: ${secondaryHoverBgColor};
        border-color: ${secondaryHoverBgColor};
      }
      &:disabled {
        color: ${disableTextColor};
        background-color: ${disableBgColor};
        border-color: ${disableBorderColor};
      }
    }

    &.ant-btn-danger {
      background-color: ${backgroundColor};
      color: ${textColor};
      border-color: ${borderColor};
      &:hover {
        background-color: ${hoverBgColor};
        border-color: ${hoverBorderColor};
      }
      &:disabled {
        color: ${disableTextColor};
        background-color: ${disableBgColor};
        border-color: ${disableBorderColor};
      }
    }

    &.ant-btn-default {
      border-color: ${borderColor};
      color: ${textColor};
      &:hover {
        border-color: ${hoverBorderColor};
      }
      &:disabled {
        color: ${disableTextColor};
        background-color: ${disableBgColor};
        border-color: ${disableBorderColor};
      }
    }

    &.ant-btn-link {
      border-color: ${borderColor};
      color: ${textColor};
      &:hover {
        color: ${textColor};
        span {
          text-decoration: underline;
        }
      }
      &:disabled {
        color: ${disableTextColor};
        background-color: ${disableBgColor};
        border-color: ${disableBorderColor};
      }
    }

    &.ant-btn-dark {
      border-color: ${borderColor};
      color: ${textColor};
      &:hover {
        color: ${hoverTextColor};
        border-color: ${hoverBorderColor};
      }
      &:disabled {
        color: ${disableTextColor};
        background-color: ${disableBgColor};
        border-color: ${disableBorderColor};
      }
    }

    &.ant-btn-background-ghost.ant-btn-link {
      &:disabled {
        border-color: ${disableBorderColor};
      }
    }

    &.ant-btn-sm {
      font-size: ${fontSize};
      height: ${buttonHeight};
    }
    &.ant-btn-lg {
      font-size: ${fontSize};
      height: ${buttonHeight};
    }

    &.ant-btn-background-ghost.ant-btn-primary {
      color: ${COLORS.PRIMARY};
      &:hover {
        color: ${COLORS.PRIMARY_DARK};
        border-color: ${COLORS.PRIMARY_DARK};
        background-color: ${COLORS.PRIMARY_LIGHT} !important;
      }
      &:focus {
        color: ${COLORS.PRIMARY_DARK};
        border-color: ${COLORS.PRIMARY_DARK};
      }
      &:active {
        color: ${COLORS.PRIMARY_DARK};
        border-color: ${COLORS.PRIMARY_DARK};
      }
    }

    &.ant-btn-background-ghost.ant-btn-danger {
      color: ${COLORS.ERROR};
      &:hover {
        color: ${COLORS.ERROR_DARK};
        border-color: ${COLORS.ERROR_DARK};
      }
      &:focus {
        color: ${COLORS.ERROR_DARK};
        border-color: ${COLORS.ERROR_DARK};
      }
      &:active {
        color: ${COLORS.ERROR_DARK};
        border-color: ${COLORS.ERROR_DARK};
      }
      &:disabled {
        color: ${disableTextColor};
        background-color: ${disableBgColor};
        border-color: ${disableBorderColor};
      }
    }
  `

/**
 * Peppo Button to trigger an operation
 */
export const Button: React.FC<ButtonProps> = ({ type = 'default', disabled = false, size = 'default', ghost = false, loading = false, htmlType = 'button', ...props }) => {
  if (type === 'secondary') {
    return (
      <StyledButton type={'primary'} className={'btn-secondary'} disabled={disabled} size={size} loading={loading} htmlType={htmlType} {...props}>
        {props.children}
      </StyledButton>
    )
  }

  return (
    <StyledButton type={type} disabled={disabled} size={size} ghost={ghost} loading={loading} htmlType={htmlType} {...props}>
      {props.children}
    </StyledButton>
  )
}
