import './index.css'

import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Col, message, Row, Switch, Table } from 'antd'
import { Button, IntlMessages } from 'components'
import { History } from 'history'
import * as jwt from 'jsonwebtoken'
import React from 'react'
import { injectIntl } from 'react-intl'
import { getAllChannels, getAllStores, onToggleStore } from 'services'
import { getViewType, intlAlertMessage } from 'Utils'
import { CONSOLE_STORE_STATUS, VIEW_TYPES } from 'Utils/constants'

import { HeaderText } from '../../styles'

interface StoreListProps extends ApolloProviderProps<any> {
  history: History
  intl: any
}
interface StoreListState {
  dataSource: any
  allStoresFromApi: any
  orgId: string
  isFetching: boolean
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '90%',
    render: storeActions => storeActions
  },
  {
    name: 'Status',
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: statusActions => statusActions
  }
]

class StoreList extends React.Component<StoreListProps, StoreListState> {
  processing: boolean

  orgId: string | { [key: string]: any }

  channels: any

  isBMSDealsOnlyView: boolean

  constructor(props: StoreListProps) {
    super(props)
    this.processing = false
    this.channels = []
    this.state = {
      dataSource: [],
      allStoresFromApi: [],
      orgId: '',
      isFetching: false
    }
  }

  componentDidMount() {
    let channels = localStorage.getItem('channels')
    const viewType = getViewType()

    this.isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY

    channels = channels ? JSON.parse(channels) : channels
    if (channels) {
      this.getAllStore()
    } else {
      this.getChannel()
    }
  }

  async getChannel() {
    this.setState({ isFetching: true })
    try {
      const response = await getAllChannels(this.props.client)

      this.channels = response.data.channels.data
      localStorage.setItem('channels', JSON.stringify(this.channels))
      this.getAllStore()
    } catch (err) {
      this.setState({ isFetching: false })
      console.error('Get channels error', err)
    }
  }

  formStatusAction = (store, storeIndex) => {
    return <Switch title={store.enable ? CONSOLE_STORE_STATUS.INACTIVE : CONSOLE_STORE_STATUS.ACTIVE} style={{ minWidth: '70px' }} checkedChildren={CONSOLE_STORE_STATUS.ACTIVE} unCheckedChildren={CONSOLE_STORE_STATUS.INACTIVE} checked={store.enable} onChange={this.processStoreEnableDisable.bind(this, store, storeIndex)} />
  }

  formStoreAction = store => {
    return (
      <div
        className="header"
        onClick={(event: any) => {
          this.props.history.push(`/orderx/store/${store.id}/edit`)
        }}>
        {store.name}
      </div>
    )
  }

  processStoreEnableDisable = async (store, storeIndex) => {
    if (this.processing) return
    this.processing = true
    const storeEnableDisableDetailsInput = {
      storeId: store.id,
      organizationId: this.orgId
    }
    const { dataSource } = this.state
    const prevStoreDataSource = dataSource[storeIndex]
    const updatedStore = { ...store, enable: !store.enable }
    const statusActions = this.formStatusAction
    
    (updatedStore, storeIndex)
    const storeActions = this.formStoreAction(updatedStore)
    const updatedStoreDataSource = {
      key: updatedStore.id,
      name: storeActions,
      address: updatedStore.addressLine1 === null ? '' : `${updatedStore.addressLine1},${updatedStore.addressLine2}`,
      email: updatedStore.email,
      customerCare: '',
      status: statusActions
    }

    dataSource.splice(storeIndex, 1, updatedStoreDataSource)
    this.setState({ dataSource: [...dataSource] })
    message.loading(
      intlAlertMessage({ id: 'menu.saving', intl: this.props.intl }),
      2
    )

    try {
      await onToggleStore(
        this.props.client,
        storeEnableDisableDetailsInput,
        store.enable
      )
      this.processing = false
    } catch (err) {
      this.processing = false
      dataSource.splice(storeIndex, 1, prevStoreDataSource)
      this.setState({ dataSource: [...dataSource] })
      console.warn('Store status change error:', err)
    }
  }

  async getAllStore() {
    const jwtToken: any = localStorage.getItem('jwt')
    const { org_id }: any = jwt.decode(jwtToken)

    this.orgId = org_id
    this.setState({ isFetching: true })
    try {
      const allStoresResponse = await getAllStores(this.props.client, org_id)
      const dataSource = []
      // eslint-disable-next-line
      allStoresResponse.data.stores.data.map((store, storeIndex) => {
        const statusActions = !this.isBMSDealsOnlyView && this.formStatusAction(store, storeIndex)
        const storeActions = this.formStoreAction(store)

        dataSource.push({
          key: store.id,
          name: storeActions,
          address: store.addressLine1 === null ? '' : `${store.addressLine1},${store.addressLine2}`,
          email: store.email,
          customerCare: '',
          status: statusActions
        })
      })

      this.setState({
        dataSource,
        allStoresFromApi: allStoresResponse.data.stores,
        orgId: org_id,
        isFetching: false
      })
    } catch (err) {
      console.warn('Store List Error:', err)
      this.setState({ isFetching: false })
    }
  }

  render() {
    const { dataSource } = this.state

    return (
      <div className="gx-main-content-wrapper">
        <Row>
          <Col span={21}>
            <div
              className="header"
              onClick={() => {
                this.getAllStore()
              }}>
              <HeaderText type="H1">
                <IntlMessages id="stores" />
              </HeaderText>
            </div>
          </Col>
          {!this.isBMSDealsOnlyView && <Col span={3} className="gx-text-right">
            <Button
            type='primary'
              onClick={() => {
                this.props.history.push('/orderx/store', {})
              }}>
              <IntlMessages id="addStore" />
            </Button>
          </Col>}
        </Row>
        <Table className="orderx-table" style={{ marginTop: '20px' }} dataSource={dataSource} columns={!this.isBMSDealsOnlyView ? columns : [columns[0]]} pagination={false} loading={this.state.isFetching} />
      </div>
    )
  }
}
// @ts-ignore
export default injectIntl(withApollo(StoreList))
