import { Checkbox, Col, Collapse, Row } from 'antd'
import { Button, CheckboxGroup, IntlMessages, Modal, MoveUpDown, TextButton, Title } from 'components'
import React from 'react'
import { intlAlertMessage } from 'Utils'
import { setItemUpDownArrowPosition } from 'Utils/catalogDetailsHelper'
import { COLORS, MENU_TABS, PRODUCT_TYPE_ENUM } from 'Utils/constants'
import { hideItemUpDownArrow } from 'Utils/menuUtils/customizeHelper'

import { getAddonsGroupWithSelection, getSelectedAddonsGroups } from '../../../Utils/menuUtils/commonFunc'
import { ButtonsContainer, StyledCheckbox, StyledCollapse, StyledIcon } from '../../../Utils/menuUtils/styleConstants'
import SelectedAddons from './selectedAddons'

interface IProductAddonsProps {
  onChange: any
  productFormDetails: any
  intl: any
  onProductRelationChange: any
  onAddonGroupsChange: any
  productRelationships: any
  categoriesWithProducts: any
  onTabChange: any
}

interface IProductAddonsState {
  showAddonsGroupPopup: boolean
  addonGroups: any[]
  selectedAddonGroups: any[]
  previousAddonGroups: any[]
}
const { Panel } = Collapse

class ProductAddons extends React.Component<IProductAddonsProps, IProductAddonsState> {
  constructor(props) {
    super(props)
    const { categoriesWithProducts, productRelationships } = props
    const selectedAddons = getAddonsGroupWithSelection(categoriesWithProducts, productRelationships)

    this.state = {
      showAddonsGroupPopup: false,
      addonGroups: selectedAddons,
      selectedAddonGroups: getSelectedAddonsGroups(selectedAddons),
      previousAddonGroups: []
    }
  }

  onAddonChange = (addonGroupId, selectedValues, updateSelectedAddons) => {
    const { addonGroups } = this.state
    const addonGroupIndex = addonGroups.findIndex(addonGroup => addonGroup.value === addonGroupId)

    console.log('selected values', selectedValues)

    const newCutomisationGroups = [...addonGroups]
    const selectedCustomisationGroup = {
      ...newCutomisationGroups[addonGroupIndex]
    }

    selectedCustomisationGroup.customisations = selectedCustomisationGroup.customisations.map(cs => {
      const newCs = { ...cs }

      newCs.selected = selectedValues.includes(cs.value)
      newCs.config.min = selectedValues.length < newCs.config.min ? selectedValues.length : newCs.config.min

      return newCs
    })
    selectedCustomisationGroup.selected = selectedValues.length === selectedCustomisationGroup.customisations.length
    selectedCustomisationGroup.indeterminate = Boolean(selectedValues.length) && selectedValues.length < selectedCustomisationGroup.customisations.length

    newCutomisationGroups[addonGroupIndex] = selectedCustomisationGroup
    this.setState({ addonGroups: newCutomisationGroups }, () => {
      if (updateSelectedAddons) {
        this.addSelectedGroup()
      }
    })
  }

  onMinMaxChange = (addonGroupId, min, max, updateSelectedAddons) => {
    const { addonGroups } = this.state
    const addonGroupIndex = addonGroups.findIndex(addonGroup => addonGroup.value === addonGroupId)

    const minNumber = Number(min)
    const maxNumber = Number(max)
    const newCutomisationGroups = [...addonGroups]
    const selectedCustomisationGroup = {
      ...newCutomisationGroups[addonGroupIndex]
    }

    selectedCustomisationGroup.customisations = selectedCustomisationGroup.customisations.map(cs => {
      const newCs = { ...cs }

      newCs.config.min = isNaN(minNumber) ? '' : minNumber
      newCs.config.max = isNaN(maxNumber) ? '' : maxNumber

      return newCs
    })
    newCutomisationGroups[addonGroupIndex] = selectedCustomisationGroup
    this.setState({ addonGroups: newCutomisationGroups }, () => {
      if (updateSelectedAddons) {
        this.addSelectedGroup()
      }
    })
  }

  onAddonGroupChange = (cgIndex, selectedValues) => {
    const { addonGroups } = this.state
    const newCutomisationGroups = [...addonGroups]
    const selectedCustomisationGroup = { ...newCutomisationGroups[cgIndex] }
    const isSelected = selectedValues.includes(selectedCustomisationGroup.value)
    let customisations = [...selectedCustomisationGroup.customisations]

    customisations = customisations.map(cs => {
      cs.selected = isSelected

      return cs
    })
    newCutomisationGroups[cgIndex] = {
      ...selectedCustomisationGroup,
      selected: isSelected,
      indeterminate: false,
      customisations: [...customisations]
    }
    this.setState({ addonGroups: newCutomisationGroups })
  }

  validateSelection = () => {
    const { addonGroups } = this.state
    let valid = false
    const selectedGroup = addonGroups.filter(ag => ag.selected || ag.indeterminate).length

    if (selectedGroup > 0) {
      valid = true
    }

    return valid
  }

  addSelectedGroup = () => {
    const { addonGroups } = this.state
    const selectedGroup = getSelectedAddonsGroups(addonGroups)

    this.setState({
      showAddonsGroupPopup: false,
      selectedAddonGroups: selectedGroup
    })
    this.props.onProductRelationChange(PRODUCT_TYPE_ENUM.ADDON, selectedGroup)
  }

  launchAddonsGroupPopup = () => {
    const { addonGroups } = this.state
    const previousAddonGroups = addonGroups.map(addonGroup => {
      return { ...addonGroup }
    })

    this.setState({
      previousAddonGroups,
      showAddonsGroupPopup: true
    })
  }

  closeAddonsGroupPopup = () => {
    const { previousAddonGroups } = this.state
    const addonGroups = previousAddonGroups.map(previousAddonGroup => {
      return { ...previousAddonGroup }
    })

    this.setState({
      showAddonsGroupPopup: false,
      addonGroups
    })
  }

  render() {
    const { onTabChange, onAddonGroupsChange } = this.props
    const { showAddonsGroupPopup, addonGroups, selectedAddonGroups } = this.state

    return (
      <>
        <Row className="mt-1">
          <Col span={20}>
            <Title
              level="h5"
              background={true}
              style={{
                color: COLORS.DARK_TEXT,
                marginBottom: 0,
                fontSize: '15px'
              }}>
              <IntlMessages id="addons/Combos" />
            </Title>
          </Col>
          <Col span={4} className="no-pd-left">
            <Button
              type="secondary"
              style={{ width: '100%', height: '41px', marginBottom: 0 }}
              onClick={() => {
                this.launchAddonsGroupPopup()
              }}>
              <IntlMessages id="button.+add" />
            </Button>
          </Col>
        </Row>
        {selectedAddonGroups.length ? <SelectedAddons selectedAddonGroups={selectedAddonGroups} onAddonChange={this.onAddonChange} onMinMaxChange={this.onMinMaxChange} /> : null}
        <Modal title={<IntlMessages id="addons/Combos" />} visible={showAddonsGroupPopup} onClose={() => this.closeAddonsGroupPopup()}>
          <StyledCollapse bordered={false} expandIcon={({ isActive }) => <StyledIcon type="caret-down" rotate={isActive ? 180 : 0} />} expandIconPosition={'right'}>
            {addonGroups.map((cg, index) => (
              <Panel
                key={index}
                style={{ borderColor: '#f7f2f2' }}
                header={
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => {
                      hideItemUpDownArrow(addonGroups)
                      setItemUpDownArrowPosition(index)
                    }}>
                    <div style={{ width: '20px' }}>
                      <MoveUpDown
                        className={`item-row-${index}`}
                        rowIndex={index}
                        array={addonGroups}
                        onReorder={(newAddonGroups, index) => {
                          hideItemUpDownArrow(newAddonGroups)
                          setItemUpDownArrowPosition(index)
                          this.setState({
                            addonGroups: newAddonGroups
                          })
                          onAddonGroupsChange(newAddonGroups)
                        }}
                        style={{
                          marginRight: '10px',
                          position: 'static',
                          height: '41px'
                        }}
                      />
                    </div>
                    <CheckboxGroup
                      onChange={selectedValues => {
                        this.onAddonGroupChange(index, selectedValues)
                      }}
                      name={`${cg.label}-index`}
                      value={cg.selected ? [cg.value] : []}>
                      <Checkbox
                        value={cg.value}
                        indeterminate={cg.indeterminate}
                        onClick={e => {
                          e.stopPropagation()
                        }}>
                        {cg.label}
                      </Checkbox>
                    </CheckboxGroup>
                  </div>
                }>
                <CheckboxGroup
                  name={cg.label}
                  onChange={selectedValues => {
                    this.onAddonChange(cg.value, selectedValues, false)
                  }}
                  value={cg.customisations.filter(cs => cs.selected).map(cus => cus.value)}>
                  {cg.customisations.map((c, cIndex) => (
                    <div key={`customization-${cIndex}`}>
                      <StyledCheckbox
                        key={`customization-cb-${cIndex}`}
                        value={c.value}
                        // Checked={c.selected}
                      >
                        {c.label}
                      </StyledCheckbox>
                    </div>
                  ))}
                </CheckboxGroup>
              </Panel>
            ))}
          </StyledCollapse>
          <ButtonsContainer>
            <TextButton
              label={intlAlertMessage({
                id: 'button.+createNew',
                intl: this.props.intl
              })}
              onClick={() => onTabChange(MENU_TABS.ADDONS)}
            />
            <Button type="primary" onClick={() => this.addSelectedGroup()} style={{ width: '100px', marginBottom: 0 }} disabled={!this.validateSelection()}>
              <IntlMessages id="add" />
            </Button>
          </ButtonsContainer>
        </Modal>
      </>
    )
  }
}
export default ProductAddons
