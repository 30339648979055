import { promotionClient } from 'request'
import { isAdmin } from 'Utils'
import { PROMOTION_MS_ENDPOINTS } from 'Utils/constants'

export const getRedemptions = (dateRange, pagination, storeId, redemption_status) => {

  const adminPayload = {
    payments_start_datetime: dateRange.startDateTime,
    payments_end_datetime: dateRange.endDateTime,
    pagination: {
      items_per_page: pagination.pageSize,
      page_number: pagination.pageNumber,
      sort_by: '-redemption_date'
    },
    redemption_status
  }

  const storeManagerPayload = {
    ...adminPayload,
    store_id: storeId,
  }

  const payload = isAdmin() ? adminPayload : storeManagerPayload

  return promotionClient(PROMOTION_MS_ENDPOINTS.REDEMPTIONS, payload)
}