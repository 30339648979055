import { ApolloClient } from '@apollo/client'
import { Divider, message,Progress, Upload } from 'antd'
import { ClientManager } from 'appApollo/client'
import { Button, Card, IntlMessages, Text, Title } from 'components'
import React from 'react'
import Lottie from 'react-lottie'
import { fetchMenu } from 'services'
import styled from 'styled-components'
import { randomString } from 'Utils'
import { COLORS, LISTENER_TYPE, SAMPLE_EXCEL_SHEET } from 'Utils/constants'
import { defaultOptions,getUploadApiConfig } from 'Utils/onboardingUtils'

import FbNotificationManager from '../../../firebaseInit'
import MenuLinkForm from './MenuLinkForm'

const SUPPORTED_FILE_TYPES = ['xls', 'csv', 'xlsx']

interface MenuLinkCardProps {
  client: any
  intl: any
  fileSystemId: string
  disable: boolean
  onMenuSave: any
  uploadProgress: any
  fetchProgress: any
  pending: boolean
}
interface MenuLinkCardState {
  processingError: boolean
  processing: boolean
  isUploaded: boolean
  fileTypeError: boolean
  uploadingFile: boolean
  uploadPercentage: number
  percent: number
  fetchPercent: number
  uploadTriggered: boolean
  validationError: string
  scrapingError: string
}

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `
const Status = styled.div`
    color: ${props => (props.pending ? COLORS.ERROR : COLORS.PRIMARY)};
    font-size: 13px;
    line-height: 31px;
  `
const StatusContainer = styled.div`
    display: flex;
    justify-content: space-between;
  `

class MenuCard extends React.Component<
  MenuLinkCardProps,
  MenuLinkCardState
> {
  pmsClient: ApolloClient<any>

  progressCounter: any

  fetchProgressCounter: any

  notificationManager: any

  firebaseToken: string

  constructor(props: MenuLinkCardProps) {
    super(props)
    this.pmsClient = ClientManager.getInstance().pms
    this.notificationManager = FbNotificationManager.getInstance()
    this.state = {
      processingError: false,
      processing: false,
      isUploaded: false,
      fileTypeError: false,
      uploadingFile: false,
      uploadPercentage: 0,
      // UploadUrl: '',
      percent: 0,
      fetchPercent: 0,
      uploadTriggered: this.notificationManager.isMenuUploading,
      validationError: '',
      scrapingError: ''
    }
    this.notificationManager
      .getFirebaseNotificationToken()
      .then(firebaseToken => {
        // eslint-disable-next-line no-console
        this.firebaseToken = firebaseToken
        console.log('firebaseToken', firebaseToken)
      })
      .catch(err => {
        console.log(err.message)

        return err
      })
  }

  componentDidMount = () => {
    this.notificationManager.addListener(
      LISTENER_TYPE.MENU_UPLOAD_UPDATE,
      message => {
        this.setState({
          processing: false,
          fetchPercent: 100,
          percent: 100,
          isUploaded: true,
          uploadingFile: false,
          uploadTriggered: false
        })
        this.props.fetchProgress(false)
        this.props.uploadProgress(false)
        this.props.onMenuSave()
        this.notificationManager.updateProp('isMenuUploading', false)
        localStorage.removeItem('isMenuUploading')
      }
    )
    const { uploadTriggered } = this.state

    if (uploadTriggered) this.props.fetchProgress(uploadTriggered)
  }

  onLinkSubmit = async values => {
    const { organizationId } = getUploadApiConfig()
    const { url } = values

    this.setState({
      processing: true,
      validationError: '',
      scrapingError: '',
      fetchPercent: 0
    })
    this.startFetchProgress()
    this.props.fetchProgress(true)
    try {
      const fetchMenuInput = {
        id: organizationId,
        menuLink: url,
        firebaseToken: this.firebaseToken
      }
      const fetchMenuRes = await fetchMenu(this.pmsClient, fetchMenuInput)

      if (fetchMenuRes) {
        // This.props.fetchProgress(false);
        if (
          fetchMenuRes &&
          fetchMenuRes.data &&
          fetchMenuRes.data.fetchAndUploadMenu &&
          fetchMenuRes.data.fetchAndUploadMenu.success === 'true'
        ) {
          /*
           * This.setState({ fetchPercent: 100 });
           * this.props.onMenuSave();
           */
          this.notificationManager.updateProp('isMenuUploading', true)
          this.setState({ uploadTriggered: true })
          this.props.onMenuSave()
        } else {
          const scrapingError = fetchMenuRes.data.fetchAndUploadMenu.message

          this.notificationManager.updateProp('isMenuUploading', false)
          this.props.fetchProgress(false)
          this.setState({
            uploadTriggered: false,
            scrapingError,
            processing: false
          })
        }

        // This.setState({ processing: false });
      }
    } catch (err) {
      this.props.fetchProgress(false)
      this.notificationManager.updateProp('isMenuUploading', false)
      this.setState({
        processing: false
      })
      console.log('Fetch menu error', err)
    }
  }

  onFetchCall = async options => {
    const { file, onSuccess, onError, onProgress } = options
    const { organizationId, token } = getUploadApiConfig()
    const fileTypeList = SUPPORTED_FILE_TYPES
    const ext = file.name
      .split('.')
      .pop()
      .toLowerCase()

    if (fileTypeList && fileTypeList.indexOf(ext) === -1) {
      this.setState({ fileTypeError: true })

      return false
    }

    this.setState({
      fileTypeError: false,
      validationError: '',
      scrapingError: '',
      percent: 0
    })
    const graphQLEndpoint = process.env.REACT_APP_PMS_URL
    const { name } = file
    const lastDot = name.lastIndexOf('.')
    const fileExtension = name.substring(lastDot + 1)
    const fileName = `menu_${randomString(16)}.${fileExtension}`
    const myHeaders = new Headers()

    myHeaders.append('accept', 'application/json')
    myHeaders.append('accept-encoding', 'gzip, deflate, br')
    myHeaders.append('connection', 'keep-alive')
    myHeaders.append('dnt', '1')
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('origin', graphQLEndpoint)
    const body: any = {
      query:
        'mutation uploadMenuFile( $orgId: String, $file: Upload!, $firebaseToken: String) { uploadMenuFile(orgId: $orgId, file: $file, firebaseToken: $firebaseToken ){ success, message}}',
      variables: {
        file: null,
        orgId: organizationId,
        firebaseToken: this.firebaseToken
      }
    }
    const formData = new FormData()

    formData.append('operations', JSON.stringify(body))
    const map = { nFile: ['variables.file'] }

    formData.append('map', JSON.stringify(map))
    formData.append('nFile', file, fileName)
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData
    }

    onProgress('ok')
    this.setState({ uploadingFile: true })
    this.startProgress()
    this.props.uploadProgress(true)
    fetch(graphQLEndpoint, requestOptions)
      .then(response => response.text())
      .then(result => {
        const value = JSON.parse(result)

        /*
         * This.setState({ percent: 100 });
         * this.props.uploadProgress(false);
         */
        if (value.data.uploadMenuFile.success === 'true') {
          /*
           * This.props.onMenuSave();
           * this.setState({ percent: 100 });
           */
          this.notificationManager.updateProp('isMenuUploading', true)
          this.setState({ uploadTriggered: true })
          message.info(value.data.uploadMenuFile.message)
          this.props.onMenuSave()
        } else {
          const validationError = value.data.uploadMenuFile.message

          this.props.uploadProgress(false)
          this.notificationManager.updateProp('isMenuUploading', false)
          this.setState({
            uploadTriggered: false,
            uploadingFile: false,
            validationError
          })
        }
        onSuccess('ok')
      })
      .catch(error => {
        console.log('error', error)
        this.props.uploadProgress(false)
        this.notificationManager.updateProp('isMenuUploading', false)
        this.setState({
          uploadingFile: false
        })
        onError('ok')
      })
  }

  onChange = info => {
    const fileTypeList = SUPPORTED_FILE_TYPES
    const ext = info.file.name
      .split('.')
      .pop()
      .toLowerCase()

    if (fileTypeList && fileTypeList.indexOf(ext) !== -1) {
      const files = info.fileList.length ? [info.file] : []

      // This.setState({ fileList: files });
      if (!files.length) {
        this.setState({ isUploaded: false })
      }
    }
  }

  startProgress = () => {
    const { percent } = this.state
    const minutes = 3
    const sec = minutes * 60
    const incrementPercent = 100 / sec

    if (percent <= 100) {
      this.progressCounter = setInterval(() => {
        this.setState(prevState => {
          const updatedPercent = prevState.percent + incrementPercent

          if (updatedPercent >= 100) {
            clearInterval(this.progressCounter)
          }

          return { percent: updatedPercent }
        })
      }, 1000)
    }
  }

  startFetchProgress = () => {
    const { fetchPercent } = this.state
    const minutes = 3
    const sec = minutes * 60
    const incrementPercent = 100 / sec

    if (fetchPercent <= 100) {
      this.fetchProgressCounter = setInterval(() => {
        this.setState(prevState => {
          const updatedPercent = prevState.fetchPercent + incrementPercent

          if (updatedPercent >= 100) {
            clearInterval(this.fetchProgressCounter)
          }

          return { fetchPercent: updatedPercent }
        })
      }, 1000)
    }
  }

  render() {
    const { intl, disable, pending } = this.props
    const {
      processingError,
      processing,
      uploadingFile,
      percent,
      fileTypeError,
      fetchPercent,
      uploadTriggered,
      validationError,
      scrapingError
    } = this.state

    return (
      <Card>
        <StatusContainer>
          <Title level={'h3'}>
            <IntlMessages id={'onboarding.menuLinkCard.title'} />
            <span style={{ marginLeft: '3px' }}>*</span>
          </Title>
          <Status>
            <Text
              level="caption"
              style={{
                color: pending ? COLORS.ERROR : COLORS.PRIMARY,
                fontSize: 11
              }}>
              <StatusContainer>
                {pending ? (
                  <span>
                    *<IntlMessages id={'pending'} />
                  </span>
                ) : (
                  <IntlMessages id={'completed'} />
                )}
                {!pending && (
                  <Lottie
                    options={defaultOptions}
                    height={31}
                    width={31}
                    style={{ marginLeft: 8 }}
                  />
                )}
              </StatusContainer>
            </Text>
          </Status>
        </StatusContainer>
        <Text level="body-2">
          <IntlMessages id="onboarding.menuLinkCard.text.pleaseAddLink" />
        </Text>
        <MenuLinkForm
          intl={intl}
          processingError={processingError}
          onLinkChange={() =>
            this.setState({ processingError: false, scrapingError: '' })
          }
          onSubmit={values => this.onLinkSubmit(values)}
          processing={processing}
          disable={disable || uploadTriggered}
        />
        {processing && (
          <Progress
            percent={Math.floor(fetchPercent)}
            status={'active'}
            strokeColor={COLORS.SUCCESS}
          />
        )}
        {scrapingError && scrapingError.length && (
          <Text level="caption" style={{ color: COLORS.ERROR }}>
            {scrapingError}
          </Text>
        )}
        <Divider>
          {' '}
          <Text level="overline">
            <IntlMessages id="onboarding.menuLinkCard.text.or" />
          </Text>
        </Divider>
        <Title level="h5" style={{ color: COLORS.DARK_TEXT }}>
          <IntlMessages id={'onboarding.menuLinkCard.secondary.title'} />
        </Title>
        <StyledRow>
          <div style={{ width: '50%' }}>
            <Text level="body-2">
              <IntlMessages id={'onboarding.menuLinkCard.text.fillYourMenu'} />
            </Text>
            <a
              href={SAMPLE_EXCEL_SHEET}
              target="_blank"
              rel="noopener noreferrer">
              <Button type="link" style={{ marginBottom: 0 }}>
                {' '}
                <IntlMessages
                  id={'onboarding.menuLinkCard.link.download'}
                />{' '}
              </Button>
            </a>
          </div>
          <div>
            <Upload
              onChange={this.onChange}
              customRequest={this.onFetchCall}
              name="file"
              fileList={[]}
              accept=".xls, .xlsx, .csv">
              <Button
                type="secondary"
                style={{ marginBottom: 0 }}
                loading={uploadingFile}
                disabled={disable || uploadTriggered}>
                {' '}
                <IntlMessages id={'button.uploadMenu'} />{' '}
              </Button>
            </Upload>
          </div>
        </StyledRow>
        {uploadingFile && (
          <Progress
            percent={Math.floor(percent)}
            status={'active'}
            strokeColor={COLORS.SUCCESS}
          />
        )}
        {fileTypeError && (
          <Text level="caption" style={{ color: COLORS.ERROR }}>
            <IntlMessages id={'error.onboarding.menuLinkCard.fileTypeError'} />
          </Text>
        )}
        {validationError && validationError.length && (
          <Text level="caption" style={{ color: COLORS.ERROR }}>
            {validationError}
          </Text>
        )}
      </Card>
    )
  }
}

export default MenuCard
