import { Card, ImageUpload, IntlMessages, Text, Title } from 'components'
import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'
import { defaultOptions,getUploadApiConfig } from 'Utils/onboardingUtils'

interface BusinessLogoCardProps {
  fileSystemId: string
  logoUrl: string
  onLogoSave: any
  pending: boolean
}
interface BusinessLogoCardState {
  fileTypeError: boolean
  fileSizeError: boolean
  fileDimensionError: boolean
  isImgUpload: boolean
}

const CardBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `
const ContentBlock = styled.div`
    width: 83%;
  `
const ErrorBlock = styled.div`
    bottom: 0;
    position: absolute;
  `
const Status = styled.div`
    color: ${props => (props.pending ? COLORS.ERROR : COLORS.PRIMARY)};
    font-size: 13px;
    line-height: 31px;
  `
const StatusContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  `

class BusinessLogoCard extends React.Component<BusinessLogoCardProps, BusinessLogoCardState> {
  constructor(props: BusinessLogoCardProps) {
    super(props)
    this.state = {
      fileTypeError: false,
      fileSizeError: false,
      fileDimensionError: false,
      isImgUpload: false
    }
  }

  resetErrors = () => {
    this.setState({
      fileTypeError: false,
      fileSizeError: false,
      fileDimensionError: false
    })
  }

  render() {
    const { fileTypeError, fileSizeError, fileDimensionError } = this.state
    const { organizationId, token } = getUploadApiConfig()
    const { fileSystemId, logoUrl, onLogoSave, pending } = this.props

    return (
      <Card>
        <CardBlock>
          <ImageUpload
            allowedFileSize={5}
            onFileTypeError={() => {
              this.resetErrors()
              this.setState({
                fileTypeError: true
              })
            }}
            onSizeError={() => {
              this.resetErrors()
              this.setState({
                fileSizeError: true
              })
            }}
            onDimensionError={() => {
              this.resetErrors()
              this.setState({
                fileDimensionError: true
              })
            }}
            onUpload={imageUrl => {
              this.resetErrors()
              onLogoSave(imageUrl)
            }}
            validateDimension={true}
            uploadApiConfig={{
              organizationId,
              authToken: token,
              graphqlUrl: process.env.REACT_APP_WCORE_URL,
              systemId: fileSystemId
            }}
            previewUrl={logoUrl}
            key={logoUrl}
          />
          <ContentBlock>
            <StatusContainer>
              <Title level={'h3'}>
                <IntlMessages id="onboarding.businessLogo" />
                <span style={{ marginLeft: '3px' }}>*</span>
              </Title>
              <Status>
                <Text
                  level="caption"
                  style={{
                    color: pending ? COLORS.ERROR : COLORS.PRIMARY,
                    fontSize: 11
                  }}>
                  <StatusContainer>
                    {pending ? (
                      <span>
                        *<IntlMessages id={'pending'} />
                      </span>
                    ) : (
                      <IntlMessages id={'completed'} />
                    )}
                    {!pending && <Lottie options={defaultOptions} height={31} width={31} style={{ marginLeft: 8 }} />}
                  </StatusContainer>
                </Text>
              </Status>
            </StatusContainer>
            <Text level={'body-2'}>{logoUrl ? <IntlMessages id="onboarding.businessLogoSuccessInfo" /> : <IntlMessages id="onboarding.businessLogoInfo" />}</Text>
            <ErrorBlock>
              {fileTypeError && (
                <Text level="caption" style={{ color: COLORS.ERROR }}>
                  <IntlMessages id="error.onboarding.fileTypeError" />
                </Text>
              )}
              {fileSizeError && (
                <Text level="caption" style={{ color: COLORS.ERROR }}>
                  <IntlMessages id="error.onboarding.fileSizeError" />
                </Text>
              )}
              {fileDimensionError && (
                <Text level="caption" style={{ color: COLORS.ERROR }}>
                  <IntlMessages id="error.onboarding.logo.dimensionError" />
                </Text>
              )}
            </ErrorBlock>
          </ContentBlock>
        </CardBlock>
      </Card>
    )
  }
}

export default BusinessLogoCard
