import { message,Spin } from 'antd'
import { ICONS } from 'assets'
import { Text } from 'components'
import jwt from 'jsonwebtoken'
import { getOrderStatusColor,intlAlertMessage } from 'Utils'
import { COLORS, CONSOLE_ORDER_STATUS, ORDER_STATUS, ORDER_TYPE } from 'Utils/constants'

export const columnHeaders = [
  {
    name: 'time',
    title: 'Time',
    key: 'time',
    dataIndex: 'time',
    width: '8%',
    render: (time,orderType)  => {      
      return <span>
        {orderType. orderType === ORDER_TYPE.MOBILE_PICKUP &&<img style={{ marginLeft: '-30px', paddingRight: '3px', height:'28px' }} src={ICONS.selfPickupIcon}/>}
        {time.split(' ')[0]} <span style={{ fontSize: '9px' }}>{time.split(' ')[1]}</span>
      </span>
    }
  },
  {
    name: 'order id',
    title: 'Order ID',
    key: 'orderId',
    dataIndex: 'orderId',
    width: '15%'
  },
  {
    name: 'customerDetails',
    title: 'Customer Details',
    key: 'customerDetails',
    dataIndex: 'customerDetails',
    width: '17%',
    render: person => (
      <div>
        <div>{person?.name || ''}</div>
        {person?.phoneNumber && <div>Ph: 0{person?.phoneNumber}</div>}
      </div>
    )
  },
  {
    name: 'assignedPerson',
    title: 'Assigned Delivery Person',
    key: 'assignedPerson',
    dataIndex: 'assignedPerson',
    width: '17%',
    render: person => (
      <div>
        <div>{person && person.name ? person.name : person}</div>
        {person && person.phone && <div>Ph: 0{person.phone}</div>}
      </div>
    )
  },
  {
    name: 'itemCount',
    title: 'No. of Items',
    key: 'itemCount',
    dataIndex: 'itemCount',
    width: '7%'
  },
  {
    name: 'Bill',
    title: 'Bill Amount',
    key: 'Bill',
    dataIndex: 'Bill',
    width: '10%',
    render: price => (
      <span>
        {'\u20B9'}
        {'  '}
        {parseFloat(price).toFixed(2)}
      </span>
    )
  },
  {
    name: 'Status',
    title: 'Order Status',
    key: 'Status',
    dataIndex: 'Status',
    width: '14%',
    render: status => {
      const statusColor = getOrderStatusColor(status)

      return status === 'loading' ? (
        <Spin size="small" />
      ) : (
        <span className="circle" style={{ color: statusColor }}>
          {CONSOLE_ORDER_STATUS[status]}
        </span>
      )
    }
  },
  {
    name: 'Action',
    title: 'Action',
    key: 'Action',
    dataIndex: 'Action',
    render: actions => actions
  }
]
export const getAllOrdersInput = (pageNumber, pageSize) => {
  const { org_id }: any = jwt.decode(localStorage.getItem('jwt'))

  return {
    orderStatus: [ORDER_STATUS.ORDER_CREATED, ORDER_STATUS.ORDER_DELIVERY_FOUND, ORDER_STATUS.ORDER_SHIPPED, ORDER_STATUS.ORDER_ACCEPTED],
    organizationId: org_id,
    pageOptions: {
      page: pageNumber,
      pageSize
    }
  }
}

export const newOrderColumnHeaders = [
  {
    name: 'itemName',
    title: 'Item',
    key: 'itemName',
    dataIndex: 'itemName',
    width: '50%',
    render: itemDetails => {
      return (
        <>
          <div>{itemDetails.name}</div>
          {itemDetails.addons.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <Text level="caption" style={{ fontSize: '14px' }}>
                Addons:{' '}
              </Text>
              <Text level="body-1" style={{ fontSize: '14px', fontWeight: 'normal' }}>
                {itemDetails.addons.join(' + ')}
              </Text>
            </div>
          )}
        </>
      )
    }
  },
  {
    name: 'quantity',
    title: 'Qty',
    key: 'quantity',
    dataIndex: 'quantity',
    width: '20%'
  },
  {
    name: 'price',
    title: 'Price',
    key: 'price',
    dataIndex: 'price',
    width: '30%'
  }
]

export const setSuccessMessage = (id, intl) => {
  message.success(
    intlAlertMessage({
      id,
      intl
    })
  )
}
export const getFormattedPhoneNumber = phoneNumber => {
  if (!phoneNumber) return ''
  let ph

  if (phoneNumber.indexOf('+') !== -1) {
    ph = phoneNumber.split('+')[1]
  } else {
    ph = phoneNumber
  }
  const formattedPh = `${ph.slice(2, 6)} ${ph.slice(6, 9)} ${ph.slice(9, 12)}`

  return formattedPh
}
export const customPanelStyle = {
  background: '#ffffff',
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
}
export const statusColor = viewOrderData => {
  return viewOrderData
    ? getOrderStatusColor(viewOrderData.orderStatus)
    : COLORS.DARK_TEXT
}
