import { Icon } from '@ant-design/compatible'
import { ApolloClient } from '@apollo/client'
import { Input } from 'antd'
import { ClientManager } from 'appApollo/client'
import { IMAGES } from 'assets'
import { Button, Card, IntlMessages, Text, Title } from 'components'
import { History } from 'history'
import React from 'react'
import { updatePMSOrganization } from 'services'
import styled from 'styled-components'
import { getOrgDetails, isAdmin, setOrgDetails } from 'Utils'
import { COLORS, GPAY_BUSINESS, ORG_STATUS } from 'Utils/constants'

interface GpayRegistrationMerchantProps {
  history: History
}
interface GpayRegistrationMerchantState {
  merchantID: string
  savingData: boolean
}

const Container = styled.div`
  margin: 2% 5%;
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  display: flex;
  align-items: baseline;
  align-self: center;
  -webkit-align-self: initial;
  margin: 0 16%;
`
const MainBlock = styled.div`
  margin: 1% 5%;
  width: 58%;
  height: 339px;
  display: flex;
  flex-direction: column;
  align-self: center;
`
const DummyImg = styled.div`
  width: 100%;
  border: 1px solid #e6e6e6;
  opacity: 1;
  margin: 21px 0px 13px 0;
`
const Form = styled.div`
  margin: 13px 0px 7px 0;
  display: flex;
  flex-direction: column;
`

class GpayRegistrationMerchant extends React.Component<
  GpayRegistrationMerchantProps,
  GpayRegistrationMerchantState
> {
  pmsClient: ApolloClient<any>

  constructor(props: GpayRegistrationMerchantProps) {
    super(props)
    this.pmsClient = ClientManager.getInstance().pms
    this.state = {
      merchantID: '',
      savingData: false
    }
  }

  componentDidMount() {
    const organization = getOrgDetails()

    if (
      !isAdmin() ||
      (isAdmin() &&
        organization &&
        organization.onboardingStatus !== ORG_STATUS.INITIATED)
    ) {
      this.props.history.push({ pathname: '/orderx/orders' })
    }
  }

  saveGpayDetails = async () => {
    const { id } = getOrgDetails()
    const { merchantID } = this.state

    try {
      const gPayRegInput = {
        id,
        associations: [
          {
            orgId: 'SPOT',
            type: 'CHANNEL',
            terms: {
              merchantId: merchantID
            }
          }
        ]
      }

      this.setState({ savingData: true })
      const updateRes = await updatePMSOrganization(
        this.pmsClient,
        gPayRegInput
      )

      this.setState({ savingData: false })
      if (updateRes && updateRes.data && updateRes.data.updateOrganization) {
        setOrgDetails(updateRes.data.updateOrganization.organization)
        this.goBackToOnboarding()
      }
    } catch (err) {
      this.setState({ savingData: false })
      console.log('Save Google pay details error', err)
    }
  }

  goBackToOnboarding = () => {
    localStorage.removeItem('isOnboarding')
    this.props.history.replace({
      pathname: '/orderx/onboarding',
      state: {
        isOnboarding: false,
        fromStoreOrCatalog: true
      }
    })
  }

  render() {
    const { savingData } = this.state

    return (
      <Container>
        <Header>
          <div
            onClick={() => this.goBackToOnboarding()}
            style={{
              fontSize: '15px',
              fontWeight: 'bold',
              color: COLORS.NORMAL_TEXT,
              padding: '0px 5% 0 1%',
              cursor: 'pointer'
            }}>
            <Icon
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                color: COLORS.NORMAL_TEXT,
                padding: '0px 5% 0 1%',
                cursor: 'pointer'
              }}
              type="arrow-left"
            />
          </div>
          <Title level="h4">
            <IntlMessages id="googlePayRegistration" />
          </Title>
        </Header>
        <MainBlock>
          <Card>
            <Title level={'h3'}>
              <IntlMessages id="onboarding.addGpayMerchantID" />
            </Title>
            <DummyImg>
              <img
                src={IMAGES.merchant_id_img}
                style={{
                  width: '100%'
                }}
                alt="Google business console"
              />
            </DummyImg>
            <Text level={'caption'}>
              <IntlMessages id="onboarding.addGpayMerchantID.infoMsg" />
            </Text>
            <Button type="link" style={{ marginBottom: 0, lineHeight: '33px' }}>
              <a href={GPAY_BUSINESS} target="_blank" rel="noopener noreferrer">
                <Text level={'caption'} style={{ color: COLORS.PRIMARY }}>
                  <IntlMessages id="onboarding.gpayBusiness" />
                </Text>
              </a>
            </Button>
            <Form>
              <Text level={'caption'}>
                <IntlMessages id="MerchantID" />
              </Text>
              <Input
                placeholder="Enter / Paste Merchant ID here"
                style={{ width: '50%' }}
                value={this.state.merchantID}
                onChange={e =>
                  this.setState({ merchantID: e.target.value.trim() })
                }
              />
            </Form>
          </Card>
          <div>
            <Button
              ghost={false}
              disabled={!this.state.merchantID}
              onClick={() => {
                this.saveGpayDetails()
              }}
              size="default"
              type="primary"
              style={{ padding: '0 5%', margin: '20px 0' }}
              loading={savingData}>
              <IntlMessages id="button.submit" />
            </Button>
          </div>
        </MainBlock>
      </Container>
    )
  }
}
export default GpayRegistrationMerchant
